/** @format */

import React, { useRef } from "react";
import InsTranslate from "../../helpers/InsTranslate";
import { numberFormatted } from "../../helpers/Numbers";
import { calculateBookingPrice } from "../MiscUIComponents/calculateBookingPrice";
import { useSelector } from "react-redux";
import { Box, Dialog, DialogContent, DialogTitle, Modal, Tooltip } from "@mui/material";
import PreviewIcon from "@mui/icons-material/Preview";

const QuickSpecs = ({ bookingData, setBookingData }) => {
  if (!bookingData.bookingFeatures) return;
  const [displayOffer, setDisplayOffer] = React.useState(false);
  //   const relevantBookings = [...bookingData];
  const relevantBookings = [bookingData];
  const numberOfColumns = 13;
  const language = "swedish";
  const translateModules = true;
  const currentVenue = useSelector((state) => state.counter.currentVenue);
  const currency = currentVenue.currency;
  const sum = calculateBookingPrice(bookingData.bookingFeatures, "totalSum");
  const vatAmounts = calculateBookingPrice(bookingData.bookingFeatures, "totalsByVat");
  console.log("vatAmounts", vatAmounts);
  //   const sumIncVat = sum + Object.values(vatAmounts).reduce((acc, curr) => acc + curr, 0);
  const sumIncVat = calculateBookingPrice(bookingData.bookingFeatures, "totalIncVat");

  const specificationsRef = useRef(null);

  function convertStringToNumberOrZero(value) {
    const number = parseFloat(value);
    if (isNaN(value)) {
      return 0;
    }
    return number;
  }

  const boxStyle = {
    // position: "absolute",
    // top: "50%",
    // left: "50%",
    // transform: "translate(-50%, -50%)",
    // width: "800px",
    // // height: "80vh",
    // bgcolor: "background.paper",
    // border: "2px solid #000",
    // boxShadow: 24,
    // p: 4,
    // overflow: "scroll",
  };

  return (
    <span>
      <Tooltip title="Preview booking Specifications">
        <PreviewIcon onClick={() => setDisplayOffer(true)} style={{ marginRight: "15px" }} />
      </Tooltip>
      <Modal open={displayOffer} onClose={() => setDisplayOffer(false)} className="quickSpecs" id="agreement">
        <Box sx={boxStyle} className="quickSpecsBox">
          <div ref={specificationsRef} style={{ borderRadius: "10px", background: "#00ffd444", padding: "10px" }}>
            <table style={{ fontSize: "12px", lineHeight: "15px", width: "100%" }}>
              {/* <th>Date</th>
              <th>Product</th>
              <th>Get in</th>
              <th>Load in</th>
              <th>Sound</th>
              <th>Doors</th>
              <th>Show</th>
              <th>Load out</th>
              <th>Curfew</th>
              <th>Each</th>
              <th>Quantity</th>
              <th>CO2e</th>
              <th style={{ width: "100px" }}>Amount</th> */}
              <thead>
                <th>Date</th>
                <th>Product</th>
                <th>Get in</th>
                <th>Load in</th>
                <th>Sound</th>
                <th>Doors</th>
                <th>Show</th>
                <th>Load out</th>
                <th>Curfew</th>
                <th></th>
                <th>Each</th>
                <th>Quantity</th>
                <th>CO2e</th>
                <th style={{ width: "100px" }}>Amount</th>
              </thead>
              {relevantBookings &&
                relevantBookings.map((item, index) => (
                  <>
                    <tr>
                      <td></td>
                    </tr>
                    <tr>
                      <td></td>
                    </tr>
                    <tr style={{ background: "#00000022", padding: "5px" }}>
                      <td>
                        <b>{item.date}</b>
                      </td>
                      <td>{/* <i>{item.product}</i> */}</td>
                      <td>{item.getIn}</td>
                      <td>{item.loadIn}</td>
                      <td>{item.soundcheck}</td>
                      <td>{item.doors}</td>
                      <td>{item.show}</td>
                      <td>{item.loadOut}</td>
                      <td>{item.curfew}</td>
                      <td></td>
                      <td></td>
                      <td></td>
                      {/* <td>{item.status}</td> */}
                      <td>{item.co2e}</td>
                      {/* <td>{item.price}</td> */}
                      <td></td>
                    </tr>

                    {item.bookingFeatures &&
                      Object.values(item.bookingFeatures).map((feature) => (
                        <>
                          {Object.values(feature.options)
                            // First, filter options that meet the conditions, then sort by price
                            .filter((option) => !option.optional && option.selected && feature.additional === "Free Text")
                            // .sort((a, b) => parseFloat(b.price) - parseFloat(a.price)) // Sort by price in descending order
                            .map((option) => (
                              <>
                                <tr style={{ lineHeight: "15px" }}>
                                  <td></td>
                                  <td style={{ borderBottom: "10px solid transparent" }}></td>
                                </tr>
                                <tr style={{ lineHeight: "15px" }}>
                                  <td style={{ opacity: "0.4" }}>{/* {feature.additional} */}</td>
                                  <td colspan={numberOfColumns - 3}>
                                    {translateModules && language === "english" ? (
                                      <InsTranslate from="sv" to="en" phrase={option.info} />
                                    ) : (
                                      option.info
                                    )}
                                  </td>
                                  <td></td>
                                  <td>{/* {option.climate ? option.climate : "-"} */}</td>
                                  <td>{/* {numberFormatted(convertStringToNumberOrZero(option.price))} */}</td>
                                </tr>
                                <tr style={{ lineHeight: "15px" }}>
                                  <td></td>
                                  <td style={{ borderBottom: "10px solid transparent" }}></td>
                                </tr>
                              </>
                            ))}
                        </>
                      ))}
                    {item.bookingFeatures &&
                      Object.values(item.bookingFeatures).map((feature) => (
                        <>
                          {/* Show rental fee first */}
                          {/* {Object.values(feature.options).map(
                    (option) =>
                      !option.optional &&
                      option.selected &&
                      feature.additional === "Rental Fee" && (
                        <tr style={{ lineHeight: "15px" }}>
                          <td style={{ opacity: "0.4" }}>{feature.additional}</td>
                          <td colspan={numberOfColumns - 3}>
                            {translateModules && language === "english" ? (
                              <InsTranslate from="sv" to="en" phrase={option.option} />
                            ) : (
                              option.option
                            )}

                            {option.info ? `, ${option.info}` : ""}
                            {option.specs ? `, ${option.specs}` : ""}
                          </td>
                          <td>{option.climate ? option.climate : "-"}</td>
                          <td>{numberFormatted(convertStringToNumberOrZero(option.price))}</td>
                        </tr>
                      )
                  )} */}

                          {Object.values(feature.options)
                            // First, filter options that meet the conditions, then sort by price
                            .filter((option) => !option.optional && option.selected && feature.additional === "Rental Fee")
                            .sort((a, b) => parseFloat(b.price) - parseFloat(a.price)) // Sort by price in descending order
                            .map((option) => (
                              <tr style={{ lineHeight: "15px" }}>
                                {/* <td style={{ opacity: "0.4" }}>{feature.additional}</td> */}
                                <td style={{ opacity: "0.4" }}>
                                  {/^\d/.test(feature.additional) ? feature.additional.slice(2) : feature.additional}
                                </td>
                                <td colspan={numberOfColumns - 3}>
                                  {translateModules && language === "english" ? (
                                    <InsTranslate from="sv" to="en" phrase={option.option} />
                                  ) : (
                                    option.option
                                  )}
                                  {option.info ? `, ${option.info}` : ""}
                                  {/* {option.specs ? `, ${option.specs}` : ""} */}
                                </td>
                                <td></td>
                                <td>{option.climate ? option.climate : "·"}</td>
                                <td>{numberFormatted(convertStringToNumberOrZero(option.price))}</td>
                              </tr>
                            ))}
                        </>
                      ))}

                    {item.bookingFeatures &&
                      Object.values(item.bookingFeatures)
                        .sort((a, b) => a.additional.localeCompare(b.additional, "sv")) // Sort by feature.additional
                        .map((feature) => (
                          <>
                            {Object.values(feature.options)
                              .sort((a, b) => a.option.localeCompare(b.option, "sv")) // Sort by option.option
                              .map((option) => (
                                <>
                                  {/* customer opted features */}
                                  {option.customerOpted && option.price && (
                                    <tr style={{ lineHeight: "15px" }}>
                                      {/* <td style={{ opacity: "0.4" }}>{feature.additional}</td> */}
                                      <td style={{ opacity: "0.4" }}>
                                        {" "}
                                        {/^\d/.test(feature.additional) ? feature.additional.slice(2) : feature.additional}
                                      </td>
                                      <td colspan={numberOfColumns - 3}>{option.option}</td>
                                      <td>{option.climate ? option.climate : "·"}</td>
                                      <td>{numberFormatted(convertStringToNumberOrZero(option.price))}</td>
                                    </tr>
                                  )}
                                  {/* Included features with a cost */}
                                  {
                                    // option.quantity > 0 &&
                                    !option.optional &&
                                      option.selected &&
                                      feature.additional !== "Staff" &&
                                      feature.additional !== "Capacity" &&
                                      feature.additional !== "Free Text" &&
                                      feature.additional !== "Rental Fee" && (
                                        // feature.additional !== "Stage" &&
                                        <tr style={{ lineHeight: "15px" }}>
                                          {/* <td style={{ opacity: "0.4" }}>{feature.additional}</td> */}
                                          <td style={{ opacity: "0.4" }}>
                                            {" "}
                                            {/^\d/.test(feature.additional) ? feature.additional.slice(2) : feature.additional}
                                          </td>
                                          <td colspan={numberOfColumns - 4}>
                                            {translateModules && language === "english" ? (
                                              <InsTranslate from="sv" to="en" phrase={option.option} />
                                            ) : (
                                              option.option
                                            )}

                                            {option.info && option.info.length > 1 ? `, ${option.info}` : ""}
                                            {/*  {option.specs ? `, ${option.specs}` : ""} */}
                                          </td>
                                          <td style={{ opacity: "0.6" }}>{option.each}</td>
                                          <td>{option.quantity}</td>
                                          <td>{option.climate ? option.climate : "."}</td>
                                          <td>{numberFormatted(convertStringToNumberOrZero(option.price))}</td>
                                        </tr>
                                      )
                                  }
                                  {/* indluded features */}
                                  {/* <DisplayObject data={option} /> */}
                                  {option.selected && option.optional && feature.additional !== "Staff" && (
                                    <tr style={{ lineHeight: "15px" }}>
                                      <td colspan={numberOfColumns - 1}>
                                        <span style={{ opacity: "0.4" }}>{feature.additional}: </span>
                                        <span>{option.option}</span>
                                        <span>{option.price === 0 || (!option.price && !option.optional && " (Included)")}</span>
                                      </td>
                                      <td>{option.optional ? <span style={{ opacity: "0.4" }}>(optional)</span> : option.price}</td>
                                    </tr>
                                  )}
                                </>
                              ))}
                          </>
                        ))}
                  </>
                ))}

              <tr>
                <td colSpan={numberOfColumns + 1}>
                  <div style={{ width: "100%", borderTop: "0.3px solid #00000044" }}></div>
                </td>
              </tr>
              <tr>
                <td colSpan={numberOfColumns - 1}>Sum</td>
                <td></td>
                <td>
                  <b>{numberFormatted(sum)}</b> {currency}
                </td>
              </tr>
              {Object.keys(vatAmounts).map(
                //Map through the vat amounts
                (obj) => (
                  <tr style={{ lineHeight: "15px" }}>
                    <td></td>
                    <td colSpan={numberOfColumns - 1}>vat({obj}%)</td>
                    <td>{numberFormatted(vatAmounts[obj])}</td>
                  </tr>
                )
              )}
              <tr>
                <td></td>
              </tr>
              <tr>
                <td colSpan={numberOfColumns + 1}>
                  <div style={{ width: "100%", borderTop: "0.3px solid black" }}></div>
                </td>
              </tr>
              <tr style={{ lineHeight: "15px" }}>
                <td colSpan={numberOfColumns - 1}>Total</td>
                <td></td>
                <td style={{ width: "50pt" }}>
                  {numberFormatted(sumIncVat)} {currency}
                </td>
              </tr>
            </table>
          </div>
        </Box>
      </Modal>
    </span>
  );
};

export default QuickSpecs;

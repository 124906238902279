/** @format */

import React, { useEffect, useState } from "react";
import axios from "axios";

const InsTranslate = ({ phrase, from, to }) => {
  const [inputText, setInputText] = useState("");
  const [translatedText, setTranslatedText] = useState("");
  const [loading, setLoading] = useState(false);
  const apiKey = "AIzaSyDamfHQJ-BsgHnfvFrJpuZ2s0opV4Qppts"; // Replace with your actual API key

  const handleTranslate = async () => {
    setLoading(true);

    try {
      const response = await axios.post(`https://translation.googleapis.com/language/translate/v2?key=${apiKey}`, {
        q: phrase,
        target: to, // Target language code (Russian in this case)
        source: from, // Source language code (English)
      });

      const translated = response.data.data.translations[0].translatedText;
      setTranslatedText(translated);
    } catch (error) {
      console.error("Error translating text:", error);
      setTranslatedText("Translation failed");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (phrase) handleTranslate();
  }, [phrase]);

  return <>{translatedText}</>;
};

export default InsTranslate;

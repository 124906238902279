/** @format */

import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
  Tooltip,
} from "@mui/material";
import React, { useEffect } from "react";
import ConfirmDialog from "../../helpers/ConfirmDialog";
import SyncTicksterEvents from "../../features/tickster/SyncTicksterEvents";
import { useSelector } from "react-redux";

const TicketServiceInput = ({ formData, handleChange, handleUpdate }) => {
  const superOwner = useSelector((state) => state.counter.superOwner);
  console.log("formData", formData);

  const handleActivateTicketingService = () => {
    // *********************************************
    // Put script to trigger organizer creation here
    // *********************************************
    // alert(`Skapa ny kund hos biljettbolag i TicketServiceInput.jsx`); // remove this

    handleChange(true, "ticketServiceActive", "value"); //set ticketServiceActive to "true" for venue.
  };

  useEffect(() => {
    handleUpdate("Venues", formData.venueid); //Autosave on ticketServiceActive
  }, [formData.ticketServiceActive]);

  return (
    <Grid container style={{ display: "flex", gap: "15px" }} xs={8}>
      <Grid item xs={12} sm={2}>
        <FormControlLabel
          control={
            <Switch disabled checked={formData["ticketServiceActive"]} onChange={(e) => handleChange(e, "ticketServiceActive", "switch")} />
          }
          label={formData["ticketServiceActive"] ? "Active" : "Inactive"}
        />
      </Grid>
      <Grid item style={{ display: "flex", gap: "15px" }}>
        <FormControl>
          <InputLabel id="demo-simple-select-label">Options</InputLabel>
          <Select
            size="small"
            style={{ width: "200px" }}
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            defaultValue={formData["None"]}
            value={formData["ticketService"]}
            label="Options"
            disabled={formData["ticketServiceActive"]}
            onChange={(e) => {
              handleChange(e, "ticketService");
            }}
            displayEmpty
          >
            <MenuItem value={"Nortic"}>Nortic</MenuItem>
            <MenuItem value={"Tickster"}>Tickster</MenuItem>
            <MenuItem value={"Tixly"}>Tixly</MenuItem>
          </Select>
        </FormControl>
        <Grid item xs={12} sm={3}>
          {!formData["ticketServiceActive"] && (
            <ConfirmDialog
              noButton
              header="Activate ticketing service?"
              message={`You're signing up your general ticketing with ${formData["ticketService"]}`}
              onConfirm={() => handleActivateTicketingService()} //if type is value it overrides other types in handleChange(checkbox, switch, text, number etc finds the value in the event instead)
            >
              <Button variant="outlined">Activate</Button>
            </ConfirmDialog>
          )}
          {formData["ticketServiceActive"] && (
            <ConfirmDialog
              noButton
              header="Deactivate ticketing service. Are you sure?"
              message={`You're about to cancel your general ticketing with ${formData["ticketService"]}`}
              onConfirm={() => handleChange(false, "ticketServiceActive", "value")} //if type is value it overrides other types in handleChange(checkbox, switch, text, number etc finds the value in the event instead)
            >
              <Button variant="outlined" disabled={superOwner ? false : true}>
                Change
              </Button>
            </ConfirmDialog>
          )}
        </Grid>
      </Grid>
      {/* {formData["ticketApiKey"] && ( */}
      {/* Input fields for TICKSTER users */}
      {formData["ticketServiceActive"] && formData["ticketService"] === "Tickster" && (
        <Grid item xs={12} sm={12} style={{ display: "flex", gap: "15px" }}>
          <TextField
            value={formData["ticketApiKey"]}
            size="small"
            label="Ticket API Key"
            onChange={(e) => handleChange(e, "ticketApiKey")}
            type={superOwner ? "text" : "password"}
            disabled={superOwner ? false : true}
          />
          <TextField
            value={formData["ticketVenueId"]}
            size="small"
            label="Venue ID (ticket providers)"
            onChange={(e) => handleChange(e, "ticketVenueId")}
            type={superOwner ? "text" : "password"}
            disabled={superOwner ? false : true}
          />
          <TextField
            value={formData["ticksterUsername"]}
            size="small"
            label="Username (at Tickster)"
            onChange={(e) => handleChange(e, "ticksterUsername")}
            type={superOwner ? "text" : "password"}
            disabled={superOwner ? false : true}
          />
          <TextField
            value={formData["ticksterPassword"]}
            size="small"
            label="Password (at Tickster)"
            onChange={(e) => handleChange(e, "ticksterPassword")}
            type={superOwner ? "text" : "password"}
            disabled={superOwner ? false : true}
          />
        </Grid>
      )}

      {/* Input fields for NORTIC users */}
      {formData["ticketServiceActive"] && formData["ticketService"] === "Nortic" && (
        <Grid item xs={12} sm={12} style={{ display: "flex", gap: "15px" }}>
          <TextField
            value={formData["ticketApiKey"]}
            size="small"
            label="Ticket API Key"
            onChange={(e) => handleChange(e, "ticketApiKey")}
            type={superOwner ? "text" : "password"}
            disabled={superOwner ? false : true}
          />
          <TextField
            value={formData["ticketVenueId"]}
            size="small"
            label="Venue ID (ticket providers)"
            onChange={(e) => handleChange(e, "ticketVenueId")}
            type={superOwner ? "text" : "password"}
            disabled={superOwner ? false : true}
          />
          <TextField
            value={formData["norticUsername"]}
            size="small"
            label="Username (at Tickster)"
            onChange={(e) => handleChange(e, "norticUsername")}
            type={superOwner ? "text" : "password"}
            disabled={superOwner ? false : true}
          />
          <TextField
            value={formData["norticPassword"]}
            size="small"
            label="Password (at Tickster)"
            onChange={(e) => handleChange(e, "norticPassword")}
            type={superOwner ? "text" : "password"}
            disabled={superOwner ? false : true}
          />
        </Grid>
      )}

      {/* Input fields for TIXLY users */}
      {formData["ticketServiceActive"] && formData["ticketService"] === "Tixly" && (
        <Grid item xs={12} sm={12} style={{ display: "flex", gap: "15px" }}>
          <TextField
            value={formData["ticketApiKey"]}
            size="small"
            label="Ticket API Key"
            onChange={(e) => handleChange(e, "ticketApiKey")}
            type={superOwner ? "text" : "password"}
            disabled={superOwner ? false : true}
          />
          <TextField
            value={formData["ticketVenueId"]}
            size="small"
            label="Venue ID (ticket providers)"
            onChange={(e) => handleChange(e, "ticketVenueId")}
            type={superOwner ? "text" : "password"}
            disabled={superOwner ? false : true}
          />
          <TextField
            value={formData["tixlyUsername"]}
            size="small"
            label="Username (at Tixly)"
            onChange={(e) => handleChange(e, "tixlyUsername")}
            type={superOwner ? "text" : "password"}
            disabled={superOwner ? false : true}
          />
          <TextField
            value={formData["tixlyPassword"]}
            size="small"
            label="Password (at Tixly)"
            onChange={(e) => handleChange(e, "tixlyPassword")}
            type={superOwner ? "text" : "password"}
            disabled={superOwner ? false : true}
          />
        </Grid>
      )}

      <Grid item xs={12}>
        <Tooltip title="This sets new bookings to deduct from tickets by default. Switch it off if you generally don't deduct from tickets.">
          <FormControlLabel
            control={<Switch checked={formData["deductFromTickets"]} onChange={(e) => handleChange(e, "deductFromTickets", "switch")} />}
            label="Deduct from tickets by default"
          />
        </Tooltip>
      </Grid>
      <Grid item xs={12}>
        {superOwner && <SyncTicksterEvents />}
      </Grid>
    </Grid>
  );
};

export default TicketServiceInput;

/** @format */

import { createSlice, current } from "@reduxjs/toolkit";
import React from "react";
import { monthsFromNow, today } from "../../helpers/DateManagement";
import { format, addDays, parse, eachDayOfInterval } from "date-fns";

const initialState = {
  count: 0,
  capMin: 50,
  capMax: 8000,
  startDate: monthsFromNow(1),
  endDate: monthsFromNow(4),
  chosenDateRange: [format(new Date(), "yyyy-MM-dd"), format(addDays(new Date(), 6), "yyyy-MM-dd")],
  prefDays: ["Thursday", "Friday", "Saturday"],
  artists: [{ artist: "Dre" }, { artist: "Snoop" }, { artist: "Tupac" }],
  venues: [
    // { venue: "Arenan", capacity: 500 },
    // { venue: "Scenen", capacity: 200 },
    // { venue: "Folkis", capacity: 2000 },
  ],
  bookings: [1, 2, 3],
  currentVenue: {
    venueid: "none",
    // venueid: "FmrY4Dm0mNiIUPxN8aOY",
    capacity: 500,
    bookings: [{ date: "2022", status: "Confirmed" }],
  },
  bookingsForCurrentVenue: [],
  bookingModalOpen: false,
  currentBooking: [{ date: "2022-08-31" }],
  selectedDate: "",
  currentBookingId: "",
  currentEventList: [],
  isNewBooking: true,
  userRole: "none",
  currentUser: [],
  eventsList: [],
  reloadBookings: false,
  availableVenuesAndDates: [],
  avails: [{ available: "", awaiting: "" }],
  averageTicketPrice: 390,
  numberOfEvents: 10,
  currentArtist: {
    artist: "Robyn",
    artistid: "Or0bfnSg4iClULBUakcw",
    artistImage: "https://firebasestorage.googleapis.com/v0/b/test-d…=media&token=c422ef1c-d727-4c64-ab0b-5563f94553c6",
  },
  currentAgent: {
    agent: "Matthew Smith",
    agentid: "testing1234",
    agentEmail: "mdernulf@gmail.com",
    agentPhone: "+46707402114",
    agentCountry: "Sweden",
    agentCity: "Stockholm",
    agentStreetAddress: "Lindvallsplan 3",
    agentZipCode: "16755",
  },
  currentArtistRoster: [
    { artist: "Robyn", artistid: "Or0bfnSg4iClULBUakcw" },
    { artist: "Petter", artistid: "Or0bfnSg4iClULBUakcw" },
    { artist: "Rusiak", artistid: "Or0bfnSg4iClULBUakcw" },
  ],
  // dynamicBtnGroup: <div>dynamicBtnGroup</div>,
  monthDisplayed: "2022-October",
  venueAccess: ["none"],
  ownedVenues: ["none"],
  UIDrawerOpen: false,
  themeMode: "darkTheme",
  currentBookings: [],
  currentEditors: [{ name: "a", phone: "b", email: "c", venueAccess: [1, 2, 3, 4, 5] }],
  currentMonth: "",
  currentYear: "",
  sortVenuesBy: "venueCity",
  timelineIsOpen: true,
  mapIsOpen: false,
  wantedList: [],
  currentArtistBookings: [],
  pendingBookings: [],
  awaitingBookings: [],
  confirmedBookings: [],
  currentProduct: false,
  tmpAYS: [],
  dayNightOrBoth: ["night"],
  currentUserId: "",
  currentSummary: {},
  users: [{}],
  currentTickets: [{}],
  similarBookings: [{}],
  currentStripe: "",
  currentSettings: {
    blTest: true,
    blConfirmed: true,
    blAwaitingArtist: true,
    blPendingRequest: true,
    blUpcoming: true,
    blHistory: false,
    vcMonth: new Date().getMonth(),
    vcYear: new Date().getFullYear(),
  },
  currentAgreements: [],
  currentVenueBookings: [],
  currentTicketSummary: [{}],
  currentPdfDocHTML: "",
  currentAgreement: {},
  startVenue: "",
  currentVersion: "1.090",
  accessibleVenues: [],
  superOwner: false,
};

// function rootReducer(state = initialState, action) {
//   switch (action.type) {
//     case "RESET_STATE":
//       return in itialState;
//     // add more case statements for other actions
//     default:
//       return state;
//   }
// }

export const counterSlice = createSlice({
  name: "counter",
  initialState,
  reducers: {
    increment: (state) => {
      state.count += 1;
    },
    storeReset: (state, action) => {
      return initialState;
    },
    decrement: (state) => {
      state.count -= 1;
    },
    storeCapMax: (state, action) => {
      state.capMax = action.payload;
      // console.log(state.capMax);
    },
    storeCapMin: (state, action) => {
      state.capMin = action.payload;
      // console.log(state.capMin);
    },
    storeStartDate: (state, action) => {
      state.startDate = action.payload;
      // console.log(state.startDate)
    },
    storeEndDate: (state, action) => {
      state.endDate = action.payload;
      // console.log(state.endDate)
    },
    storeChosenDateRange: (state, action) => {
      state.chosenDateRange = action.payload;
      // console.log(state.endDate);
    },
    storePrefDays: (state, action) => {
      state.prefDays = action.payload;
      // console.log(state.prefDays);
    },
    storeVenues: (state, action) => {
      state.venues = action.payload;
      // console.log(state.venues)
    },
    storeCurrentVenue: (state, action) => {
      state.currentVenue = action.payload;
      // alert("currentVenue stored! length: " + state.currentVenue.products.length);

      // console.log("😀 currentVenue-->", state.currentVenue);
      // alert("currentVenue stored!");
    },
    storeBookingsForCurrentVenue: (state, action) => {
      state.bookingsForCurrentVenue = action.payload;
      console.log("😀 bookings currentVenue-->", state.bookingsForCurrentVenue);
    },
    storeBookings: (state, action) => {
      state.bookings = action.payload;
      console.log("😀 Bookings-->", state.bookings);
    },
    storeBookingModalOpen: (state, action) => {
      state.bookingModalOpen = action.payload;
      // console.log(state.bookingModalOpen)
    },
    storeCurrentBooking: (state, action) => {
      state.currentBooking = action.payload;
      console.log(state.currentBooking);
    },
    storeSelectedDate: (state, action) => {
      state.selectedDate = action.payload;
      // console.log(state.selectedDate)
    },
    storeCurrentBookingId: (state, action) => {
      state.currentBookingId = action.payload;
      console.log(state.currentBookingId);
    },
    storeArtists: (state, action) => {
      state.artists = action.payload;
      // console.log(state.artists)
    },
    storeCurrentEventlist: (state, action) => {
      state.currentEventlist = action.payload;
      // console.log(state.currentEventlist)
    },
    storeIsNewBooking: (state, action) => {
      state.isNewBooking = action.payload;
      // console.log(state.isNewBooking)
    },
    storeUserRole: (state, action) => {
      state.userRole = action.payload;
      // console.log(state.userRole)
    },
    storeCurrentUser: (state, action) => {
      state.currentUser = action.payload;
      console.log(state.currentUser);
    },
    storeEventsList: (state, action) => {
      state.eventsList = action.payload;
      // console.log(state.eventsList)
    },
    storeReloadBookings: (state, action) => {
      state.reloadBookings = action.payload;
      // console.log(state.reloadBookings)
    },
    storeAvailableVenuesAndDates: (state, action) => {
      state.availableVenuesAndDates = action.payload;
      console.log(state.availableVenuesAndDates);
    },
    storeAvails: (state, action) => {
      state.avails = action.payload;
    },
    storeAverageTicketPrice: (state, action) => {
      state.averageTicketPrice = action.payload;
      // console.log(state.averageTicketPrice);
    },
    storeNumberOfEvents: (state, action) => {
      state.numberOfEvents = action.payload;
      // console.log(state.numberOfEvents);
    },
    storeCurrentArtist: (state, action) => {
      state.currentArtist = action.payload;
      // console.log(state.currentArtist);
    },
    storeCurrentAgent: (state, action) => {
      state.currentAgent = action.payload;
      // console.log(state.currentAgent);
    },
    storeCurrentArtistRoster: (state, action) => {
      state.currentArtistRoster = action.payload;
      // console.log(state.currentArtistRoster);
    },
    // storeDynamicBtnGroup: (state, action) => {
    //   state.dynamicBtnGroup = action.payload;
    //   console.log(state.dynamicBtnGroup);
    // },
    storeMonthDisplayed: (state, action) => {
      state.monthDisplayed = action.payload;
      // console.log(state.monthDisplayed);
    },
    storeVenueAccess: (state, action) => {
      state.venueAccess = action.payload;
      // console.log(state.venueAccess);
    },
    storeOwnedVenues: (state, action) => {
      state.ownedVenues = action.payload;
      console.log("❎", state.ownedVenues);
    },
    storeUIDrawerOpen: (state, action) => {
      state.UIDrawerOpen = action.payload;
      // console.log(state.UIDrawerOpen);
    },
    storeThemeMode: (state, action) => {
      state.themeMode = action.payload;
      // console.log(state.themeMode);
    },
    storeCurrentBookings: (state, action) => {
      state.currentBookings = action.payload;
      console.log(action.payload);
    },
    storeCurrentEditors: (state, action) => {
      state.currentEditors = action.payload;
      // console.log(state.currentEditors);
    },
    storeCurrentMonth: (state, action) => {
      state.currentMonth = action.payload;
      // console.log(state.currentMonth);
    },
    storeCurrentYear: (state, action) => {
      state.currentYear = action.payload;
      // console.log(state.currentYear);
    },
    storeSortVenuesBy: (state, action) => {
      state.sortVenuesBy = action.payload;
      console.log(state.sortVenuesBy);
    },
    storeTimelineIsOpen: (state, action) => {
      state.timelineIsOpen = action.payload;
      console.log(state.timelineIsOpen);
    },
    storeMapIsOpen: (state, action) => {
      state.mapIsOpen = action.payload;
      console.log(state.mapIsOpen);
    },
    storeWantedList: (state, action) => {
      state.wantedList = action.payload;
      console.log("Wanted List:", state.wantedList);
    },
    storeCurrentArtistBookings: (state, action) => {
      state.currentArtistBookings = action.payload;
      console.log(state.currentArtistBookings);
    },
    storePendingBookings: (state, action) => {
      state.pendingBookings = action.payload;
      console.log(state.pendingBookings);
    },
    storeAwaitingBookings: (state, action) => {
      state.awaitingBookings = action.payload;
      console.log(state.awaitingBookings);
    },
    storeConfirmedBookings: (state, action) => {
      state.confirmedBookings = action.payload;
      console.log(state.confirmedBookings);
    },
    storeCurrentProduct: (state, action) => {
      state.currentProduct = action.payload;
      console.log(state.currentProduct);
    },
    storeTmpAYS: (state, action) => {
      state.tmpAYS = action.payload;
      console.log(state.tmpAYS);
    },
    storeDayNightOrBoth: (state, action) => {
      state.dayNightOrBoth = action.payload;
      console.log(state.dayNightOrBoth);
    },
    storeCurrentUserId: (state, action) => {
      state.currentUserId = action.payload;
      console.log(state.CurrentUserId);
    },
    storeSummary: (state, action) => {
      state.currentSummary = action.payload;
      console.log(state.CurrentSummary);
    },
    storeSummary: (state, action) => {
      state.users = action.payload;
      console.log(state.users);
    },
    storeCurrentTickets: (state, action) => {
      state.currentTickets = action.payload;
      console.log("Current tickets in redux:", state.currentTickets);
      // alert("currentTickets stored!");
    },
    storeSimilarBookings: (state, action) => {
      state.similarBookings = action.payload;
      console.log(state.similarBookings);
    },
    storeCurrentStripe: (state, action) => {
      state.currentStripe = action.payload;
      console.log(state.currentStripe);
    },
    storeCurrentSettings: (state, action) => {
      state.currentSettings = action.payload;
      console.log(state.currentSettings);
    },
    storeCurrentAgreements: (state, action) => {
      state.currentAgreements = action.payload;
      // console.log(state.currentAgreements);
    },
    storeCurrentVenueBookings: (state, action) => {
      state.currentVenueBookings = action.payload;
      console.log("🦊", state.currentVenueBookings);
      console.timeEnd("fetchbookings");
    },
    storeCurrentTicketSummary: (state, action) => {
      state.currentTicketSummary = action.payload;
      console.log("🐸", state.currentTicketSummary);
    },
    storeStartVenue: (state, action) => {
      state.startVenue = action.payload;
      console.log("🐸", state.startVenue);
    },
    storeSuperOwner: (state, action) => {
      state.superOwner = action.payload;
      console.log("🌟", state.superOwner);
    },
    storeAccessibleVenues: (state, action) => {
      state.accessibleVenues = action.payload;
      console.log("🌟", state.accessibleVenues);
    },
    storeCurrentPdfDocHTML: (state, action) => {
      state.currentPdfDocHTML = action.payload;
      console.log("🌟", state.currentPdfDocHTML);
    },
    storeCurrentAgreement: (state, action) => {
      state.currentAgreement = action.payload;
      console.log("🌟", state.currentAgreement);
    },
  },
});

export const {
  increment,
  storeReset,
  decrement,
  storeCapMin,
  storeCapMax,
  storeStartDate,
  storeEndDate,
  storeChosenDateRange,
  storePrefDays,
  storeVenues,
  storeCurrentVenue,
  storeBookingsForCurrentVenue,
  storeBookings,
  storeBookingModalOpen,
  storeCurrentBooking,
  storeSelectedDate,
  storeCurrentBookingId,
  storeArtists,
  storeCurrentEventlist,
  storeIsNewBooking,
  storeUserRole,
  storeCurrentUser,
  storeEventsList,
  storeReloadBookings,
  storeAvailableVenuesAndDates,
  storeAvails,
  storeAverageTicketPrice,
  storeNumberOfEvents,
  storeCurrentArtist,
  storeCurrentAgent,
  storeCurrentArtistRoster,
  // storeDynamicBtnGroup,
  storeMonthDisplayed,
  storeVenueAccess,
  storeOwnedVenues,
  storeUIDrawerOpen,
  storeThemeMode,
  storeCurrentBookings,
  storeCurrentEditors,
  storeCurrentMonth,
  storeCurrentYear,
  storeSortVenuesBy,
  storeTimelineIsOpen,
  storeMapIsOpen,
  storeWantedList,
  storeCurrentArtistBookings,
  storePendingBookings,
  storeAwaitingBookings,
  storeConfirmedBookings,
  storeCurrentProduct,
  storeTmpAYS,
  storeDayNightOrBoth,
  storeCurrentUserId,
  storeSummary,
  storeUsers,
  storeCurrentTickets,
  storeSimilarBookings,
  storeCurrentStripe,
  storeCurrentSettings,
  storeCurrentAgreements,
  storeCurrentVenueBookings,
  storeCurrentTicketSummary,
  storeStartVenue,
  storeSuperOwner,
  storeAccessibleVenues,
  storeCurrentPdfDocHTML,
  storeCurrentAgreement,
} = counterSlice.actions;

export default counterSlice.reducer;

/** @format */

import { Button, Grid, TextField } from "@mui/material";
import { collection, deleteDoc, doc, getDocs, query, setDoc } from "firebase/firestore";
import React, { useState } from "react";
import { useEffect } from "react";
import { createMidId, db } from "../firebase";

const LedgerView = () => {
  const [ledger, setLedger] = useState([]);
  const [postData, setPostData] = useState([]);
  const [account, setAccount] = useState([]);
  const [standing, setStanding] = useState([]);
  const newTimestamp = new Date().toLocaleString();

  const handlePost = (value, type) => {
    setPostData((prev) => ({ ...prev, [type]: value }));
  };

  const submitPostData = async () => {
    const postId = createMidId();
    await setDoc(doc(db, "Ledger", postId), {
      ...postData,
      timestamp: newTimestamp,
    });
    setPostData({ account: "", debit: "", credit: "", reference: "", description: "", timeStamp: "" });
    getData();
  };

  useEffect(() => {
    getData();
  }, []);

  async function getData() {
    let tmp = [];
    const querySnapshot = await getDocs(collection(db, "Ledger"));
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      console.log(doc.id, " => ", doc.data());
      tmp.push(doc.data());
    });
    tmp.sort((a, b) => a.timestamp - b.timestamp);
    setLedger(tmp);
  }

  async function deleteAllPosts() {
    const querySnapshot = await getDocs(query(collection(db, "Ledger")));
    querySnapshot.forEach(async (item) => {
      await deleteDoc(doc(db, "Ledger", item.id));
    });
    setPostData({ account: "", debit: "", credit: "", reference: "", description: "" });
    getData();
  }

  const referenceIds = Array.from(new Set(ledger.map((item) => item.reference)));
  const sumsDebit = (id) => ledger.filter((entry) => entry.reference === id).reduce((sum, entry) => sum + Number(entry.debit), 0);
  const sumsCredit = (id) => ledger.filter((entry) => entry.reference === id).reduce((sum, entry) => sum + Number(entry.credit), 0);

  return (
    <Grid container xs={12}>
      <Grid item xs={12} style={{ display: "flex", gap: "15px" }}>
        {console.log(postData)}
        LedgerView
        <TextField label="Account" value={postData["account"]} onChange={(e) => handlePost(e.target.value, "account")} size="small" />
        <TextField label="Debit" value={postData["debit"]} onChange={(e) => handlePost(e.target.value, "debit")} size="small" />
        <TextField label="Credit" value={postData["credit"]} onChange={(e) => handlePost(e.target.value, "credit")} size="small" />
        <TextField label="Reference" value={postData["reference"]} onChange={(e) => handlePost(e.target.value, "reference")} size="small" />
        <TextField
          label="Description"
          value={postData["description"]}
          onChange={(e) => handlePost(e.target.value, "description")}
          size="small"
        />
        <Button onClick={() => submitPostData()}>Submit</Button>
      </Grid>
      <Grid item xs={12} mt={4}>
        <table className="ledgerTable">
          <thead style={{ background: "#333" }}>
            <th>Account</th>
            <th>Debit</th>
            <th>Credit</th>
            <th>Reference</th>
            <th>Description</th>
            <th>TimeStamp</th>
          </thead>
          {ledger &&
            ledger.map((post) => (
              <tr>
                <td>{post.account}</td>
                <td>{post.debit}</td>
                <td>{post.credit}</td>
                <td>{post.reference}</td>
                <td>{post.description}</td>
                <td>{new Date(post.timestamp).toLocaleDateString()}</td>
              </tr>
            ))}
          {referenceIds &&
            referenceIds.map((referenceId) => (
              <tr style={{ color: "orange" }}>
                <td>
                  <b>Balance</b>
                </td>
                <td>{sumsDebit(referenceId)}</td>
                <td>{sumsCredit(referenceId)}</td>
                <td>{referenceId}</td>
                <td>{sumsCredit(referenceId) - sumsDebit(referenceId)}</td>
              </tr>
            ))}
        </table>
        <Grid item xs={12} mt={4}>
          <table className="ledgerTable">
            <thead>
              <th>account</th>
              <th>debt/recievables</th>
            </thead>
            <tr>
              <td>{account}</td>
              <td>{standing}</td>
            </tr>
          </table>
        </Grid>
        <Grid item>
          <Button onClick={() => deleteAllPosts()}>Reset</Button>
        </Grid>
      </Grid>
    </Grid>
  );
};
export default LedgerView;

export async function createDebitPost(account, debit, bookingid, description) {
  const newTimestamp = new Date();
  const postId = createMidId();

  await setDoc(doc(db, "Ledger", postId), {
    account: account,
    debit: debit,
    credit: 0,
    reference: bookingid,
    description: description,
    timestamp: newTimestamp,
  });
}

export async function createCreditPost(account, credit, bookingid, description) {
  const newTimestamp = new Date();
  const postId = createMidId();

  await setDoc(doc(db, "Ledger", postId), {
    account: account,
    debit: 0,
    credit: credit,
    reference: bookingid,
    description: description,
    timestamp: newTimestamp,
  });
}

/** @format */

import { Box, Button, Grid, Modal, TextField } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import GetBookingsForCurrentVenue from "../../helpers/GetBookingsForCurrentVenue";
import { createId, db } from "../../firebase";
import { collection, doc, getDocs, query, setDoc, where } from "firebase/firestore";
import { useDispatch } from "react-redux";
import ProductSelect from "../MiscUIComponents/ProductSelect";
import checkAvails from "../../features/avails/CheckAvails";
import { all } from "axios";
import { SendRequest } from "../../helpers/SendRequest";
import { storeCurrentProduct } from "../../features/venueFilter/venueFilterSlice";
import { wait } from "./Wait";
import { generateOCR } from "../../helpers/generateOCR";

const AddBookings = ({ currentVenue }) => {
  const divRef = useRef();
  const currentUser = useSelector((state) => state.counter.currentUser);
  const month = useSelector((state) => state.counter.currentMonth);
  const year = useSelector((state) => state.counter.currentYear);
  //   const bookings = useSelector((state) => state.counter.bookingsForCurrentVenue);
  const [bookings, setBookings] = useState([]);
  const selectedDate = useSelector((state) => state.counter.selectedDate);
  const currentProduct = useSelector((state) => state.counter.currentProduct);
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [datesDone, setDatesDone] = useState(false);
  const [renderedDates, setRenderedDates] = useState(false);
  const [allStatuses, setAllStatuses] = useState();
  const [chosenDateRange, setChosenDateRange] = useState();
  const [avails, setAvails] = useState();
  const [awaiting, setAwaiting] = useState();
  const [pickedDates, setPickedDates] = useState([]);

  const [isSelecting, setIsSelecting] = useState(false);
  const [selectedDivs, setSelectedDivs] = useState([]);

  const [hoveredDay, setHoveredDay] = useState(null);
  // const handleMouseOver = (date) => {
  //   setHoveredDay(date);
  // };
  const handleMouseLeave = () => {
    setHoveredDay(null);
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "90%",
    bgcolor: "none",
    background: "linear-gradient(180deg, rgba(255,255,255,0.1), rgba(0,0,0,0.5))",
    backdropFilter: "blur(30px)",
    boxShadow: 24,
    p: 4,
    height: "80vh",
    overflow: "hidden",
    borderRadius: "10px",
  };

  const weekdays = ["S", "M", "T", "W", "T", "F", "S"];
  const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  const years = [year, year + 1, year + 2, year + 3];

  //Get all bookings for venue
  const [done, setDone] = useState(false);
  useEffect(() => {
    if (!open) return;
    setPickedDates([]);
    async function getData() {
      // alert("getData");
      //   const data = await getDocs(query(collection(db, "Bookings"), where("venueid", "==", "ppt4ilbghuvbzeawiy6vxqsjlmq5gage")));
      const data = await getDocs(query(collection(db, "Bookings"), where("venueid", "==", currentVenue.venueid)));
      const bookingData = data.docs.map((doc) => doc.data());
      console.log("bookingData", bookingData);

      setBookings(bookingData);
      // alert("bookingData");
      // Set all dates for the next 4 years with status
      let tmpStatuses = [];
      let allDates = [];
      await years.map((year) =>
        months.map((month, monthIndex) => {
          const daysInMonth = new Date(year, monthIndex + 1, 0).getDate();
          const dates = Array.from({ length: daysInMonth }, (_, index) =>
            new Date(year, monthIndex, index + 1).toLocaleDateString("sv-SE")
          ); // Generate dates for each day of the month

          dates.forEach((date) => {
            bookingData.find(
              (booking) =>
                booking.date === date &&
                booking.status !== "Pending Request" &&
                booking.status !== "Killed" &&
                booking.status !== "Declined" &&
                // booking.status !== "Blocked" &&
                tmpStatuses.push({
                  date: date,
                  artist: bookingData.find((booking) => booking.date === date).artist,
                  status: booking.status,
                  loadIn: booking.loadIn,
                  loadOut: booking.loadOut,
                })
            );
            allDates.push(date);
          });
        })
      );
      setChosenDateRange(allDates);
      setAllStatuses(tmpStatuses);
    }
    getData();
  }, [open]);

  useEffect(() => {
    if (!chosenDateRange) return;
    function chain() {
      console.log("✅ currentProduct set");
      console.log("bookings", bookings);
      console.log("chosenDateRange", chosenDateRange);
      setAwaiting([]); // Clear awaiting
      setAvails([]); // Clear avails
      checkAvailabilities();
      setPickedDates([]); // Clear picked dates
    }
    chain();
  }, [chosenDateRange, currentProduct]);

  useEffect(() => {
    console.log("allStatuses", allStatuses);
  }, [allStatuses]);

  useEffect(() => {
    // bookings.length && setDone(true);
  }, [bookings]);

  const prefDays = currentProduct
    ? currentProduct.preferredDays
    : ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];

  const checkAvailabilities = async () => {
    console.log("🌿Checking Avails");
    console.log("currentVenue", currentVenue);
    console.log("chosenDateRange", chosenDateRange);
    console.log("prefDays", prefDays);
    console.log("currentProduct", currentProduct);
    checkAvails(currentVenue, chosenDateRange, prefDays, currentProduct, setAvails, setAwaiting);
  };

  useEffect(() => {
    console.log("avails", avails);
    console.log("awaiting", awaiting);
    getAvailColor();
  }, [avails, awaiting]);

  const getDateStatus = (date) => {
    const status = allStatuses && allStatuses.find((stat) => stat.date === date);
    // console.log(status, "status");
    if (status) {
      switch (status.status) {
        // case "Killed":
        //   return "Killed";
        // case "Pending Request":
        //   return "Pending Request";
        case "Awaiting Artist":
          return `Awaiting Artist: ${status.artist.artist}`;
        case "Confirmed":
          return `Confirmed: ${status.artist.artist}`;
        case "Blocked":
          return "Blocked";

        default:
          return "Available";
      }
    }

    return "Available"; // Default color if no status is found
  };

  const dayStuff = (date) => {
    return (
      <div style={{ position: "relative" }}>
        {hoveredDay === date && (
          <div
            style={{
              position: "absolute",
              left: "120%",
              top: "-50px",
              marginLeft: "10px",
              backgroundColor: "#222",
              padding: "10px",
              borderRadius: "5px",
              zIndex: 2, // Ensure it appears above other content
              whiteSpace: "nowrap", // Prevent the content from wrapping
            }}
          >
            {/* Display dynamic content based on the day here */}
            {/* {date} */}
            {/* {allStatuses.find((stat) => stat.date === date) && allStatuses.find((stat) => stat.date === date).artist.artist} */}
            {allStatuses && allStatuses.find((stat) => stat.date === date) && getDateStatus(date)}
          </div>
        )}
      </div>
    );
  };

  const [availColor, setAvailColor] = useState({});
  const getAvailColor = () => {
    chosenDateRange &&
      chosenDateRange.map((date) => {
        const avail = avails && avails.find((avail) => avail === date);
        const awaitingArtist = awaiting && awaiting.find((tmpAwaiting) => tmpAwaiting === date);
        // const picked = pickedDates && pickedDates.find((pick) => pick === date);

        if (avail) {
          setAvailColor((prev) => ({ ...prev, [date]: { background: "#00ffd433", opacity: "1" } }));
        } else if (awaitingArtist) {
          setAvailColor((prev) => ({
            ...prev,
            [date]: {
              background: "#FFA50099",
              opacity: "0.6",
              color: "#ffffff33",
            },
          }));
        } else {
          setAvailColor((prev) => ({
            ...prev,
            [date]: {
              background: "#FF000044",
              opacity: "0.6",
              color: "#ffffff33",
            },
          }));
        }
      });
  };

  useEffect(() => {
    console.log("availColor", availColor);
  }, [availColor]);

  const createBooking = async () => {
    const agreementId = createId();
    const OCR = await generateOCR();
    const bookingIds = [...(pickedDates && pickedDates.map((date) => createId()))];
    console.log(bookingIds, "bookingIds");

    const agreementPackage = {
      OCR: OCR,
      userId: currentUser,
      bookingids: [...bookingIds],
      status: "Pending Request",
      email: currentVenue.email,
      agent: { email: email, phone: "" },
      venue: currentVenue.venue,
      venueid: currentVenue.venueid,
      documentid: agreementId,
      agreementId: agreementId,
      venueLogo: currentVenue.venueLogo || "",
      artist: { artist: artist, image: "" },
      upfront: currentVenue.upfront,
      upfrontAt: currentVenue.upfrontAt,
      preCharge: currentVenue.preCharge,
      preChargeAt: currentVenue.preChargeAt,
      balance: currentVenue.balance,
      balanceAt: currentVenue.balanceAt,
      earlyCancelation: currentVenue.earlyCancelation,
      earlyCancelationAt: currentVenue.earlyCancelationAt,
      lateCancelation: currentVenue.lateCancelation,
      lateCancelationAt: currentVenue.lateCancelationAt,
    };

    console.log("agreementPackage", agreementPackage);
    // Create Agreement
    await setDoc(doc(db, "Agreements", agreementId), {
      ...agreementPackage,
    });

    // setShowBookingFormLite(false);
    let venue = { ...currentVenue };
    // venue.currentProduct = currentProduct;

    bookingIds.map(async (bookingid, index) => {
      console.log("CurrentProduct", currentProduct);
      await SendRequest(
        { artistid: "-", artist: artist }, //currentArtist
        { agentid: "-", email: email, agent: name }, //currentAgent
        {
          venue: currentVenue && { ...currentVenue },
          venueid: currentVenue.venueid,
          product: currentProduct.product,
          productid: currentProduct.productid,
          getIn: currentProduct.getIn,
          loadIn: currentProduct.loadIn,
          soundcheck: currentProduct.soundcheck,
          doors: currentProduct.doors,
          show: currentProduct.show,
          loadOut: currentProduct.loadOut,
          curfew: currentProduct.curfew,
          price: currentProduct.price,
          priceIncVat: currentProduct.priceIncVat,
          upfront: currentVenue.upfront,
          upfrontAt: currentVenue.upfrontAt,
          preCharge: currentVenue.preCharge,
          preChargeAt: currentVenue.preChargeAt,
          balance: currentVenue.balance,
          balanceAt: currentVenue.balanceAt,
          earlyCancelation: currentVenue.earlyCancelation,
          earlyCancelationAt: currentVenue.earlyCancelationAt,
          lateCancelation: currentVenue.lateCancelation,
          lateCancelationAt: currentVenue.lateCancelationAt,
        }, //availData
        pickedDates[index], // proposedDate
        agreementId, // agreementId
        bookingid // bookingid
      );
    });
    // SendRequest = (currentArtist, currentAgent, availData, proposedDate, agreementId, bookingid)
    setOpen(false);
  };

  //Show month in calendar
  const showMonth = (year, month) => {
    const daysInMonth = new Date(year, month + 1, 0).getDate();
    const firstWeekday = new Date(year, month, "01").getDay();
    const numberOfPaddingDays = firstWeekday - 1; //Change this number to change startday of the week -2 gives monday, -1 gives sunday
    const paddingDays = [];
    for (let i = 0; i <= numberOfPaddingDays; i++) {
      paddingDays.push(i);
    }

    const days = [];
    for (let i = 1; i <= daysInMonth; i++) {
      days.push(i);
    }

    const [loading, setLoading] = useState(false);
    // if (currentProduct)
    return (
      <div style={{ width: "300px", height: "350px", float: "left" }}>
        {months[month]}
        <div style={{ width: "100%", float: "left", display: "block" }} onMouseUp={handleMouseUp}>
          {weekdays.map((day) => (
            <div
              // className="day"
              style={{
                userSelect: "none",
                WebkitUserSelect: "none",
                float: "left",
                background: "transparent",
                width: "12%",
                height: "20px",
                borderRadius: "10px",
                marginBottom: "10px",
                margin: "2px",
              }}
            >
              <span className="weekday" style={{ opacity: "0.3" }}>
                {day}
              </span>
            </div>
          ))}
          {paddingDays.map((day) => (
            <div
              className="day"
              style={{
                float: "left",
                background: "transparent",
                width: "12%",
                height: "40px",
                borderRadius: "10px",
                margin: "2px",
                // border: `1px solid ${statusColor}`,
              }}
            >
              <span className="day-number" style={{ opacity: "0.3" }}></span>
            </div>
          ))}
          {days.map((day) => (
            <div
              // data-value={formatDate(year, month, day)}
              className="day"
              style={{
                userSelect: "none",
                WebkitUserSelect: "none",
                float: "left",
                width: "12%",
                height: "40px",
                borderRadius: "5px",
                margin: "2px",
                color: "white",
                ...(!availColor || availColor[new Date(year, month, day).toLocaleDateString("sv-SE")]),
              }}
              // onMouseDown={() => handleMouseDown(new Date(year + "-" + (month + 1) + "-" + day).toLocaleDateString("sv-SE"))}
              // onMouseOver={() => handleMouseOver(new Date(year + "-" + (month + 1) + "-" + day).toLocaleDateString("sv-SE"))}
              // onMouseUp={() => handleMouseUp(new Date(year + "-" + (month + 1) + "-" + day).toLocaleDateString("sv-SE"))}
              //
              // onPointerDown={() => handleMouseDown(new Date(year + "-" + (month + 1) + "-" + day).toLocaleDateString("sv-SE"))}
              // onPointerOver={() => handleMouseOver(new Date(year + "-" + (month + 1) + "-" + day).toLocaleDateString("sv-SE"))}
              // onPointerUp={() => handleMouseUp(new Date(year + "-" + (month + 1) + "-" + day).toLocaleDateString("sv-SE"))}
              //
              onMouseDown={() => handleMouseDown(formatDate(year, month, day))}
              onMouseOver={() => handleMouseOver(formatDate(year, month, day))}
              onMouseUp={() => handleMouseUp(formatDate(year, month, day))}
              //
              // onMouseDown={(event) => handleMouseDown(event.currentTarget.getAttribute("data-value"))}
              // onMouseOver={(event) => handleMouseOver(event.currentTarget.getAttribute("data-value"))}
              // onMouseUp={(event) => handleMouseUp(event.currentTarget.getAttribute("data-value"))}
            >
              <span className="day-number" style={{ pointerEvents: "none" }}>
                {/* {dayStuff(new Date(year + "-" + (month + 1) + "-" + day).toLocaleDateString("sv-SE"))} */}
                {day}
                {/* {bookings.length && open && status(year, month, day)} */}
              </span>
            </div>
          ))}
        </div>
      </div>
    );
  };

  function formatDate(year, month, day) {
    // Pad the month and day with a leading zero if necessary
    const paddedMonth = String(month + 1).padStart(2, "0");
    const paddedDay = String(day).padStart(2, "0");
    const dateString = `${year}-${paddedMonth}-${paddedDay}`;

    // Create a Date object and convert to the desired locale string
    const date = new Date(dateString);
    return date.toLocaleDateString("sv-SE");
  }

  const handleMouseDown = (date) => {
    if (!avails) {
      console.log("⊘ avails not set");
      return;
    }
    setIsSelecting(true);
    // if (allStatuses.find((stat) => stat.date === date)) return;

    if (pickedDates.includes(date)) {
      // If already selected, remove from selection
      setAvailColor((prev) => ({ ...prev, [date]: { background: "#00ffd433", opacity: "1" } }));
      setPickedDates((previous) => [...pickedDates.filter((i) => i !== date)]);
      // return pickedDates.filter((i) => i !== date);
    } else if (avails.includes(date)) {
      setPickedDates((previous) => [...previous, date]);
      setAvailColor((prev) => ({ ...prev, [date]: { background: "#00ffd499", opacity: "1" } }));
    }
  };

  const handleMouseOver = (date) => {
    setHoveredDay(date);
    if (isSelecting) {
      toggleSelection(date);
    }
  };

  const toggleSelection = (date) => {
    console.log("toggleSelection", date);
    // if (allStatuses.find((stat) => stat.date === date)) return;

    if (pickedDates.includes(date)) {
      // If already selected, remove from selection
      setAvailColor((prev) => ({ ...prev, [date]: { background: "#00ffd433", opacity: "1" } }));
      setPickedDates((previous) => [...pickedDates.filter((i) => i !== date)]);
    } else if (avails.includes(date)) {
      // If not selected, add to selection
      setAvailColor((prev) => ({ ...prev, [date]: { background: "#00ffd499", opacity: "1" } }));
      setPickedDates((previous) => [...previous, date]);
    }
  };

  const handleMouseUp = (date) => {
    setIsSelecting(false);
  };

  useEffect(() => {
    console.log("pickedDates", pickedDates);
  }, [pickedDates]);

  const [artist, setArtist] = useState([]);
  const [email, setEmail] = useState("Email");
  const [name, setName] = useState("");

  return (
    <Grid item mt={4}>
      {currentVenue && currentVenue.products && (
        <Button variant="outlined" fullWidth onClick={() => handleOpen()}>
          Add Bookings
        </Button>
      )}

      <Modal open={open} onClose={handleClose}>
        <Grid sx={style}>
          <Grid container className="pickDates" spacing={2}>
            <Grid item mb={4}>
              {console.log("currentVenue", currentVenue, "chosenDateRange", chosenDateRange)}
              {chosenDateRange && currentVenue && <ProductSelect products={currentVenue.products} />}
            </Grid>
            <Grid item flexDirection="column">
              {currentProduct && ` ${currentProduct.product}: ${currentProduct.loadIn}-${currentProduct.loadOut}`}
              <Grid item>{pickedDates && `Picked Dates: ${pickedDates.length}`}</Grid>
            </Grid>
            <Grid
              item
              // flexDirection="row"
              style={{ display: "flex", gap: "15px" }}
            >
              <Grid item xs={12} sm={4}>
                <TextField
                  fullWidth
                  required
                  label="Event Name"
                  value={artist}
                  placeholder="Enter event name"
                  onChange={(e) => setArtist(e.target.value)}
                  size="small"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  fullWidth
                  label="Email"
                  value={email}
                  placeholder="Enter email"
                  onChange={(e) => setEmail(e.target.value)}
                  size="small"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  fullWidth
                  required
                  label="Name"
                  value={name}
                  placeholder="Enter name"
                  onChange={(e) => setName(e.target.value)}
                  size="small"
                />
              </Grid>
            </Grid>

            <Grid item>
              <Button
                variant="contained"
                onClick={() => createBooking()}
                disabled={!currentProduct.product || !currentVenue.venue || !pickedDates.length || !name || !artist}
              >
                Set Booking
              </Button>
            </Grid>
            <div style={{ width: "100%", overflow: "auto", maxHeight: "70vh", marginTop: "30px" }}>
              {years.map((year, yearIndex) => (
                <div key={yearIndex}>
                  <span className="addBookingsYear">
                    <h2>{year}</h2>
                  </span>
                  {months.map((month, monthIndex) => (
                    <div key={monthIndex} style={{ userSelect: "none", WebkitUserSelect: "none" }}>
                      {showMonth(year, monthIndex)}
                    </div>
                  ))}
                </div>
              ))}
            </div>
          </Grid>
        </Grid>
      </Modal>
    </Grid>
  );
};

export default AddBookings;

/** @format */

import { Today } from "@mui/icons-material";
import { Accordion, AccordionDetails, AccordionSummary, Button, CircularProgress, Grid } from "@mui/material";
import { getDataGridUtilityClass } from "@mui/x-data-grid";
import { AES, enc } from "crypto-js";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { db } from "../firebase";
import { today } from "../helpers/DateManagement";
import DisplayObject from "../helpers/displayObject";
import { createPayment } from "./createPayment";
import { createPayout } from "./createPayout";
import SendIcon from "@mui/icons-material/Send";
import SendInvoiceEmail from "../features/avails/SendInvoiceEmail";
import jsPDF from "jspdf";
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
import { set } from "date-fns";
import { site } from "../utils/site";
import EmailProgressBar from "../helpers/EmailProgressBar";
import { makeNumber } from "../helpers/Numbers";

const Tmp_VenueInvoice = ({ bookingid, paymentButton, payoutButton, sendButton, handleSend, close }) => {
  const currentVenue = useSelector((state) => state.counter.currentVenue);
  const currentUser = useSelector((state) => state.counter.currentUser);
  const currentAgent = useSelector((state) => state.counter.currentAgent);

  const [booking, setBooking] = useState();
  const [venue, setVenue] = useState();
  const [loading, setLoading] = useState(true);
  const [invoiceUrl, setInvoiceUrl] = useState(null);
  const [readyToSavePDF, setReadyToSavePDF] = useState(false);

  const secretKey = process.env.REACT_APP_FORM_KEY;
  //   const encryptedSocialId = currentUser.socialId;
  //   const socialId = AES.decrypt(encryptedSocialId, secretKey).toString(enc.Utf8);

  const [iframeURL, setIframeURL] = useState();
  // const paymentId = "abcde12345",

  const region = "SE";
  // const redirectUri = `http://localhost:3000/Payment`;
  const redirectUri = `${site}/Payment`;
  // const client_id = "";
  // const client_secret = "";
  const [paymentId, setPaymentId] = useState();
  const [clientId, setClientId] = useState();
  const [IBAN, setIBAN] = useState(booking && booking.venue.IBAN);
  const [BIC, setBIC] = useState(booking && booking.venue.BIC);

  const [ticketsSold, setTicketsSold] = useState([booking && booking.ticketSummary.ticketsSold]);
  const [ticketsTotal, setTicketsTotal] = useState([booking && booking.ticketSummary.ticketsTotal]);
  const [tickets, setTickets] = useState([]);
  const [sumAdditionalCosts, setSumAdditionalCosts] = useState(0);
  const [sumVatAdditionalCosts, setSumVatAdditionalCosts] = useState(0);
  const [vatAdditionalCosts, setVatAdditionalCosts] = useState(0);
  const [totalAdditionalCosts, setTotalAdditionalCosts] = useState(0);
  const [finalAmount, setFinalAmount] = useState(0);

  const getFinalAmount = () => {
    // if tickets should be deducted then...
    if (booking.deductFromTickets) {
    }
    // ... otherwise if no tickets then...
  };

  var bodyData = JSON.stringify(body);

  async function send() {
    let url;
    await savePDF();
    // booking.invoiceUrl && handleSend();
  }
  // async function handleSend() {
  //   console.log("saving pdf...");
  //   let url = await savePDF();
  //   console.log(booking);
  //   // setTimeout(async () => {
  //   //   console.log("sending mail...");
  //   //   await SendInvoiceEmail(booking);
  //   //   console.log("done...");
  //   // }, 4000);
  // }

  // var PDFdoc = new jsPDF("p", "mm", [297, 210]);
  var PDFdoc = new jsPDF();

  async function savePDF() {
    console.log("The Booking 💓", booking);

    // PDFdoc.addFileToVFS("AvenirBlack.ttf", AvenirBlack);
    // PDFdoc.addFont("AvenirBlack.ttf", "AvenirBlack", "bold");
    // PDFdoc.setFont("CustomFont"); // now you can use your custom font
    console.log("trying...");
    await PDFdoc.html(document.querySelector("#invoice"), {
      // let url;
      // html2canvas: { scale: 0.25 }, // Adjust scale as needed

      callback: function (pdf) {
        console.log("0");
        var img = new Image();
        img.src =
          "https://firebasestorage.googleapis.com/v0/b/venuehub-64e72.appspot.com/o/images%2Fvh-logos%2FVenuehub_Blackonwhite.png?alt=media&token=f6fe81d1-74f8-424f-8f48-1f4ddf89a943";

        img.onload = function () {
          // pdf.addImage(this, "10px", "10px", "20px", "auto");
          var blob = pdf.output("blob");
          const storage = getStorage();
          const storageRef = ref(storage, `invoices/${booking.documentid}.pdf`);
          console.log("1");
          uploadBytes(storageRef, blob).then((snapshot) => {
            // console.log("PDF uploaded to Firebase Storage!");

            // Get the download URL
            getDownloadURL(storageRef).then((url) => {
              console.log("2");
              // Update the invoiceUrl state
              setInvoiceUrl(url);
              url = url;
              // Store invoiceUrl to Booking
              updateDoc(doc(db, "Bookings", booking.bookingid), {
                invoiceUrl: url,
              });

              // SendInvoiceEmail(booking);
              handleSend(url);
            });
          });
        };
      },
    });
  }

  // function newPayment() {
  //   fetch(`https://europe-west3-venuehub-64e72.cloudfunctions.net/createPayment?bodyData=${JSON.stringify(body)}`)
  //     .then((response) => response.json())
  //     //   .then((data) => window.open(data.url, "_blank"))
  //     .then((data) => window.open(data.url))
  //     .catch((error) => console.error(error));
  // }

  function PayDirect() {
    alert("Not this one?");
    fetch(`https://europe-west3-venuehub-64e72.cloudfunctions.net/createPayment?bodyData=${JSON.stringify(body)}`)
      .then((response) => response.json())
      .then((data) => {
        console.log("Payment ID: ", data.paymentId);
        console.log("Client ID: ", data.clientId);
        console.log("Response Data 💚", data.responseData);
        console.log(data);
        setPaymentId(data.paymentId);
        setClientId(data.clientId);
      })
      .catch((error) => console.error(error));
  }

  useEffect(() => {
    // alert(booking ? booking.venue.IBAN : "nada");
    async function getVenueData() {
      // setLoading(true);

      console.log("booking is here", booking);
      // alert("venueid: " + booking.venueid);
      const docSnap = await getDoc(doc(db, "Venues", booking && booking.venueid));
      setVenue(docSnap.data());

      // setLoading(false);
    }
    if (booking) getVenueData();
  }, [booking && booking.venueid]);

  useEffect(() => {
    //Get booking data
    async function getData() {
      setLoading(true);

      const docSnap = await getDoc(doc(db, "Bookings", bookingid));

      if (docSnap.exists()) {
        setBooking(docSnap.data());
        console.log("BOOKING AGENT EMAIL 💢", docSnap.data().agent.email);
      } else {
        // docSnap.data() will be undefined in this cas
        console.log("No such document!");
      }
      setLoading(false);
    }
    getData();
  }, [bookingid]);

  useEffect(() => {
    booking && setTickets(booking.tickets);
    booking && setSumAdditionalCosts(booking.summaryTotal.totalExclVat);
    booking && setVatAdditionalCosts(booking.summaryTotal.vat);
    booking && setTotalAdditionalCosts(booking.summaryTotal.totalInclVat);
    booking && setSumVatAdditionalCosts(booking.summary.reduce((a, b) => a + b.vatSum, 0));
    booking && setReadyToSavePDF(true);
  }, [booking]);

  useEffect(() => {
    console.log("heard ya...");
    savePDF();
  }, [readyToSavePDF]);

  useEffect(() => {
    if (venue) {
      setIBAN(AES.decrypt(venue.IBAN, secretKey).toString(enc.Utf8));
      setBIC(AES.decrypt(venue.BIC, secretKey).toString(enc.Utf8));
    }
  }, [venue]);

  let tmp = { booking };
  tmp.invoiceUrl = invoiceUrl;

  let body = {};
  if (venue && booking) {
    body = {
      ClientAccess: "SANDBOX",
      status: "CREATED",
      amount: {
        currency: "SEK",
        value: booking.summaryTotal.totalInclVat && booking.summaryTotal.totalInclVat,
      },
      chargeBearer: "SHAR",
      creditor: {
        account: {
          accountNumber: "SE8273693077948953984714",
          accountType: "IBAN",
          bic: "HANDSESS",
          currencyCode: "SEK",
        },
        address: {
          city: "Stockholm",
          street: "Västerledstorget",
          country: "Sweden",
        },
        message: "Meddelande",
        name: "Marcus Dernulf",
      },
      debtor: {
        account: {
          accountNumber: "string",
          accountType: "IBAN",
          bic: "string",
          currencyCode: "string",
        },
        email: booking.agent.email,
        message: "172800000",
        name: `${booking.agent.firstName} ${booking.agent.lastName}`,
        phoneNumber: booking.agent.phone,
      },
      externalReference: booking.venue.venue,
      remittanceInformation: {
        value: "Darfor betalar du...",
        type: "UNSTRUCTURED",
      },
      region: "SE",
      paymentProductId: "se-domestic-credit-transfer",
      paymentTemplateId: "string",
      settlementCreditors: [
        {
          accountNumber: "SE8273693077948953984714",
          accountType: "IBAN",
          bic: "HANDSESS",
          amount: booking.summaryTotal.totalInclVat && booking.summaryTotal.totalInclVat,
          currencyCode: "SEK",
          name: "string",
        },
      ],
    };
  }

  // function getToken(client_id, client_secret, body) {
  //   const authUrl = "https://auth.finshark.io/connect/token";
  //   // const apiUrl = "https://api.finshark.io/";
  //   const apiUrl = "https://api.finshark.io/v1/payments";
  //   const authData = new URLSearchParams({
  //     client_id: process.env.REACT_APP_CLIENT_ID_FINSHARK,
  //     client_secret: process.env.REACT_APP_CLIENT_SECRET_FINSHARK,
  //     grant_type: "client_credentials",
  //   });

  //   return fetch(authUrl, {
  //     method: "POST",
  //     headers: {
  //       "Content-Type": "application/x-www-form-urlencoded",
  //     },
  //     body: authData,
  //   })
  //     .then((response) => response.json())
  //     .then((data) => {
  //       const access_token = data.access_token;
  //       console.log(access_token);

  //       return fetch(apiUrl, {
  //         method: "POST",
  //         headers: {
  //           Authorization: `Bearer ${access_token}`,
  //           "Content-Type": "application/json",
  //         },
  //         mode: "no-cors",
  //         body: body,
  //       });
  //     })
  //     .then((response) => response)
  //     .then((data) => {
  //       console.log(data);
  //     })
  //     .catch((snap) => {
  //       console.error("Snap! ", snap);
  //     });
  // }

  setTimeout(() => {
    setLoading(false);
  }, 500);

  // useEffect(() => {
  //   {
  //     paymentId &&
  //       setIframeURL(
  //         `https://flows.finshark.io?type=payments&clientId=${clientId}&redirectUri=${redirectUri}&region=${region}&paymentId=${paymentId}`
  //       );
  //   }
  // }, [paymentId && clientId]);

  if (booking)
    return (
      <Grid container mt={4} p={4} spacing={4}>
        {loading && <CircularProgress />}
        <Grid container xs={8} spacing={4} p={0} mt={4}>
          {IBAN ? (
            <Grid item>
              <div style={{ zoom: "4" }}>
                <body
                  id="invoice"
                  // style={{ color: "black", background: "white", padding: "20px", fontSize: "15px", width: "600px", fontFamily: "Avenir" }}
                  style={{
                    // color: "black",
                    // background: "white",
                    // padding: "20px",
                    // fontSize: "5pt",
                    // width: "210mm",
                    fontFamily: "Avenir",
                  }}
                  className="invoice"
                  style={{}}
                >
                  {/* <img
                    className="invoiceImg"
                    width="20px"
                    height="auto"
                    style={{ marginLeft: "-1px" }}
                    src="https://firebasestorage.googleapis.com/v0/b/venuehub-64e72.appspot.com/o/images%2Fvh-logos%2FVenuehub_Blackonwhite.png?alt=media&token=f6fe81d1-74f8-424f-8f48-1f4ddf89a943"
                  /> */}
                  <br></br>
                  <b>
                    {booking.deductFromTickets ? (
                      <h6
                        style={{
                          marginTop: "20px",
                          fontSize: "6px",
                        }}
                      >
                        <b>Settlement</b>
                      </h6>
                    ) : (
                      <h6
                        style={{
                          marginTop: "20px",
                          fontSize: "6px",
                        }}
                      >
                        Invoice
                      </h6>
                    )}
                  </b>
                  <div class="invoice-details">
                    <p></p>
                  </div>
                  <table className="invoiceTable">
                    {!booking.deductFromTickets && (
                      <tr>
                        <td>
                          <b>Invoice Number</b>
                        </td>
                        <td>001</td>
                      </tr>
                    )}
                    {!booking.deductFromTickets && (
                      <tr>
                        <td>
                          <b>Invoice Date</b>
                        </td>
                        <td>{today}</td>
                      </tr>
                    )}
                    <tr>
                      <td>
                        <b>Client</b>
                        <br />
                        {booking.agent.firstName} {booking.agent.lastName}
                        <br />
                        {booking.agent.streetAddress}
                        <br />
                        {booking.agent.zipCode}
                        <br />
                        {booking.agent.city}, {booking.agent.country}
                        <br />
                      </td>
                      <td></td>
                    </tr>
                    <divider />
                    <tr>
                      <th>Description</th>
                      <th>Quantity</th>
                      <th>Unit Price</th>
                      <th>VAT</th>
                      <th>Sum</th>
                    </tr>

                    {booking.deductFromTickets && (
                      <tr>
                        <td>
                          <b>Tickets</b>
                        </td>
                      </tr>
                    )}
                    {/* {booking.deductFromTickets && (
                  <tr>
                    <td>Ticket sales</td>
                    <td>{booking.tickets.sold}</td>
                    <td>{booking.tickets.price}</td>
                    <td>TBA</td>
                    <td>{Number(booking.tickets.sold) * Number(booking.tickets.price)}</td>
                  </tr>
                )} */}
                    {console.log(booking.tickets)}
                    {console.log("tickets:", tickets)}
                    {booking.deductFromTickets &&
                      Object.values(tickets).map((item) => (
                        <tr>
                          <td>{item.type}</td>
                          <td>{item.quantity}</td>
                          <td>{item.cost}</td>
                          <td>{(item.quantity * item.cost * Number(item.vat && item.vat.slice(0, -1))) / 100}</td>
                          <td>{item.sum}</td>
                        </tr>
                      ))}
                    {booking.deductFromTickets && (
                      <tr>
                        <td>Tickets total</td>
                        <td>
                          <b>{Number(booking.ticketSummary.ticketsSold)}</b>
                        </td>
                        <td></td>
                        <td></td>
                        <td>
                          <b>{Number(booking.ticketSummary.ticketsTotal)}</b>
                        </td>
                      </tr>
                    )}
                    <tr
                      style={{
                        borderTop: "10px solid transparent",
                      }}
                    >
                      <td colspan="3">
                        <b>According to agreement</b>
                      </td>
                    </tr>
                    <tr>
                      <td>Rental fee</td>
                      <td>1</td>
                      <td>{makeNumber(booking.price)}</td>
                      <td>TBA</td>
                      <td>{makeNumber(booking.price) * 1.25}</td>
                    </tr>
                    <tr>
                      <td> </td>
                    </tr>
                    <tr
                      style={{
                        borderTop: "10px solid transparent",
                      }}
                    >
                      <td>
                        <b>Additional</b>
                      </td>
                    </tr>
                    {booking.summary &&
                      booking.summary.map((item) => (
                        <tr>
                          <td>{item.type}</td>
                          <td>{item.quantity}</td>
                          <td>{item.cost}</td>
                          <td>{(item.quantity * item.cost * Number(item.vat && item.vat.slice(0, -1))) / 100}</td>
                          <td>{item.sum}</td>
                        </tr>
                      ))}
                    <tr>
                      <td>
                        <b>Sum Additional Costs</b>
                      </td>
                      <td></td>
                      <td></td>
                      {/* <td>{vatAdditionalCosts - booking.price * 0.25}</td> */}
                      <td>{sumVatAdditionalCosts}</td>
                      <td>
                        <b>{totalAdditionalCosts - makeNumber(booking.price) * 1.25}</b>
                      </td>
                    </tr>

                    <tr>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                    </tr>
                    {!booking.deductFromTickets && (
                      <tr
                        style={{
                          borderTop: "2px solid black",
                        }}
                      >
                        <td>
                          <b>Amount to Pay</b>
                        </td>
                        <td></td>
                        <td>{sumAdditionalCosts}</td>
                        <td>{vatAdditionalCosts}</td>
                        <td>
                          <b>{totalAdditionalCosts}</b>
                        </td>
                      </tr>
                    )}
                    {booking.deductFromTickets && (
                      <tr>
                        <td>
                          <b>Total deduction</b>
                        </td>
                        <td></td>
                        <td>{sumAdditionalCosts}</td>
                        <td>{vatAdditionalCosts}</td>
                        <td>- {totalAdditionalCosts}</td>
                      </tr>
                    )}
                    {booking.deductFromTickets && (
                      <tr
                        style={{
                          borderTop: "2px solid black",
                        }}
                      >
                        <td colspan="2">
                          <b>AMOUNT TO RECIEVE</b>
                        </td>
                        <td></td>
                        <td></td>
                        <td>
                          <b>{booking.ticketSummary.ticketsTotal - totalAdditionalCosts}</b>
                        </td>
                      </tr>
                    )}
                  </table>
                </body>
              </div>
              <Grid
                container
                xs={12}
                p={4}
                gap={2}
                // className="invoiceButtons"
              >
                {paymentButton && (
                  // <Button variant="contained" color="primary" onClick={() => createPayment({ body, setPaymentId, setClientId })}>
                  <Button variant="contained" color="primary" onClick={() => PayDirect()}>
                    Pay direct
                    {/* {console.log(body)} */}
                  </Button>
                )}
                {payoutButton && (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() =>
                      createPayout({
                        body,
                        setPaymentId,
                        setClientId,
                      })
                    }
                  >
                    Make payment
                  </Button>
                )}
                {sendButton && booking && (
                  <>
                    {/* handleSend is a functional prop of VenueInvoice, in order to send email. */}
                    <Button variant="contained" color="primary" onClick={() => send()} endIcon={<SendIcon fontSize="small" />}>
                      Send
                    </Button>
                    {/* {console.log("recipient:", currentAgent)} */}
                    <EmailProgressBar docId={`invoice${bookingid}`} close={close} />
                    {/* <stat style={{ opacity: "0.6" }}>Recipient: {currentAgent && currentAgent.agentEmail}</stat> */}
                    <stat style={{ opacity: "0.6" }}>Recipient: {booking && booking.agent.email}</stat>
                  </>
                )}
              </Grid>
              <Grid item xs={12} sm={3} mt={4}>
                <iframe src={iframeURL} height="640px" width="360px"></iframe>
              </Grid>
            </Grid>
          ) : (
            <Grid item>
              <CircularProgress sx={{ zoom: "0.4", marginTop: "3px" }} /> Preparing invoice data...
            </Grid>
          )}
        </Grid>
        {invoiceUrl && (
          <a href={invoiceUrl} target="_blank">
            Download
          </a>
        )}
        {console.log("readyToSavePDF", readyToSavePDF)}
        {/* <DisplayObject data={booking} /> */}
      </Grid>
    );
};

export default Tmp_VenueInvoice;

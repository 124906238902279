/** @format */

/** @format */

// Create array of form fields.
export let venueObj = [
  { section: "Document ID", type: "Section" },
  {
    section: "Document",
    placeholder: "Document ID",
    label: "Document ID",
    id: "documentid",
    type: "text",
    priority: 10,
    disabled: true,
  },
  //   {
  //     section: "select",
  //     name: "vfvenues",
  //     placeholder: "Choose venue",
  //     id: "vfvenues",
  //     class: "form",
  //     type: "Node",
  //   },
  // {
  //   type: "formData",
  //   id: "currentVenue",
  //   code: "()=>{const currentVenue=useSelector((state) => state.counter.currentVenue);}",
  // },
  // {
  //   type: "formData",
  //   id: "currentVenue",
  //   code: "()=>{console.log('= = = = = = = = = > > > >');}",
  // },
  { section: "Image", type: "Section", priority: 1 },
  {
    section: "Image",
    name: "image",
    placeholder: "Main Image",
    label: "Main Image",
    id: "venueImage",
    defaultValue:
      "https://firebasestorage.googleapis.com/v0/b/venuehub-64e72.appspot.com/o/venueImages%2FvenuehubStandardImage.jpg?alt=media&token=7efa6926-985b-4d90-8eff-09fcbcdb1be7",
    type: "imageUpload",
    priority: 1,
    storage: "venueImages",
  },
  {
    section: "Logotype",
    type: "Section",
    priority: 1,
  },
  {
    section: "Logotype",
    placeholder: "Logotype",
    label: "Logotype",
    id: "venueLogo",
    priority: 1,
    type: "logoUpload",
  },
  // {
  //   section: "Images",
  //   type: "Section",
  //   priority: 1,
  // },
  // {
  //   section: "Images",
  //   placeholder: "Images",
  //   label: "Images",
  //   id: "images",
  //   priority: 1,
  //   type: "multiImagesUpload",
  // },
  {
    section: "Attachments",
    type: "Section",
    priority: 1,
  },
  {
    section: "Attachments",
    placeholder: "Attachments",
    label: "Attachments",
    id: "attachments",
    priority: 1,
    type: "pdfUploader",
    info: "Upload attachable PDF's and documents.",
  },
  // {
  //   section: "Time Range",
  //   type: "Section",
  // },
  // {
  //   section: "Time Range",
  //   placeholder: "Time Range",
  //   label: "Time Range",
  //   id: "timeRange",
  //   min: 0,
  //   max: 100,
  //   step: 1,
  //   style: { width: "200px", color: "white" },
  //   type: "slider",
  // },
  {
    section: "Products",
    type: "Section",
    priority: 2,
  },
  {
    section: "Products",
    type: "productsComponent",
    priority: 2,
  },
  // {
  //   section: "Products",
  //   id: "product",
  //   options: ["Concert", "Theater", "Stand Up", "Private Event", "DJ/Club"],
  //   type: "select",
  //   style: { width: "200px", color: "white" },
  // },
  {
    section: "Venue",
    type: "Section",
    priority: 1,
  },
  {
    section: "Venue",
    name: "venue",
    placeholder: "Venue Name",
    label: "Venue Name",
    id: "venue",
    priority: 1,
    type: "text",
  },
  {
    section: "Venue",
    name: "website",
    placeholder: "Website",
    label: "Website",
    id: "webesite",
    priority: 2,
    type: "text",
  },
  // {
  //   section: "Subscription",
  //   type: "Section",
  //   priority: 2,
  // },
  // {
  //   section: "Subscription",
  //   name: "Subscription",
  //   placeholder: "Subscription",
  //   label: "Subscription",
  //   id: "subscription",
  //   priority: 2,
  //   type: "subscriptionInput",
  //   disabled: "true",
  // },
  {
    section: "Account",
    type: "Section",
    priority: 10,
  },
  {
    section: "Account",
    name: "venueid",
    placeholder: "VenueID",
    label: "VenueID",
    id: "venueid",
    priority: 10,
    type: "text",
    disabled: "true",
  },
  {
    section: "Account",
    name: "userid",
    placeholder: "UserID",
    label: "UserID",
    id: "uid",
    priority: 10,
    type: "text",
    disabled: "true",
  },
  {
    section: "Account",
    name: "ownerid",
    placeholder: "OwnerID",
    label: "OwnerID",
    id: "ownerid",
    priority: 10,
    type: "text",
    disabled: "true",
  },

  {
    section: "Account",
    name: "ownerid",
    placeholder: "OwnerID",
    label: "OwnerID",
    id: "ownerid",
    priority: 10,
    type: "text",
    disabled: "true",
  },

  { section: "Company", type: "Section", priority: 1 },
  {
    section: "Company",
    placeholder: "Company Name",
    label: "Company Name",
    id: "company",
    required: true,
    priority: 1,
    type: "text",
    encrypt: true,
  },

  // {
  //   section: "Company",
  //   name: "Corporate Identity Number",
  //   placeholder: "Corporate Identity Number",
  //   label: "Corporate Identity Number",
  //   id: "orgnummer",
  //   priority: 2,
  //   type: "text",
  // },
  {
    section: "Company",
    placeholder: "Organization Number",
    label: "Organization Number",
    id: "orgNumber",
    required: true,
    priority: 1,
    type: "text",
    encrypt: true,
  },
  {
    section: "Company",
    placeholder: "VAT number",
    label: "VAT number Number",
    id: "vatNumber",
    required: true,
    priority: 1,
    type: "text",
    encrypt: true,
  },
  // {
  //   section: "Company",
  //   placeholder: "Company IBAN",
  //   label: "Company IBAN",
  //   id: "IBAN",
  //   required: true,
  //   priority: 10,
  //   type: "text",
  //   encrypt: true,
  // },

  // {
  //   section: "Type",
  //   type: "Section",
  // },
  // {
  //   section: "Type",
  //   id: "type",
  //   label: "Type",
  //   options: ["Public", "Conference"],
  //   defaultValue: "Public",
  //   // type: "toggleButton",
  // },
  // {
  //   section: "Preferred Days",
  //   type: "Section",
  // },
  // {
  //   section: "Preferred Days",
  //   id: "preferredDays",
  //   label: "Preferred Days",
  //   options: ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"],
  //   exclusive: false,
  //   type: "toggleButton",
  // },
  // {
  //   section: "Time Slot",
  //   type: "Section",
  // },
  // {
  //   section: "Time Slot",
  //   id: "timeSlot",
  //   label: "Time Slot",
  //   type: "text",
  //   defaultValue: [{ endTime: 18, startTime: 21 }],
  // },
  {
    section: "Messages",
    priority: 2,
    type: "Section",
  },
  {
    section: "Messages",
    name: "declineMessage",
    placeholder: "Decline message",
    label: "Decline message",
    id: "declineMessage",
    defaultValue: "Unfortunately this date is no longer available.",
    priority: 2,
    type: "text",
    width: "300px",
    multiline: true,
  },
  {
    section: "Location",
    priority: 2,
    type: "Section",
  },
  {
    section: "Location",
    name: "streetAddress",
    placeholder: "Street Address",
    label: "Street Address",
    id: "venueStreetAddress",
    priority: 2,
    type: "text",
  },
  {
    section: "Location",
    name: "zipCode",
    placeholder: "Zip Code",
    label: "Zip Code",
    id: "venueZipCode",
    priority: 2,
    type: "text",
  },
  {
    section: "Location",
    name: "city",
    placeholder: "City",
    label: "City",
    id: "venueCity",
    priority: 2,
    type: "text",
    defaultValue: "Stockholm",
  },
  {
    section: "Location",
    name: "country",
    placeholder: "Country",
    label: "Country",
    id: "country",
    priority: 2,
    type: "country",
    defaultValue: "Sweden",
  },
  // {
  //   section: "Location",
  //   name: "county",
  //   placeholder: "County",
  //   label: "County",
  //   id: "venueCounty",
  //   priority: 2,
  //   type: "text",
  //   defaultValue: "Sweden",
  // },
  // {
  //   section: "Location",
  //   name: "countyB",
  //   placeholder: "CountyB",
  //   label: "CountyB",
  //   id: "venueCountyB",
  //   priority: 3,
  //   type: "text",
  //   defaultValue: "Sweden",
  // },

  //THIS
  // {
  //   section: "Location",
  //   name: "lat",
  //   placeholder: "Latitude",
  //   label: "Latitude",
  //   id: "venueLat",
  //   disabled: true,
  //   priority: 2,
  //   type: "number",
  //   defaultValue: 59,
  // },
  // {
  //   section: "Location",
  //   name: "lng",
  //   placeholder: "Longitude",
  //   label: "Longitude",
  //   id: "venueLng",
  //   disabled: true,
  //   priority: 2,
  //   type: "number",
  //   defaultValue: 18,
  // },
  {
    section: "Location",
    type: "addLocationComponent",
    priority: 10,
    fieldPrepend: "venue",
  },

  {
    section: "Postal",
    priority: 2,
    type: "Section",
  },
  {
    section: "Postal",
    name: "postalStreetAddress",
    placeholder: "Street Address",
    label: "Street Address",
    id: "postalStreetAddress",
    priority: 2,
    type: "text",
  },
  {
    section: "Postal",
    name: "postalZipCode",
    placeholder: "Zip Code",
    label: "Zip Code",
    id: "postalZipCode",
    priority: 2,
    type: "text",
  },
  {
    section: "Postal",
    name: "postalCity",
    placeholder: "City",
    label: "City",
    id: "postalCity",
    priority: 2,
    type: "text",
    defaultValue: "",
  },
  {
    section: "Postal",
    name: "postalCountry",
    placeholder: "Country",
    label: "Country",
    id: "country",
    disabled: true,
    priority: 2,
    type: "text",
    defaultValue: "Sweden",
  },
  {
    section: "Contact",
    priority: 1,
    type: "Section",
  },
  {
    section: "Contact",
    placeholder: "Telephone",
    label: "Telephone",
    id: "phone",
    priority: 1,
    type: "phone",
  },
  {
    section: "Contact",
    name: "Email",
    placeholder: "Email",
    label: "Email",
    id: "email",
    priority: 1,
    type: "email",
    defaultValue: "",
  },
  // {
  //   section: "Location",
  //   name: "coordinates",
  //   id: "coordinates",
  //   label: "Set Coordinates",
  //   type: "Button",
  // },
  {
    section: "Audience",
    priority: 1,
    type: "Section",
  },
  {
    section: "Audience",
    name: "",
    placeholder: "Capacity Seated",
    label: "Capacity Seated",
    id: "capseated",
    priority: 1,
    type: "number",
    defaultValue: "",
  },
  {
    section: "Audience",
    name: "",
    placeholder: "Capacity Standing",
    label: "Capacity Standing",
    id: "capstanding",
    priority: 1,
    type: "number",
    defaultValue: "",
  },
  {
    section: "Audience",
    name: "",
    placeholder: "Capacity Combined",
    label: "Capacity Combined",
    id: "capacity",
    priority: 2,
    type: "number",
    defaultValue: "",
  },
  // {
  //   section: "Pricing",
  //   type: "Section",
  // },
  // {
  //   section: "Pricing",
  //   name: "priceWknd",
  //   placeholder: "Price Thu - Sat",
  //   label: "Price Thu - Sat",
  //   id: "priceWknd",

  //   type: "number",
  //   defaultValue: "15000",
  // },
  // {
  //   section: "Pricing",
  //   name: "priceWkday",
  //   placeholder: "Price Sun - Wed",
  //   label: "Price Sun - Wed",
  //   id: "priceWkday",

  //   type: "number",
  //   defaultValue: "10000",
  // },
  {
    section: "Tickets",
    priority: 2,
    type: "Section",
  },
  {
    section: "Tickets",
    priority: 1,
    type: "ticketService",
  },
  {
    //(percentage of rent to precharge)
    section: "Tickets",
    name: "Ticket Service Active",
    placeholder: "Ticket Service Active",
    label: "Ticket Service Active",
    id: "ticketServiceActive",
    // options: ["true", "false"],
    priority: 2,
    // type: "switch",
    // style: { width: "200px", color: "white" },
    // defaultValue='false',
    info: "",
  },
  {
    //(percentage of rent to precharge)
    section: "Tickets",
    name: "Deduct from tickets",
    placeholder: "Deduct from tickets",
    label: "Deduct from tickets",
    id: "deductFromTickets",
    // options: ["true", "false"],
    priority: 2,
    // type: "switch",
    // style: { width: "200px", color: "white" },
    // defaultValue='false',
    info: "Generally deduct rental fee and additional costs from ticket sales.",
  },
  {
    //(percentage of rent to precharge)
    section: "Tickets",
    name: "Ticket API key",
    placeholder: "Ticket API key",
    label: "Ticket API key",
    id: "ticketApiKey",
    // options: ["true", "false"],
    priority: 2,
    // type: "text",
    // style: { width: "200px", color: "white" },
    // defaultValue='false',
    info: "Enter API key from ticket provider.",
  },

  {
    section: "Tickets",
    name: "Ticket Service",
    placeholder: "Ticket Service",
    label: "Ticket Service",
    id: "ticketService",
    options: ["None", "Nortic", "Tixly", "Biletto"],
    priority: 2,
    // type: "select",
    style: { width: "200px", color: "white" },
    defaultValue: "None",
    info: "Choose ticket service provider.",
  },
  {
    section: "Tickets",
    name: "Username",
    placeholder: "Username (Tickster)",
    label: "Username",
    id: "ticksterUsername",
    // options: ["None", "Nortic", "Tixly", "Biletto"],
    priority: 2,
    // type: "select",
    style: { width: "200px", color: "white" },
    defaultValue: "None",
    info: "Ticket service username.",
  },
  {
    section: "Tickets",
    name: "Password",
    placeholder: "Password (Tickster)",
    label: "Password",
    id: "ticksterPassword",
    // options: ["None", "Nortic", "Tixly", "Biletto"],
    priority: 2,
    // type: "select",
    style: { width: "200px", color: "white" },
    defaultValue: "None",
    info: "Ticket service password.",
  },
  // {
  //   section: "Tickets",
  //   placeholder: "Telephone",
  //   label: "Telephone",
  //   id: "publicPhone",
  //   priority: 1,
  //   type: "phone",
  // },
  // {
  //   section: "Tickets",
  //   name: "Email",
  //   placeholder: "Email",
  //   label: "Email",
  //   id: "publicEmail",
  //   priority: 1,
  //   type: "email",
  //   defaultValue: "",
  // },
  // {
  //   section: "Tickets",
  //   name: "Open Hours",
  //   placeholder: "monday 10:00-16:00...",
  //   label: "Open Hours",
  //   id: "openHours",
  //   priority: 1,
  //   type: "text",
  //   defaultValue: "",
  //   multiline: true,
  // },

  // { id: "upfront", label: "Upfront", placeholder: "Upfront", priority: 2, section: "Payment", type: "text", defaultValue: 0 },
  // { id: "upfrontAt", label: "Upfront at", placeholder: "Upfront at", priority: 2, section: "Payment", type: "text", defaultValue: 365 },
  // { id: "preCharge", label: "Deposit", placeholder: "Deposit", priority: 2, section: "Payment", type: "text", defaultValue: 30 },
  // {
  //   id: "preChargeAt",
  //   label: "Charge deposit days ahead",
  //   placeholder: "Charge deposit days ahead",
  //   priority: 2,
  //   section: "Payment",
  //   type: "text",
  //   defaultValue: 180,
  // },
  // { id: "charge", label: "Balance", placeholder: "Balance", priority: 2, section: "Payment", type: "text", defaultValue: 70 },
  // {
  //   id: "chargeAt",
  //   defaultValue: 60,
  //   label: "Charge full payment days ahead",
  //   placeholder: "Charge full payment days ahead",
  //   priority: 2,
  //   section: "Payment",
  //   type: "text",
  // },
  {
    section: "Payment",
    priority: 2,
    type: "Section",
  },
  {
    section: "Payment",
    label: "Payments",
    priority: 2,
    valueLabelDisplay: "auto",
    id: "payments",
    min: 0,
    max: 100,
    step: 5,
    defaultValue: 0,
    type: "paymentSlider",
  },
  {
    section: "Cancelations",
    priority: 2,
    type: "Section",
  },
  {
    section: "Cancelations",
    label: "Early Cancelation Fee",
    priority: 20,
    id: "earlyCancelation",
    defaultValue: 30,
    type: "text",
  },
  {
    section: "Cancelations",
    label: "Early Cancelation, days ahead of event",
    priority: 20,
    id: "earlyCancelationAt",
    defaultValue: 180,
    type: "text",
  },
  {
    section: "Cancelations",
    label: "Late Cancelation Fee",
    priority: 20,
    id: "lateCancelation",
    defaultValue: 80,
    type: "text",
  },
  {
    section: "Cancelations",
    label: "Late Cancelation, days ahead of event",
    priority: 20,
    id: "lateCancelationAt",
    defaultValue: 60,
    type: "text",
  },
  {
    section: "Cancelations",
    label: "Cancelations",
    priority: 2,
    valueLabelDisplay: "auto",
    id: "cancelations",
    defaultValue: 0,
    type: "cancelationSlider",
  },

  {
    section: "Payment",
    label: "Upfront Payment",
    priority: 20,
    valueLabelDisplay: "auto",
    id: "upfront",
    min: 0,
    max: 100,
    step: 5,
    defaultValue: 10,
    type: "slider",
  },
  {
    section: "Payment",
    label: "Upfront Payment",
    priority: 20,
    valueLabelDisplay: "auto",
    id: "upfrontAt",
    min: 0,
    max: 100,
    step: 5,
    defaultValue: 1000, // 1000 equals "Direct"
    type: "slider",
  },
  {
    //(percentage of rent to precharge)
    section: "Payment",
    name: "Deposit",
    placeholder: "Deposit",
    label: "Deposit",
    id: "preCharge",
    min: 0,
    max: 100,
    step: 5,
    valueLabelDisplay: "auto",
    priority: 40,
    type: "slider",
    style: { width: "200px", color: "white" },
    defaultValue: 50,
    info: "Enter how much of the rental fee should be payed ahead",
    placement: "right",
  },
  {
    //(days ahead of event to precharge rent)
    section: "Payment",
    name: "Charge deposit days ahead",
    placeholder: "Charge deposit days ahead",
    label: "Charge deposit days ahead",
    id: "preChargeAt",
    defaultValue: 120,
    min: 0,
    max: 300,
    step: 5,
    valueLabelDisplay: "auto",
    priority: 20,
    type: "slider",
    info: "Enter days ahead of event to precharge rent",
    placement: "right",
  },
  {
    //(days ahead of event to precharge rent)
    section: "Payment",
    name: "Charge full payment days ahead",
    placeholder: "Charge full payment days ahead",
    label: "Charge full payment days ahead",
    id: "balance",
    defaultValue: 50,
    priority: 20,
    type: "number",
    info: "Enter days ahead of event to charge full pyment.",
  },
  {
    //(days ahead of event to precharge rent)
    section: "Payment",
    name: "Charge full payment days ahead",
    placeholder: "Charge full payment days ahead",
    label: "Charge full payment days ahead",
    id: "balanceAt",
    defaultValue: 60,
    priority: 20,
    type: "number",
    info: "Enter days ahead of event to charge full pyment.",
  },

  {
    section: "Bank Account",
    priority: 2,
    type: "Section",
  },
  {
    //(days after event to charge remaining amount including extras)
    section: "Bank Account",
    name: "IBAN",
    placeholder: "IBAN",
    label: "IBAN",
    id: "IBAN",
    priority: 2,
    // type: "encrypted",
    type: "IBAN",
    defaultValue: "",
    info: "Enter your IBAN account number. This is where you will recieve payments.",
  },
  {
    //(days after event to charge remaining amount including extras)
    section: "Bank Account",
    name: "BIC",
    placeholder: "BIC",
    label: "BIC",
    id: "BIC",
    priority: 2,
    // type: "encrypted",
    type: "BIC",
    defaultValue: "",
    info: "Enter your BIC  number.",
  },
  {
    section: "Bank Account",
    name: "Currency",
    placeholder: "Currency",
    label: "Currency",
    id: "currency",
    disabled: false,
    priority: 2,
    type: "select",
    options: [
      "SEK",
      "",
      // "EUR",
      // "US",
    ],
    defaultValue: "SEK",
    info: "Enter your preferred currency. (Currently only SEK)",
  },
  {
    section: "Administrative",
    priority: 2,
    type: "Section",
  },
  {
    section: "Administrative",
    name: "Accounting Email",
    placeholder: "Accounting Email",
    label: "Accounting Email",
    id: "accountingEmail",
    priority: 2,
    defaultValue: "",
    type: "text",
    info: "Enter accounting recipient",
  },
  {
    section: "Administrative",
    name: "Charge span",
    placeholder: "Charge span",
    label: "Charge span",
    id: "chargeSpan",
    priority: 2,
    defaultValue: "Monthly",
    options: ["Weekly", "Monthly"],
    type: "select",
    info: "Bundle charges weekly or monthly",
  },

  // {
  //   section: "Payment",
  //   name: "Standard Vat",
  //   placeholder: "Standard Vat",
  //   label: "Standard Vat",
  //   id: "standardVat",
  //   priority: 2,
  //   type: "standardVat",
  //   defaultValue: "Standard Rate",
  //   info: "Set standard Vat rate for rental fee",
  // },

  // {
  // 	section:'Pricing',
  // 	name:'price',
  // 	placeholder:'Price Sun - Wed',
  //    label: "Price Sun - Wed"),
  // 	id:'preeice',

  // 	type:'number'
  // },
  {
    section: "Stage",
    priority: 3,
    type: "Section",
  },
  {
    section: "Stage",
    name: "Stage Width",
    placeholder: "Stage Width",
    label: "Stage Width",
    id: "stageWidth",
    priority: 3,
    type: "number",
  },
  {
    section: "Stage",
    name: "Stage Height",
    placeholder: "Stage Height",
    label: "Stage Height",
    id: "stageHeight",
    priority: 3,
    type: "number",
  },
  {
    section: "Stage",
    name: "Stage Depth",
    placeholder: "Stage Depth",
    label: "Stage Depth",
    id: "stageDepth",
    priority: 3,
    type: "number",
  },
  {
    section: "Stage",
    name: "Clearance Total",
    placeholder: "Clearance Total",
    label: "Clearance Total",
    id: "clearanceTotal",
    priority: 3,
    type: "number",
  },
  {
    section: "Stage",
    name: "Clearance to house rig",
    placeholder: "Clearance to house rig",
    label: "Clearance to house rig",
    id: "clearanceRig",
    priority: 3,
    type: "number",
  },
  {
    section: "Venue Features",
    priority: 3,
    type: "Section",
  },
  {
    section: "Venue Features",
    type: "VenueAdditionals",
    priority: 3,
  },
  {
    section: "Lights",
    priority: 30,
    type: "Section",
  },
  {
    section: "Lights",
    name: "",
    placeholder: "Lights",
    label: "Lights",
    id: "lights",
    priority: 30,
    type: "textarea",
  },
  {
    section: "Sound Equipment",
    type: "Section",
  },
  {
    section: "Sound Equipment",
    placeholder: "Mixerposition",
    label: "Mixerposition",
    id: "mixerposition",

    type: "text",
    defaultValue: "",
  },
  {
    section: "Sound Equipment",
    placeholder: "House PA",
    label: "House PA",
    id: "housepa",

    type: "text",
    defaultValue: "",
  },
  {
    section: "Sound Equipment",
    placeholder: "Power",
    label: "Power",
    id: "power",

    type: "text",
    defaultValue: "",
  },
  {
    section: "Sound Equipment",
    placeholder: "Power Points",
    label: "Power Points",
    id: "powerpoints",

    type: "text",
    defaultValue: "",
  },
  {
    section: "Sound Equipment",
    placeholder: "Main speaker system",
    label: "Main speaker system",
    id: "mainSpeakerSyst",

    type: "text",
    defaultValue: "",
  },
  {
    section: "Sound Equipment",
    placeholder: "Front Fill",
    label: "Front Fill",
    id: "frontFill",

    type: "text",
    defaultValue: "",
  },
  {
    section: "Sound Equipment",
    placeholder: "Amplification",
    label: "Amplification",
    id: "amplification",

    type: "text",
    defaultValue: "",
  },
  {
    section: "Sound Equipment",
    placeholder: "Delay system",
    label: "Delay system",
    id: "delaySystem",

    type: "text",
    defaultValue: "",
  },
  {
    section: "Sound Equipment",
    placeholder: "Amp delay system",
    label: "Amp delay system",
    id: "ampDelaySystem",

    type: "text",
    defaultValue: "",
  },
  {
    section: "Sound Equipment",
    placeholder: "FOH mixer/driverack",
    label: "FOH mixer/driverack",
    id: "FOHmixerDriverack",

    type: "text",
  },
  {
    section: "Sound Equipment",
    placeholder: "Monitor mixer",
    label: "Monitor mixer",
    id: "monitorMixer",

    type: "text",
  },
  {
    section: "Sound Equipment",
    placeholder: "Stagerack/Splitter",
    label: "Stagerack/Splitter",
    id: "stagerackSplitter",
    type: "text",
  },
  {
    section: "Sound Equipment",
    placeholder: "Signal Stage-FOH",
    label: "Signal Stage-FOH",
    id: "signalStageFOH",
    type: "text",
  },
  {
    section: "Sound Equipment",
    placeholder: "Analog mixer",
    label: "Analog mixer",
    id: "analogMixer",
    type: "text",
  },
  {
    section: "Sound Equipment",
    placeholder: "Dynamics",
    label: "Dynamics",
    id: "dynamics",
    type: "text",
  },
  {
    section: "Sound Equipment",
    placeholder: "Wedges",
    label: "Wedges",
    id: "wedges",
    type: "text",
  },
  {
    section: "Sound Equipment",
    placeholder: "Sidefills",
    label: "Sidefills",
    id: "sidefills",
    type: "text",
  },
  {
    section: "Sound Equipment",
    placeholder: "Drumfill subs",
    label: "Drumfill subs",
    id: "drumfillSubs",
    type: "text",
  },
  {
    section: "Sound Equipment",
    placeholder: "Microphones / DIs",
    label: "Microphones / DIs",
    id: "microphonesDIs",
    type: "text",
  },
  {
    section: "Sound Equipment",
    placeholder: "Stands",
    label: "Stands",
    id: "stands",
    priority: 10,
    type: "text",
  },
  {
    section: "Sound Equipment",
    placeholder: "Cables",
    label: "Cables",
    id: "cables",
    priority: 10,
    type: "text",
  },
  // {
  //   section: "Rooms",
  //   priority: 3,
  //   type: "Section",
  // },
  // {
  //   section: "Rooms",
  //   placeholder: "Dressing Rooms",
  //   label: "Dressing Rooms",
  //   id: "dressingrooms",
  //   priority: 3,
  //   type: "number",
  // },
  // {
  //   section: "Rooms",
  //   placeholder: "Production Office",
  //   label: "Production Office",
  //   id: "productionoffice",
  //   type: "number",
  // },
  // {
  //   section: "Rooms",
  //   placeholder: "Catering Area",
  //   label: "Catering Area",
  //   id: "cateringarea",
  //   priority: 3,
  //   type: "number",
  // },
  // {
  // 	section: 'Standard Running Times',
  // 	type: 'Section',
  // },
  // {
  //   section: "Standard Running Times",
  //   placeholder: "Load In Weekdays",
  //   label: "Load In Weekdays",
  //   id: "loadInWeekdays",
  //   type: "time",
  //   defaultValue: "14:00",
  // },
  // {
  //   section: "Standard Running Times",
  //   placeholder: "Load In Weekends",
  //   label: "Load In Weekends",
  //   id: "loadInWeekends",
  //   type: "time",
  //   defaultValue: "14:00",
  // },
  // {
  //   section: "Standard Running Times",
  //   placeholder: "Soundcheck Weekdays",
  //   label: "Soundcheck Weekdays",
  //   id: "soundcheckWeekdays",
  //   type: "time",
  //   defaultValue: "15:00",
  // },
  // {
  //   section: "Standard Running Times",
  //   placeholder: "Soundcheck Weekends",
  //   label: "Soundcheck Weekends",
  //   id: "soundcheckWeekends",
  //   type: "time",
  //   defaultValue: "15:00",
  // },
  // {
  //   section: "Standard Running Times",
  //   placeholder: "Doors",
  //   label: "Doors",
  //   id: "doors",
  //   type: "time",
  //   defaultValue: "18:00",
  // },
  // {
  //   section: "Standard Running Times",
  //   placeholder: "Show",
  //   label: "Show",
  //   id: "show",
  //   type: "time",
  //   defaultValue: "20:00",
  // },
  // {
  //   section: "Standard Running Times",
  //   placeholder: "Curfew Weekdays",
  //   label: "Curfew Weekdays",
  //   id: "curfewWeekdays",
  //   type: "time",
  //   defaultValue: "23:00",
  // },
  // {
  //   section: "Standard Running Times",
  //   placeholder: "Curfew Weekends",
  //   label: "Curfew Weekends",
  //   id: "curfewWeekends",
  //   type: "time",
  //   defaultValue: "01:00",
  // },
  // {
  // 	section:'Genre',
  // 	placeholder:'Genre',
  //    label: "Genre",
  // 	id:'genre',

  // 	options:['Concert', 'Theater', 'Stand Up', 'Private Event', 'DJ/Club'],
  // 	type:'select'
  // },

  //   {
  //     section: "Bookings",
  //     type: "Section",
  //   },
  //   {
  //     section: "Bookings",
  //     name: "bookings",
  //     placeholder: "Bookings",
  //     label: "Bookings",
  //     id: "bookings",

  //     type: "text",
  //   },
  // {
  // 	section:'submitta',
  // 	name:'submit',
  // 	placeholder:'Submit this Venue',
  //    label: "Submit this Venue"),
  // 	id:'send',

  // 	type:'submit'
  // }

  // {
  //   section: "Messages",
  //   name: "declineMessage",
  //   placeholder: "Decline message",
  //   label: "Decline message",
  //   id: "declineMessage",
  //   defaultValue: "Unfortunately this date is no longer available.",
  //   priority: 2,
  //   type: "text",
  //   width: "300px",
  //   multiline: true,
  // },

  {
    section: "Notifications",
    type: "Section",
    priority: 1,
  },
  ,
  {
    //(percentage of rent to precharge)
    section: "Notifications",
    name: "Notifications",
    placeholder: "Notifications",
    label: "on confirmed",
    id: "notifications",
    // options: ["true", "false"],
    priority: 2,
    type: "switch",
    // style: { width: "200px", color: "white" },
    // defaultValue='false',
    info: "Get notified when bookings are confirmed.",
  },
  {
    //(percentage of rent to precharge)
    section: "Notifications",
    name: "Requests",
    placeholder: "Notifications",
    label: "on requests",
    id: "notificationsOnRequest",
    // options: ["true", "false"],
    priority: 2,
    type: "switch",
    // style: { width: "200px", color: "white" },
    // defaultValue='false',
    info: "Get notified on incoming requests.",
  },
  {
    //(percentage of rent to precharge)
    section: "Notifications",
    name: "Payments",
    placeholder: "Notifications",
    label: "on payments",
    id: "notificationsOnPayment",
    // options: ["true", "false"],
    priority: 2,
    type: "switch",
    // style: { width: "200px", color: "white" },
    // defaultValue='false',
    info: "Get notified on payments.",
  },
  {
    //(percentage of rent to precharge)
    section: "Notifications",
    name: "Late Payments",
    placeholder: "Notifications",
    label: "on late payments",
    id: "notificationsOnLatePayment",
    // options: ["true", "false"],
    priority: 2,
    type: "switch",
    // style: { width: "200px", color: "white" },
    // defaultValue='false',
    info: "Get notified on late payments.",
  },
  { id: "features" },
  { type: "Section", section: " " },
  {
    section: "Bottom Buttons",
    id: "update",
    priority: 2,
    type: "update",
    label: "Save",
    collection: "Venues",
  },
  {
    section: "Bottom Buttons",
    id: "goback",
    priority: 2,
    type: "goback",
    label: "Cancel",
  },
  {
    section: "Bottom Buttons",
    id: "delete",
    priority: 2,
    type: "deleteVenue",
    label: "Delete",
    collection: "Venues",
  },
];

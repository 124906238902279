/** @format */

import {
  Avatar,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemButton,
  Modal,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import WhatDayIsIt from "../components/MiscUIComponents/WhatDayIsIt";
import PaymentSlider from "./PaymentSlider";
import CancelationSlider from "./CancelationSlider";
import shortText from "./shortText";
import { db } from "../firebase";
import { collection, doc, onSnapshot, query, setDoc, where } from "firebase/firestore";
import { Delete, Settings, SettingsAccessibility } from "@mui/icons-material";
import ConfirmDialog from "./ConfirmDialog";
import { useDispatch } from "react-redux";
import { storeCurrentAgreements } from "../features/venueFilter/venueFilterSlice";
import Edit from "@mui/icons-material/Edit";
import BookingFormGeneral from "../components/Forms/BookingFormGeneral";
import SelectProductInBookingForm from "../components/MiscUIComponents/SelectProductInBookingForm";
import { set } from "date-fns";

const ConnectAgreement = ({ agreementId }) => {
  const currentVenueBookings = useSelector((state) => state.counter.currentVenueBookings);
  const currentAgreements = useSelector((state) => state.counter.currentAgreements);
  const currentVenue = useSelector((state) => state.counter.currentVenue);

  const selectedButtonRef = useRef(null);

  //
  //   Sorting bokings by date, artist and agreementId
  const sortedBookingsDate = [...currentVenueBookings].sort((a, b) => {
    return new Date(a.date) - new Date(b.date);
  });

  const sortedBookingsArtist = [...currentVenueBookings].sort((a, b) => {
    const artistA = a.artist && a.artist.artist && a.artist.artist.toLowerCase(); // Convert to lowercase for case-insensitive comparison
    const artistB = b.artist && b.artist.artist && b.artist.artist.toLowerCase();
    if (artistA < artistB) return -1;
    if (artistA > artistB) return 1;
    return 0; // If they are equal
  });

  const sortedBookingsAgreementId = [...currentVenueBookings].sort((a, b) => {
    const agreementA = a.agreementId && a.agreementId.toLowerCase(); // Convert to lowercase for case-insensitive comparison
    const agreementB = b.agreementId && b.agreementId.toLowerCase();
    if (agreementA < agreementB) return -1;
    if (agreementA > agreementB) return 1;
    return 0; // If they are equal
  });

  const dispatch = useDispatch();

  const [sortedBookings, setSortedBookings] = useState(currentVenueBookings && currentVenueBookings);
  const [open, setOpen] = useState(false);
  const [settingsOpen, setSettingsOpen] = useState(false);
  const [sortBy, setSortBy] = useState();
  const [loading, setLoading] = useState(false);

  const [bookingIds, setBookingIds] = useState([]);
  const [connectedBookings, setConnectedBookings] = useState(
    sortedBookings && bookingIds && sortedBookings.filter((booking) => bookingIds.includes(booking.bookingid))
  );
  const filteredAgreements = currentAgreements.filter((agreement) =>
    currentVenueBookings.some((booking) => booking.agreementId === agreement.agreementId)
  );
  const [currentAgreement, setCurrentAgreement] = useState(filteredAgreements && filteredAgreements[0]);
  const [agreementData, setAgreementData] = useState();
  // console.log("currentVenueBookings", currentVenueBookings);
  // console.log("currentAgreements", currentAgreements);

  // for editBookings
  const [openBooking, setOpenBooking] = useState(false);
  const [bookingToEdit, setBookingToEdit] = useState();
  const closeBooking = () => {
    setOpenBooking(false);
  };
  const handleBookingToEdit = (booking) => {
    setBookingToEdit(booking);
    setOpenBooking(true);
  };

  //
  const handleChange = (event, newBookingIds) => {
    // if no agreement is selected, select the agreement of the clicked booking
    if (!currentAgreement) {
      const tmpAgreement = filteredAgreements.filter((agreement) => agreement.bookingids.includes(newBookingIds[0]));
      handleSelectAgreement(tmpAgreement[0]);
      return;
    }
    // otherwise, update the bookingIds
    setBookingIds(newBookingIds);
  };

  const handleSortBy = (event, newSortBy) => {
    // console.log(newSortBy);
    setSortBy(newSortBy);
    if (newSortBy === "date") {
      setSortedBookings(sortedBookingsDate);
    } else if (newSortBy === "artist") {
      setSortedBookings(sortedBookingsArtist);
    } else if (newSortBy === "agreementId") {
      setSortedBookings(sortedBookingsAgreementId);
    }
  };

  const handleSelectAgreement = (agreement) => {
    if (!agreement) {
      setBookingIds([]);
      setCurrentAgreement(null);
      return;
    }
    setBookingIds(agreement.bookingids);
    setCurrentAgreement(agreement);
    setAgreementData(agreement);

    // scroll to selected agreement
    document.getElementById(agreement.agreementId).scrollIntoView({ behavior: "smooth" });

    // then scroll to first booking in agreement, or if it doesn't exist, scroll to last booking
    setTimeout(() => {
      if (connectedBookings.includes((booking) => booking.bookingid === agreement.bookingids[0])) {
        agreement.bookingids && document.getElementById(agreement.bookingids[0]).scrollIntoView({ behavior: "smooth" });
      } else {
        const numberOfBookingids = agreement.bookingids.length - 1;
        agreement.bookingids && document.getElementById(agreement.bookingids[numberOfBookingids]).scrollIntoView({ behavior: "smooth" });
      }
    }, 300);
  };

  const storeAgreement = async (agreement) => {
    setLoading(true);
    // only bring visible bookingIds (not production bookings from tickster sync)
    const bookingIds = connectedBookings.map((booking) => booking.bookingid);

    // Remove bookingIds from any agreements previously connected to the booking
    const agreementsToRemoveBookingFrom = currentAgreements.filter((item) => item.bookingids.some((id) => bookingIds.includes(id)));
    agreementsToRemoveBookingFrom.forEach(async (obj) => {
      const newBookingIds = obj.bookingids.filter((id) => !bookingIds.includes(id));
      await setDoc(doc(db, "Agreements", obj.agreementId), {
        ...obj,
        bookingids: newBookingIds,
      });
    });

    // ...Now store, and only bring visible bookingIds
    await setDoc(doc(db, "Agreements", agreement.agreementId), {
      ...agreement,
      bookingids: bookingIds,
    });

    // ...and lastly update the agreementId in the bookings
    bookingIds.forEach(async (id) => {
      const booking = connectedBookings.find((booking) => booking.bookingid === id);
      await setDoc(doc(db, "Bookings", booking.bookingid), {
        ...booking,
        agreementId: agreement.agreementId,
      });
    });

    setLoading(false);
  };

  const DeleteAgreement = async () => {
    setLoading(true);
    // Delete current agreement
    await db.collection("Agreements").doc(currentAgreement.agreementId).delete();
  };

  useEffect(() => {
    // console.log("currentAgreement", currentAgreement);
  }, [currentAgreement]);

  useEffect(() => {
    handleSortBy(null, "artist");
  }, [currentVenueBookings]);

  useEffect(() => {
    connectedBookings && bookingIds && setConnectedBookings(sortedBookings.filter((booking) => bookingIds.includes(booking.bookingid)));
  }, [bookingIds]);

  // centered box
  const style = {
    display: "flex",
    flexWrap: "wrap",
    gap: 1,
    p: 1,
    mt: "5vh",
    ml: "5vw",
    width: "90vw",
    height: "90vh",
    bgcolor: "linearGradient(180deg, rgba(255,255,255,0.1), rgba(0,0,0,0.5))",
    justifyContent: "center",
    alignItems: "center",
  };

  const settingsStyle = {
    // display: "flex",
    // flexWrap: "wrap",
    gap: 1,
    p: 10,
    mt: "5vh",
    ml: "5vw",
    width: "80vw",
    height: "80vh",
    bgcolor: "linearGradient(180deg, rgba(255,255,255,0.1), rgba(0,0,0,0.5))",
    // justifyContent: "center",
    // alignItems: "center",
  };

  useEffect(() => {
    if (!currentAgreement) return;
    setBookingIds(currentAgreement.bookingids && currentAgreement.bookingids);
  }, [currentAgreement]);

  //   useEffect(() => {
  //     setCurrentAgreement((previous) => ({ ...previous, ...agreementData }));
  //   }, [agreementData]);

  // Set agreements listener
  useEffect(() => {
    const agreementsRef = collection(db, "Agreements");
    const q = query(agreementsRef, where("venueid", "==", currentVenue.venueid));

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const updatedAgreements = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      dispatch(storeCurrentAgreements(updatedAgreements));
    });

    return () => unsubscribe();
  }, [currentVenue.venueid, dispatch]); // Add dispatch to dependencies

  useEffect(() => {
    if (bookingIds.length === 0) setCurrentAgreement(null);
  }, [bookingIds]);

  useEffect(() => {
    setTimeout(() => {
      // setConnectedBookings([]);
      setBookingIds([]);
    }, 200);
  }, []);

  useEffect(() => {
    // if agreementId is passed, set currentAgreement and get bookingData
    setTimeout(() => {
      if (agreementId && !currentAgreement) {
        setCurrentAgreement(filteredAgreements.find((agreement) => agreement.agreementId === agreementId));
        setAgreementData(filteredAgreements.find((agreement) => agreement.agreementId === agreementId));
        console.log("filteredAgreements", filteredAgreements);
      }
    }, 1000);
  }, [filteredAgreements]);

  return (
    <div>
      {!open && (
        <Button onClick={() => setOpen(true)} startIcon={<Edit />}>
          Manage agreements
        </Button>
      )}
      {/* {bookingIds.length}/{sortedBookings.length} */}
      <Modal open={open} onClose={() => setOpen(!open)} aria-labelledby="modal-connectAgreement">
        <Box sx={style} className="standardModal">
          <Grid container style={{ height: "4" }}>
            {/* current Agreement:{currentAgreement ? currentAgreement.agrementId : "none"}
            agreementId:{agreementId ? agreementId : "none"} */}
            <Grid item xs={12} md={3} p={2}>
              <h2>Agreements</h2>
            </Grid>
            <Grid item xs={12} md={9}>
              <ToggleButtonGroup value={sortBy} onChange={handleSortBy} className="sortBy" size="small" exclusive>
                Sort by:
                <ToggleButton value="date">Date</ToggleButton>
                <ToggleButton value="artist">Artist</ToggleButton>
                <ToggleButton value="agreementId">Agreement</ToggleButton>
              </ToggleButtonGroup>
            </Grid>
            <Grid item xs={12} md={9}>
              {/* {currentAgreement && (
                <table>
                  <tr>Upfront: {agreementData.upfront}%, upon agreement signature.</tr>
                  <tr>
                    Deposit: {agreementData.preCharge}%, {agreementData.preChargeAt} days ahead of event.
                  </tr>
                  <tr>
                    Balance: {agreementData.balance}%, {agreementData.balanceAt} days ahead of event.
                  </tr>
                  <tr>
                    Early cancelation: {agreementData.earlyCancelation}%, {agreementData.earlyCancelationAt} days ahead of event.
                  </tr>
                  <tr>
                    Late cancelation: {agreementData.lateCancelation}%, {agreementData.lateCancelationAt} days ahead of event.
                  </tr>
                </table>
              )} */}
              <Modal open={settingsOpen} onClose={() => setSettingsOpen(false)} aria-labelledby="modal-settings">
                <Box sx={settingsStyle} className="standardModal">
                  <Grid container>
                    <Grid item xs={12} md={6}>
                      <h2>
                        <input
                          type="text"
                          value={agreementData && agreementData.eventTitle ? agreementData.eventTitle : ""}
                          placeholder="Artist or Event"
                          className="bookingArtist"
                          style={{ cursor: "pointer" }}
                          contentEditable
                          maxLength={30}
                          onChange={(e) =>
                            setAgreementData({
                              ...agreementData,
                              eventTitle: e.target.value,
                            })
                          }
                        />
                        <div>
                          {"("}
                          {agreementData && agreementData.bookingids.length} date
                          {agreementData && agreementData.artist && agreementData.bookingids.length > 1 && "s"}
                          {")"}
                        </div>
                      </h2>
                      <div>
                        <PaymentSlider
                          currentVenue={currentVenue}
                          bookingData={agreementData}
                          setBookingData={setAgreementData}
                          formlock={false}
                        />
                      </div>
                      <div>
                        <CancelationSlider bookingData={agreementData} setBookingData={setAgreementData} formlock={false} />
                      </div>
                    </Grid>
                    {agreementData && (
                      <Grid item xs={12} md={6}>
                        <h7>Customer info</h7>
                        <table className="smallTable">
                          <tr>
                            <td>First Name:</td>
                            <td>
                              <input
                                contentEditable
                                value={agreementData.agent.firstName}
                                placeholder="First Name"
                                onChange={(e) =>
                                  setAgreementData((previous) => ({
                                    ...previous,
                                    agent: { ...previous.agent, firstName: e.target.value },
                                  }))
                                }
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>Last Name:</td>
                            <td>
                              <input
                                contentEditable
                                value={agreementData.agent.lastName}
                                placeholder="Last Name"
                                onChange={(e) =>
                                  setAgreementData((previous) => ({
                                    ...previous,
                                    agent: { ...previous.agent, lastName: e.target.value },
                                  }))
                                }
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>Email:</td>
                            <td>
                              <input
                                contentEditable
                                value={agreementData.agent.email}
                                placeholder="Email"
                                onChange={(e) =>
                                  setAgreementData((previous) => ({
                                    ...previous,
                                    agent: { ...previous.agent, email: e.target.value },
                                  }))
                                }
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>Phone:</td>
                            <td>
                              <input
                                contentEditable
                                value={agreementData.agent.phone}
                                placeholder="Phone"
                                onChange={(e) =>
                                  setAgreementData((previous) => ({
                                    ...previous,
                                    agent: { ...previous.agent, phone: e.target.value },
                                  }))
                                }
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>Street Address:</td>
                            <td>
                              <input
                                contentEditable
                                value={agreementData.agent.streetAddress}
                                placeholder="Street Address"
                                onChange={(e) =>
                                  setAgreementData((previous) => ({
                                    ...previous,
                                    agent: { ...previous.agent, streetAddress: e.target.value },
                                  }))
                                }
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>Zip Code:</td>
                            <td>
                              <input
                                contentEditable
                                value={agreementData.agent.zipCode}
                                placeholder="Zip Code"
                                onChange={(e) =>
                                  setAgreementData((previous) => ({ ...previous, agent: { ...previous.agent, zipCode: e.target.value } }))
                                }
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>City:</td>
                            <td>
                              <input
                                contentEditable
                                value={agreementData.agent.city}
                                placeholder="City"
                                onChange={(e) =>
                                  setAgreementData((previous) => ({ ...previous, agent: { ...previous.agent, city: e.target.value } }))
                                }
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>Company:</td>
                            <td>
                              <input
                                contentEditable
                                value={agreementData.agent.company}
                                placeholder="Company"
                                onChange={(e) =>
                                  setAgreementData((previous) => ({ ...previous, agent: { ...previous.agent, company: e.target.value } }))
                                }
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>Reference:</td>
                            <td>
                              <input
                                contentEditable
                                value={agreementData.agent.reference}
                                placeholder="Reference"
                                onChange={(e) =>
                                  setAgreementData((previous) => ({ ...previous, agent: { ...previous.agent, reference: e.target.value } }))
                                }
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>Org Number:</td>
                            <td>
                              <input
                                contentEditable
                                value={agreementData.agent.orgNumber}
                                placeholder="Org Number"
                                onChange={(e) =>
                                  setAgreementData((previous) => ({ ...previous, agent: { ...previous.agent, orgNumber: e.target.value } }))
                                }
                              />
                            </td>
                          </tr>
                        </table>
                        <h7 style={{ marginTop: "20px" }}>Venue info</h7>
                        <table className="smallTable">
                          <tr>
                            <td>Email:</td>
                            <td>
                              <input
                                contentEditable
                                value={agreementData.venue.email || currentVenue.venue.email}
                                placeholder="Email"
                                onChange={(e) =>
                                  setAgreementData((previous) => ({ ...previous, venue: { ...previous.venue, email: e.target.value } }))
                                }
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>Phone:</td>
                            <td>
                              <input
                                contentEditable
                                value={agreementData.venue.phone || currentVenue.venue.phone}
                                placeholder="Phone"
                                onChange={(e) =>
                                  setAgreementData((previous) => ({ ...previous, venue: { ...previous.venue, phone: e.target.value } }))
                                }
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>Street Address:</td>
                            <td>
                              <input
                                contentEditable
                                value={agreementData.venue.postalStreetAddress || currentVenue.venue.postalStreetAddress}
                                placeholder="Street Address"
                                onChange={(e) =>
                                  setAgreementData((previous) => ({
                                    ...previous,
                                    venue: { ...previous.venue, postalStreetAddress: e.target.value },
                                  }))
                                }
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>Zip Code:</td>
                            <td>
                              <input
                                contentEditable
                                value={agreementData.venue.postalZipCode || currentVenue.venue.postalZipCode}
                                placeholder="Zip Code"
                                onChange={(e) =>
                                  setAgreementData((previous) => ({
                                    ...previous,
                                    venue: { ...previous.venue, postalZipCode: e.target.value },
                                  }))
                                }
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>City:</td>
                            <td>
                              <input
                                contentEditable
                                value={agreementData.venue.postalCity || currentVenue.venue.postalCity}
                                placeholder="City"
                                onChange={(e) =>
                                  setAgreementData((previous) => ({
                                    ...previous,
                                    venue: { ...previous.venue, postalCity: e.target.value },
                                  }))
                                }
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>Company:</td>
                            <td>
                              <input
                                contentEditable
                                value={agreementData.venue.company || currentVenue.venue.company}
                                placeholder="Company"
                                onChange={(e) =>
                                  setAgreementData((previous) => ({ ...previous, venue: { ...previous.venue, company: e.target.value } }))
                                }
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>Org Number:</td>
                            <td>
                              <input
                                contentEditable
                                value={agreementData.venue.orgNumber || currentVenue.venue.orgNumber}
                                placeholder="Org Number"
                                onChange={(e) =>
                                  setAgreementData((previous) => ({ ...previous, venue: { ...previous.venue, orgNumber: e.target.value } }))
                                }
                              />
                            </td>
                          </tr>
                        </table>
                      </Grid>
                    )}
                  </Grid>
                  <Grid container gap={1}>
                    <Button
                      onClick={() => storeAgreement(agreementData)}
                      startIcon={
                        loading && (
                          <span style={{ fontSize: "10px" }}>
                            <CircularProgress />
                          </span>
                        )
                      }
                      variant="contained"
                    >
                      Save
                    </Button>
                    <Button onClick={() => setSettingsOpen(false)} variant="outlined">
                      Close
                    </Button>
                  </Grid>
                </Box>
              </Modal>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} md={3} style={{ height: "44vh", overflow: "scroll" }} ref={selectedButtonRef}>
              <h7>Agreements</h7>
              <div className="agreements">
                {filteredAgreements.map(
                  (agreement) =>
                    agreement.bookingids.length > 0 && (
                      <Button
                        onClick={() => (currentAgreement === agreement ? handleSelectAgreement(null) : handleSelectAgreement(agreement))}
                        size="small"
                        variant={agreement === currentAgreement && "contained"}
                        startIcon={<Avatar src={agreement && agreement.artist && agreement.artist.image} />}
                        style={{ width: "100%", justifyContent: "flex-start" }}
                        id={agreement.agreementId}
                      >
                        ({agreement.bookingids.length}) {agreement.artist && shortText(agreement.artist.artist, 20)}
                      </Button>
                    )
                )}
              </div>
            </Grid>
            <Grid item xs={12} md={9} style={{ height: "44vh", overflow: "scroll" }}>
              <ToggleButtonGroup value={bookingIds} onChange={handleChange} className="connectAgreement">
                {sortedBookings.map((booking) => (
                  <ToggleButton value={booking.bookingid} size="small" id={booking.bookingid}>
                    {/* <Avatar src={booking.artist && booking.artist.image} /> */}
                    <div className="image">
                      <img src={booking.artist && booking.artist.image} height="50px" width="100%" />
                    </div>
                    <div className="title">{booking.artist && booking.artist.artist}</div>
                    <div className="date">
                      <WhatDayIsIt date={booking.date} numberOfLetters={3} />
                      {booking.date}
                    </div>
                  </ToggleButton>
                ))}
              </ToggleButtonGroup>
            </Grid>
          </Grid>
          <Grid container style={{ height: "20vh" }} xs={12}>
            <Grid item xs={12} md={3} p={4}>
              <Button
                onClick={() => setSettingsOpen(!settingsOpen)}
                disabled={currentAgreement ? false : true}
                startIcon={<Settings />}
                variant="outlined"
                fullWidth
              >
                {settingsOpen ? "Close" : "Settings"}
              </Button>
            </Grid>
            {connectedBookings &&
              connectedBookings.map((booking) => (
                <div
                  className="connectedBookings"
                  onClick={() => {
                    handleBookingToEdit(booking);
                  }}
                >
                  <div className="image">
                    <img src={booking.artist && booking.artist.image} height="50px" width="100%" />
                  </div>
                  <div className="title">{booking.artist && booking.artist.artist}</div>
                  <div className="date">
                    <WhatDayIsIt date={booking.date} numberOfLetters={3} />
                    {booking.date}
                  </div>
                  <div style={{ zoom: "0.4" }}>
                    <SelectProductInBookingForm
                      bookingData={booking}
                      setBookingData={setBookingToEdit}
                      currentVenue={currentVenue}
                      formLock={false}
                    />
                  </div>
                  <Dialog
                    className="bookingFormModal"
                    open={openBooking}
                    // onClose={() => closeBooking}
                    // aria-labelledby="alert-dialog-title"
                    // aria-describedby="alert-dialog-description"
                    className="bookingFormModal"
                    sx={{ borderRadius: "20px" }}
                  >
                    {bookingToEdit && <BookingFormGeneral bookingDataProp={bookingToEdit} handleClose={closeBooking} noNumber />}
                  </Dialog>
                </div>
              ))}
          </Grid>
          <Grid container gap={1} p={4}>
            <Button
              onClick={() => storeAgreement(agreementData)}
              startIcon={
                loading && (
                  <span style={{ fontSize: "10px" }}>
                    <CircularProgress />
                  </span>
                )
              }
              variant="contained"
            >
              Save
            </Button>
            <Button onClick={() => setOpen(false)} variant="outlined">
              Close
            </Button>
            {currentAgreement && (
              <ConfirmDialog onConfirm={() => DeleteAgreement} header="Delete selected Agreement?">
                <Button startIcon={<Delete />}>Delete agreement</Button>
              </ConfirmDialog>
            )}
          </Grid>
        </Box>
      </Modal>
    </div>
  );
};

export default ConnectAgreement;

/** @format */

import QRCode from "qrcode";
import { getStorage, ref as storageRef, uploadBytes, getDownloadURL } from "firebase/storage";

export function generateAndUploadQRCode({ bg, amount, ocr, referenceId }) {
  const epcData = `
    {
      "uqr": 1,
      "tp": 1,
      "nme": "",
      "cid": "",
      "idt": "",
      "ddt": "",
      "pt": "BG",
      "iref": "${ocr}",
      "due": ${amount},
      "acc": "363-0274"
    }`;
  // {
  //     "uqr": 1,
  //     "tp": 1,
  //     "nme": "Test AB",
  //     "cid": "1234",
  //     "iref": "1234123412341234",
  //     "idt": "20240415",
  //     "ddt": "20240515",
  //     "due": "50",
  //     "pt": "BG",
  //     "acc": "363-0274"
  //   }`;

  return QRCode.toDataURL(epcData, { errorCorrectionLevel: "H" })
    .then((url) => fetch(url))
    .then((res) => res.blob())
    .then((blob) => {
      const storage = getStorage();
      const qrCodeRef = storageRef(storage, `QR-codes-OCR/${referenceId}.png`);
      return uploadBytes(qrCodeRef, blob).then(() => qrCodeRef);
    })
    .then((qrCodeRef) => getDownloadURL(qrCodeRef))
    .then((url) => {
      console.log("QR code uploaded to Firestore Storage and available at", url);
      return url; // This returns the URL of the uploaded QR code
    })
    .catch((error) => {
      console.error("Error generating or uploading QR code:", error);
      throw error; // Rethrow error to handle it in the calling code
    });
}

/** @format */

import { Accordion, AccordionDetails, AccordionSummary, Button, ButtonGroup, Grid } from "@mui/material";
import React, { useEffect } from "react";
import axios from "axios";
import { useState } from "react";
import { Circle, GoogleMap, LoadScript, Marker, OverlayView, useJsApiLoader } from "@react-google-maps/api";

const TravelFootprint = () => {
  const [travelData, setTravelData] = useState(travelDataA);
  const [targetLocation, setTargetLocation] = useState({ lat: 59.3293, lng: 18.0686 });
  const [zipCode, setZipCode] = useState("");
  const [distance, setDistance] = useState(null);
  const [travelDataWithCoords, setTravelDataWithCoords] = useState();

  const googleApiKey = process.env.REACT_APP_GOOGLE_API_KEY;
  const googleCX = process.env.REACT_APP_GOOGLE_CX;

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: googleApiKey,
  });

  // The center of the map (arbitrary, can be any coordinates)
  const CENTER = {
    lat: 59.3293, // latitude of Stockholm
    lng: 18.0686, // longitude of Stockholm
  };

  async function handleSubmit(event) {
    event.preventDefault();

    const response = await axios.get("https://maps.googleapis.com/maps/api/geocode/json", {
      params: {
        address: zipCode,
        key: googleApiKey, // replace with your actual API key
      },
    });

    const location = response.data.results[0].geometry.location;
    const distanceToStockholm = getDistance(59.3293, 18.0686, location.lat, location.lng);
    setDistance(distanceToStockholm);
    setTargetLocation(location);
  }

  function getDistance(lat1, lon1, lat2, lon2) {
    const R = 6371; // Radius of the earth in km
    const dLat = deg2rad(lat2 - lat1);
    const dLon = deg2rad(lon2 - lon1);
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const distance = R * c; // Distance in km
    return distance;
  }

  function deg2rad(deg) {
    return deg * (Math.PI / 180);
  }

  // Setting json data with coords from ticket data
  const [input, setInput] = useState("");
  const [jsonData, setJsonData] = useState(null);
  const GOOGLE_MAPS_API_KEY = "YOUR_GOOGLE_MAPS_API_KEY"; // Replace with your Google Maps API Key

  const handleInputChange = (event) => {
    setInput(event.target.value);
  };

  const handleConvert = async () => {
    const lines = input.split("\n");
    const header = lines[0].split("\t");

    const data = [];
    for (let i = 1; i < lines.length; i++) {
      const values = lines[i].split("\t");
      let obj = {};
      for (let j = 0; j < header.length; j++) {
        obj[header[j]] = values[j];
      }

      const response = await axios.get("https://maps.googleapis.com/maps/api/geocode/json", {
        params: {
          address: `${obj.City} ${obj.zipCode}`,
          key: googleApiKey,
        },
      });

      if (response.data.results[0]) {
        obj.lat = response.data.results[0].geometry.location.lat;
        obj.lng = response.data.results[0].geometry.location.lng;
      }

      data.push(obj);
      console.log(obj);
    }

    setJsonData(JSON.stringify(data, null, 2));
  };
  const BLUE_MARKER_URL = "http://maps.google.com/mapfiles/ms/icons/blue-dot.png";

  //   const totalDistance = () => {
  //     let dist = 0;
  //     travelData.map((item) => {
  //       dist = getDistance(59.3293, 18.0686, parseFloat(item.lat), parseFloat(item.lng));
  //       console.log(dist);
  //     });
  //     return dist;
  //   };

  // Assuming Stockholm is the starting point for all trips
  const STOCKHOLM_COORDINATES = {
    latitude: 59.3293, // Latitude of Stockholm
    longitude: 18.0686, // Longitude of Stockholm
  };
  let range10km = [];
  let range20km = [];
  let range70km = [];
  let range800km = [];
  let rangeAbroad = [];

  const toRad = (value) => {
    return (value * Math.PI) / 180;
  };

  const [totalDistance, setTotalDistance] = useState(0);
  const [r10km, setR10km] = useState([]);
  const [r20km, setR20km] = useState([]);
  const [r70km, setR70km] = useState([]);
  const [r800km, setR800km] = useState([]);
  const [rAbroad, setRAbroad] = useState([]);

  useEffect(() => {
    let distance = 0;

    travelData.forEach((travel, index) => {
      if (!travel.lat || !travel.lng) return;
      const end = {
        latitude: parseFloat(travel.lat),
        longitude: parseFloat(travel.lng),
      };

      const R = 6371; // Radius of the earth in km
      //   const dLat = toRad(end.latitude - targetLocation.lat);
      //   const dLon = toRad(end.longitude - targetLocation.lon);
      const dLat = toRad(end.latitude - STOCKHOLM_COORDINATES.latitude);
      const dLon = toRad(end.longitude - STOCKHOLM_COORDINATES.longitude);
      const a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        // Math.cos(toRad(targetLocation.lat)) * Math.cos(toRad(end.latitude)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
        Math.cos(toRad(STOCKHOLM_COORDINATES.latitude)) * Math.cos(toRad(end.latitude)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
      const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
      const tripDistance = R * c * travel.visitors; // Distance in km
      if (tripDistance < 10) range10km.push({ ...travel, distance: tripDistance });
      if (tripDistance < 40 && tripDistance > 20) range20km.push({ ...travel, distance: tripDistance });
      if (tripDistance < 70 && tripDistance > 40) range70km.push({ ...travel, distance: tripDistance });
      if (tripDistance < 800 && tripDistance > 70) range800km.push({ ...travel, distance: tripDistance });
      if (tripDistance > 800) rangeAbroad.push({ ...travel, distance: tripDistance });
      distance += tripDistance;
    });

    setTotalDistance(parseFloat(distance));
    setR10km(range10km);
    setR20km(range20km);
    setR70km(range70km);
    setR800km(range800km);
    setRAbroad(rangeAbroad);
  }, [travelData]);

  const [totalvisitors, setTotalvisitors] = useState(0);

  useEffect(() => {
    const visitorCount = travelData.reduce((acc, travel) => acc + parseFloat(travel.visitors), 0);

    setTotalvisitors(visitorCount);
  }, [travelData]);

  const otherData = 100;

  //   lat: 59.3293,
  //     lng: 18.0686,

  return (
    <Grid container p={4} xs={12} gap={4}>
      <Grid item xs={12}>
        <h1>Travel Footprint</h1>
      </Grid>

      {/* <LoadScript googleMapsApiKey={googleApiKey}> */}
      {isLoaded && (
        <Grid className="travelDataMap" xs={12}>
          <GoogleMap
            mapContainerStyle={{ width: "100%", height: "600px" }}
            center={CENTER}
            zoom={5}
            options={{ styles: darkMapStyles, disableDefaultUI: true }}
            className="travelDataMap"
          >
            {travelData.map((data, index) => (
              <OverlayView
                style={{}}
                position={{
                  lat: parseFloat(data.lat),
                  lng: parseFloat(data.lng),
                }}
                mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
              >
                <div
                  className="travelDataPoint"
                  // style={{ width: "20px", height: "20px", background: "#fa5a2655", borderRadius: "20px" }}
                ></div>
              </OverlayView>
            ))}
            <Circle
              center={CENTER}
              radius={10000} // 10 km in meters
              options={{
                fillColor: "blue",
                fillOpacity: 0.0, // adjust for desired transparency
                strokeColor: "#00ffd4",
                strokeOpacity: 0.1, // adjust for desired transparency
              }}
            />
            <Circle
              center={CENTER}
              radius={20000} // 10 km in meters
              options={{
                fillColor: "blue",
                fillOpacity: 0.0, // adjust for desired transparency
                strokeColor: "#00ffd4",
                strokeOpacity: 0.1, // adjust for desired transparency
              }}
            />
            <Circle
              center={CENTER}
              radius={70000} // 10 km in meters
              options={{
                fillColor: "blue",
                fillOpacity: 0.0, // adjust for desired transparency
                strokeColor: "#00ffd4",
                strokeOpacity: 0.1, // adjust for desired transparency
              }}
            />
            <Circle
              center={CENTER}
              radius={500000} // 10 km in meters
              options={{
                fillColor: "blue",
                fillOpacity: 0.0, // adjust for desired transparency
                strokeColor: "#00ffd4",
                strokeOpacity: 0.1, // adjust for desired transparency
                strokeDashArray: "5 2",
              }}
            />
            {/* {travelData.map((data, index) => (
            <Marker key={index} position={{ lat: parseFloat(data.lat), lng: parseFloat(data.lng) }} />
          ))} */}
          </GoogleMap>
        </Grid>
      )}
      {/* </LoadScript> */}
      <Grid item xs={12}>
        <ButtonGroup>
          <Button onClick={() => setTravelData(travelDataD)}>2024</Button>
          <Button onClick={() => setTravelData(travelDataA)}>2023</Button>
          <Button onClick={() => setTravelData(travelDataB)}>2022</Button>
          <Button onClick={() => setTravelData(travelDataC)}>2019</Button>
        </ButtonGroup>
      </Grid>
      <Grid item>
        <h6>Visitor travel</h6>
        <table className="travelData">
          <thead>
            <th>Distance</th>
            <th>Number of visitors</th>
            <th>Average distance traveled (km)</th>
            <th>Total distance traveled (km)</th>
          </thead>
          {/* <tr>
            <td></td>
            <td>{totalvisitors}</td>
            <td>{(totalDistance / totalvisitors).toFixed(2)}</td>
            <td>{totalDistance.toFixed(2)}</td>
          </tr> */}
          <tr>
            <td>0-10km</td>
            <td>{r10km.reduce((acc, travel) => acc + parseFloat(travel.visitors), 0)}</td>
            <td>
              {(
                r10km.reduce((acc, travel) => acc + parseFloat(travel.distance), 0) /
                r10km.reduce((acc, travel) => acc + parseFloat(travel.visitors), 0)
              ).toFixed(2)}
            </td>
            <td>{r10km.reduce((acc, travel) => acc + parseFloat(travel.distance), 0).toFixed(2)}</td>
          </tr>
          <tr>
            <td>10-20km</td>
            <td>{r20km.reduce((acc, travel) => acc + parseFloat(travel.visitors), 0)}</td>
            <td>
              {(
                r20km.reduce((acc, travel) => acc + parseFloat(travel.distance), 0) /
                r20km.reduce((acc, travel) => acc + parseFloat(travel.visitors), 0)
              ).toFixed(2)}
            </td>
            <td>{r20km.reduce((acc, travel) => acc + parseFloat(travel.distance), 0).toFixed(2)}</td>
          </tr>
          <tr>
            <td>20-70km</td>
            <td>{r70km.reduce((acc, travel) => acc + parseFloat(travel.visitors), 0)}</td>
            <td>
              {(
                r70km.reduce((acc, travel) => acc + parseFloat(travel.distance), 0) /
                r70km.reduce((acc, travel) => acc + parseFloat(travel.visitors), 0)
              ).toFixed(2)}
            </td>
            <td>{r70km.reduce((acc, travel) => acc + parseFloat(travel.distance), 0).toFixed(2)}</td>
          </tr>
          <tr>
            <td>70-800km</td>
            <td>{r800km.reduce((acc, travel) => acc + parseFloat(travel.visitors), 0)}</td>
            <td>
              {(
                r800km.reduce((acc, travel) => acc + parseFloat(travel.distance), 0) /
                r800km.reduce((acc, travel) => acc + parseFloat(travel.visitors), 0)
              ).toFixed(2)}
            </td>
            <td>{r800km.reduce((acc, travel) => acc + parseFloat(travel.distance), 0).toFixed(2)}</td>
          </tr>
          <tr>
            <td>800+km</td>
            <td>{rAbroad.reduce((acc, travel) => acc + parseFloat(travel.visitors), 0)}</td>
            <td>
              {(
                rAbroad.reduce((acc, travel) => acc + parseFloat(travel.distance), 0) /
                rAbroad.reduce((acc, travel) => acc + parseFloat(travel.visitors), 0)
              ).toFixed(2)}
            </td>
            <td>{rAbroad.reduce((acc, travel) => acc + parseFloat(travel.distance), 0).toFixed(2)}</td>
          </tr>
        </table>
      </Grid>

      <Grid item>
        <h6>Footprint travel</h6>
        <table className="travelData">
          <thead>
            <th>Transportation</th>
            <th>Used estimate (GHG)</th>
            <th>Share</th>
            <th>CO2e</th>
          </thead>
          <tr>
            <td>Flyg</td>
            <td>0.13 kg/km</td>
            <td>1.7%</td>
            <td>{(0.13 * 531057.32 * 0.017).toFixed(2)}</td>
          </tr>
          <tr>
            <td>Bil</td>
            <td>0.15 kg/km</td>
            <td>7.1%</td>
            <td>{(0.15 * totalDistance * 0.071).toFixed(2)}</td>
          </tr>
          <tr>
            <td>Buss</td>
            <td>0.033 kg/km</td>
            <td>8.3%</td>
            <td>{(0.033 * totalDistance * 0.083).toFixed(2)}</td>
          </tr>
          <tr>
            <td>Tunnelbana</td>
            <td>0.05 kg/km</td>
            <td>40.8%</td>
            <td>{(0.05 * 9314.32 * 0.408).toFixed(2)}</td>
          </tr>
          <tr>
            <td>Tåg</td>
            <td>0.05 kg/km</td>
            <td>13.3%</td>
            <td>{(0.05 * totalDistance * 0.0133).toFixed(2)}</td>
          </tr>
          <tr>
            <td>Taxi</td>
            <td>0.15 kg/km</td>
            <td>13%</td>
            <td>{(0.05 * 9314.32 * 0.013).toFixed(2)}</td>
          </tr>
        </table>
      </Grid>

      <Grid item xs={12}></Grid>
      <Accordion>
        <AccordionSummary>Manual input</AccordionSummary>
        <AccordionDetails>
          <Grid item xs={12}>
            <form onSubmit={handleSubmit}>
              <input type="text" value={zipCode} onChange={(e) => setZipCode(e.target.value)} placeholder="Enter ZIP code" />
              <Button type="submit">Calculate Distance</Button>
            </form>
            {distance && <p>The distance from Stockholm is {distance} km.</p>}
          </Grid>
          <Grid item>
            klistra in tabelldata (3 kolumner)<br></br>
            <textarea value={input} onChange={handleInputChange} rows={10} cols={50} placeholder="visitors(antal) - City - zipCode" />
            <Button onClick={handleConvert}>Convert to JSON</Button>
            <pre>{jsonData}</pre>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Grid>
  );
};

export default TravelFootprint;

const travelDataA = [
  {
    visitors: "2",
    City: "Årsta",
    zipCode: "12054",
    lat: 59.298572,
    lng: 18.0499684,
  },
  {
    visitors: "1",
    City: "Årsta",
    zipCode: "12052",
    lat: 59.29488430000001,
    lng: 18.054947,
  },
  {
    visitors: "2",
    City: "Sundbyberg",
    zipCode: "17264",
    lat: 59.3602077,
    lng: 17.9685871,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11826",
    lat: 59.3156423,
    lng: 18.068439,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11826",
    lat: 59.3156423,
    lng: 18.068439,
  },
  {
    visitors: "1",
    City: "Sundbyberg",
    zipCode: "17270",
    lat: 59.3624863,
    lng: 17.9734906,
  },
  {
    visitors: "1",
    City: "Bromma",
    zipCode: "16831",
    lat: 59.33626659999999,
    lng: 17.9499288,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11667",
    lat: 59.30675269999999,
    lng: 18.0785613,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11733",
    lat: 59.32239069999999,
    lng: 18.0290508,
  },
  {
    visitors: "4",
    City: "Nacka",
    zipCode: "13131",
    lat: 59.315617,
    lng: 18.1334547,
  },
  {
    visitors: "2",
    City: "Bromma",
    zipCode: "16852",
    lat: 59.3358692,
    lng: 17.8924463,
  },
  {
    visitors: "1",
    City: "Lakewood",
    zipCode: "08701-3681",
    lat: 40.0807404,
    lng: -74.2061931,
  },
  {
    visitors: "1",
    City: "Skogås",
    zipCode: "14232",
    lat: 59.2143982,
    lng: 18.1492983,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11347",
    lat: 59.3536674,
    lng: 18.0496961,
  },
  {
    visitors: "2",
    City: "Hägersten",
    zipCode: "12626",
    lat: 59.2991333,
    lng: 17.9887311,
  },
  {
    visitors: "1",
    City: "Hägersten",
    zipCode: "12650",
    lat: 59.3079066,
    lng: 18.0054548,
  },
  {
    visitors: "1",
    City: "Timrå",
    zipCode: "86135",
    lat: 62.4877678,
    lng: 17.3120176,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11620",
    lat: 59.3173323,
    lng: 18.0774194,
  },
  {
    visitors: "1",
    City: "Bromma",
    zipCode: "16732",
    lat: 59.33772389999999,
    lng: 17.9632213,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11345",
    lat: 59.34790940000001,
    lng: 18.0470283,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11853",
    lat: 59.3151166,
    lng: 18.0513174,
  },
  {
    visitors: "1",
    City: "Kista",
    zipCode: "164 76",
    lat: 59.41826829999999,
    lng: 17.909424,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11768",
    lat: 59.31461539999999,
    lng: 18.0001365,
  },
  {
    visitors: "1",
    City: "Bandhagen",
    zipCode: "12457",
    lat: 59.262389,
    lng: 18.0507523,
  },
  {
    visitors: "1",
    City: "MADRID",
    zipCode: "28035",
    lat: 40.4781971,
    lng: -3.7282769,
  },
  {
    visitors: "1",
    City: "Hägersten",
    zipCode: "12949",
    lat: 59.291059,
    lng: 17.9775714,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11637",
    lat: 59.31242160000001,
    lng: 18.0792381,
  },
  {
    visitors: "1",
    City: "Hägersten",
    zipCode: "12626",
    lat: 59.2991333,
    lng: 17.9887311,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11245",
    lat: 59.3343961,
    lng: 18.0257303,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11734",
    lat: 59.3163132,
    lng: 18.0364435,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11642",
    lat: 59.3092808,
    lng: 18.0868777,
  },
  {
    visitors: "1",
    City: "Partille",
    zipCode: "43348",
    lat: 57.72500419999999,
    lng: 12.1361482,
  },
  {
    visitors: "1",
    City: "Lidingö",
    zipCode: "18137",
    lat: 59.3507232,
    lng: 18.1325877,
  },
  {
    visitors: "3",
    City: "Stockholm",
    zipCode: "11263",
    lat: 59.3241564,
    lng: 18.0049513,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11263",
    lat: 59.3241564,
    lng: 18.0049513,
  },
  {
    visitors: "1",
    City: "Norrköping",
    zipCode: "60219",
    lat: 58.5908306,
    lng: 16.1625798,
  },
  {
    visitors: "2",
    City: "Oslo",
    zipCode: "0569",
    lat: 59.92974809999999,
    lng: 10.7739507,
  },
  {
    visitors: "2",
    City: "Nacka",
    zipCode: "13145",
    lat: 59.3135377,
    lng: 18.1969962,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "16274",
    lat: 59.35374100000001,
    lng: 17.8843971,
  },
  {
    visitors: "1",
    City: "Kungsängen",
    zipCode: "19636",
    lat: 59.49486659999999,
    lng: 17.7299275,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11646",
    lat: 59.3185448,
    lng: 18.0728497,
  },
  {
    visitors: "2",
    City: "Järfälla",
    zipCode: "17543",
    lat: 59.4077477,
    lng: 17.8205825,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11529",
    lat: 59.3422116,
    lng: 18.0959392,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11730",
    lat: 59.3179512,
    lng: 18.0366939,
  },
  {
    visitors: "1",
    City: "Västerås",
    zipCode: "72337",
    lat: 59.62379769999999,
    lng: 16.5809733,
  },
  {
    visitors: "1",
    City: "Hägersten",
    zipCode: "12652",
    lat: 59.30729299999999,
    lng: 17.9915032,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11359",
    lat: 59.3396915,
    lng: 18.0576664,
  },
  {
    visitors: "1",
    City: "Nacka",
    zipCode: "13138",
    lat: 59.30571500000001,
    lng: 18.145389,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11824",
    lat: 59.31996059999999,
    lng: 18.06296,
  },
  {
    visitors: "1",
    City: "Hägersten",
    zipCode: "12635",
    lat: 59.30018450000001,
    lng: 18.0074495,
  },
  {
    visitors: "1",
    City: "Västerås",
    zipCode: "72245",
    lat: 59.6447759,
    lng: 16.5507737,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11417",
    lat: 59.37049219999999,
    lng: 18.0673629,
  },
  {
    visitors: "1",
    City: "Lotorp",
    zipCode: "61276",
    lat: 58.7361885,
    lng: 15.8276897,
  },
  {
    visitors: "1",
    City: "Johanneshov",
    zipCode: "12153",
    lat: 59.2900858,
    lng: 18.1247144,
  },
  {
    visitors: "1",
    City: "Enskededalen",
    zipCode: "12133",
    lat: 59.28385359999999,
    lng: 18.1007693,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11727",
    lat: 59.3188049,
    lng: 18.046335,
  },
  {
    visitors: "1",
    City: "Malmö",
    zipCode: "21443",
    lat: 55.5906107,
    lng: 13.0181838,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11631",
    lat: 59.3114214,
    lng: 18.0862177,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11335",
    lat: 59.3429678,
    lng: 18.0282274,
  },
  {
    visitors: "1",
    City: "Huddinge",
    zipCode: "14156",
    lat: 59.2212395,
    lng: 17.9484968,
  },
  {
    visitors: "1",
    City: "Göteborg",
    zipCode: "41252",
    lat: 57.7004068,
    lng: 11.9846421,
  },
  {
    visitors: "1",
    City: "Miami",
    zipCode: "33133-5703",
    lat: 25.7307897,
    lng: -80.2377078,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "12079",
    lat: 59.3031194,
    lng: 18.0974169,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11856",
    lat: 59.31006250000001,
    lng: 18.0723352,
  },
  {
    visitors: "2",
    City: "Tyresö",
    zipCode: "13544",
    lat: 59.236394,
    lng: 18.2287399,
  },
  {
    visitors: "1",
    City: "Hägersten",
    zipCode: "12935",
    lat: 59.3014712,
    lng: 17.9775598,
  },
  {
    visitors: "1",
    City: "Skärholmen",
    zipCode: "12743",
    lat: 59.2733875,
    lng: 17.8926893,
  },
  {
    visitors: "1",
    City: "Farsta",
    zipCode: "12359",
    lat: 59.2556382,
    lng: 18.0900656,
  },
  {
    visitors: "2",
    City: "Solna",
    zipCode: "17150",
    lat: 59.3482803,
    lng: 17.9909718,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11329",
    lat: 59.3409434,
    lng: 18.051513,
  },
  {
    visitors: "2",
    City: "Dún Laoghaire",
    zipCode: "A96 H771",
    lat: 53.29127829999999,
    lng: -6.1407487,
  },
  {
    visitors: "1",
    City: "Norrköping",
    zipCode: "60226",
    lat: 58.5907885,
    lng: 16.1927828,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11730",
    lat: 59.3179512,
    lng: 18.0366939,
  },
  {
    visitors: "1",
    City: "Hägersten",
    zipCode: "12653",
    lat: 59.30652240000001,
    lng: 17.9838643,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11847",
    lat: 59.3174468,
    lng: 18.0675278,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11335",
    lat: 59.3429678,
    lng: 18.0282274,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11726",
    lat: 59.3169608,
    lng: 18.0488263,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11353",
    lat: 59.3454055,
    lng: 18.0593376,
  },
  {
    visitors: "1",
    City: "Hägersten",
    zipCode: "12647",
    lat: 59.30310060000001,
    lng: 17.9948274,
  },
  {
    visitors: "1",
    City: "Västra Frölunda",
    zipCode: "42170",
    lat: 57.66179239999999,
    lng: 11.9259746,
  },
  {
    visitors: "1",
    City: "Johanneshov",
    zipCode: "12140",
    lat: 59.2981908,
    lng: 18.0812019,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11445",
    lat: 59.3373704,
    lng: 18.0842802,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11737",
    lat: 59.31781279999999,
    lng: 18.0285502,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11248",
    lat: 59.338765,
    lng: 18.0263967,
  },
  {
    visitors: "1",
    City: "Bromma",
    zipCode: "16866",
    lat: 59.3585356,
    lng: 17.9528732,
  },
  {
    visitors: "1",
    City: "Enskededalen",
    zipCode: "12132",
    lat: 59.288124,
    lng: 18.0891528,
  },
  {
    visitors: "2",
    City: "Farsta",
    zipCode: "12352",
    lat: 59.21115570000001,
    lng: 18.0839793,
  },
  {
    visitors: "1",
    City: "Bromma",
    zipCode: "16831",
    lat: 59.33626659999999,
    lng: 17.9499288,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11864",
    lat: 59.3131127,
    lng: 18.0601238,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11636",
    lat: 59.3141967,
    lng: 18.0902143,
  },
  {
    visitors: "1",
    City: "Solna",
    zipCode: "17161",
    lat: 59.3466214,
    lng: 18.0026143,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11726",
    lat: 59.3169608,
    lng: 18.0488263,
  },
  {
    visitors: "1",
    City: "Bromma",
    zipCode: "16744",
    lat: 59.33531759999999,
    lng: 17.977519,
  },
  {
    visitors: "1",
    City: "Norrköping",
    zipCode: "60350",
    lat: 58.5826465,
    lng: 16.1975448,
  },
  {
    visitors: "1",
    City: "Lock Haven",
    zipCode: "17745-1860",
    lat: 41.1370133,
    lng: -77.4469263,
  },
  {
    visitors: "1",
    City: "Bromma",
    zipCode: "16767",
    lat: 59.32558520000001,
    lng: 17.9439714,
  },
  {
    visitors: "1",
    City: "Hägersten",
    zipCode: "12945",
    lat: 59.2954052,
    lng: 17.967939,
  },
  {
    visitors: "1",
    City: "Kista",
    zipCode: "16436",
    lat: 59.4064726,
    lng: 17.9322808,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11631",
    lat: 59.3114214,
    lng: 18.0862177,
  },
  {
    visitors: "1",
    City: "Bromma",
    zipCode: "16737",
    lat: 59.3390242,
    lng: 17.9828338,
  },
  {
    visitors: "1",
    City: "Västerås",
    zipCode: "72214",
    lat: 59.61289839999999,
    lng: 16.5506654,
  },
  {
    visitors: "1",
    City: "Borlänge",
    zipCode: "78435",
    lat: 60.4829099,
    lng: 15.4395242,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11417",
    lat: 59.37049219999999,
    lng: 18.0673629,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11258",
    lat: 59.3271447,
    lng: 18.0087731,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11359",
    lat: 59.3396915,
    lng: 18.0576664,
  },
  {
    visitors: "1",
    City: "Johanneshov",
    zipCode: "12154",
    lat: 59.29641530000001,
    lng: 18.0755919,
  },
  {
    visitors: "1",
    City: "Enskede",
    zipCode: "12266",
    lat: 59.2690316,
    lng: 18.0588936,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11137",
    lat: 59.33591550000001,
    lng: 18.0606554,
  },
  {
    visitors: "1",
    City: "Årsta",
    zipCode: "12051",
    lat: 59.3016082,
    lng: 18.0599405,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11847",
    lat: 59.3174468,
    lng: 18.0675278,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11529",
    lat: 59.3422116,
    lng: 18.0959392,
  },
  {
    visitors: "2",
    City: "Sundbyberg",
    zipCode: "17461",
    lat: 59.3810514,
    lng: 17.9514925,
  },
  {
    visitors: "3",
    City: "Bromma",
    zipCode: "16846",
    lat: 59.3489212,
    lng: 17.8737845,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11343",
    lat: 59.3478588,
    lng: 18.0408724,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11765",
    lat: 59.3119428,
    lng: 18.008444,
  },
  {
    visitors: "1",
    City: "Hägersten",
    zipCode: "12934",
    lat: 59.30122799999999,
    lng: 17.9818769,
  },
  {
    visitors: "2",
    City: "Sköndal",
    zipCode: "12867",
    lat: 59.2556578,
    lng: 18.1106446,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11348",
    lat: 59.3451491,
    lng: 18.0559265,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11229",
    lat: 59.3324448,
    lng: 18.0406945,
  },
  {
    visitors: "2",
    City: "SAINT-OUEN-SUR-SEINE",
    zipCode: "93400",
    lat: 48.911856,
    lng: 2.333764,
  },
  {
    visitors: "1",
    City: "Enskede",
    zipCode: "12244",
    lat: 59.2647023,
    lng: 18.0890934,
  },
  {
    visitors: "2",
    City: "Tyresö",
    zipCode: "13568",
    lat: 59.23196180000001,
    lng: 18.3310168,
  },
  {
    visitors: "1",
    City: "Sundbyberg",
    zipCode: "17448",
    lat: 59.3792497,
    lng: 17.9353557,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11729",
    lat: 59.31825159999999,
    lng: 18.0410986,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11349",
    lat: 59.3475834,
    lng: 18.0545151,
  },
  {
    visitors: "1",
    City: "Farsta",
    zipCode: "12347",
    lat: 59.242296,
    lng: 18.0923523,
  },
  {
    visitors: "3",
    City: "Stockholm",
    zipCode: "11241",
    lat: 59.3311632,
    lng: 18.0262281,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11853",
    lat: 59.3151166,
    lng: 18.0513174,
  },
  {
    visitors: "1",
    City: "Lidingö",
    zipCode: "18163",
    lat: 59.35258549999999,
    lng: 18.2282174,
  },
  {
    visitors: "2",
    City: "Nacka",
    zipCode: "13165",
    lat: 59.3023385,
    lng: 18.1171117,
  },
  {
    visitors: "1",
    City: "Tibro",
    zipCode: "54335",
    lat: 58.4249288,
    lng: 14.1429834,
  },
  {
    visitors: "1",
    City: "Vällingby",
    zipCode: "16261",
    lat: 59.3614352,
    lng: 17.8720638,
  },
  {
    visitors: "4",
    City: "PARIS",
    zipCode: "75018",
    lat: 48.891305,
    lng: 2.3529867,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11741",
    lat: 59.31435019999999,
    lng: 18.0436713,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "12071",
    lat: 59.3087092,
    lng: 18.1038324,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11732",
    lat: 59.3198462,
    lng: 18.040352,
  },
  {
    visitors: "1",
    City: "Uppsala",
    zipCode: "75235",
    lat: 59.85885210000001,
    lng: 17.6183006,
  },
  {
    visitors: "1",
    City: "Figeholm",
    zipCode: "57295",
    lat: 57.3763329,
    lng: 16.5550694,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11445",
    lat: 59.3373704,
    lng: 18.0842802,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11532",
    lat: 59.3429221,
    lng: 18.0922798,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11750",
    lat: 59.3189319,
    lng: 18.0194102,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11750",
    lat: 59.3189319,
    lng: 18.0194102,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11646",
    lat: 59.3185448,
    lng: 18.0728497,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11737",
    lat: 59.31781279999999,
    lng: 18.0285502,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11144",
    lat: 59.33466619999999,
    lng: 18.0693861,
  },
  {
    visitors: "2",
    City: "Göteborg",
    zipCode: "41647",
    lat: 57.7135453,
    lng: 11.9969599,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11130",
    lat: 59.32312529999999,
    lng: 18.0759345,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11161",
    lat: 59.33784009999999,
    lng: 18.0550026,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11848",
    lat: 59.3179864,
    lng: 18.0624583,
  },
  {
    visitors: "1",
    City: "Hägersten",
    zipCode: "12949",
    lat: 59.291059,
    lng: 17.9775714,
  },
  {
    visitors: "1",
    City: "Västerhaninge",
    zipCode: "13736",
    lat: 59.132637,
    lng: 18.0718642,
  },
  {
    visitors: "1",
    City: "Bagarmossen",
    zipCode: "12847",
    lat: 59.2827044,
    lng: 18.1346562,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11559",
    lat: 59.3452485,
    lng: 18.1110931,
  },
  {
    visitors: "1",
    City: "Älvsjö",
    zipCode: "12534",
    lat: 59.27850449999999,
    lng: 17.9978277,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11858",
    lat: 59.3092637,
    lng: 18.0714195,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11245",
    lat: 59.3343961,
    lng: 18.0257303,
  },
  {
    visitors: "1",
    City: "Årsta",
    zipCode: "12053",
    lat: 59.300929,
    lng: 18.0542907,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11227",
    lat: 59.33123699999999,
    lng: 18.0478438,
  },
  {
    visitors: "1",
    City: "Johanneshov",
    zipCode: "12144",
    lat: 59.2949099,
    lng: 18.0755459,
  },
  {
    visitors: "4",
    City: "Järpen",
    zipCode: "83731",
    lat: 63.3455409,
    lng: 13.4631728,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11735",
    lat: 59.3167248,
    lng: 18.0309594,
  },
  {
    visitors: "1",
    City: "Helsinki",
    zipCode: "00140",
    lat: 60.15744170000001,
    lng: 24.9538806,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "12064",
    lat: 59.30459020000001,
    lng: 18.0988338,
  },
  {
    visitors: "3",
    City: "Uppsala",
    zipCode: "75318",
    lat: 59.8471124,
    lng: 17.6614483,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11733",
    lat: 59.32239069999999,
    lng: 18.0290508,
  },
  {
    visitors: "1",
    City: "Kista",
    zipCode: "16436",
    lat: 59.4064726,
    lng: 17.9322808,
  },
  {
    visitors: "2",
    City: "Sundbyberg",
    zipCode: "17447",
    lat: 59.37359420000001,
    lng: 17.9424419,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11427",
    lat: 59.34538079999999,
    lng: 18.0738136,
  },
  {
    visitors: "1",
    City: "Enskede",
    zipCode: "12263",
    lat: 59.2791331,
    lng: 18.0818234,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11636",
    lat: 59.3141967,
    lng: 18.0902143,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11427",
    lat: 59.34538079999999,
    lng: 18.0738136,
  },
  {
    visitors: "2",
    City: "Sollentuna",
    zipCode: "19146",
    lat: 59.4302185,
    lng: 17.9143775,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11231",
    lat: 59.3308822,
    lng: 18.0347074,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11867",
    lat: 59.30865169999999,
    lng: 18.0523067,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11771",
    lat: 59.3167961,
    lng: 17.9986892,
  },
  {
    visitors: "1",
    City: "Hägersten",
    zipCode: "12635",
    lat: 59.30018450000001,
    lng: 18.0074495,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11847",
    lat: 59.3174468,
    lng: 18.0675278,
  },
  {
    visitors: "2",
    City: "Hägersten",
    zipCode: "12635",
    lat: 59.30018450000001,
    lng: 18.0074495,
  },
  {
    visitors: "2",
    City: "Johanneshov",
    zipCode: "12150",
    lat: 59.29641530000001,
    lng: 18.0755919,
  },
  {
    visitors: "1",
    City: "Vällingby",
    zipCode: "16243",
    lat: 59.3734694,
    lng: 17.8459583,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11123",
    lat: 59.33510630000001,
    lng: 18.0520055,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11641",
    lat: 59.3114746,
    lng: 18.0975226,
  },
  {
    visitors: "1",
    City: "Trångsund",
    zipCode: "14265",
    lat: 59.2370491,
    lng: 18.1417712,
  },
  {
    visitors: "1",
    City: "Johanneshov",
    zipCode: "12135",
    lat: 59.2906003,
    lng: 18.0887437,
  },
  {
    visitors: "2",
    City: "Uppsala",
    zipCode: "75244",
    lat: 59.8255094,
    lng: 17.5910182,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11734",
    lat: 59.3163132,
    lng: 18.0364435,
  },
  {
    visitors: "3",
    City: "Stockholm",
    zipCode: "11243",
    lat: 59.33270169999999,
    lng: 18.0167517,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "12844",
    lat: 59.27263550000001,
    lng: 18.142589,
  },
  {
    visitors: "4",
    City: "Huddinge",
    zipCode: "14135",
    lat: 59.23901189999999,
    lng: 17.9776239,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11454",
    lat: 59.33409690000001,
    lng: 18.0863526,
  },
  {
    visitors: "4",
    City: "Bromma",
    zipCode: "16875",
    lat: 59.3435472,
    lng: 17.9379458,
  },
  {
    visitors: "1",
    City: "Johanneshov",
    zipCode: "12143",
    lat: 59.2968405,
    lng: 18.0750514,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11851",
    lat: 59.3169244,
    lng: 18.0529817,
  },
  {
    visitors: "1",
    City: "Johanneshov",
    zipCode: "12150",
    lat: 59.29641530000001,
    lng: 18.0755919,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11736",
    lat: 59.3174805,
    lng: 18.0308767,
  },
  {
    visitors: "2",
    City: "Årsta",
    zipCode: "12056",
    lat: 59.2925275,
    lng: 18.0506254,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11447",
    lat: 59.3374698,
    lng: 18.0790397,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11727",
    lat: 59.3188049,
    lng: 18.046335,
  },
  {
    visitors: "1",
    City: "Hägersten",
    zipCode: "12940",
    lat: 59.305595,
    lng: 17.9433528,
  },
  {
    visitors: "1",
    City: "Nacka",
    zipCode: "13142",
    lat: 59.31026259999999,
    lng: 18.1913199,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11849",
    lat: 59.3179366,
    lng: 18.0563075,
  },
  {
    visitors: "2",
    City: "Årsta",
    zipCode: "12053",
    lat: 59.300929,
    lng: 18.0542907,
  },
  {
    visitors: "2",
    City: "Vendelsö",
    zipCode: "13673",
    lat: 59.20518360000001,
    lng: 18.1873858,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11454",
    lat: 59.33409690000001,
    lng: 18.0863526,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11631",
    lat: 59.3114214,
    lng: 18.0862177,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11328",
    lat: 59.3416126,
    lng: 18.0494344,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11265",
    lat: 59.31828170000001,
    lng: 17.9848496,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11631",
    lat: 59.3114214,
    lng: 18.0862177,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11457",
    lat: 59.33241839999999,
    lng: 18.0876796,
  },
  {
    visitors: "1",
    City: "Solna",
    zipCode: "17071",
    lat: 59.3848933,
    lng: 18.0290711,
  },
  {
    visitors: "1",
    City: "Sundbyberg",
    zipCode: "17453",
    lat: 59.3787335,
    lng: 17.9678069,
  },
  {
    visitors: "1",
    City: "Solna",
    zipCode: "17151",
    lat: 59.3497722,
    lng: 17.9804935,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "12232",
    lat: 59.2848437,
    lng: 18.0834974,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11732",
    lat: 59.3198462,
    lng: 18.040352,
  },
  {
    visitors: "1",
    City: "Bandhagen",
    zipCode: "12464",
    lat: 59.2542117,
    lng: 18.027354,
  },
  {
    visitors: "2",
    City: "Hägersten",
    zipCode: "12648",
    lat: 59.30496240000001,
    lng: 18.002632,
  },
  {
    visitors: "1",
    City: "Hägersten",
    zipCode: "12648",
    lat: 59.30496240000001,
    lng: 18.002632,
  },
  {
    visitors: "1",
    City: "Hägersten",
    zipCode: "12648",
    lat: 59.30496240000001,
    lng: 18.002632,
  },
  {
    visitors: "1",
    City: "Johanneshov",
    zipCode: "12143",
    lat: 59.2968405,
    lng: 18.0750514,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11661",
    lat: 59.30814040000001,
    lng: 18.0805588,
  },
  {
    visitors: "1",
    City: "Västerås",
    zipCode: "72214",
    lat: 59.61289839999999,
    lng: 16.5506654,
  },
  {
    visitors: "1",
    City: "Västerås",
    zipCode: "72214",
    lat: 59.61289839999999,
    lng: 16.5506654,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11853",
    lat: 59.3151166,
    lng: 18.0513174,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11263",
    lat: 59.3241564,
    lng: 18.0049513,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11759",
    lat: 59.30601069999999,
    lng: 18.0265513,
  },
  {
    visitors: "1",
    City: "København S",
    zipCode: "2300",
    lat: 55.6571378,
    lng: 12.6094233,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11257",
    lat: 59.3286472,
    lng: 18.0034538,
  },
  {
    visitors: "1",
    City: "Örnsköldsvik",
    zipCode: "89133",
    lat: 63.2884878,
    lng: 18.7200146,
  },
  {
    visitors: "1",
    City: "Göteborg",
    zipCode: "41478",
    lat: 57.6755581,
    lng: 11.9324101,
  },
  {
    visitors: "1",
    City: "Grisslehamn",
    zipCode: "76457",
    lat: 60.1704586,
    lng: 18.7650315,
  },
  {
    visitors: "1",
    City: "Solna",
    zipCode: "16956",
    lat: 59.3713983,
    lng: 17.9984398,
  },
  {
    visitors: "4",
    City: "Bandhagen",
    zipCode: "12433",
    lat: 59.26968239999999,
    lng: 18.0439577,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11267",
    lat: 59.3213431,
    lng: 18.0051185,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11267",
    lat: 59.3213431,
    lng: 18.0051185,
  },
  {
    visitors: "3",
    City: "Stockholm",
    zipCode: "11822",
    lat: 59.31937139999999,
    lng: 18.0618785,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11854",
    lat: 59.3147506,
    lng: 18.0603755,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11854",
    lat: 59.3147506,
    lng: 18.0603755,
  },
  {
    visitors: "1",
    City: "Solna",
    zipCode: "16963",
    lat: 59.3621341,
    lng: 18.0099276,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11737",
    lat: 59.31781279999999,
    lng: 18.0285502,
  },
  {
    visitors: "1",
    City: "Älvsjö",
    zipCode: "12546",
    lat: 59.2883751,
    lng: 18.0250491,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11223",
    lat: 59.3294253,
    lng: 18.0436019,
  },
  {
    visitors: "1",
    City: "Johanneshov",
    zipCode: "12150",
    lat: 59.29641530000001,
    lng: 18.0755919,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11851",
    lat: 59.3169244,
    lng: 18.0529817,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11358",
    lat: 59.3404938,
    lng: 18.0611609,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11336",
    lat: 59.3394061,
    lng: 18.0336313,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11348",
    lat: 59.3451491,
    lng: 18.0559265,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11730",
    lat: 59.3179512,
    lng: 18.0366939,
  },
  {
    visitors: "1",
    City: "Nykvarn",
    zipCode: "15593",
    lat: 59.2020947,
    lng: 17.3020286,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "16751",
    lat: 59.3303737,
    lng: 17.9835081,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11730",
    lat: 59.3179512,
    lng: 18.0366939,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11431",
    lat: 59.34151799999999,
    lng: 18.074805,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11827",
    lat: 59.311614,
    lng: 18.0705928,
  },
  {
    visitors: "2",
    City: "Lidingö",
    zipCode: "18131",
    lat: 59.37407,
    lng: 18.1203419,
  },
  {
    visitors: "2",
    City: "Göteborg",
    zipCode: "41762",
    lat: 57.70298049999999,
    lng: 11.9163353,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11360",
    lat: 59.3393085,
    lng: 18.05384,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11447",
    lat: 59.3374698,
    lng: 18.0790397,
  },
  {
    visitors: "3",
    City: "Stockholm",
    zipCode: "11824",
    lat: 59.31996059999999,
    lng: 18.06296,
  },
  {
    visitors: "1",
    City: "Farsta",
    zipCode: "12361",
    lat: 59.2548802,
    lng: 18.0875745,
  },
  {
    visitors: "1",
    City: "Lisboa",
    zipCode: "1300-383",
    lat: 38.6997589,
    lng: -9.1819097,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11320",
    lat: 59.3394968,
    lng: 18.0382051,
  },
  {
    visitors: "1",
    City: "Järfälla",
    zipCode: "17675",
    lat: 59.4641811,
    lng: 17.8002033,
  },
  {
    visitors: "1",
    City: "Järfälla",
    zipCode: "17675",
    lat: 59.4641811,
    lng: 17.8002033,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11649",
    lat: 59.32932349999999,
    lng: 18.0685808,
  },
  {
    visitors: "5",
    City: "Stockholm",
    zipCode: "11218",
    lat: 59.3340817,
    lng: 18.0169221,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11726",
    lat: 59.3169608,
    lng: 18.0488263,
  },
  {
    visitors: "2",
    City: "Hägersten",
    zipCode: "12648",
    lat: 59.30496240000001,
    lng: 18.002632,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11354",
    lat: 59.3502308,
    lng: 18.0555166,
  },
  {
    visitors: "2",
    City: "Hägersten",
    zipCode: "12679",
    lat: 59.29043599999999,
    lng: 18.0028027,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11732",
    lat: 59.3198462,
    lng: 18.040352,
  },
  {
    visitors: "1",
    City: "Farsta",
    zipCode: "12361",
    lat: 59.2548802,
    lng: 18.0875745,
  },
  {
    visitors: "4",
    City: "Stockholm",
    zipCode: "11630",
    lat: 59.3156702,
    lng: 18.0942083,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11848",
    lat: 59.3179864,
    lng: 18.0624583,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11665",
    lat: 59.3096489,
    lng: 18.0778194,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11665",
    lat: 59.3096489,
    lng: 18.0778194,
  },
  {
    visitors: "1",
    City: "Storvreta",
    zipCode: "74345",
    lat: 59.9403813,
    lng: 17.6777549,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11633",
    lat: 59.31377189999999,
    lng: 18.0853919,
  },
  {
    visitors: "1",
    City: "Saltsjö-Duvnäs",
    zipCode: "13150",
    lat: 59.3008006,
    lng: 18.1982809,
  },
  {
    visitors: "1",
    City: "Hägersten",
    zipCode: "12931",
    lat: 59.29174210000001,
    lng: 17.9832141,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11726",
    lat: 59.3169608,
    lng: 18.0488263,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11543",
    lat: 59.35304730000001,
    lng: 18.0956333,
  },
  {
    visitors: "1",
    City: "Hägersten",
    zipCode: "12648",
    lat: 59.30496240000001,
    lng: 18.002632,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11246",
    lat: 59.336545,
    lng: 18.0302207,
  },
  {
    visitors: "2",
    City: "Sundbyberg",
    zipCode: "17273",
    lat: 59.3673762,
    lng: 17.9613382,
  },
  {
    visitors: "1",
    City: "Lidingö",
    zipCode: "18141",
    lat: 59.3542694,
    lng: 18.1855698,
  },
  {
    visitors: "1",
    City: "Solna",
    zipCode: "16938",
    lat: 59.3679746,
    lng: 17.9872097,
  },
  {
    visitors: "1",
    City: "Farsta",
    zipCode: "12355",
    lat: 59.25351689999999,
    lng: 18.0868188,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11771",
    lat: 59.3167961,
    lng: 17.9986892,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11730",
    lat: 59.3179512,
    lng: 18.0366939,
  },
  {
    visitors: "1",
    City: "Bromma",
    zipCode: "16762",
    lat: 59.32026920000001,
    lng: 17.9708925,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11828",
    lat: 59.3142975,
    lng: 18.0674393,
  },
  {
    visitors: "2",
    City: "Solna",
    zipCode: "16952",
    lat: 59.3624572,
    lng: 18.0024396,
  },
  {
    visitors: "4",
    City: "Stockholm",
    zipCode: "11356",
    lat: 59.3475067,
    lng: 18.0602555,
  },
  {
    visitors: "1",
    City: "Hägersten",
    zipCode: "12628",
    lat: 59.29790389999999,
    lng: 17.9999777,
  },
  {
    visitors: "1",
    City: "Solna",
    zipCode: "16966",
    lat: 59.362475,
    lng: 18.0127564,
  },
  {
    visitors: "1",
    City: "Bromma",
    zipCode: "16844",
    lat: 59.3487101,
    lng: 17.8594983,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11740",
    lat: 59.31734159999999,
    lng: 18.0227335,
  },
  {
    visitors: "1",
    City: "Enskede",
    zipCode: "12247",
    lat: 59.2727321,
    lng: 18.0642114,
  },
  {
    visitors: "2",
    City: "Uppsala",
    zipCode: "75329",
    lat: 59.8643491,
    lng: 17.6473871,
  },
  {
    visitors: "1",
    City: "Handen",
    zipCode: "13639",
    lat: 59.156349,
    lng: 18.1394217,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11855",
    lat: 59.3145841,
    lng: 18.0615388,
  },
  {
    visitors: "1",
    City: "Hägersten",
    zipCode: "12934",
    lat: 59.30122799999999,
    lng: 17.9818769,
  },
  {
    visitors: "1",
    City: "Solna",
    zipCode: "17157",
    lat: 59.34946040000001,
    lng: 17.9931328,
  },
  {
    visitors: "1",
    City: "Hägersten",
    zipCode: "12635",
    lat: 59.30018450000001,
    lng: 18.0074495,
  },
  {
    visitors: "1",
    City: "Handen",
    zipCode: "13648",
    lat: 59.17831219999999,
    lng: 18.1262739,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11637",
    lat: 59.31242160000001,
    lng: 18.0792381,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11849",
    lat: 59.3179366,
    lng: 18.0563075,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11350",
    lat: 59.34351079999999,
    lng: 18.0556749,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11326",
    lat: 59.34433610000001,
    lng: 18.044696,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11731",
    lat: 59.3186634,
    lng: 18.0356141,
  },
  {
    visitors: "1",
    City: "Bromma",
    zipCode: "16735",
    lat: 59.3368293,
    lng: 17.9773508,
  },
  {
    visitors: "1",
    City: "Hägersten",
    zipCode: "12640",
    lat: 59.28540539999999,
    lng: 18.0067878,
  },
  {
    visitors: "2",
    City: "Uppsala",
    zipCode: "75234",
    lat: 59.85338429999999,
    lng: 17.6133266,
  },
  {
    visitors: "1",
    City: "Södertälje",
    zipCode: "15250",
    lat: 59.2227066,
    lng: 17.6141834,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11663",
    lat: 59.3092322,
    lng: 18.0807273,
  },
  {
    visitors: "1",
    City: "Skärholmen",
    zipCode: "12744",
    lat: 59.27479390000001,
    lng: 17.8853903,
  },
  {
    visitors: "1",
    City: "Bromma",
    zipCode: "16762",
    lat: 59.32026920000001,
    lng: 17.9708925,
  },
  {
    visitors: "2",
    City: "Solna",
    zipCode: "17175",
    lat: 59.36887909999999,
    lng: 18.0084334,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11228",
    lat: 59.33442669999999,
    lng: 18.0463503,
  },
  {
    visitors: "1",
    City: "Hägersten",
    zipCode: "12934",
    lat: 59.30122799999999,
    lng: 17.9818769,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "12078",
    lat: 59.3028184,
    lng: 18.094306,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11417",
    lat: 59.37049219999999,
    lng: 18.0673629,
  },
  {
    visitors: "3",
    City: "Bromma",
    zipCode: "16841",
    lat: 59.33068939999999,
    lng: 17.9027637,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11768",
    lat: 59.31461539999999,
    lng: 18.0001365,
  },
  {
    visitors: "1",
    City: "Johanneshov",
    zipCode: "12152",
    lat: 59.2942813,
    lng: 18.1214057,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11241",
    lat: 59.3311632,
    lng: 18.0262281,
  },
  {
    visitors: "1",
    City: "San Diego",
    zipCode: "92129-4438",
    lat: 32.9657005,
    lng: -117.1147095,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11530",
    lat: 59.3428362,
    lng: 18.0902826,
  },
  {
    visitors: "1",
    City: "Danderyd",
    zipCode: "18256",
    lat: 59.41161139999999,
    lng: 18.0244081,
  },
  {
    visitors: "1",
    City: "Lafayette",
    zipCode: "94549-3043",
    lat: 37.8929461,
    lng: -122.1178261,
  },
  {
    visitors: "2",
    City: "Hässelby",
    zipCode: "16554",
    lat: 59.3666722,
    lng: 17.8534222,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11355",
    lat: 59.3464977,
    lng: 18.0595054,
  },
  {
    visitors: "1",
    City: "Hägersten",
    zipCode: "12950",
    lat: 59.2870225,
    lng: 17.9745887,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11667",
    lat: 59.30675269999999,
    lng: 18.0785613,
  },
  {
    visitors: "2",
    City: "Knivsta",
    zipCode: "74130",
    lat: 59.7227773,
    lng: 17.7833452,
  },
  {
    visitors: "1",
    City: "Järfälla",
    zipCode: "17567",
    lat: 59.3890093,
    lng: 17.8479786,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11368",
    lat: 59.3473229,
    lng: 18.0335217,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11332",
    lat: 59.3432762,
    lng: 18.0377917,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11737",
    lat: 59.31781279999999,
    lng: 18.0285502,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11357",
    lat: 59.34272139999999,
    lng: 18.062495,
  },
  {
    visitors: "1",
    City: "Täby",
    zipCode: "18743",
    lat: 59.44722279999999,
    lng: 18.0043806,
  },
  {
    visitors: "3",
    City: "Stockholm",
    zipCode: "11236",
    lat: 59.32949720000001,
    lng: 18.0352885,
  },
  {
    visitors: "2",
    City: "Lidingö",
    zipCode: "18155",
    lat: 59.34440350000001,
    lng: 18.1608684,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "118 53",
    lat: 59.3151166,
    lng: 18.0513174,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11337",
    lat: 59.3427698,
    lng: 18.036128,
  },
  {
    visitors: "1",
    City: "Sollentuna",
    zipCode: "19148",
    lat: 59.4258535,
    lng: 17.9530533,
  },
  {
    visitors: "1",
    City: "Saltsjöbaden",
    zipCode: "13336",
    lat: 59.2669058,
    lng: 18.2954558,
  },
  {
    visitors: "1",
    City: "Malmö",
    zipCode: "21437",
    lat: 55.5908479,
    lng: 13.022527,
  },
  {
    visitors: "3",
    City: "Södertälje",
    zipCode: "15138",
    lat: 59.16755209999999,
    lng: 17.6583856,
  },
  {
    visitors: "2",
    City: "Sundbyberg",
    zipCode: "17449",
    lat: 59.37701120000001,
    lng: 17.9485065,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11726",
    lat: 59.3169608,
    lng: 18.0488263,
  },
  {
    visitors: "2",
    City: "Västerås",
    zipCode: "72213",
    lat: 59.6114219,
    lng: 16.5477545,
  },
  {
    visitors: "1",
    City: "Johanneshov",
    zipCode: "12139",
    lat: 59.29641530000001,
    lng: 18.0755919,
  },
  {
    visitors: "2",
    City: "Farsta",
    zipCode: "12361",
    lat: 59.2548802,
    lng: 18.0875745,
  },
  {
    visitors: "2",
    City: "Västerhaninge",
    zipCode: "13742",
    lat: 59.1243049,
    lng: 18.1194369,
  },
  {
    visitors: "1",
    City: "Enskede",
    zipCode: "12260",
    lat: 59.2698955,
    lng: 18.078814,
  },
  {
    visitors: "1",
    City: "Hägersten",
    zipCode: "12642",
    lat: 59.2904947,
    lng: 17.9893568,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11661",
    lat: 59.30814040000001,
    lng: 18.0805588,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11241",
    lat: 59.3311632,
    lng: 18.0262281,
  },
  {
    visitors: "2",
    City: "Hägersten",
    zipCode: "12944",
    lat: 59.2931988,
    lng: 17.9573203,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11431",
    lat: 59.34151799999999,
    lng: 18.074805,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11221",
    lat: 59.32820659999999,
    lng: 18.0430189,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11557",
    lat: 59.3476674,
    lng: 18.0916254,
  },
  {
    visitors: "3",
    City: "Stockholm",
    zipCode: "11853",
    lat: 59.3151166,
    lng: 18.0513174,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11852",
    lat: 59.315929,
    lng: 18.0625382,
  },
  {
    visitors: "2",
    City: "Saltsjö-Boo",
    zipCode: "13232",
    lat: 59.3330868,
    lng: 18.2600677,
  },
  {
    visitors: "1",
    City: "Kalmar",
    zipCode: "39231",
    lat: 56.6580084,
    lng: 16.3688376,
  },
  {
    visitors: "1",
    City: "Segeltorp",
    zipCode: "14172",
    lat: 59.2693313,
    lng: 17.9474118,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11739",
    lat: 59.3140818,
    lng: 18.0325367,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "12632",
    lat: 59.30246469999999,
    lng: 18.0149229,
  },
  {
    visitors: "1",
    City: "Farsta",
    zipCode: "12355",
    lat: 59.25351689999999,
    lng: 18.0868188,
  },
  {
    visitors: "1",
    City: "Johanneshov",
    zipCode: "12147",
    lat: 59.295174,
    lng: 18.0866781,
  },
  {
    visitors: "1",
    City: "Nacka",
    zipCode: "13147",
    lat: 59.3176523,
    lng: 18.2020133,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "12153",
    lat: 59.2900858,
    lng: 18.1247144,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11738",
    lat: 59.31558519999999,
    lng: 18.0272198,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11662",
    lat: 59.31065450000001,
    lng: 18.075993,
  },
  {
    visitors: "1",
    City: "Bandhagen",
    zipCode: "12470",
    lat: 59.2642714,
    lng: 18.0193971,
  },
  {
    visitors: "1",
    City: "Åkers Styckebruk",
    zipCode: "64750",
    lat: 59.26559129999999,
    lng: 17.118693,
  },
  {
    visitors: "1",
    City: "Västerås",
    zipCode: "72358",
    lat: 59.60990049999999,
    lng: 16.5448091,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11230",
    lat: 59.33273399999999,
    lng: 18.037369,
  },
  {
    visitors: "1",
    City: "Älvsjö",
    zipCode: "12572",
    lat: 59.289475,
    lng: 18.0303628,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11323",
    lat: 59.34420579999999,
    lng: 18.0417015,
  },
  {
    visitors: "1",
    City: "Bandhagen",
    zipCode: "12466",
    lat: 59.260167,
    lng: 18.0247036,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11330",
    lat: 59.3457102,
    lng: 18.0363793,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11858",
    lat: 59.3092637,
    lng: 18.0714195,
  },
  {
    visitors: "1",
    City: "Lake Arrowhead",
    zipCode: "92352",
    lat: 34.2693794,
    lng: -117.2088167,
  },
  {
    visitors: "3",
    City: "Stockholm",
    zipCode: "11527",
    lat: 59.3381236,
    lng: 18.1322083,
  },
  {
    visitors: "1",
    City: "Sköndal",
    zipCode: "12867",
    lat: 59.2556578,
    lng: 18.1106446,
  },
  {
    visitors: "1",
    City: "Johanneshov",
    zipCode: "12154",
    lat: 59.29641530000001,
    lng: 18.0755919,
  },
  {
    visitors: "1",
    City: "Johanneshov",
    zipCode: "12150",
    lat: 59.29641530000001,
    lng: 18.0755919,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11428",
    lat: 59.35117519999999,
    lng: 18.0723261,
  },
  {
    visitors: "2",
    City: "Johanneshov",
    zipCode: "12150",
    lat: 59.29641530000001,
    lng: 18.0755919,
  },
  {
    visitors: "1",
    City: "Nacka",
    zipCode: "13132",
    lat: 59.3100621,
    lng: 18.1151422,
  },
  {
    visitors: "1",
    City: "Lidingö",
    zipCode: "18141",
    lat: 59.3542694,
    lng: 18.1855698,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11322",
    lat: 59.3408062,
    lng: 18.0433622,
  },
  {
    visitors: "1",
    City: "Johanneshov",
    zipCode: "12145",
    lat: 59.29921249999999,
    lng: 18.0948304,
  },
  {
    visitors: "1",
    City: "Enskede",
    zipCode: "12266",
    lat: 59.2690316,
    lng: 18.0588936,
  },
  {
    visitors: "1",
    City: "Kalmar",
    zipCode: "39246",
    lat: 56.6603453,
    lng: 16.3461466,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11432",
    lat: 59.3398337,
    lng: 18.0709753,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11261",
    lat: 59.32516560000001,
    lng: 18.0056989,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11646",
    lat: 59.3185448,
    lng: 18.0728497,
  },
  {
    visitors: "3",
    City: "Johanneshov",
    zipCode: "12155",
    lat: 59.29641530000001,
    lng: 18.0755919,
  },
  {
    visitors: "2",
    City: "Berlin",
    zipCode: "14109",
    lat: 52.415113,
    lng: 13.1468678,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11623",
    lat: 59.3125021,
    lng: 18.0760798,
  },
  {
    visitors: "4",
    City: "Stockholm",
    zipCode: "11829",
    lat: 59.3121569,
    lng: 18.0681005,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11645",
    lat: 59.3198495,
    lng: 18.0754293,
  },
  {
    visitors: "3",
    City: "Hägersten",
    zipCode: "12634",
    lat: 59.3022899,
    lng: 18.0109368,
  },
  {
    visitors: "2",
    City: "Nacka",
    zipCode: "13137",
    lat: 59.3090724,
    lng: 18.1427423,
  },
  {
    visitors: "1",
    City: "Älvsjö",
    zipCode: "12573",
    lat: 59.2884782,
    lng: 18.0373359,
  },
  {
    visitors: "3",
    City: "Roma",
    zipCode: "00183",
    lat: 41.8795238,
    lng: 12.5082935,
  },
  {
    visitors: "1",
    City: "Huddinge",
    zipCode: "14168",
    lat: 59.2375335,
    lng: 17.9540995,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "12249",
    lat: 59.2609651,
    lng: 18.0818234,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11421",
    lat: 59.3523354,
    lng: 18.0590139,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11625",
    lat: 59.3159875,
    lng: 18.0764192,
  },
  {
    visitors: "2",
    City: "Farsta",
    zipCode: "12344",
    lat: 59.2398663,
    lng: 18.0963264,
  },
  {
    visitors: "2",
    City: "Saltsjöbaden",
    zipCode: "13333",
    lat: 59.29544680000001,
    lng: 18.2937199,
  },
  {
    visitors: "3",
    City: "Sundbyberg",
    zipCode: "17265",
    lat: 59.3589989,
    lng: 17.9731631,
  },
  {
    visitors: "3",
    City: "Sundbyberg",
    zipCode: "17265",
    lat: 59.3589989,
    lng: 17.9731631,
  },
  {
    visitors: "3",
    City: "Stockholm",
    zipCode: "12060",
    lat: 59.3019122,
    lng: 18.0370145,
  },
  {
    visitors: "1",
    City: "Täby",
    zipCode: "18374",
    lat: 59.4448173,
    lng: 18.0891596,
  },
  {
    visitors: "1",
    City: "Täby",
    zipCode: "18374",
    lat: 59.4448173,
    lng: 18.0891596,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11858",
    lat: 59.3092637,
    lng: 18.0714195,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11625",
    lat: 59.3159875,
    lng: 18.0764192,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11320",
    lat: 59.3394968,
    lng: 18.0382051,
  },
  {
    visitors: "1",
    City: "Solna",
    zipCode: "17171",
    lat: 59.35031799999999,
    lng: 18.0027788,
  },
  {
    visitors: "1",
    City: "Skogås",
    zipCode: "14252",
    lat: 59.2061766,
    lng: 18.1495911,
  },
  {
    visitors: "3",
    City: "Jönköping",
    zipCode: "55309",
    lat: 57.78261370000001,
    lng: 14.1617876,
  },
  {
    visitors: "1",
    City: "Årsta",
    zipCode: "12057",
    lat: 59.2965475,
    lng: 18.0433218,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11739",
    lat: 59.3140818,
    lng: 18.0325367,
  },
  {
    visitors: "2",
    City: "Lidingö",
    zipCode: "18131",
    lat: 59.37407,
    lng: 18.1203419,
  },
  {
    visitors: "2",
    City: "Hägersten",
    zipCode: "12634",
    lat: 59.3022899,
    lng: 18.0109368,
  },
  {
    visitors: "1",
    City: "Nacka",
    zipCode: "13135",
    lat: 59.3082204,
    lng: 18.1227845,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11861",
    lat: 59.3054695,
    lng: 18.0589493,
  },
  {
    visitors: "2",
    City: "Lidingö",
    zipCode: "18141",
    lat: 59.3542694,
    lng: 18.1855698,
  },
  {
    visitors: "2",
    City: "Bandhagen",
    zipCode: "12453",
    lat: 59.2628726,
    lng: 18.0369817,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11329",
    lat: 59.3409434,
    lng: 18.051513,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11266",
    lat: 59.32071959999999,
    lng: 17.9860103,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11640",
    lat: 59.31196459999999,
    lng: 18.0837254,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11846",
    lat: 59.31849860000001,
    lng: 18.0692752,
  },
  {
    visitors: "2",
    City: "Solna",
    zipCode: "17070",
    lat: 59.3825338,
    lng: 18.0247407,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11642",
    lat: 59.3092808,
    lng: 18.0868777,
  },
  {
    visitors: "1",
    City: "Hägersten",
    zipCode: "12945",
    lat: 59.2954052,
    lng: 17.967939,
  },
  {
    visitors: "1",
    City: "Västerås",
    zipCode: "72217",
    lat: 59.62144989999999,
    lng: 16.5247262,
  },
  {
    visitors: "1",
    City: "Järna",
    zipCode: "15336",
    lat: 59.0975254,
    lng: 17.5545612,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11629",
    lat: 59.31471440000001,
    lng: 18.0833575,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11860",
    lat: 59.3039771,
    lng: 18.0745671,
  },
  {
    visitors: "1",
    City: "Upplands Väsby",
    zipCode: "19492",
    lat: 59.552426,
    lng: 17.945357,
  },
  {
    visitors: "1",
    City: "Hässelby",
    zipCode: "16573",
    lat: 59.37888359999999,
    lng: 17.8354001,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11127",
    lat: 59.322194,
    lng: 18.0694479,
  },
  {
    visitors: "1",
    City: "Farsta",
    zipCode: "12343",
    lat: 59.23837020000001,
    lng: 18.1119141,
  },
  {
    visitors: "1",
    City: "Solna",
    zipCode: "16934",
    lat: 59.36491150000001,
    lng: 17.9891264,
  },
  {
    visitors: "1",
    City: "Farsta",
    zipCode: "12355",
    lat: 59.25351689999999,
    lng: 18.0868188,
  },
  {
    visitors: "1",
    City: "Skogås",
    zipCode: "14232",
    lat: 59.2143982,
    lng: 18.1492983,
  },
  {
    visitors: "2",
    City: "Bandhagen",
    zipCode: "12472",
    lat: 59.2630651,
    lng: 18.0018141,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11140",
    lat: 59.3394881,
    lng: 18.0629894,
  },
  {
    visitors: "2",
    City: "Nacka",
    zipCode: "13140",
    lat: 59.3097514,
    lng: 18.1587118,
  },
  {
    visitors: "1",
    City: "Edsbyn",
    zipCode: "82834",
    lat: 61.4062406,
    lng: 15.8291648,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11625",
    lat: 59.3159875,
    lng: 18.0764192,
  },
  {
    visitors: "1",
    City: "Reykjavík",
    zipCode: "202",
    lat: 64.1469868,
    lng: -21.9407552,
  },
  {
    visitors: "1",
    City: "Sköndal",
    zipCode: "12868",
    lat: 59.2506253,
    lng: 18.1146108,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11364",
    lat: 59.34498619999999,
    lng: 18.0297252,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11662",
    lat: 59.31065450000001,
    lng: 18.075993,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11220",
    lat: 59.3257766,
    lng: 18.0470069,
  },
  {
    visitors: "3",
    City: "Stockholm",
    zipCode: "11735",
    lat: 59.3167248,
    lng: 18.0309594,
  },
  {
    visitors: "2",
    City: "Farsta",
    zipCode: "12360",
    lat: 59.258498,
    lng: 18.0960478,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11527",
    lat: 59.3381236,
    lng: 18.1322083,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11527",
    lat: 59.3381236,
    lng: 18.1322083,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11248",
    lat: 59.338765,
    lng: 18.0263967,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11862",
    lat: 59.30889029999999,
    lng: 18.0753247,
  },
  {
    visitors: "1",
    City: "Hägersten",
    zipCode: "12628",
    lat: 59.29790389999999,
    lng: 17.9999777,
  },
  {
    visitors: "1",
    City: "Hägersten",
    zipCode: "12652",
    lat: 59.30729299999999,
    lng: 17.9915032,
  },
  {
    visitors: "2",
    City: "Gustavsberg",
    zipCode: "13431",
    lat: 59.328938,
    lng: 18.3706928,
  },
  {
    visitors: "2",
    City: "Uppsala",
    zipCode: "75318",
    lat: 59.8471124,
    lng: 17.6614483,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11662",
    lat: 59.31065450000001,
    lng: 18.075993,
  },
  {
    visitors: "1",
    City: "Hägersten",
    zipCode: "12634",
    lat: 59.3022899,
    lng: 18.0109368,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11637",
    lat: 59.31242160000001,
    lng: 18.0792381,
  },
  {
    visitors: "2",
    City: "Lidingö",
    zipCode: "18151",
    lat: 59.36309230000001,
    lng: 18.1251176,
  },
  {
    visitors: "1",
    City: "Linköping",
    zipCode: "58215",
    lat: 58.4036263,
    lng: 15.6011767,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11736",
    lat: 59.3174805,
    lng: 18.0308767,
  },
  {
    visitors: "1",
    City: "Johanneshov",
    zipCode: "12136",
    lat: 59.292824,
    lng: 18.0875031,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11341",
    lat: 59.3397781,
    lng: 18.0297231,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11341",
    lat: 59.3397781,
    lng: 18.0297231,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11857",
    lat: 59.31030930000001,
    lng: 18.0680142,
  },
  {
    visitors: "1",
    City: "PAMPLONA/IRUÑA",
    zipCode: "31001",
    lat: 42.81913309999999,
    lng: -1.6432837,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11262",
    lat: 59.3263753,
    lng: 18.0011279,
  },
  {
    visitors: "6",
    City: "Stockholm",
    zipCode: "11129",
    lat: 59.3244212,
    lng: 18.070782,
  },
  {
    visitors: "1",
    City: "Bandhagen",
    zipCode: "12464",
    lat: 59.2542117,
    lng: 18.027354,
  },
  {
    visitors: "5",
    City: "Stockholm",
    zipCode: "11331",
    lat: 59.3456742,
    lng: 18.0405381,
  },
  {
    visitors: "1",
    City: "Johanneshov",
    zipCode: "12137",
    lat: 59.2952652,
    lng: 18.093823,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11259",
    lat: 59.32908450000001,
    lng: 18.013427,
  },
  {
    visitors: "1",
    City: "Bagarmossen",
    zipCode: "12844",
    lat: 59.27263550000001,
    lng: 18.142589,
  },
  {
    visitors: "2",
    City: "Nacka",
    zipCode: "13134",
    lat: 59.30435860000001,
    lng: 18.1237684,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11852",
    lat: 59.315929,
    lng: 18.0625382,
  },
  {
    visitors: "1",
    City: "Lidingö",
    zipCode: "18130",
    lat: 59.387848,
    lng: 18.1177185,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11730",
    lat: 59.3179512,
    lng: 18.0366939,
  },
  {
    visitors: "1",
    City: "Täby",
    zipCode: "18731",
    lat: 59.44595140000001,
    lng: 18.0551133,
  },
  {
    visitors: "3",
    City: "Stockholm",
    zipCode: "118 52",
    lat: 59.315929,
    lng: 18.0625382,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11637",
    lat: 59.31242160000001,
    lng: 18.0792381,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "12070",
    lat: 59.30728790000001,
    lng: 18.1111431,
  },
  {
    visitors: "1",
    City: "Johanneshov",
    zipCode: "12146",
    lat: 59.29829720000001,
    lng: 18.1038021,
  },
  {
    visitors: "1",
    City: "Sollentuna",
    zipCode: "19255",
    lat: 59.4307088,
    lng: 17.9843837,
  },
  {
    visitors: "1",
    City: "Järfälla",
    zipCode: "17732",
    lat: 59.413685,
    lng: 17.83652,
  },
  {
    visitors: "1",
    City: "Täby",
    zipCode: "18772",
    lat: 59.50179649999999,
    lng: 18.060505,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11451",
    lat: 59.33345929999999,
    lng: 18.0791149,
  },
  {
    visitors: "1",
    City: "Skarpnäck",
    zipCode: "12838",
    lat: 59.27395809999999,
    lng: 18.1126974,
  },
  {
    visitors: "1",
    City: "Bromma",
    zipCode: "16874",
    lat: 59.34491749999999,
    lng: 17.9492451,
  },
  {
    visitors: "1",
    City: "Curridabat",
    zipCode: "11801",
    lat: 9.9211764,
    lng: -84.039086,
  },
  {
    visitors: "1",
    City: "Tullinge",
    zipCode: "14652",
    lat: 59.22520300000001,
    lng: 17.8918734,
  },
  {
    visitors: "1",
    City: "Hägersten",
    zipCode: "12655",
    lat: 59.30993299999999,
    lng: 18.000807,
  },
  {
    visitors: "3",
    City: "Stockholm",
    zipCode: "11227",
    lat: 59.33123699999999,
    lng: 18.0478438,
  },
  {
    visitors: "2",
    City: "Bromma",
    zipCode: "16751",
    lat: 59.3303737,
    lng: 17.9835081,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11853",
    lat: 59.3151166,
    lng: 18.0513174,
  },
  {
    visitors: "2",
    City: "Hägersten",
    zipCode: "12638",
    lat: 59.2934389,
    lng: 17.9921767,
  },
  {
    visitors: "1",
    City: "Bandhagen",
    zipCode: "12473",
    lat: 59.2623328,
    lng: 18.0147518,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11330",
    lat: 59.3457102,
    lng: 18.0363793,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11757",
    lat: 59.3051404,
    lng: 18.0339435,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11535",
    lat: 59.3450277,
    lng: 18.0983595,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11360",
    lat: 59.3393085,
    lng: 18.05384,
  },
  {
    visitors: "2",
    City: "Lidingö",
    zipCode: "18147",
    lat: 59.38014389999999,
    lng: 18.1817057,
  },
  {
    visitors: "1",
    City: "Hägersten",
    zipCode: "12632",
    lat: 59.30246469999999,
    lng: 18.0149229,
  },
  {
    visitors: "1",
    City: "Nacka",
    zipCode: "13141",
    lat: 59.3035431,
    lng: 18.1759789,
  },
  {
    visitors: "1",
    City: "Bromma",
    zipCode: "16766",
    lat: 59.3245933,
    lng: 17.9509504,
  },
  {
    visitors: "1",
    City: "Årsta",
    zipCode: "12058",
    lat: 59.29982289999999,
    lng: 18.0438234,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11642",
    lat: 59.3092808,
    lng: 18.0868777,
  },
  {
    visitors: "1",
    City: "Hägersten",
    zipCode: "12629",
    lat: 59.2974064,
    lng: 18.0034648,
  },
  {
    visitors: "1",
    City: "Bromma",
    zipCode: "16744",
    lat: 59.33531759999999,
    lng: 17.977519,
  },
  {
    visitors: "1",
    City: "Sundbyberg",
    zipCode: "17263",
    lat: 59.3586235,
    lng: 17.9744944,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11347",
    lat: 59.3536674,
    lng: 18.0496961,
  },
  {
    visitors: "1",
    City: "Sundbyberg",
    zipCode: "17275",
    lat: 59.3672766,
    lng: 17.954185,
  },
  {
    visitors: "1",
    City: "Farsta",
    zipCode: "12334",
    lat: 59.2506064,
    lng: 18.0940343,
  },
  {
    visitors: "1",
    City: "Värmdö",
    zipCode: "13955",
    lat: 59.32673430000001,
    lng: 18.5789155,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11826",
    lat: 59.3156423,
    lng: 18.068439,
  },
  {
    visitors: "1",
    City: "Lövestad",
    zipCode: "27575",
    lat: 55.6536604,
    lng: 13.8912636,
  },
  {
    visitors: "1",
    City: "Lövestad",
    zipCode: "27575",
    lat: 55.6536604,
    lng: 13.8912636,
  },
  {
    visitors: "2",
    City: "Järfälla",
    zipCode: "17564",
    lat: 59.39979239999999,
    lng: 17.8559039,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11228",
    lat: 59.33442669999999,
    lng: 18.0463503,
  },
  {
    visitors: "1",
    City: "Vällingby",
    zipCode: "16274",
    lat: 59.35374100000001,
    lng: 17.8843971,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11122",
    lat: 59.3351131,
    lng: 18.0571615,
  },
  {
    visitors: "2",
    City: "Hägersten",
    zipCode: "12635",
    lat: 59.30018450000001,
    lng: 18.0074495,
  },
  {
    visitors: "1",
    City: "Saltsjö-Boo",
    zipCode: "13237",
    lat: 59.35019320000001,
    lng: 18.2962117,
  },
  {
    visitors: "1",
    City: "Gagnef",
    zipCode: "78530",
    lat: 60.59098299999999,
    lng: 15.0700491,
  },
  {
    visitors: "1",
    City: "Johanneshov",
    zipCode: "12152",
    lat: 59.2942813,
    lng: 18.1214057,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "16560",
    lat: 59.3609203,
    lng: 17.841489,
  },
  {
    visitors: "1",
    City: "Hägersten",
    zipCode: "12642",
    lat: 59.2904947,
    lng: 17.9893568,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11663",
    lat: 59.3092322,
    lng: 18.0807273,
  },
  {
    visitors: "3",
    City: "Stockholm",
    zipCode: "11735",
    lat: 59.3167248,
    lng: 18.0309594,
  },
  {
    visitors: "1",
    City: "Farsta",
    zipCode: "12334",
    lat: 59.2506064,
    lng: 18.0940343,
  },
  {
    visitors: "1",
    City: "Chelmsford",
    zipCode: "CM3 4NU",
    lat: 51.72077160000001,
    lng: 0.583804,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11322",
    lat: 59.3408062,
    lng: 18.0433622,
  },
  {
    visitors: "1",
    City: "Göteborg",
    zipCode: "41469",
    lat: 57.68533000000001,
    lng: 11.9242021,
  },
  {
    visitors: "1",
    City: "Vällingby",
    zipCode: "16253",
    lat: 59.360387,
    lng: 17.8873601,
  },
  {
    visitors: "1",
    City: "Nacka",
    zipCode: "13139",
    lat: 59.31278,
    lng: 18.1790219,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11856",
    lat: 59.31006250000001,
    lng: 18.0723352,
  },
  {
    visitors: "3",
    City: "Stockholm",
    zipCode: "11727",
    lat: 59.3188049,
    lng: 18.046335,
  },
  {
    visitors: "1",
    City: "Lidingö",
    zipCode: "18167",
    lat: 59.3532618,
    lng: 18.2028922,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11622",
    lat: 59.3183437,
    lng: 18.0807468,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11349",
    lat: 59.3475834,
    lng: 18.0545151,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11349",
    lat: 59.3475834,
    lng: 18.0545151,
  },
  {
    visitors: "1",
    City: "Hägersten",
    zipCode: "12642",
    lat: 59.2904947,
    lng: 17.9893568,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11637",
    lat: 59.31242160000001,
    lng: 18.0792381,
  },
  {
    visitors: "2",
    City: "Bandhagen",
    zipCode: "12455",
    lat: 59.26699319999999,
    lng: 18.0419635,
  },
  {
    visitors: "2",
    City: "Solna",
    zipCode: "16936",
    lat: 59.3653989,
    lng: 17.9804739,
  },
  {
    visitors: "3",
    City: "Stockholm",
    zipCode: "11620",
    lat: 59.3173323,
    lng: 18.0774194,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11620",
    lat: 59.3173323,
    lng: 18.0774194,
  },
  {
    visitors: "1",
    City: "Farsta",
    zipCode: "12355",
    lat: 59.25351689999999,
    lng: 18.0868188,
  },
  {
    visitors: "2",
    City: "Åkersberga",
    zipCode: "18430",
    lat: 59.48280059999999,
    lng: 18.3017239,
  },
  {
    visitors: "1",
    City: "Sollentuna",
    zipCode: "19259",
    lat: 59.44798469999999,
    lng: 17.9623397,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11359",
    lat: 59.3396915,
    lng: 18.0576664,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11731",
    lat: 59.3186634,
    lng: 18.0356141,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11739",
    lat: 59.3140818,
    lng: 18.0325367,
  },
  {
    visitors: "2",
    City: "Johanneshov",
    zipCode: "12144",
    lat: 59.2949099,
    lng: 18.0755459,
  },
  {
    visitors: "2",
    City: "Johanneshov",
    zipCode: "12144",
    lat: 59.2949099,
    lng: 18.0755459,
  },
  {
    visitors: "1",
    City: "Bromma",
    zipCode: "16869",
    lat: 59.3434396,
    lng: 17.9648737,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11848",
    lat: 59.3179864,
    lng: 18.0624583,
  },
  {
    visitors: "2",
    City: "Årsta",
    zipCode: "12038",
    lat: 59.2980063,
    lng: 18.0669126,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11416",
    lat: 59.37035950000001,
    lng: 18.0617842,
  },
  {
    visitors: "2",
    City: "Lidingö",
    zipCode: "18135",
    lat: 59.3554263,
    lng: 18.1309396,
  },
  {
    visitors: "2",
    City: "Hägersten",
    zipCode: "12941",
    lat: 59.30076580000001,
    lng: 17.9616229,
  },
  {
    visitors: "1",
    City: "Nacka",
    zipCode: "13144",
    lat: 59.31110210000001,
    lng: 18.2112924,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11768",
    lat: 59.31461539999999,
    lng: 18.0001365,
  },
  {
    visitors: "1",
    City: "Sköndal",
    zipCode: "12862",
    lat: 59.2642169,
    lng: 18.1080165,
  },
  {
    visitors: "2",
    City: "Johanneshov",
    zipCode: "12155",
    lat: 59.29641530000001,
    lng: 18.0755919,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11540",
    lat: 59.3465891,
    lng: 18.1069357,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11623",
    lat: 59.3125021,
    lng: 18.0760798,
  },
  {
    visitors: "1",
    City: "Saltsjö-Boo",
    zipCode: "13236",
    lat: 59.3320304,
    lng: 18.3187143,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11663",
    lat: 59.3092322,
    lng: 18.0807273,
  },
  {
    visitors: "2",
    City: "Bandhagen",
    zipCode: "12476",
    lat: 59.25863,
    lng: 18.0094438,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11358",
    lat: 59.3404938,
    lng: 18.0611609,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11324",
    lat: 59.3403071,
    lng: 18.0468548,
  },
  {
    visitors: "1",
    City: "Järfälla",
    zipCode: "17556",
    lat: 59.40024,
    lng: 17.7893639,
  },
  {
    visitors: "1",
    City: "Spånga",
    zipCode: "16345",
    lat: 59.36565259999999,
    lng: 17.9079542,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11620",
    lat: 59.3173323,
    lng: 18.0774194,
  },
  {
    visitors: "2",
    City: "Bagarmossen",
    zipCode: "12845",
    lat: 59.2760739,
    lng: 18.1367893,
  },
  {
    visitors: "2",
    City: "Handen",
    zipCode: "13642",
    lat: 59.1640692,
    lng: 18.1477336,
  },
  {
    visitors: "1",
    City: "Skattungbyn",
    zipCode: "79495",
    lat: 61.1866825,
    lng: 14.853163,
  },
  {
    visitors: "1",
    City: "Lidingö",
    zipCode: "18160",
    lat: 59.345363,
    lng: 18.1698664,
  },
  {
    visitors: "1",
    City: "Sollentuna",
    zipCode: "19137",
    lat: 59.4000153,
    lng: 17.9777622,
  },
  {
    visitors: "1",
    City: "Viken",
    zipCode: "26361",
    lat: 56.1459215,
    lng: 12.5804235,
  },
  {
    visitors: "1",
    City: "Solna",
    zipCode: "16950",
    lat: 59.3650479,
    lng: 17.9947004,
  },
  {
    visitors: "1",
    City: "London",
    zipCode: "N4 2GJ",
    lat: 51.57167150000001,
    lng: -0.08949879999999999,
  },
  {
    visitors: "1",
    City: "Stocksund",
    zipCode: "18274",
    lat: 59.3881332,
    lng: 18.0637231,
  },
  {
    visitors: "2",
    City: "Johanneshov",
    zipCode: "12137",
    lat: 59.2952652,
    lng: 18.093823,
  },
  {
    visitors: "3",
    City: "Stockholm",
    zipCode: "11853",
    lat: 59.3151166,
    lng: 18.0513174,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11535",
    lat: 59.3450277,
    lng: 18.0983595,
  },
  {
    visitors: "1",
    City: "Arvika",
    zipCode: "67151",
    lat: 59.65302039999999,
    lng: 12.5749897,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11247",
    lat: 59.3357892,
    lng: 18.0303035,
  },
  {
    visitors: "2",
    City: "Hägersten",
    zipCode: "12940",
    lat: 59.305595,
    lng: 17.9433528,
  },
  {
    visitors: "1",
    City: "Bromma",
    zipCode: "16843",
    lat: 59.34381739999999,
    lng: 17.8809513,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11824",
    lat: 59.31996059999999,
    lng: 18.06296,
  },
  {
    visitors: "1",
    City: "Hägersten",
    zipCode: "12931",
    lat: 59.29174210000001,
    lng: 17.9832141,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11738",
    lat: 59.31558519999999,
    lng: 18.0272198,
  },
  {
    visitors: "3",
    City: "Norrköping",
    zipCode: "60243",
    lat: 58.58992419999999,
    lng: 16.2065747,
  },
  {
    visitors: "3",
    City: "Åre",
    zipCode: "83797",
    lat: 63.37809429999999,
    lng: 13.1472907,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11850",
    lat: 59.3164285,
    lng: 18.0590482,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11758",
    lat: 59.3076962,
    lng: 18.0303731,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11256",
    lat: 59.33132930000001,
    lng: 18.0002944,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11732",
    lat: 59.3198462,
    lng: 18.040352,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11124",
    lat: 59.3364515,
    lng: 18.053005,
  },
  {
    visitors: "2",
    City: "Sundbyberg",
    zipCode: "17234",
    lat: 59.36524049999999,
    lng: 17.9645861,
  },
  {
    visitors: "2",
    City: "Jönköping",
    zipCode: "55335",
    lat: 57.7714654,
    lng: 14.1525191,
  },
  {
    visitors: "1",
    City: "Bandhagen",
    zipCode: "12434",
    lat: 59.2678465,
    lng: 18.0515894,
  },
  {
    visitors: "1",
    City: "Hägersten",
    zipCode: "12930",
    lat: 59.2931754,
    lng: 17.9862008,
  },
  {
    visitors: "1",
    City: "Solna",
    zipCode: "17163",
    lat: 59.35434660000001,
    lng: 18.0006142,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11329",
    lat: 59.3409434,
    lng: 18.051513,
  },
  {
    visitors: "1",
    City: "Årsta",
    zipCode: "12052",
    lat: 59.29488430000001,
    lng: 18.054947,
  },
  {
    visitors: "2",
    City: "Sundbyberg",
    zipCode: "17441",
    lat: 59.3859739,
    lng: 17.9744533,
  },
  {
    visitors: "3",
    City: "Stockholm",
    zipCode: "11416",
    lat: 59.37035950000001,
    lng: 18.0617842,
  },
  {
    visitors: "1",
    City: "Danderyd",
    zipCode: "18234",
    lat: 59.4043888,
    lng: 18.0280746,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11539",
    lat: 59.34788439999999,
    lng: 18.0991992,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11666",
    lat: 59.3079362,
    lng: 18.0858772,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11662",
    lat: 59.31065450000001,
    lng: 18.075993,
  },
  {
    visitors: "1",
    City: "Västerås",
    zipCode: "72130",
    lat: 59.51322020000001,
    lng: 16.6159425,
  },
  {
    visitors: "3",
    City: "Hägersten",
    zipCode: "12947",
    lat: 59.2927139,
    lng: 17.9659512,
  },
  {
    visitors: "1",
    City: "Hässelby",
    zipCode: "16571",
    lat: 59.36700929999999,
    lng: 17.8035682,
  },
  {
    visitors: "4",
    City: "Stockholm",
    zipCode: "11236",
    lat: 59.32949720000001,
    lng: 18.0352885,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11550",
    lat: 59.3456448,
    lng: 18.0849637,
  },
  {
    visitors: "1",
    City: "Bromma",
    zipCode: "16841",
    lat: 59.33068939999999,
    lng: 17.9027637,
  },
  {
    visitors: "1",
    City: "Hägersten",
    zipCode: "12934",
    lat: 59.30122799999999,
    lng: 17.9818769,
  },
  {
    visitors: "3",
    City: "Stockholm",
    zipCode: "11632",
    lat: 59.314452,
    lng: 18.0936233,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11847",
    lat: 59.3174468,
    lng: 18.0675278,
  },
  {
    visitors: "1",
    City: "Lidingö",
    zipCode: "18136",
    lat: 59.3536649,
    lng: 18.1354296,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11215",
    lat: 59.338047,
    lng: 18.0050293,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11449",
    lat: 59.3398021,
    lng: 18.0802922,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11452",
    lat: 59.3341776,
    lng: 18.083192,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11346",
    lat: 59.35097649999999,
    lng: 18.0476967,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11538",
    lat: 59.3455783,
    lng: 18.1036039,
  },
  {
    visitors: "1",
    City: "Kista",
    zipCode: "16436",
    lat: 59.4064726,
    lng: 17.9322808,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11253",
    lat: 59.3353572,
    lng: 17.9981311,
  },
  {
    visitors: "1",
    City: "London",
    zipCode: "N10 2PS",
    lat: 51.5922744,
    lng: -0.1410844,
  },
  {
    visitors: "1",
    City: "Milano",
    zipCode: "20121",
    lat: 45.4720482,
    lng: 9.1920381,
  },
  {
    visitors: "1",
    City: "Guildford",
    zipCode: "GU5 9EQ",
    lat: 51.1919328,
    lng: -0.4821767,
  },
  {
    visitors: "1",
    City: "Timrå",
    zipCode: "86135",
    lat: 62.4877678,
    lng: 17.3120176,
  },
  {
    visitors: "2",
    City: "Älvsjö",
    zipCode: "12570",
    lat: 59.2801559,
    lng: 18.030523,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11345",
    lat: 59.34790940000001,
    lng: 18.0470283,
  },
  {
    visitors: "1",
    City: "Johanneshov",
    zipCode: "12139",
    lat: 59.29641530000001,
    lng: 18.0755919,
  },
  {
    visitors: "4",
    City: "Bagarmossen",
    zipCode: "12841",
    lat: 59.27883660000001,
    lng: 18.1253378,
  },
  {
    visitors: "2",
    City: "Järfälla",
    zipCode: "17747",
    lat: 59.4431024,
    lng: 17.8336732,
  },
  {
    visitors: "1",
    City: "Johanneshov",
    zipCode: "12138",
    lat: 59.29619499999999,
    lng: 18.1003061,
  },
  {
    visitors: "3",
    City: "Mölndal",
    zipCode: "43139",
    lat: 57.6674853,
    lng: 11.9941921,
  },
  {
    visitors: "1",
    City: "Göteborg",
    zipCode: "41706",
    lat: 57.72201239999999,
    lng: 11.9430356,
  },
  {
    visitors: "1",
    City: "Bromma",
    zipCode: "16878",
    lat: 59.364959,
    lng: 17.9500921,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11341",
    lat: 59.3397781,
    lng: 18.0297231,
  },
  {
    visitors: "2",
    City: "Uppsala",
    zipCode: "75444",
    lat: 59.8682314,
    lng: 17.6791596,
  },
  {
    visitors: "1",
    City: "Älvsjö",
    zipCode: "12574",
    lat: 59.2847914,
    lng: 18.0423137,
  },
  {
    visitors: "1",
    City: "Handen",
    zipCode: "13648",
    lat: 59.17831219999999,
    lng: 18.1262739,
  },
  {
    visitors: "1",
    City: "Göteborg",
    zipCode: "41320",
    lat: 57.69042649999999,
    lng: 11.9649386,
  },
  {
    visitors: "1",
    City: "Malmö",
    zipCode: "21436",
    lat: 55.59305209999999,
    lng: 13.015169,
  },
  {
    visitors: "1",
    City: "Hägersten",
    zipCode: "12631",
    lat: 59.2971622,
    lng: 18.0077821,
  },
  {
    visitors: "1",
    City: "Fort Collins",
    zipCode: "80521-4327",
    lat: 40.5889664,
    lng: -105.1375908,
  },
  {
    visitors: "2",
    City: "Washington",
    zipCode: "20003",
    lat: 38.8831372,
    lng: -76.9902276,
  },
  {
    visitors: "3",
    City: "Stockholm",
    zipCode: "11750",
    lat: 59.3189319,
    lng: 18.0194102,
  },
  {
    visitors: "2",
    City: "Tyresö",
    zipCode: "13569",
    lat: 59.21174809999999,
    lng: 18.3879394,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11731",
    lat: 59.3186634,
    lng: 18.0356141,
  },
  {
    visitors: "1",
    City: "Järfälla",
    zipCode: "17745",
    lat: 59.41108509999999,
    lng: 17.8654968,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11330",
    lat: 59.3457102,
    lng: 18.0363793,
  },
  {
    visitors: "2",
    City: "Saltsjö-Boo",
    zipCode: "13253",
    lat: 59.34062889999999,
    lng: 18.2288202,
  },
  {
    visitors: "2",
    City: "Johanneshov",
    zipCode: "12152",
    lat: 59.2942813,
    lng: 18.1214057,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11644",
    lat: 59.31248979999999,
    lng: 18.1060048,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11668",
    lat: 59.3057526,
    lng: 18.0855397,
  },
  {
    visitors: "2",
    City: "Slite",
    zipCode: "62449",
    lat: 57.7182365,
    lng: 18.7922808,
  },
  {
    visitors: "1",
    City: "Sundbyberg",
    zipCode: "17269",
    lat: 59.3618675,
    lng: 17.9791475,
  },
  {
    visitors: "1",
    City: "Årsta",
    zipCode: "12038",
    lat: 59.2980063,
    lng: 18.0669126,
  },
  {
    visitors: "1",
    City: "Farsta",
    zipCode: "12332",
    lat: 59.2411878,
    lng: 18.0767583,
  },
  {
    visitors: "1",
    City: "Hägersten",
    zipCode: "12957",
    lat: 59.28603409999999,
    lng: 17.9524659,
  },
  {
    visitors: "1",
    City: "Johanneshov",
    zipCode: "12151",
    lat: 59.28957299999999,
    lng: 18.1127486,
  },
  {
    visitors: "1",
    City: "Södertälje",
    zipCode: "15132",
    lat: 59.187041,
    lng: 17.6304601,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11263",
    lat: 59.3241564,
    lng: 18.0049513,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11545",
    lat: 59.3576592,
    lng: 18.0868206,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11344",
    lat: 59.34756950000001,
    lng: 18.0441996,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11326",
    lat: 59.34433610000001,
    lng: 18.044696,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11623",
    lat: 59.3125021,
    lng: 18.0760798,
  },
  {
    visitors: "1",
    City: "Rimbo",
    zipCode: "76291",
    lat: 59.7436995,
    lng: 18.3601496,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11249",
    lat: 59.3375856,
    lng: 18.0242345,
  },
  {
    visitors: "5",
    City: "Stockholm",
    zipCode: "11823",
    lat: 59.31906499999999,
    lng: 18.0523194,
  },
  {
    visitors: "1",
    City: "Nacka",
    zipCode: "13132",
    lat: 59.3100621,
    lng: 18.1151422,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11739",
    lat: 59.3140818,
    lng: 18.0325367,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11851",
    lat: 59.3169244,
    lng: 18.0529817,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11240",
    lat: 59.33166970000001,
    lng: 18.0278909,
  },
  {
    visitors: "2",
    City: "Bromma",
    zipCode: "16840",
    lat: 59.33080589999999,
    lng: 17.9150541,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11431",
    lat: 59.34151799999999,
    lng: 18.074805,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11431",
    lat: 59.34151799999999,
    lng: 18.074805,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "12078",
    lat: 59.3028184,
    lng: 18.094306,
  },
  {
    visitors: "1",
    City: "Uppsala",
    zipCode: "75313",
    lat: 59.86034859999999,
    lng: 17.6223238,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11538",
    lat: 59.3455783,
    lng: 18.1036039,
  },
  {
    visitors: "2",
    City: "Sollentuna",
    zipCode: "19150",
    lat: 59.4475853,
    lng: 17.9336554,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11537",
    lat: 59.3473789,
    lng: 18.0975334,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11261",
    lat: 59.32516560000001,
    lng: 18.0056989,
  },
  {
    visitors: "1",
    City: "Värmdö",
    zipCode: "13954",
    lat: 59.33668789999999,
    lng: 18.6125136,
  },
  {
    visitors: "1",
    City: "Solna",
    zipCode: "17073",
    lat: 59.3780049,
    lng: 18.0304017,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11639",
    lat: 59.3102435,
    lng: 18.084054,
  },
  {
    visitors: "1",
    City: "Enskede",
    zipCode: "12263",
    lat: 59.2791331,
    lng: 18.0818234,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11266",
    lat: 59.32071959999999,
    lng: 17.9860103,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11357",
    lat: 59.34272139999999,
    lng: 18.062495,
  },
  {
    visitors: "2",
    City: "Lidingö",
    zipCode: "18150",
    lat: 59.36383459999999,
    lng: 18.1254991,
  },
  {
    visitors: "2",
    City: "Hägersten",
    zipCode: "12653",
    lat: 59.30652240000001,
    lng: 17.9838643,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11860",
    lat: 59.3039771,
    lng: 18.0745671,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11258",
    lat: 59.3271447,
    lng: 18.0087731,
  },
  {
    visitors: "1",
    City: "Täby",
    zipCode: "18749",
    lat: 59.4369317,
    lng: 18.0575294,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11164",
    lat: 59.33049159999999,
    lng: 18.0556587,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11859",
    lat: 59.3063213,
    lng: 18.0685889,
  },
  {
    visitors: "1",
    City: "Bromma",
    zipCode: "16874",
    lat: 59.34491749999999,
    lng: 17.9492451,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11664",
    lat: 59.3074681,
    lng: 18.0800587,
  },
  {
    visitors: "2",
    City: "Nacka",
    zipCode: "13160",
    lat: 59.3037016,
    lng: 18.1490386,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "114 25",
    lat: 59.3428511,
    lng: 18.0654899,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11633",
    lat: 59.31377189999999,
    lng: 18.0853919,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11634",
    lat: 59.31365819999999,
    lng: 18.097861,
  },
  {
    visitors: "3",
    City: "Sundbyberg",
    zipCode: "17241",
    lat: 59.3672425,
    lng: 17.9779757,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "12070",
    lat: 59.30728790000001,
    lng: 18.1111431,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "12070",
    lat: 59.30728790000001,
    lng: 18.1111431,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11848",
    lat: 59.3179864,
    lng: 18.0624583,
  },
  {
    visitors: "3",
    City: "Enskededalen",
    zipCode: "12133",
    lat: 59.28385359999999,
    lng: 18.1007693,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11733",
    lat: 59.32239069999999,
    lng: 18.0290508,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11862",
    lat: 59.30889029999999,
    lng: 18.0753247,
  },
  {
    visitors: "2",
    City: "Saltsjöbaden",
    zipCode: "13333",
    lat: 59.29544680000001,
    lng: 18.2937199,
  },
  {
    visitors: "1",
    City: "Sundbyberg",
    zipCode: "17441",
    lat: 59.3859739,
    lng: 17.9744533,
  },
  {
    visitors: "1",
    City: "Sundbyberg",
    zipCode: "17441",
    lat: 59.3859739,
    lng: 17.9744533,
  },
  {
    visitors: "1",
    City: "Johanneshov",
    zipCode: "12152",
    lat: 59.2942813,
    lng: 18.1214057,
  },
  {
    visitors: "2",
    City: "Domsjö",
    zipCode: "89251",
    lat: 63.27513459999999,
    lng: 18.6774865,
  },
  {
    visitors: "1",
    City: "Hägersten",
    zipCode: "12652",
    lat: 59.30729299999999,
    lng: 17.9915032,
  },
  {
    visitors: "4",
    City: "Hägersten",
    zipCode: "12647",
    lat: 59.30310060000001,
    lng: 17.9948274,
  },
  {
    visitors: "3",
    City: "Örebro",
    zipCode: "70381",
    lat: 59.2839191,
    lng: 15.2388782,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11138",
    lat: 59.3376433,
    lng: 18.0654819,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11640",
    lat: 59.31196459999999,
    lng: 18.0837254,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11822",
    lat: 59.31937139999999,
    lng: 18.0618785,
  },
  {
    visitors: "1",
    City: "Hägersten",
    zipCode: "12655",
    lat: 59.30993299999999,
    lng: 18.000807,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11625",
    lat: 59.3159875,
    lng: 18.0764192,
  },
  {
    visitors: "2",
    City: "Hägersten",
    zipCode: "12942",
    lat: 59.2972129,
    lng: 17.9500103,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "113 58",
    lat: 59.3404938,
    lng: 18.0611609,
  },
  {
    visitors: "2",
    City: "Stocksund",
    zipCode: "18278",
    lat: 59.38324360000001,
    lng: 18.0510564,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11354",
    lat: 59.3502308,
    lng: 18.0555166,
  },
  {
    visitors: "1",
    City: "Bandhagen",
    zipCode: "12475",
    lat: 59.2604814,
    lng: 18.0120977,
  },
  {
    visitors: "3",
    City: "Täby",
    zipCode: "18337",
    lat: 59.4390712,
    lng: 18.0667896,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11637",
    lat: 59.31242160000001,
    lng: 18.0792381,
  },
  {
    visitors: "1",
    City: "Bromma",
    zipCode: "16765",
    lat: 59.3153477,
    lng: 17.9479804,
  },
  {
    visitors: "1",
    City: "Nürnberg",
    zipCode: "90429",
    lat: 49.4511786,
    lng: 11.0525563,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11536",
    lat: 59.34687340000001,
    lng: 18.0958677,
  },
  {
    visitors: "1",
    City: "Berlin",
    zipCode: "12059",
    lat: 52.480543,
    lng: 13.4525932,
  },
  {
    visitors: "1",
    City: "Azzano Decimo",
    zipCode: "33082",
    lat: 45.8813211,
    lng: 12.7144703,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11440",
    lat: 59.3389628,
    lng: 18.080956,
  },
  {
    visitors: "2",
    City: "Solna",
    zipCode: "16951",
    lat: 59.3629113,
    lng: 17.9979467,
  },
  {
    visitors: "2",
    City: "Solna",
    zipCode: "16951",
    lat: 59.3629113,
    lng: 17.9979467,
  },
  {
    visitors: "1",
    City: "Solna",
    zipCode: "16951",
    lat: 59.3629113,
    lng: 17.9979467,
  },
  {
    visitors: "1",
    City: "Solna",
    zipCode: "16951",
    lat: 59.3629113,
    lng: 17.9979467,
  },
  {
    visitors: "1",
    City: "London",
    zipCode: "NW2 1JG",
    lat: 51.5692327,
    lng: -0.2108829,
  },
  {
    visitors: "1",
    City: "Sundbyberg",
    zipCode: "17450",
    lat: 59.37336860000001,
    lng: 17.9741397,
  },
  {
    visitors: "1",
    City: "Saltsjöbaden",
    zipCode: "13335",
    lat: 59.27932200000001,
    lng: 18.3022245,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11422",
    lat: 59.3481825,
    lng: 18.0633348,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11536",
    lat: 59.34687340000001,
    lng: 18.0958677,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11642",
    lat: 59.3092808,
    lng: 18.0868777,
  },
  {
    visitors: "2",
    City: "Uppsala",
    zipCode: "75434",
    lat: 59.8679004,
    lng: 17.6675764,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11129",
    lat: 59.3244212,
    lng: 18.070782,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "12078",
    lat: 59.3028184,
    lng: 18.094306,
  },
  {
    visitors: "2",
    City: "Sundbyberg",
    zipCode: "17232",
    lat: 59.3645393,
    lng: 17.9708257,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11340",
    lat: 59.34330019999999,
    lng: 18.025899,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11538",
    lat: 59.3455783,
    lng: 18.1036039,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11557",
    lat: 59.3476674,
    lng: 18.0916254,
  },
  {
    visitors: "1",
    City: "Sundbyberg",
    zipCode: "17263",
    lat: 59.3586235,
    lng: 17.9744944,
  },
  {
    visitors: "1",
    City: "Täby",
    zipCode: "18357",
    lat: 59.433221,
    lng: 18.1024966,
  },
  {
    visitors: "1",
    City: "Bandhagen",
    zipCode: "12464",
    lat: 59.2542117,
    lng: 18.027354,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11265",
    lat: 59.31828170000001,
    lng: 17.9848496,
  },
  {
    visitors: "2",
    City: "Sigtuna",
    zipCode: "19332",
    lat: 59.62674449999999,
    lng: 17.7024728,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "12061",
    lat: 59.3032408,
    lng: 18.0926804,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11634",
    lat: 59.31365819999999,
    lng: 18.097861,
  },
  {
    visitors: "2",
    City: "Kista",
    zipCode: "16453",
    lat: 59.40066789999999,
    lng: 17.9482831,
  },
  {
    visitors: "1",
    City: "Farsta",
    zipCode: "12355",
    lat: 59.25351689999999,
    lng: 18.0868188,
  },
  {
    visitors: "1",
    City: "Enskededalen",
    zipCode: "12131",
    lat: 59.2856124,
    lng: 18.0962892,
  },
  {
    visitors: "3",
    City: "Stockholm",
    zipCode: "12078",
    lat: 59.3028184,
    lng: 18.094306,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11320",
    lat: 59.3394968,
    lng: 18.0382051,
  },
  {
    visitors: "2",
    City: "Danderyd",
    zipCode: "18257",
    lat: 59.39279549999999,
    lng: 18.0310718,
  },
  {
    visitors: "1",
    City: "Solna",
    zipCode: "17156",
    lat: 59.34743169999999,
    lng: 17.9864827,
  },
  {
    visitors: "1",
    City: "Solna",
    zipCode: "17156",
    lat: 59.34743169999999,
    lng: 17.9864827,
  },
  {
    visitors: "1",
    City: "Sundbyberg",
    zipCode: "17230",
    lat: 59.3629989,
    lng: 17.977732,
  },
  {
    visitors: "1",
    City: "Hallstahammar",
    zipCode: "73440",
    lat: 59.6032196,
    lng: 16.2159831,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11646",
    lat: 59.3185448,
    lng: 18.0728497,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11763",
    lat: 59.30860560000001,
    lng: 18.0214021,
  },
  {
    visitors: "1",
    City: "Solna",
    zipCode: "17172",
    lat: 59.3477922,
    lng: 17.9996201,
  },
  {
    visitors: "1",
    City: "Skogås",
    zipCode: "14291",
    lat: 59.1782729,
    lng: 18.0852159,
  },
  {
    visitors: "1",
    City: "LYON",
    zipCode: "69007",
    lat: 45.7304251,
    lng: 4.8399378,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11769",
    lat: 59.3149276,
    lng: 17.9875107,
  },
  {
    visitors: "2",
    City: "Årsta",
    zipCode: "12056",
    lat: 59.2925275,
    lng: 18.0506254,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11227",
    lat: 59.33123699999999,
    lng: 18.0478438,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11263",
    lat: 59.3241564,
    lng: 18.0049513,
  },
  {
    visitors: "1",
    City: "Lidingö",
    zipCode: "18135",
    lat: 59.3554263,
    lng: 18.1309396,
  },
  {
    visitors: "3",
    City: "Hägersten",
    zipCode: "12934",
    lat: 59.30122799999999,
    lng: 17.9818769,
  },
  {
    visitors: "1",
    City: "Hägersten",
    zipCode: "12650",
    lat: 59.3079066,
    lng: 18.0054548,
  },
  {
    visitors: "2",
    City: "Västra Frölunda",
    zipCode: "42679",
    lat: 57.66273999999999,
    lng: 11.8631577,
  },
  {
    visitors: "1",
    City: "Västra Frölunda",
    zipCode: "42679",
    lat: 57.66273999999999,
    lng: 11.8631577,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11160",
    lat: 59.3379267,
    lng: 18.0569988,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11160",
    lat: 59.3379267,
    lng: 18.0569988,
  },
  {
    visitors: "1",
    City: "Älvsjö",
    zipCode: "12559",
    lat: 59.27963330000001,
    lng: 17.9742656,
  },
  {
    visitors: "1",
    City: "Farsta",
    zipCode: "12372",
    lat: 59.2348445,
    lng: 18.1105751,
  },
  {
    visitors: "1",
    City: "Lidingö",
    zipCode: "18132",
    lat: 59.3634944,
    lng: 18.1369656,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "12065",
    lat: 59.30341729999999,
    lng: 18.1018224,
  },
  {
    visitors: "1",
    City: "Bromma",
    zipCode: "16875",
    lat: 59.3435472,
    lng: 17.9379458,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11357",
    lat: 59.34272139999999,
    lng: 18.062495,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11263",
    lat: 59.3241564,
    lng: 18.0049513,
  },
  {
    visitors: "2",
    City: "Berlin",
    zipCode: "10785",
    lat: 52.50691150000001,
    lng: 13.3650508,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11232",
    lat: 59.3342456,
    lng: 18.0372037,
  },
  {
    visitors: "1",
    City: "Hägersten",
    zipCode: "12932",
    lat: 59.2977865,
    lng: 17.9825444,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11329",
    lat: 59.3409434,
    lng: 18.051513,
  },
  {
    visitors: "1",
    City: "London",
    zipCode: "W8 6DL",
    lat: 51.4950943,
    lng: -0.1970472,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11250",
    lat: 59.3362401,
    lng: 18.0232365,
  },
  {
    visitors: "1",
    City: "SHENTON PARK",
    zipCode: "6008",
    lat: -31.9608733,
    lng: 115.8148972,
  },
  {
    visitors: "1",
    City: "Bagarmossen",
    zipCode: "12844",
    lat: 59.27263550000001,
    lng: 18.142589,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11426",
    lat: 59.3438229,
    lng: 18.0703998,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11255",
    lat: 59.3304997,
    lng: 18.0061127,
  },
  {
    visitors: "1",
    City: "Hässelby",
    zipCode: "16552",
    lat: 59.3719233,
    lng: 17.8399296,
  },
  {
    visitors: "1",
    City: "Älvsjö",
    zipCode: "12557",
    lat: 59.2722656,
    lng: 17.9842268,
  },
  {
    visitors: "2",
    City: "'S-GRAVENHAGE",
    zipCode: "2565 AX",
    lat: 52.069675,
    lng: 4.2503716,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11345",
    lat: 59.34790940000001,
    lng: 18.0470283,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11639",
    lat: 59.3102435,
    lng: 18.084054,
  },
  {
    visitors: "1",
    City: "Lidingö",
    zipCode: "18155",
    lat: 59.34440350000001,
    lng: 18.1608684,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11766",
    lat: 59.3159005,
    lng: 18.014591,
  },
  {
    visitors: "1",
    City: "Handen",
    zipCode: "13635",
    lat: 59.1691908,
    lng: 18.1402459,
  },
  {
    visitors: "1",
    City: "Hägersten",
    zipCode: "12650",
    lat: 59.3079066,
    lng: 18.0054548,
  },
  {
    visitors: "1",
    City: "Oslo",
    zipCode: "0596",
    lat: 59.94120859999999,
    lng: 10.8474544,
  },
  {
    visitors: "1",
    City: "Södertälje",
    zipCode: "15132",
    lat: 59.187041,
    lng: 17.6304601,
  },
  {
    visitors: "2",
    City: "SHENTON PARK",
    zipCode: "6008",
    lat: -31.9608733,
    lng: 115.8148972,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11526",
    lat: 59.3365032,
    lng: 18.0994189,
  },
  {
    visitors: "10",
    City: "Stockholm",
    zipCode: "11523",
    lat: 59.33356089999999,
    lng: 18.0965827,
  },
  {
    visitors: "3",
    City: "Stockholm",
    zipCode: "11523",
    lat: 59.33356089999999,
    lng: 18.0965827,
  },
  {
    visitors: "2",
    City: "Johanneshov",
    zipCode: "12137",
    lat: 59.2952652,
    lng: 18.093823,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11160",
    lat: 59.3379267,
    lng: 18.0569988,
  },
  {
    visitors: "1",
    City: "Enskede",
    zipCode: "12242",
    lat: 59.2760729,
    lng: 18.0512685,
  },
  {
    visitors: "1",
    City: "London",
    zipCode: "W14 8QB",
    lat: 51.495677,
    lng: -0.2033775,
  },
  {
    visitors: "1",
    City: "Dublin",
    zipCode: "",
    lat: 53.3498053,
    lng: -6.2603097,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11323",
    lat: 59.34420579999999,
    lng: 18.0417015,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11321",
    lat: 59.33727640000001,
    lng: 18.0420287,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "12067",
    lat: 59.30568659999999,
    lng: 18.1041561,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11826",
    lat: 59.3156423,
    lng: 18.068439,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11330",
    lat: 59.3457102,
    lng: 18.0363793,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11537",
    lat: 59.3473789,
    lng: 18.0975334,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11635",
    lat: 59.3128091,
    lng: 18.0882159,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11638",
    lat: 59.31045859999999,
    lng: 18.0890415,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11635",
    lat: 59.3128091,
    lng: 18.0882159,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11538",
    lat: 59.3455783,
    lng: 18.1036039,
  },
  {
    visitors: "1",
    City: "Göteborg",
    zipCode: "41316",
    lat: 57.6936472,
    lng: 11.9425421,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11638",
    lat: 59.31045859999999,
    lng: 18.0890415,
  },
  {
    visitors: "1",
    City: "Bushmills",
    zipCode: "BT57 8SB",
    lat: 55.2157829,
    lng: -6.5512476,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11761",
    lat: 59.31138329999999,
    lng: 18.0253904,
  },
  {
    visitors: "1",
    City: "Södertälje",
    zipCode: "15231",
    lat: 59.19652869999999,
    lng: 17.6475071,
  },
  {
    visitors: "1",
    City: "Austin",
    zipCode: "78746-7517",
    lat: 30.2962208,
    lng: -97.8299383,
  },
  {
    visitors: "3",
    City: "Gustavsberg",
    zipCode: "13441",
    lat: 59.3228569,
    lng: 18.3919626,
  },
  {
    visitors: "3",
    City: "Stockholm",
    zipCode: "11558",
    lat: 59.3456167,
    lng: 18.0994429,
  },
  {
    visitors: "3",
    City: "Stockholm",
    zipCode: "11829",
    lat: 59.3121569,
    lng: 18.0681005,
  },
  {
    visitors: "1",
    City: "London",
    zipCode: "SW18 2SP",
    lat: 51.4596158,
    lng: -0.1753416,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11731",
    lat: 59.3186634,
    lng: 18.0356141,
  },
  {
    visitors: "1",
    City: "Hägersten",
    zipCode: "12949",
    lat: 59.291059,
    lng: 17.9775714,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11739",
    lat: 59.3140818,
    lng: 18.0325367,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "115 57",
    lat: 59.3476674,
    lng: 18.0916254,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11324",
    lat: 59.3403071,
    lng: 18.0468548,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11366",
    lat: 59.3476984,
    lng: 18.0357693,
  },
  {
    visitors: "1",
    City: "Järfälla",
    zipCode: "17741",
    lat: 59.4185347,
    lng: 17.8285004,
  },
  {
    visitors: "2",
    City: "Edsbyn",
    zipCode: "82832",
    lat: 61.3783307,
    lng: 15.8370539,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11846",
    lat: 59.31849860000001,
    lng: 18.0692752,
  },
  {
    visitors: "1",
    City: "London",
    zipCode: "E1W 2JP",
    lat: 51.5064481,
    lng: -0.0626014,
  },
  {
    visitors: "2",
    City: "Sundbyberg",
    zipCode: "17457",
    lat: 59.37589469999999,
    lng: 17.938027,
  },
  {
    visitors: "1",
    City: "Solna",
    zipCode: "17067",
    lat: 59.3808216,
    lng: 18.0054254,
  },
  {
    visitors: "2",
    City: "Enskede",
    zipCode: "12248",
    lat: 59.2739164,
    lng: 18.0715179,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11237",
    lat: 59.32907,
    lng: 18.0304668,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11445",
    lat: 59.3373704,
    lng: 18.0842802,
  },
  {
    visitors: "3",
    City: "Johanneshov",
    zipCode: "12143",
    lat: 59.2968405,
    lng: 18.0750514,
  },
  {
    visitors: "1",
    City: "Johanneshov",
    zipCode: "12143",
    lat: 59.2968405,
    lng: 18.0750514,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11355",
    lat: 59.3464977,
    lng: 18.0595054,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11560",
    lat: 59.34379910000001,
    lng: 18.1118038,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "16561",
    lat: 59.35600540000001,
    lng: 17.8288917,
  },
  {
    visitors: "2",
    City: "Johanneshov",
    zipCode: "12144",
    lat: 59.2949099,
    lng: 18.0755459,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11733",
    lat: 59.32239069999999,
    lng: 18.0290508,
  },
  {
    visitors: "1",
    City: "Berlin",
    zipCode: "10245",
    lat: 52.5019332,
    lng: 13.4554471,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11360",
    lat: 59.3393085,
    lng: 18.05384,
  },
  {
    visitors: "2",
    City: "Solna",
    zipCode: "16953",
    lat: 59.36493040000001,
    lng: 17.9994429,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11637",
    lat: 59.31242160000001,
    lng: 18.0792381,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11636",
    lat: 59.3141967,
    lng: 18.0902143,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11733",
    lat: 59.32239069999999,
    lng: 18.0290508,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11850",
    lat: 59.3164285,
    lng: 18.0590482,
  },
  {
    visitors: "2",
    City: "Bromma",
    zipCode: "16741",
    lat: 59.33542569999999,
    lng: 17.9898193,
  },
  {
    visitors: "1",
    City: "Solna",
    zipCode: "17168",
    lat: 59.35758479999999,
    lng: 18.0027171,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11622",
    lat: 59.3183437,
    lng: 18.0807468,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11460",
    lat: 59.3356541,
    lng: 18.0897666,
  },
  {
    visitors: "1",
    City: "Ursvik",
    zipCode: "17463",
    lat: 59.3863253,
    lng: 17.9466752,
  },
  {
    visitors: "1",
    City: "Bromma",
    zipCode: "16844",
    lat: 59.3487101,
    lng: 17.8594983,
  },
  {
    visitors: "1",
    City: "Enskede",
    zipCode: "12241",
    lat: 59.28009220000001,
    lng: 18.0439691,
  },
  {
    visitors: "1",
    City: "Hägersten",
    zipCode: "12632",
    lat: 59.30246469999999,
    lng: 18.0149229,
  },
  {
    visitors: "1",
    City: "Saltsjö-Boo",
    zipCode: "13243",
    lat: 59.3169698,
    lng: 18.2479527,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11559",
    lat: 59.3452485,
    lng: 18.1110931,
  },
  {
    visitors: "1",
    City: "Uppsala",
    zipCode: "75335",
    lat: 59.8751368,
    lng: 17.6259833,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11136",
    lat: 59.3362917,
    lng: 18.0593253,
  },
  {
    visitors: "2",
    City: "Örebro",
    zipCode: "70214",
    lat: 59.26925110000001,
    lng: 15.2291669,
  },
  {
    visitors: "3",
    City: "Stockholm",
    zipCode: "11736",
    lat: 59.3174805,
    lng: 18.0308767,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11550",
    lat: 59.3456448,
    lng: 18.0849637,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11739",
    lat: 59.3140818,
    lng: 18.0325367,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11263",
    lat: 59.3241564,
    lng: 18.0049513,
  },
  {
    visitors: "1",
    City: "Katrineholm",
    zipCode: "64136",
    lat: 58.9867399,
    lng: 16.1955511,
  },
  {
    visitors: "2",
    City: "Tyresö",
    zipCode: "13543",
    lat: 59.24025160000001,
    lng: 18.2380671,
  },
  {
    visitors: "1",
    City: "Bromma",
    zipCode: "16875",
    lat: 59.3435472,
    lng: 17.9379458,
  },
  {
    visitors: "2",
    City: "Täby",
    zipCode: "18773",
    lat: 59.50114949999999,
    lng: 18.0859249,
  },
  {
    visitors: "2",
    City: "Lidingö",
    zipCode: "18150",
    lat: 59.36383459999999,
    lng: 18.1254991,
  },
  {
    visitors: "1",
    City: "Årsta",
    zipCode: "12051",
    lat: 59.3016082,
    lng: 18.0599405,
  },
  {
    visitors: "1",
    City: "Västerås",
    zipCode: "72215",
    lat: 59.6144115,
    lng: 16.5389921,
  },
  {
    visitors: "2",
    City: "Upplands Väsby",
    zipCode: "19440",
    lat: 59.5198291,
    lng: 17.8966554,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11228",
    lat: 59.33442669999999,
    lng: 18.0463503,
  },
  {
    visitors: "2",
    City: "Sundbyberg",
    zipCode: "17266",
    lat: 59.3596506,
    lng: 17.9633487,
  },
  {
    visitors: "2",
    City: "Järfälla",
    zipCode: "17669",
    lat: 59.45944050000001,
    lng: 17.7915741,
  },
  {
    visitors: "1",
    City: "Bromma",
    zipCode: "16843",
    lat: 59.34381739999999,
    lng: 17.8809513,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11824",
    lat: 59.31996059999999,
    lng: 18.06296,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11738",
    lat: 59.31558519999999,
    lng: 18.0272198,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "12079",
    lat: 59.3031194,
    lng: 18.0974169,
  },
  {
    visitors: "1",
    City: "Nacka",
    zipCode: "13165",
    lat: 59.3023385,
    lng: 18.1171117,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11732",
    lat: 59.3198462,
    lng: 18.040352,
  },
  {
    visitors: "1",
    City: "Solna",
    zipCode: "17171",
    lat: 59.35031799999999,
    lng: 18.0027788,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11138",
    lat: 59.3376433,
    lng: 18.0654819,
  },
  {
    visitors: "1",
    City: "Hägersten",
    zipCode: "12632",
    lat: 59.30246469999999,
    lng: 18.0149229,
  },
  {
    visitors: "2",
    City: "Älvsjö",
    zipCode: "12570",
    lat: 59.2801559,
    lng: 18.030523,
  },
  {
    visitors: "2",
    City: "Hägersten",
    zipCode: "12637",
    lat: 59.2984889,
    lng: 17.998483,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11559",
    lat: 59.3452485,
    lng: 18.1110931,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11827",
    lat: 59.311614,
    lng: 18.0705928,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11545",
    lat: 59.3576592,
    lng: 18.0868206,
  },
  {
    visitors: "3",
    City: "Handen",
    zipCode: "13643",
    lat: 59.1671701,
    lng: 18.1442719,
  },
  {
    visitors: "1",
    City: "Linköping",
    zipCode: "58212",
    lat: 58.4056455,
    lng: 15.6084579,
  },
  {
    visitors: "1",
    City: "Årsta",
    zipCode: "12057",
    lat: 59.2965475,
    lng: 18.0433218,
  },
  {
    visitors: "2",
    City: "Farsta",
    zipCode: "12355",
    lat: 59.25351689999999,
    lng: 18.0868188,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11460",
    lat: 59.3356541,
    lng: 18.0897666,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11233",
    lat: 59.33592359999999,
    lng: 18.0358744,
  },
  {
    visitors: "2",
    City: "Årsta",
    zipCode: "12039",
    lat: 59.2999432,
    lng: 18.0715683,
  },
  {
    visitors: "2",
    City: "Bromma",
    zipCode: "16844",
    lat: 59.3487101,
    lng: 17.8594983,
  },
  {
    visitors: "1",
    City: "Enskede",
    zipCode: "12244",
    lat: 59.2647023,
    lng: 18.0890934,
  },
  {
    visitors: "1",
    City: "Danderyd",
    zipCode: "18234",
    lat: 59.4043888,
    lng: 18.0280746,
  },
  {
    visitors: "2",
    City: "Huddinge",
    zipCode: "14158",
    lat: 59.21490109999999,
    lng: 17.953771,
  },
  {
    visitors: "1",
    City: "Sköndal",
    zipCode: "12867",
    lat: 59.2556578,
    lng: 18.1106446,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11355",
    lat: 59.3464977,
    lng: 18.0595054,
  },
  {
    visitors: "2",
    City: "Bromma",
    zipCode: "16841",
    lat: 59.33068939999999,
    lng: 17.9027637,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11632",
    lat: 59.314452,
    lng: 18.0936233,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11631",
    lat: 59.3114214,
    lng: 18.0862177,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11631",
    lat: 59.3114214,
    lng: 18.0862177,
  },
  {
    visitors: "1",
    City: "Solna",
    zipCode: "17159",
    lat: 59.3513667,
    lng: 18.0019466,
  },
  {
    visitors: "1",
    City: "Johanneshov",
    zipCode: "12138",
    lat: 59.29619499999999,
    lng: 18.1003061,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11335",
    lat: 59.3429678,
    lng: 18.0282274,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11241",
    lat: 59.3311632,
    lng: 18.0262281,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11349",
    lat: 59.3475834,
    lng: 18.0545151,
  },
  {
    visitors: "1",
    City: "Bromma",
    zipCode: "16841",
    lat: 59.33068939999999,
    lng: 17.9027637,
  },
  {
    visitors: "1",
    City: "Tibro",
    zipCode: "54335",
    lat: 58.4249288,
    lng: 14.1429834,
  },
  {
    visitors: "2",
    City: "Älvsjö",
    zipCode: "12573",
    lat: 59.2884782,
    lng: 18.0373359,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11223",
    lat: 59.3294253,
    lng: 18.0436019,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "12061",
    lat: 59.3032408,
    lng: 18.0926804,
  },
  {
    visitors: "1",
    City: "Norsborg",
    zipCode: "14573",
    lat: 59.2555772,
    lng: 17.8347862,
  },
  {
    visitors: "1",
    City: "Duved",
    zipCode: "83771",
    lat: 63.3924767,
    lng: 12.9175818,
  },
  {
    visitors: "2",
    City: "Nacka",
    zipCode: "13161",
    lat: 59.30563489999999,
    lng: 18.1537034,
  },
  {
    visitors: "1",
    City: "Sandarne",
    zipCode: "82673",
    lat: 61.2486518,
    lng: 17.195476,
  },
  {
    visitors: "1",
    City: "Hägersten",
    zipCode: "12650",
    lat: 59.3079066,
    lng: 18.0054548,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11861",
    lat: 59.3054695,
    lng: 18.0589493,
  },
  {
    visitors: "2",
    City: "Täby",
    zipCode: "18335",
    lat: 59.4465562,
    lng: 18.0691359,
  },
  {
    visitors: "1",
    City: "Johanneshov",
    zipCode: "12139",
    lat: 59.29641530000001,
    lng: 18.0755919,
  },
  {
    visitors: "2",
    City: "Skärholmen",
    zipCode: "12744",
    lat: 59.27479390000001,
    lng: 17.8853903,
  },
  {
    visitors: "1",
    City: "Jönköping",
    zipCode: "55456",
    lat: 57.775381,
    lng: 14.2340393,
  },
  {
    visitors: "3",
    City: "Stockholm",
    zipCode: "11430",
    lat: 59.3418453,
    lng: 18.0673185,
  },
  {
    visitors: "2",
    City: "Skellefteå",
    zipCode: "93158",
    lat: 64.7406782,
    lng: 20.9901899,
  },
  {
    visitors: "1",
    City: "Timrå",
    zipCode: "86135",
    lat: 62.4877678,
    lng: 17.3120176,
  },
  {
    visitors: "1",
    City: "Johanneshov",
    zipCode: "12137",
    lat: 59.2952652,
    lng: 18.093823,
  },
  {
    visitors: "1",
    City: "Trångsund",
    zipCode: "14262",
    lat: 59.229409,
    lng: 18.1354345,
  },
  {
    visitors: "1",
    City: "Bandhagen",
    zipCode: "12466",
    lat: 59.260167,
    lng: 18.0247036,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11638",
    lat: 59.31045859999999,
    lng: 18.0890415,
  },
  {
    visitors: "2",
    City: "Hägersten",
    zipCode: "12628",
    lat: 59.29790389999999,
    lng: 17.9999777,
  },
  {
    visitors: "1",
    City: "Järfälla",
    zipCode: "17744",
    lat: 59.4134902,
    lng: 17.866431,
  },
  {
    visitors: "1",
    City: "SAINT-AMOUR-BELLEVUE",
    zipCode: "71570",
    lat: 46.245875,
    lng: 4.743208000000001,
  },
  {
    visitors: "1",
    City: "SAINT-AMOUR-BELLEVUE",
    zipCode: "71570",
    lat: 46.245875,
    lng: 4.743208000000001,
  },
  {
    visitors: "1",
    City: "Skarpnäck",
    zipCode: "12834",
    lat: 59.2656619,
    lng: 18.1315988,
  },
  {
    visitors: "2",
    City: "Skärholmen",
    zipCode: "12761",
    lat: 59.289976,
    lng: 17.9433876,
  },
  {
    visitors: "1",
    City: "Johanneshov",
    zipCode: "12139",
    lat: 59.29641530000001,
    lng: 18.0755919,
  },
  {
    visitors: "2",
    City: "Roma",
    zipCode: "00166",
    lat: 41.90586039999999,
    lng: 12.3234816,
  },
  {
    visitors: "1",
    City: "Saltsjö-Boo",
    zipCode: "13237",
    lat: 59.35019320000001,
    lng: 18.2962117,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11128",
    lat: 59.3256304,
    lng: 18.0636338,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11218",
    lat: 59.3340817,
    lng: 18.0169221,
  },
  {
    visitors: "2",
    City: "Älvsjö",
    zipCode: "12572",
    lat: 59.289475,
    lng: 18.0303628,
  },
  {
    visitors: "1",
    City: "Västerås",
    zipCode: "72223",
    lat: 59.6340206,
    lng: 16.5307362,
  },
  {
    visitors: "1",
    City: "Sundbyberg",
    zipCode: "17230",
    lat: 59.3629989,
    lng: 17.977732,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11160",
    lat: 59.3379267,
    lng: 18.0569988,
  },
  {
    visitors: "1",
    City: "Bromma",
    zipCode: "16832",
    lat: 59.3319856,
    lng: 17.9512673,
  },
  {
    visitors: "1",
    City: "Bandhagen",
    zipCode: "12474",
    lat: 59.2585605,
    lng: 18.0177365,
  },
  {
    visitors: "1",
    City: "Tyresö",
    zipCode: "13539",
    lat: 59.2499896,
    lng: 18.2221928,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11732",
    lat: 59.3198462,
    lng: 18.040352,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11763",
    lat: 59.30860560000001,
    lng: 18.0214021,
  },
  {
    visitors: "1",
    City: "Enskede Gård",
    zipCode: "12047",
    lat: 59.2901978,
    lng: 18.0668989,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11760",
    lat: 59.3085567,
    lng: 18.0290851,
  },
  {
    visitors: "1",
    City: "Älvsjö",
    zipCode: "12574",
    lat: 59.2847914,
    lng: 18.0423137,
  },
  {
    visitors: "1",
    City: "Bandhagen",
    zipCode: "12462",
    lat: 59.25557240000001,
    lng: 18.0386337,
  },
  {
    visitors: "2",
    City: "Årsta",
    zipCode: "12053",
    lat: 59.300929,
    lng: 18.0542907,
  },
  {
    visitors: "1",
    City: "Nacka",
    zipCode: "13132",
    lat: 59.3100621,
    lng: 18.1151422,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11864",
    lat: 59.3131127,
    lng: 18.0601238,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11131",
    lat: 59.3251799,
    lng: 18.0732777,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11540",
    lat: 59.3465891,
    lng: 18.1069357,
  },
  {
    visitors: "2",
    City: "Falun",
    zipCode: "79150",
    lat: 60.596821,
    lng: 15.6452361,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11229",
    lat: 59.3324448,
    lng: 18.0406945,
  },
  {
    visitors: "2",
    City: "Rimbo",
    zipCode: "76292",
    lat: 59.7436995,
    lng: 18.3601496,
  },
  {
    visitors: "2",
    City: "Hägersten",
    zipCode: "12930",
    lat: 59.2931754,
    lng: 17.9862008,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11623",
    lat: 59.3125021,
    lng: 18.0760798,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11449",
    lat: 59.3398021,
    lng: 18.0802922,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11630",
    lat: 59.3156702,
    lng: 18.0942083,
  },
  {
    visitors: "1",
    City: "Hägersten",
    zipCode: "12949",
    lat: 59.291059,
    lng: 17.9775714,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11260",
    lat: 59.3257293,
    lng: 18.0160867,
  },
  {
    visitors: "2",
    City: "Hägersten",
    zipCode: "12934",
    lat: 59.30122799999999,
    lng: 17.9818769,
  },
  {
    visitors: "1",
    City: "Sundbyberg",
    zipCode: "17448",
    lat: 59.3792497,
    lng: 17.9353557,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11264",
    lat: 59.32267,
    lng: 17.9958121,
  },
  {
    visitors: "1",
    City: "Sollentuna",
    zipCode: "19278",
    lat: 59.48001410000001,
    lng: 17.9201993,
  },
  {
    visitors: "4",
    City: "Årsta",
    zipCode: "12055",
    lat: 59.2963017,
    lng: 18.0476402,
  },
  {
    visitors: "2",
    City: "Västerås",
    zipCode: "72462",
    lat: 59.6100146,
    lng: 16.515683,
  },
  {
    visitors: "1",
    City: "Lund",
    zipCode: "224 82",
    lat: 55.7210592,
    lng: 13.2313933,
  },
  {
    visitors: "1",
    City: "Malmö",
    zipCode: "21433",
    lat: 55.5957521,
    lng: 13.022847,
  },
  {
    visitors: "1",
    City: "Hägersten",
    zipCode: "12631",
    lat: 59.2971622,
    lng: 18.0077821,
  },
  {
    visitors: "2",
    City: "Sundbyberg",
    zipCode: "17271",
    lat: 59.3658363,
    lng: 17.968245,
  },
  {
    visitors: "1",
    City: "Nacka",
    zipCode: "131 74",
    lat: 59.31801069999999,
    lng: 18.133785,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11850",
    lat: 59.3164285,
    lng: 18.0590482,
  },
  {
    visitors: "1",
    City: "Hägersten",
    zipCode: "12635",
    lat: 59.30018450000001,
    lng: 18.0074495,
  },
  {
    visitors: "1",
    City: "Lidingö",
    zipCode: "18130",
    lat: 59.387848,
    lng: 18.1177185,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11859",
    lat: 59.3063213,
    lng: 18.0685889,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11830",
    lat: 59.3119331,
    lng: 18.0742087,
  },
  {
    visitors: "1",
    City: "Segeltorp",
    zipCode: "14171",
    lat: 59.27974179999999,
    lng: 17.9473909,
  },
  {
    visitors: "1",
    City: "Västerås",
    zipCode: "72224",
    lat: 59.62470949999999,
    lng: 16.5510863,
  },
  {
    visitors: "1",
    City: "Uppsala",
    zipCode: "75341",
    lat: 59.85267689999999,
    lng: 17.655657,
  },
  {
    visitors: "1",
    City: "Stockhol",
    zipCode: "16578",
    lat: 59.3963048,
    lng: 17.8173314,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11638",
    lat: 59.31045859999999,
    lng: 18.0890415,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11641",
    lat: 59.3114746,
    lng: 18.0975226,
  },
  {
    visitors: "1",
    City: "Solna",
    zipCode: "16956",
    lat: 59.3713983,
    lng: 17.9984398,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "12147",
    lat: 59.295174,
    lng: 18.0866781,
  },
  {
    visitors: "1",
    City: "Åkersberga",
    zipCode: "18433",
    lat: 59.514937,
    lng: 18.2909373,
  },
  {
    visitors: "1",
    City: "Älvsjö",
    zipCode: "12573",
    lat: 59.2884782,
    lng: 18.0373359,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11140",
    lat: 59.3394881,
    lng: 18.0629894,
  },
  {
    visitors: "1",
    City: "NOGENT-SUR-MARNE",
    zipCode: "94130",
    lat: 48.8357664,
    lng: 2.480569,
  },
  {
    visitors: "1",
    City: "Älvsjö",
    zipCode: "12552",
    lat: 59.26754680000001,
    lng: 17.9756062,
  },
  {
    visitors: "2",
    City: "Hägersten",
    zipCode: "12649",
    lat: 59.3054783,
    lng: 18.0094417,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11739",
    lat: 59.3140818,
    lng: 18.0325367,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11449",
    lat: 59.3398021,
    lng: 18.0802922,
  },
  {
    visitors: "2",
    City: "Järfälla",
    zipCode: "17670",
    lat: 59.4494491,
    lng: 17.8203039,
  },
  {
    visitors: "1",
    City: "Skärholmen",
    zipCode: "12734",
    lat: 59.2936588,
    lng: 17.9384014,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11257",
    lat: 59.3286472,
    lng: 18.0034538,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "12652",
    lat: 59.30729299999999,
    lng: 17.9915032,
  },
  {
    visitors: "6",
    City: "Stockholm",
    zipCode: "11823",
    lat: 59.31906499999999,
    lng: 18.0523194,
  },
  {
    visitors: "2",
    City: "Nacka",
    zipCode: "13135",
    lat: 59.3082204,
    lng: 18.1227845,
  },
  {
    visitors: "3",
    City: "Stockholm",
    zipCode: "11731",
    lat: 59.3186634,
    lng: 18.0356141,
  },
  {
    visitors: "2",
    City: "127 45 Skärholmen",
    zipCode: "12745",
    lat: 59.2775155,
    lng: 17.8976473,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11827",
    lat: 59.311614,
    lng: 18.0705928,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11733",
    lat: 59.32239069999999,
    lng: 18.0290508,
  },
  {
    visitors: "1",
    City: "Göteborg",
    zipCode: "41543",
    lat: 57.76071229999999,
    lng: 12.0347886,
  },
  {
    visitors: "2",
    City: "Goole",
    zipCode: "DN14 7BA",
    lat: 53.7495791,
    lng: -0.8507572,
  },
  {
    visitors: "2",
    City: "Årsta",
    zipCode: "12060",
    lat: 59.3019122,
    lng: 18.0370145,
  },
  {
    visitors: "2",
    City: "Sundbyberg",
    zipCode: "17460",
    lat: 59.3795925,
    lng: 17.9381834,
  },
  {
    visitors: "1",
    City: "Porvoo",
    zipCode: "06100",
    lat: 60.3925864,
    lng: 25.6669091,
  },
  {
    visitors: "2",
    City: "Djursholm",
    zipCode: "18261",
    lat: 59.3921818,
    lng: 18.0770603,
  },
  {
    visitors: "1",
    City: "Bromma",
    zipCode: "16874",
    lat: 59.34491749999999,
    lng: 17.9492451,
  },
  {
    visitors: "1",
    City: "Årsta",
    zipCode: "12039",
    lat: 59.2999432,
    lng: 18.0715683,
  },
  {
    visitors: "1",
    City: "Värmdö",
    zipCode: "13941",
    lat: 59.294581,
    lng: 18.5543088,
  },
  {
    visitors: "1",
    City: "Bromma",
    zipCode: "16840",
    lat: 59.33080589999999,
    lng: 17.9150541,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11767",
    lat: 59.3179883,
    lng: 18.0077781,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11761",
    lat: 59.31138329999999,
    lng: 18.0253904,
  },
  {
    visitors: "2",
    City: "Årsta",
    zipCode: "12057",
    lat: 59.2965475,
    lng: 18.0433218,
  },
  {
    visitors: "1",
    City: "Huddinge",
    zipCode: "14140",
    lat: 59.25098029999999,
    lng: 18.0031429,
  },
  {
    visitors: "1",
    City: "Västerås",
    zipCode: "72228",
    lat: 59.6313717,
    lng: 16.5683399,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11760",
    lat: 59.3085567,
    lng: 18.0290851,
  },
  {
    visitors: "1",
    City: "Eskilstuna",
    zipCode: "63505",
    lat: 59.42843989999999,
    lng: 16.720063,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11217",
    lat: 59.3387484,
    lng: 18.0160865,
  },
  {
    visitors: "1",
    City: "Bandhagen",
    zipCode: "12453",
    lat: 59.2628726,
    lng: 18.0369817,
  },
  {
    visitors: "1",
    City: "Hägersten",
    zipCode: "12647",
    lat: 59.30310060000001,
    lng: 17.9948274,
  },
  {
    visitors: "1",
    City: "Uppsala",
    zipCode: "75418",
    lat: 59.8708882,
    lng: 17.6378507,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11137",
    lat: 59.33591550000001,
    lng: 18.0606554,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11224",
    lat: 59.3293926,
    lng: 18.0503362,
  },
  {
    visitors: "2",
    City: "Huddinge",
    zipCode: "14152",
    lat: 59.22214109999999,
    lng: 17.9395548,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "12844",
    lat: 59.27263550000001,
    lng: 18.142589,
  },
  {
    visitors: "2",
    City: "Djursholm",
    zipCode: "18261",
    lat: 59.3921818,
    lng: 18.0770603,
  },
  {
    visitors: "1",
    City: "Vaxholm",
    zipCode: "18594",
    lat: 59.4348501,
    lng: 18.3290636,
  },
  {
    visitors: "4",
    City: "Nacka Strand",
    zipCode: "13152",
    lat: 59.31764539999999,
    lng: 18.1607449,
  },
  {
    visitors: "1",
    City: "Farsta",
    zipCode: "12355",
    lat: 59.25351689999999,
    lng: 18.0868188,
  },
  {
    visitors: "3",
    City: "Bromma",
    zipCode: "16843",
    lat: 59.34381739999999,
    lng: 17.8809513,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "117 32",
    lat: 59.3198462,
    lng: 18.040352,
  },
  {
    visitors: "2",
    City: "Täby",
    zipCode: "18362",
    lat: 59.4492129,
    lng: 18.1312621,
  },
  {
    visitors: "2",
    City: "Farsta",
    zipCode: "12371",
    lat: 59.2348351,
    lng: 18.100292,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11240",
    lat: 59.33166970000001,
    lng: 18.0278909,
  },
  {
    visitors: "1",
    City: "Lidingö",
    zipCode: "18166",
    lat: 59.3626675,
    lng: 18.2202833,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11761",
    lat: 59.31138329999999,
    lng: 18.0253904,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11761",
    lat: 59.31138329999999,
    lng: 18.0253904,
  },
  {
    visitors: "1",
    City: "Flen",
    zipCode: "64237",
    lat: 59.0534733,
    lng: 16.5680417,
  },
  {
    visitors: "1",
    City: "Hägersten",
    zipCode: "12677",
    lat: 59.2829776,
    lng: 18.0107715,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11860",
    lat: 59.3039771,
    lng: 18.0745671,
  },
  {
    visitors: "1",
    City: "Solna",
    zipCode: "17072",
    lat: 59.381623,
    lng: 18.0337335,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "111 29",
    lat: 59.3244212,
    lng: 18.070782,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "12939",
    lat: 59.3079797,
    lng: 17.957958,
  },
  {
    visitors: "1",
    City: "Trosa",
    zipCode: "61933",
    lat: 58.90823259999999,
    lng: 17.5630035,
  },
  {
    visitors: "1",
    City: "Trosa",
    zipCode: "61933",
    lat: 58.90823259999999,
    lng: 17.5630035,
  },
  {
    visitors: "4",
    City: "Stockholm",
    zipCode: "11432",
    lat: 59.3398337,
    lng: 18.0709753,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11853",
    lat: 59.3151166,
    lng: 18.0513174,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11760",
    lat: 59.3085567,
    lng: 18.0290851,
  },
  {
    visitors: "2",
    City: "Huddinge",
    zipCode: "14151",
    lat: 59.2102383,
    lng: 17.9448751,
  },
  {
    visitors: "1",
    City: "Osøyro",
    zipCode: "5210",
    lat: 60.18471659999999,
    lng: 5.469273400000001,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11358",
    lat: 59.3404938,
    lng: 18.0611609,
  },
  {
    visitors: "1",
    City: "Johanneshov",
    zipCode: "12152",
    lat: 59.2942813,
    lng: 18.1214057,
  },
  {
    visitors: "2",
    City: "Nacka",
    zipCode: "13146",
    lat: 59.32235420000001,
    lng: 18.2106977,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11560",
    lat: 59.34379910000001,
    lng: 18.1118038,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11649",
    lat: 59.32932349999999,
    lng: 18.0685808,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11235",
    lat: 59.3270914,
    lng: 18.0273904,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11620",
    lat: 59.3173323,
    lng: 18.0774194,
  },
  {
    visitors: "1",
    City: "Hägersten",
    zipCode: "12626",
    lat: 59.2991333,
    lng: 17.9887311,
  },
  {
    visitors: "1",
    City: "Solna",
    zipCode: "17065",
    lat: 59.3780417,
    lng: 18.0014314,
  },
  {
    visitors: "1",
    City: "Hägersten",
    zipCode: "12649",
    lat: 59.3054783,
    lng: 18.0094417,
  },
  {
    visitors: "2",
    City: "Uttran",
    zipCode: "14760",
    lat: 59.2020857,
    lng: 17.8033443,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "16836",
    lat: 59.33666199999999,
    lng: 17.9392928,
  },
  {
    visitors: "2",
    City: "Solna",
    zipCode: "17169",
    lat: 59.3527142,
    lng: 18.0064531,
  },
  {
    visitors: "4",
    City: "Stockholm",
    zipCode: "17457",
    lat: 59.37589469999999,
    lng: 17.938027,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11750",
    lat: 59.3189319,
    lng: 18.0194102,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "12070",
    lat: 59.30728790000001,
    lng: 18.1111431,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11827",
    lat: 59.311614,
    lng: 18.0705928,
  },
  {
    visitors: "1",
    City: "Hägersten",
    zipCode: "12652",
    lat: 59.30729299999999,
    lng: 17.9915032,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11320",
    lat: 59.3394968,
    lng: 18.0382051,
  },
  {
    visitors: "2",
    City: "Hägersten",
    zipCode: "12635",
    lat: 59.30018450000001,
    lng: 18.0074495,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11864",
    lat: 59.3131127,
    lng: 18.0601238,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11864",
    lat: 59.3131127,
    lng: 18.0601238,
  },
  {
    visitors: "1",
    City: "Huddinge",
    zipCode: "14136",
    lat: 59.23633,
    lng: 17.9821561,
  },
  {
    visitors: "1",
    City: "Enköping",
    zipCode: "74944",
    lat: 59.62815939999999,
    lng: 17.1266271,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11632",
    lat: 59.314452,
    lng: 18.0936233,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11629",
    lat: 59.31471440000001,
    lng: 18.0833575,
  },
  {
    visitors: "1",
    City: "Västerås",
    zipCode: "72220",
    lat: 59.62236149999999,
    lng: 16.540592,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11247",
    lat: 59.3357892,
    lng: 18.0303035,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11728",
    lat: 59.3176261,
    lng: 18.044173,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11321",
    lat: 59.33727640000001,
    lng: 18.0420287,
  },
  {
    visitors: "1",
    City: "Huddinge",
    zipCode: "14147",
    lat: 59.2355829,
    lng: 17.983426,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11157",
    lat: 59.3335278,
    lng: 18.0656418,
  },
  {
    visitors: "1",
    City: "Bromma",
    zipCode: "16743",
    lat: 59.33600080000001,
    lng: 17.9831696,
  },
  {
    visitors: "2",
    City: "Skärholmen",
    zipCode: "12760",
    lat: 59.29096699999999,
    lng: 17.9364167,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11667",
    lat: 59.30675269999999,
    lng: 18.0785613,
  },
  {
    visitors: "2",
    City: "Södertälje",
    zipCode: "15173",
    lat: 59.1951408,
    lng: 17.6229401,
  },
  {
    visitors: "1",
    City: "Spånga",
    zipCode: "16362",
    lat: 59.3972707,
    lng: 17.9158275,
  },
  {
    visitors: "1",
    City: "Hägersten",
    zipCode: "12952",
    lat: 59.2870006,
    lng: 17.9643003,
  },
  {
    visitors: "2",
    City: "Skärholmen",
    zipCode: "12749",
    lat: 59.2693781,
    lng: 17.8999972,
  },
  {
    visitors: "1",
    City: "Hässelby",
    zipCode: "16559",
    lat: 59.3716345,
    lng: 17.8287954,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11428",
    lat: 59.35117519999999,
    lng: 18.0723261,
  },
  {
    visitors: "1",
    City: "Venezia",
    zipCode: "30121",
    lat: 45.45001509999999,
    lng: 12.330613,
  },
  {
    visitors: "2",
    City: "Sollentuna",
    zipCode: "19257",
    lat: 59.4329141,
    lng: 17.9950528,
  },
  {
    visitors: "1",
    City: "Enskede Gård",
    zipCode: "12048",
    lat: 59.287521,
    lng: 18.0751986,
  },
  {
    visitors: "1",
    City: "Hägersten",
    zipCode: "12634",
    lat: 59.3022899,
    lng: 18.0109368,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11829",
    lat: 59.3121569,
    lng: 18.0681005,
  },
  {
    visitors: "1",
    City: "Farsta",
    zipCode: "12359",
    lat: 59.2556382,
    lng: 18.0900656,
  },
  {
    visitors: "2",
    City: "Enskede",
    zipCode: "12245",
    lat: 59.26100329999999,
    lng: 18.0837729,
  },
  {
    visitors: "1",
    City: "Älvsjö",
    zipCode: "12530",
    lat: 59.2744881,
    lng: 18.0051298,
  },
  {
    visitors: "1",
    City: "Hägersten",
    zipCode: "12653",
    lat: 59.30652240000001,
    lng: 17.9838643,
  },
  {
    visitors: "2",
    City: "Vagnhärad",
    zipCode: "61994",
    lat: 58.9636027,
    lng: 17.5269353,
  },
  {
    visitors: "1",
    City: "LYON",
    zipCode: "69007",
    lat: 45.7304251,
    lng: 4.8399378,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11636",
    lat: 59.3141967,
    lng: 18.0902143,
  },
  {
    visitors: "2",
    City: "Johanneshov",
    zipCode: "12139",
    lat: 59.29641530000001,
    lng: 18.0755919,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11632",
    lat: 59.314452,
    lng: 18.0936233,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11733",
    lat: 59.32239069999999,
    lng: 18.0290508,
  },
  {
    visitors: "3",
    City: "Lidingö",
    zipCode: "18158",
    lat: 59.3511532,
    lng: 18.1529059,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11625",
    lat: 59.3159875,
    lng: 18.0764192,
  },
  {
    visitors: "1",
    City: "Vallentuna",
    zipCode: "18696",
    lat: 59.6535297,
    lng: 18.3648033,
  },
  {
    visitors: "1",
    City: "STEINSEL",
    zipCode: "7302",
    lat: 49.6769797,
    lng: 6.1248767,
  },
  {
    visitors: "3",
    City: "Bettna",
    zipCode: "64297",
    lat: 58.9076422,
    lng: 16.6355386,
  },
  {
    visitors: "1",
    City: "Västerås",
    zipCode: "722 15",
    lat: 59.6144115,
    lng: 16.5389921,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11439",
    lat: 59.3356837,
    lng: 18.0778717,
  },
  {
    visitors: "1",
    City: "Hässelby",
    zipCode: "16564",
    lat: 59.3631458,
    lng: 17.8283494,
  },
  {
    visitors: "1",
    City: "Solna",
    zipCode: "16961",
    lat: 59.3597305,
    lng: 18.004604,
  },
  {
    visitors: "1",
    City: "Uppsala",
    zipCode: "75435",
    lat: 59.86411609999999,
    lng: 17.6706627,
  },
  {
    visitors: "2",
    City: "Hägersten",
    zipCode: "12948",
    lat: 59.29448960000001,
    lng: 17.971758,
  },
  {
    visitors: "2",
    City: "Enskededalen",
    zipCode: "12130",
    lat: 59.2875429,
    lng: 18.0957961,
  },
  {
    visitors: "1",
    City: "Köln",
    zipCode: "50933",
    lat: 50.94010859999999,
    lng: 6.8851369,
  },
  {
    visitors: "1",
    City: "Sundbyberg",
    zipCode: "17457",
    lat: 59.37589469999999,
    lng: 17.938027,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11639",
    lat: 59.3102435,
    lng: 18.084054,
  },
  {
    visitors: "2",
    City: "Berlin",
    zipCode: "10785",
    lat: 52.50691150000001,
    lng: 13.3650508,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11820",
    lat: 59.3203432,
    lng: 18.0667845,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11740",
    lat: 59.31734159999999,
    lng: 18.0227335,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "19141",
    lat: 59.412824,
    lng: 17.9530842,
  },
  {
    visitors: "2",
    City: "Sundbyberg",
    zipCode: "17451",
    lat: 59.3770764,
    lng: 17.97946,
  },
  {
    visitors: "1",
    City: "Älvsjö",
    zipCode: "12573",
    lat: 59.2884782,
    lng: 18.0373359,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11130",
    lat: 59.32312529999999,
    lng: 18.0759345,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11320",
    lat: 59.3394968,
    lng: 18.0382051,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11320",
    lat: 59.3394968,
    lng: 18.0382051,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11255",
    lat: 59.3304997,
    lng: 18.0061127,
  },
  {
    visitors: "1",
    City: "Vårby",
    zipCode: "14345",
    lat: 59.2656993,
    lng: 17.8761471,
  },
  {
    visitors: "1",
    City: "Lidingö",
    zipCode: "18155",
    lat: 59.34440350000001,
    lng: 18.1608684,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11863",
    lat: 59.313106,
    lng: 18.0549714,
  },
  {
    visitors: "1",
    City: "Uppsala",
    zipCode: "75341",
    lat: 59.85267689999999,
    lng: 17.655657,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11247",
    lat: 59.3357892,
    lng: 18.0303035,
  },
  {
    visitors: "2",
    City: "Årsta",
    zipCode: "12050",
    lat: 59.2948111,
    lng: 18.0632524,
  },
  {
    visitors: "1",
    City: "Johanneshov",
    zipCode: "12137",
    lat: 59.2952652,
    lng: 18.093823,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11533",
    lat: 59.34321780000001,
    lng: 18.0941111,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11847",
    lat: 59.3174468,
    lng: 18.0675278,
  },
  {
    visitors: "2",
    City: "Uppsala",
    zipCode: "75644",
    lat: 59.83346859999999,
    lng: 17.6306364,
  },
  {
    visitors: "1",
    City: "Torsåker",
    zipCode: "81395",
    lat: 60.4280331,
    lng: 16.4405051,
  },
  {
    visitors: "4",
    City: "Stockholm",
    zipCode: "11264",
    lat: 59.32267,
    lng: 17.9958121,
  },
  {
    visitors: "1",
    City: "Skärholmen",
    zipCode: "12732",
    lat: 59.30003349999999,
    lng: 17.9353987,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11261",
    lat: 59.32516560000001,
    lng: 18.0056989,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11445",
    lat: 59.3373704,
    lng: 18.0842802,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11217",
    lat: 59.3387484,
    lng: 18.0160865,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11438",
    lat: 59.34064129999999,
    lng: 18.0796284,
  },
  {
    visitors: "4",
    City: "Stockholm",
    zipCode: "11846",
    lat: 59.31849860000001,
    lng: 18.0692752,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11824",
    lat: 59.31996059999999,
    lng: 18.06296,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11635",
    lat: 59.3128091,
    lng: 18.0882159,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11622",
    lat: 59.3183437,
    lng: 18.0807468,
  },
  {
    visitors: "1",
    City: "Södertälje",
    zipCode: "15231",
    lat: 59.19652869999999,
    lng: 17.6475071,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11823",
    lat: 59.31906499999999,
    lng: 18.0523194,
  },
  {
    visitors: "2",
    City: "Täby",
    zipCode: "187 34",
    lat: 59.45603070000001,
    lng: 18.0471072,
  },
  {
    visitors: "4",
    City: "Sigtuna",
    zipCode: "19341",
    lat: 59.62932980000001,
    lng: 17.7587082,
  },
  {
    visitors: "2",
    City: "Johanneshov",
    zipCode: "12144",
    lat: 59.2949099,
    lng: 18.0755459,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11663",
    lat: 59.3092322,
    lng: 18.0807273,
  },
  {
    visitors: "3",
    City: "Stockholm",
    zipCode: "11771",
    lat: 59.3167961,
    lng: 17.9986892,
  },
  {
    visitors: "2",
    City: "Bromma",
    zipCode: "16762",
    lat: 59.32026920000001,
    lng: 17.9708925,
  },
  {
    visitors: "1",
    City: "Hägersten",
    zipCode: "12635",
    lat: 59.30018450000001,
    lng: 18.0074495,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11361",
    lat: 59.3393414,
    lng: 18.0471034,
  },
  {
    visitors: "3",
    City: "Stockholm",
    zipCode: "11738",
    lat: 59.31558519999999,
    lng: 18.0272198,
  },
  {
    visitors: "1",
    City: "Bandhagen",
    zipCode: "12473",
    lat: 59.2623328,
    lng: 18.0147518,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11324",
    lat: 59.3403071,
    lng: 18.0468548,
  },
  {
    visitors: "1",
    City: "Solna",
    zipCode: "32217",
    lat: 59.36887909999999,
    lng: 18.0084334,
  },
  {
    visitors: "1",
    City: "Vilnius",
    zipCode: "03201",
    lat: 54.6723356,
    lng: 25.2586084,
  },
  {
    visitors: "2",
    City: "Hägersten",
    zipCode: "12650",
    lat: 59.3079066,
    lng: 18.0054548,
  },
  {
    visitors: "1",
    City: "Hägersten",
    zipCode: "12650",
    lat: 59.3079066,
    lng: 18.0054548,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11852",
    lat: 59.315929,
    lng: 18.0625382,
  },
  {
    visitors: "1",
    City: "Cully",
    zipCode: "1096",
    lat: 46.48975249999999,
    lng: 6.7283917,
  },
  {
    visitors: "5",
    City: "Södertälje",
    zipCode: "15144",
    lat: 59.1866631,
    lng: 17.622548,
  },
  {
    visitors: "5",
    City: "Berlin",
    zipCode: "10439",
    lat: 52.5517268,
    lng: 13.4123013,
  },
  {
    visitors: "1",
    City: "Solna",
    zipCode: "17152",
    lat: 59.3502895,
    lng: 17.9873113,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11867",
    lat: 59.30865169999999,
    lng: 18.0523067,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11423",
    lat: 59.3499042,
    lng: 18.0630045,
  },
  {
    visitors: "1",
    City: "Huddinge",
    zipCode: "14152",
    lat: 59.22214109999999,
    lng: 17.9395548,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11352",
    lat: 59.34406019999999,
    lng: 18.0583375,
  },
  {
    visitors: "1",
    City: "Järfälla",
    zipCode: "17675",
    lat: 59.4641811,
    lng: 17.8002033,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11338",
    lat: 59.3419191,
    lng: 18.0290587,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11442",
    lat: 59.3361895,
    lng: 18.0795363,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11247",
    lat: 59.3357892,
    lng: 18.0303035,
  },
  {
    visitors: "1",
    City: "Skarpnäck",
    zipCode: "12834",
    lat: 59.2656619,
    lng: 18.1315988,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11218",
    lat: 59.3340817,
    lng: 18.0169221,
  },
  {
    visitors: "2",
    City: "Bandhagen",
    zipCode: "12432",
    lat: 59.272704,
    lng: 18.0436291,
  },
  {
    visitors: "2",
    City: "Torino",
    zipCode: "10138",
    lat: 45.0732947,
    lng: 7.657422899999999,
  },
  {
    visitors: "1",
    City: "Bagarmossen",
    zipCode: "12842",
    lat: 59.2741368,
    lng: 18.1269813,
  },
  {
    visitors: "1",
    City: "Katrineholm",
    zipCode: "64132",
    lat: 58.9912442,
    lng: 16.212133,
  },
  {
    visitors: "2",
    City: "Tullinge",
    zipCode: "14653",
    lat: 59.2153054,
    lng: 17.8935635,
  },
  {
    visitors: "1",
    City: "Solna",
    zipCode: "17161",
    lat: 59.3466214,
    lng: 18.0026143,
  },
  {
    visitors: "1",
    City: "Linköping",
    zipCode: "54680",
    lat: 58.41080700000001,
    lng: 15.6213727,
  },
  {
    visitors: "2",
    City: "Hässelby",
    zipCode: "16565",
    lat: 59.3660614,
    lng: 17.820854,
  },
  {
    visitors: "2",
    City: "Järfälla",
    zipCode: "17569",
    lat: 59.39832639999999,
    lng: 17.8715526,
  },
  {
    visitors: "2",
    City: "Hägersten",
    zipCode: "12626",
    lat: 59.2991333,
    lng: 17.9887311,
  },
  {
    visitors: "3",
    City: "Stockholm",
    zipCode: "11736",
    lat: 59.3174805,
    lng: 18.0308767,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11858",
    lat: 59.3092637,
    lng: 18.0714195,
  },
  {
    visitors: "2",
    City: "Hägersten",
    zipCode: "12642",
    lat: 59.2904947,
    lng: 17.9893568,
  },
  {
    visitors: "2",
    City: "Hudiksvall",
    zipCode: "82444",
    lat: 61.7273909,
    lng: 17.107401,
  },
  {
    visitors: "1",
    City: "Vilnius",
    zipCode: "10259",
    lat: 54.6871555,
    lng: 25.2796514,
  },
  {
    visitors: "2",
    City: "Solna",
    zipCode: "17167",
    lat: 59.3579368,
    lng: 17.9884679,
  },
  {
    visitors: "1",
    City: "Bandhagen",
    zipCode: "12471",
    lat: 59.26727500000001,
    lng: 18.0087805,
  },
  {
    visitors: "1",
    City: "Skultuna",
    zipCode: "72631",
    lat: 59.71648150000001,
    lng: 16.4302921,
  },
  {
    visitors: "1",
    City: "Hässelby",
    zipCode: "16566",
    lat: 59.36302060000001,
    lng: 17.834899,
  },
  {
    visitors: "1",
    City: "Enskededalen",
    zipCode: "12130",
    lat: 59.2875429,
    lng: 18.0957961,
  },
  {
    visitors: "1",
    City: "Åkersberga",
    zipCode: "18436",
    lat: 59.4847296,
    lng: 18.3064299,
  },
  {
    visitors: "2",
    City: "Lidingö",
    zipCode: "18155",
    lat: 59.34440350000001,
    lng: 18.1608684,
  },
  {
    visitors: "1",
    City: "Hägersten",
    zipCode: "12632",
    lat: 59.30246469999999,
    lng: 18.0149229,
  },
  {
    visitors: "2",
    City: "Solna",
    zipCode: "17070",
    lat: 59.3825338,
    lng: 18.0247407,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11325",
    lat: 59.34060359999999,
    lng: 18.0486848,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11360",
    lat: 59.3393085,
    lng: 18.05384,
  },
  {
    visitors: "2",
    City: "Danderyd",
    zipCode: "18256",
    lat: 59.41161139999999,
    lng: 18.0244081,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11227",
    lat: 59.33123699999999,
    lng: 18.0478438,
  },
  {
    visitors: "1",
    City: "Sundbyberg",
    zipCode: "17264",
    lat: 59.3602077,
    lng: 17.9685871,
  },
  {
    visitors: "2",
    City: "Vega",
    zipCode: "13657",
    lat: 59.18341539999999,
    lng: 18.1302066,
  },
  {
    visitors: "1",
    City: "Johanneshov",
    zipCode: "12155",
    lat: 59.29641530000001,
    lng: 18.0755919,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11217",
    lat: 59.3387484,
    lng: 18.0160865,
  },
  {
    visitors: "1",
    City: "Tullinge",
    zipCode: "14634",
    lat: 59.17593069999999,
    lng: 17.9145221,
  },
  {
    visitors: "1",
    City: "Johanneshov",
    zipCode: "12139",
    lat: 59.29641530000001,
    lng: 18.0755919,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11431",
    lat: 59.34151799999999,
    lng: 18.074805,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11260",
    lat: 59.3257293,
    lng: 18.0160867,
  },
  {
    visitors: "1",
    City: "Skellefteå",
    zipCode: "93130",
    lat: 64.7501937,
    lng: 20.9574155,
  },
  {
    visitors: "1",
    City: "Jönköping",
    zipCode: "55456",
    lat: 57.775381,
    lng: 14.2340393,
  },
  {
    visitors: "1",
    City: "Segeltorp",
    zipCode: "14174",
    lat: 59.2768695,
    lng: 17.9268494,
  },
  {
    visitors: "1",
    City: "Skarpnäck",
    zipCode: "12832",
    lat: 59.26675640000001,
    lng: 18.1369173,
  },
  {
    visitors: "2",
    City: "Årsta",
    zipCode: "12054",
    lat: 59.298572,
    lng: 18.0499684,
  },
  {
    visitors: "1",
    City: "Årsta",
    zipCode: "12052",
    lat: 59.29488430000001,
    lng: 18.054947,
  },
  {
    visitors: "2",
    City: "Sundbyberg",
    zipCode: "17264",
    lat: 59.3602077,
    lng: 17.9685871,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11826",
    lat: 59.3156423,
    lng: 18.068439,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11826",
    lat: 59.3156423,
    lng: 18.068439,
  },
  {
    visitors: "1",
    City: "Sundbyberg",
    zipCode: "17270",
    lat: 59.3624863,
    lng: 17.9734906,
  },
  {
    visitors: "1",
    City: "Bromma",
    zipCode: "16831",
    lat: 59.33626659999999,
    lng: 17.9499288,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11667",
    lat: 59.30675269999999,
    lng: 18.0785613,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11733",
    lat: 59.32239069999999,
    lng: 18.0290508,
  },
  {
    visitors: "4",
    City: "Nacka",
    zipCode: "13131",
    lat: 59.315617,
    lng: 18.1334547,
  },
  {
    visitors: "2",
    City: "Bromma",
    zipCode: "16852",
    lat: 59.3358692,
    lng: 17.8924463,
  },
  {
    visitors: "1",
    City: "Lakewood",
    zipCode: "08701-3681",
    lat: 40.0807404,
    lng: -74.2061931,
  },
  {
    visitors: "1",
    City: "Skogås",
    zipCode: "14232",
    lat: 59.2143982,
    lng: 18.1492983,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11347",
    lat: 59.3536674,
    lng: 18.0496961,
  },
  {
    visitors: "2",
    City: "Hägersten",
    zipCode: "12626",
    lat: 59.2991333,
    lng: 17.9887311,
  },
  {
    visitors: "1",
    City: "Hägersten",
    zipCode: "12650",
    lat: 59.3079066,
    lng: 18.0054548,
  },
  {
    visitors: "1",
    City: "Timrå",
    zipCode: "86135",
    lat: 62.4877678,
    lng: 17.3120176,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11620",
    lat: 59.3173323,
    lng: 18.0774194,
  },
  {
    visitors: "1",
    City: "Bromma",
    zipCode: "16732",
    lat: 59.33772389999999,
    lng: 17.9632213,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11345",
    lat: 59.34790940000001,
    lng: 18.0470283,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11853",
    lat: 59.3151166,
    lng: 18.0513174,
  },
  {
    visitors: "1",
    City: "Kista",
    zipCode: "164 76",
    lat: 59.41826829999999,
    lng: 17.909424,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11768",
    lat: 59.31461539999999,
    lng: 18.0001365,
  },
  {
    visitors: "1",
    City: "Bandhagen",
    zipCode: "12457",
    lat: 59.262389,
    lng: 18.0507523,
  },
  {
    visitors: "1",
    City: "MADRID",
    zipCode: "28035",
    lat: 40.4781971,
    lng: -3.7282769,
  },
  {
    visitors: "1",
    City: "Hägersten",
    zipCode: "12949",
    lat: 59.291059,
    lng: 17.9775714,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11637",
    lat: 59.31242160000001,
    lng: 18.0792381,
  },
  {
    visitors: "1",
    City: "Hägersten",
    zipCode: "12626",
    lat: 59.2991333,
    lng: 17.9887311,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11245",
    lat: 59.3343961,
    lng: 18.0257303,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11734",
    lat: 59.3163132,
    lng: 18.0364435,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11642",
    lat: 59.3092808,
    lng: 18.0868777,
  },
  {
    visitors: "1",
    City: "Partille",
    zipCode: "43348",
    lat: 57.72500419999999,
    lng: 12.1361482,
  },
  {
    visitors: "1",
    City: "Lidingö",
    zipCode: "18137",
    lat: 59.3507232,
    lng: 18.1325877,
  },
  {
    visitors: "3",
    City: "Stockholm",
    zipCode: "11263",
    lat: 59.3241564,
    lng: 18.0049513,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11263",
    lat: 59.3241564,
    lng: 18.0049513,
  },
  {
    visitors: "1",
    City: "Norrköping",
    zipCode: "60219",
    lat: 58.5908306,
    lng: 16.1625798,
  },
  {
    visitors: "2",
    City: "Oslo",
    zipCode: "0569",
    lat: 59.92974809999999,
    lng: 10.7739507,
  },
  {
    visitors: "2",
    City: "Nacka",
    zipCode: "13145",
    lat: 59.3135377,
    lng: 18.1969962,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "16274",
    lat: 59.35374100000001,
    lng: 17.8843971,
  },
  {
    visitors: "1",
    City: "Kungsängen",
    zipCode: "19636",
    lat: 59.49486659999999,
    lng: 17.7299275,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11646",
    lat: 59.3185448,
    lng: 18.0728497,
  },
  {
    visitors: "2",
    City: "Järfälla",
    zipCode: "17543",
    lat: 59.4077477,
    lng: 17.8205825,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11529",
    lat: 59.3422116,
    lng: 18.0959392,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11730",
    lat: 59.3179512,
    lng: 18.0366939,
  },
  {
    visitors: "1",
    City: "Västerås",
    zipCode: "72337",
    lat: 59.62379769999999,
    lng: 16.5809733,
  },
  {
    visitors: "1",
    City: "Hägersten",
    zipCode: "12652",
    lat: 59.30729299999999,
    lng: 17.9915032,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11359",
    lat: 59.3396915,
    lng: 18.0576664,
  },
  {
    visitors: "1",
    City: "Nacka",
    zipCode: "13138",
    lat: 59.30571500000001,
    lng: 18.145389,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11824",
    lat: 59.31996059999999,
    lng: 18.06296,
  },
  {
    visitors: "1",
    City: "Hägersten",
    zipCode: "12635",
    lat: 59.30018450000001,
    lng: 18.0074495,
  },
  {
    visitors: "1",
    City: "Västerås",
    zipCode: "72245",
    lat: 59.6447759,
    lng: 16.5507737,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11417",
    lat: 59.37049219999999,
    lng: 18.0673629,
  },
  {
    visitors: "1",
    City: "Lotorp",
    zipCode: "61276",
    lat: 58.7361885,
    lng: 15.8276897,
  },
  {
    visitors: "1",
    City: "Johanneshov",
    zipCode: "12153",
    lat: 59.2900858,
    lng: 18.1247144,
  },
  {
    visitors: "1",
    City: "Enskededalen",
    zipCode: "12133",
    lat: 59.28385359999999,
    lng: 18.1007693,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11727",
    lat: 59.3188049,
    lng: 18.046335,
  },
  {
    visitors: "1",
    City: "Malmö",
    zipCode: "21443",
    lat: 55.5906107,
    lng: 13.0181838,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11631",
    lat: 59.3114214,
    lng: 18.0862177,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11335",
    lat: 59.3429678,
    lng: 18.0282274,
  },
  {
    visitors: "1",
    City: "Huddinge",
    zipCode: "14156",
    lat: 59.2212395,
    lng: 17.9484968,
  },
  {
    visitors: "1",
    City: "Göteborg",
    zipCode: "41252",
    lat: 57.7004068,
    lng: 11.9846421,
  },
  {
    visitors: "1",
    City: "Miami",
    zipCode: "33133-5703",
    lat: 25.7307897,
    lng: -80.2377078,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "12079",
    lat: 59.3031194,
    lng: 18.0974169,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11856",
    lat: 59.31006250000001,
    lng: 18.0723352,
  },
  {
    visitors: "2",
    City: "Tyresö",
    zipCode: "13544",
    lat: 59.236394,
    lng: 18.2287399,
  },
  {
    visitors: "1",
    City: "Hägersten",
    zipCode: "12935",
    lat: 59.3014712,
    lng: 17.9775598,
  },
  {
    visitors: "1",
    City: "Skärholmen",
    zipCode: "12743",
    lat: 59.2733875,
    lng: 17.8926893,
  },
  {
    visitors: "1",
    City: "Farsta",
    zipCode: "12359",
    lat: 59.2556382,
    lng: 18.0900656,
  },
  {
    visitors: "2",
    City: "Solna",
    zipCode: "17150",
    lat: 59.3482803,
    lng: 17.9909718,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11329",
    lat: 59.3409434,
    lng: 18.051513,
  },
  {
    visitors: "2",
    City: "Dún Laoghaire",
    zipCode: "A96 H771",
    lat: 53.29127829999999,
    lng: -6.1407487,
  },
  {
    visitors: "1",
    City: "Norrköping",
    zipCode: "60226",
    lat: 58.5907885,
    lng: 16.1927828,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11730",
    lat: 59.3179512,
    lng: 18.0366939,
  },
  {
    visitors: "1",
    City: "Hägersten",
    zipCode: "12653",
    lat: 59.30652240000001,
    lng: 17.9838643,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11847",
    lat: 59.3174468,
    lng: 18.0675278,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11335",
    lat: 59.3429678,
    lng: 18.0282274,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11726",
    lat: 59.3169608,
    lng: 18.0488263,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11353",
    lat: 59.3454055,
    lng: 18.0593376,
  },
  {
    visitors: "1",
    City: "Hägersten",
    zipCode: "12647",
    lat: 59.30310060000001,
    lng: 17.9948274,
  },
  {
    visitors: "1",
    City: "Västra Frölunda",
    zipCode: "42170",
    lat: 57.66179239999999,
    lng: 11.9259746,
  },
  {
    visitors: "1",
    City: "Johanneshov",
    zipCode: "12140",
    lat: 59.2981908,
    lng: 18.0812019,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11445",
    lat: 59.3373704,
    lng: 18.0842802,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11737",
    lat: 59.31781279999999,
    lng: 18.0285502,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11248",
    lat: 59.338765,
    lng: 18.0263967,
  },
  {
    visitors: "1",
    City: "Bromma",
    zipCode: "16866",
    lat: 59.3585356,
    lng: 17.9528732,
  },
  {
    visitors: "1",
    City: "Enskededalen",
    zipCode: "12132",
    lat: 59.288124,
    lng: 18.0891528,
  },
  {
    visitors: "2",
    City: "Farsta",
    zipCode: "12352",
    lat: 59.21115570000001,
    lng: 18.0839793,
  },
  {
    visitors: "1",
    City: "Bromma",
    zipCode: "16831",
    lat: 59.33626659999999,
    lng: 17.9499288,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11864",
    lat: 59.3131127,
    lng: 18.0601238,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11636",
    lat: 59.3141967,
    lng: 18.0902143,
  },
  {
    visitors: "1",
    City: "Solna",
    zipCode: "17161",
    lat: 59.3466214,
    lng: 18.0026143,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11726",
    lat: 59.3169608,
    lng: 18.0488263,
  },
  {
    visitors: "1",
    City: "Bromma",
    zipCode: "16744",
    lat: 59.33531759999999,
    lng: 17.977519,
  },
  {
    visitors: "1",
    City: "Norrköping",
    zipCode: "60350",
    lat: 58.5826465,
    lng: 16.1975448,
  },
  {
    visitors: "1",
    City: "Lock Haven",
    zipCode: "17745-1860",
    lat: 41.1370133,
    lng: -77.4469263,
  },
  {
    visitors: "1",
    City: "Bromma",
    zipCode: "16767",
    lat: 59.32558520000001,
    lng: 17.9439714,
  },
  {
    visitors: "1",
    City: "Hägersten",
    zipCode: "12945",
    lat: 59.2954052,
    lng: 17.967939,
  },
  {
    visitors: "1",
    City: "Kista",
    zipCode: "16436",
    lat: 59.4064726,
    lng: 17.9322808,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11631",
    lat: 59.3114214,
    lng: 18.0862177,
  },
  {
    visitors: "1",
    City: "Bromma",
    zipCode: "16737",
    lat: 59.3390242,
    lng: 17.9828338,
  },
  {
    visitors: "1",
    City: "Västerås",
    zipCode: "72214",
    lat: 59.61289839999999,
    lng: 16.5506654,
  },
  {
    visitors: "1",
    City: "Borlänge",
    zipCode: "78435",
    lat: 60.4829099,
    lng: 15.4395242,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11417",
    lat: 59.37049219999999,
    lng: 18.0673629,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11258",
    lat: 59.3271447,
    lng: 18.0087731,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11359",
    lat: 59.3396915,
    lng: 18.0576664,
  },
  {
    visitors: "1",
    City: "Johanneshov",
    zipCode: "12154",
    lat: 59.29641530000001,
    lng: 18.0755919,
  },
  {
    visitors: "1",
    City: "Enskede",
    zipCode: "12266",
    lat: 59.2690316,
    lng: 18.0588936,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11137",
    lat: 59.33591550000001,
    lng: 18.0606554,
  },
  {
    visitors: "1",
    City: "Årsta",
    zipCode: "12051",
    lat: 59.3016082,
    lng: 18.0599405,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11847",
    lat: 59.3174468,
    lng: 18.0675278,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11529",
    lat: 59.3422116,
    lng: 18.0959392,
  },
  {
    visitors: "2",
    City: "Sundbyberg",
    zipCode: "17461",
    lat: 59.3810514,
    lng: 17.9514925,
  },
  {
    visitors: "3",
    City: "Bromma",
    zipCode: "16846",
    lat: 59.3489212,
    lng: 17.8737845,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11343",
    lat: 59.3478588,
    lng: 18.0408724,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11765",
    lat: 59.3119428,
    lng: 18.008444,
  },
  {
    visitors: "1",
    City: "Hägersten",
    zipCode: "12934",
    lat: 59.30122799999999,
    lng: 17.9818769,
  },
  {
    visitors: "2",
    City: "Sköndal",
    zipCode: "12867",
    lat: 59.2556578,
    lng: 18.1106446,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11348",
    lat: 59.3451491,
    lng: 18.0559265,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11229",
    lat: 59.3324448,
    lng: 18.0406945,
  },
  {
    visitors: "2",
    City: "SAINT-OUEN-SUR-SEINE",
    zipCode: "93400",
    lat: 48.911856,
    lng: 2.333764,
  },
  {
    visitors: "1",
    City: "Enskede",
    zipCode: "12244",
    lat: 59.2647023,
    lng: 18.0890934,
  },
  {
    visitors: "2",
    City: "Tyresö",
    zipCode: "13568",
    lat: 59.23196180000001,
    lng: 18.3310168,
  },
  {
    visitors: "1",
    City: "Sundbyberg",
    zipCode: "17448",
    lat: 59.3792497,
    lng: 17.9353557,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11729",
    lat: 59.31825159999999,
    lng: 18.0410986,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11349",
    lat: 59.3475834,
    lng: 18.0545151,
  },
  {
    visitors: "1",
    City: "Farsta",
    zipCode: "12347",
    lat: 59.242296,
    lng: 18.0923523,
  },
  {
    visitors: "3",
    City: "Stockholm",
    zipCode: "11241",
    lat: 59.3311632,
    lng: 18.0262281,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11853",
    lat: 59.3151166,
    lng: 18.0513174,
  },
  {
    visitors: "1",
    City: "Lidingö",
    zipCode: "18163",
    lat: 59.35258549999999,
    lng: 18.2282174,
  },
  {
    visitors: "2",
    City: "Nacka",
    zipCode: "13165",
    lat: 59.3023385,
    lng: 18.1171117,
  },
  {
    visitors: "1",
    City: "Tibro",
    zipCode: "54335",
    lat: 58.4249288,
    lng: 14.1429834,
  },
  {
    visitors: "1",
    City: "Vällingby",
    zipCode: "16261",
    lat: 59.3614352,
    lng: 17.8720638,
  },
  {
    visitors: "4",
    City: "PARIS",
    zipCode: "75018",
    lat: 48.891305,
    lng: 2.3529867,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11741",
    lat: 59.31435019999999,
    lng: 18.0436713,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "12071",
    lat: 59.3087092,
    lng: 18.1038324,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11732",
    lat: 59.3198462,
    lng: 18.040352,
  },
  {
    visitors: "1",
    City: "Uppsala",
    zipCode: "75235",
    lat: 59.85885210000001,
    lng: 17.6183006,
  },
  {
    visitors: "1",
    City: "Figeholm",
    zipCode: "57295",
    lat: 57.3763329,
    lng: 16.5550694,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11445",
    lat: 59.3373704,
    lng: 18.0842802,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11532",
    lat: 59.3429221,
    lng: 18.0922798,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11750",
    lat: 59.3189319,
    lng: 18.0194102,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11750",
    lat: 59.3189319,
    lng: 18.0194102,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11646",
    lat: 59.3185448,
    lng: 18.0728497,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11737",
    lat: 59.31781279999999,
    lng: 18.0285502,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11144",
    lat: 59.33466619999999,
    lng: 18.0693861,
  },
  {
    visitors: "2",
    City: "Göteborg",
    zipCode: "41647",
    lat: 57.7135453,
    lng: 11.9969599,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11130",
    lat: 59.32312529999999,
    lng: 18.0759345,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11161",
    lat: 59.33784009999999,
    lng: 18.0550026,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11848",
    lat: 59.3179864,
    lng: 18.0624583,
  },
  {
    visitors: "1",
    City: "Hägersten",
    zipCode: "12949",
    lat: 59.291059,
    lng: 17.9775714,
  },
  {
    visitors: "1",
    City: "Västerhaninge",
    zipCode: "13736",
    lat: 59.132637,
    lng: 18.0718642,
  },
  {
    visitors: "1",
    City: "Bagarmossen",
    zipCode: "12847",
    lat: 59.2827044,
    lng: 18.1346562,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11559",
    lat: 59.3452485,
    lng: 18.1110931,
  },
  {
    visitors: "1",
    City: "Älvsjö",
    zipCode: "12534",
    lat: 59.27850449999999,
    lng: 17.9978277,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11858",
    lat: 59.3092637,
    lng: 18.0714195,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11245",
    lat: 59.3343961,
    lng: 18.0257303,
  },
  {
    visitors: "1",
    City: "Årsta",
    zipCode: "12053",
    lat: 59.300929,
    lng: 18.0542907,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11227",
    lat: 59.33123699999999,
    lng: 18.0478438,
  },
  {
    visitors: "1",
    City: "Johanneshov",
    zipCode: "12144",
    lat: 59.2949099,
    lng: 18.0755459,
  },
  {
    visitors: "4",
    City: "Järpen",
    zipCode: "83731",
    lat: 63.3455409,
    lng: 13.4631728,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11735",
    lat: 59.3167248,
    lng: 18.0309594,
  },
  {
    visitors: "1",
    City: "Helsinki",
    zipCode: "00140",
    lat: 60.15744170000001,
    lng: 24.9538806,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "12064",
    lat: 59.30459020000001,
    lng: 18.0988338,
  },
  {
    visitors: "3",
    City: "Uppsala",
    zipCode: "75318",
    lat: 59.8471124,
    lng: 17.6614483,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11733",
    lat: 59.32239069999999,
    lng: 18.0290508,
  },
  {
    visitors: "1",
    City: "Kista",
    zipCode: "16436",
    lat: 59.4064726,
    lng: 17.9322808,
  },
  {
    visitors: "2",
    City: "Sundbyberg",
    zipCode: "17447",
    lat: 59.37359420000001,
    lng: 17.9424419,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11427",
    lat: 59.34538079999999,
    lng: 18.0738136,
  },
  {
    visitors: "1",
    City: "Enskede",
    zipCode: "12263",
    lat: 59.2791331,
    lng: 18.0818234,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11636",
    lat: 59.3141967,
    lng: 18.0902143,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11427",
    lat: 59.34538079999999,
    lng: 18.0738136,
  },
  {
    visitors: "2",
    City: "Sollentuna",
    zipCode: "19146",
    lat: 59.4302185,
    lng: 17.9143775,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11231",
    lat: 59.3308822,
    lng: 18.0347074,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11867",
    lat: 59.30865169999999,
    lng: 18.0523067,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11771",
    lat: 59.3167961,
    lng: 17.9986892,
  },
  {
    visitors: "1",
    City: "Hägersten",
    zipCode: "12635",
    lat: 59.30018450000001,
    lng: 18.0074495,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11847",
    lat: 59.3174468,
    lng: 18.0675278,
  },
  {
    visitors: "2",
    City: "Hägersten",
    zipCode: "12635",
    lat: 59.30018450000001,
    lng: 18.0074495,
  },
  {
    visitors: "2",
    City: "Johanneshov",
    zipCode: "12150",
    lat: 59.29641530000001,
    lng: 18.0755919,
  },
  {
    visitors: "1",
    City: "Vällingby",
    zipCode: "16243",
    lat: 59.3734694,
    lng: 17.8459583,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11123",
    lat: 59.33510630000001,
    lng: 18.0520055,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11641",
    lat: 59.3114746,
    lng: 18.0975226,
  },
  {
    visitors: "1",
    City: "Trångsund",
    zipCode: "14265",
    lat: 59.2370491,
    lng: 18.1417712,
  },
  {
    visitors: "1",
    City: "Johanneshov",
    zipCode: "12135",
    lat: 59.2906003,
    lng: 18.0887437,
  },
  {
    visitors: "2",
    City: "Uppsala",
    zipCode: "75244",
    lat: 59.8255094,
    lng: 17.5910182,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11734",
    lat: 59.3163132,
    lng: 18.0364435,
  },
  {
    visitors: "3",
    City: "Stockholm",
    zipCode: "11243",
    lat: 59.33270169999999,
    lng: 18.0167517,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "12844",
    lat: 59.27263550000001,
    lng: 18.142589,
  },
  {
    visitors: "4",
    City: "Huddinge",
    zipCode: "14135",
    lat: 59.23901189999999,
    lng: 17.9776239,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11454",
    lat: 59.33409690000001,
    lng: 18.0863526,
  },
  {
    visitors: "4",
    City: "Bromma",
    zipCode: "16875",
    lat: 59.3435472,
    lng: 17.9379458,
  },
  {
    visitors: "1",
    City: "Johanneshov",
    zipCode: "12143",
    lat: 59.2968405,
    lng: 18.0750514,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11851",
    lat: 59.3169244,
    lng: 18.0529817,
  },
  {
    visitors: "1",
    City: "Johanneshov",
    zipCode: "12150",
    lat: 59.29641530000001,
    lng: 18.0755919,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11736",
    lat: 59.3174805,
    lng: 18.0308767,
  },
  {
    visitors: "2",
    City: "Årsta",
    zipCode: "12056",
    lat: 59.2925275,
    lng: 18.0506254,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11447",
    lat: 59.3374698,
    lng: 18.0790397,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11727",
    lat: 59.3188049,
    lng: 18.046335,
  },
  {
    visitors: "1",
    City: "Hägersten",
    zipCode: "12940",
    lat: 59.305595,
    lng: 17.9433528,
  },
  {
    visitors: "1",
    City: "Nacka",
    zipCode: "13142",
    lat: 59.31026259999999,
    lng: 18.1913199,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11849",
    lat: 59.3179366,
    lng: 18.0563075,
  },
  {
    visitors: "2",
    City: "Årsta",
    zipCode: "12053",
    lat: 59.300929,
    lng: 18.0542907,
  },
  {
    visitors: "2",
    City: "Vendelsö",
    zipCode: "13673",
    lat: 59.20518360000001,
    lng: 18.1873858,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11454",
    lat: 59.33409690000001,
    lng: 18.0863526,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11631",
    lat: 59.3114214,
    lng: 18.0862177,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11328",
    lat: 59.3416126,
    lng: 18.0494344,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11265",
    lat: 59.31828170000001,
    lng: 17.9848496,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11631",
    lat: 59.3114214,
    lng: 18.0862177,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11457",
    lat: 59.33241839999999,
    lng: 18.0876796,
  },
  {
    visitors: "1",
    City: "Solna",
    zipCode: "17071",
    lat: 59.3848933,
    lng: 18.0290711,
  },
  {
    visitors: "1",
    City: "Sundbyberg",
    zipCode: "17453",
    lat: 59.3787335,
    lng: 17.9678069,
  },
  {
    visitors: "1",
    City: "Solna",
    zipCode: "17151",
    lat: 59.3497722,
    lng: 17.9804935,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "12232",
    lat: 59.2848437,
    lng: 18.0834974,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11732",
    lat: 59.3198462,
    lng: 18.040352,
  },
  {
    visitors: "1",
    City: "Bandhagen",
    zipCode: "12464",
    lat: 59.2542117,
    lng: 18.027354,
  },
  {
    visitors: "2",
    City: "Hägersten",
    zipCode: "12648",
    lat: 59.30496240000001,
    lng: 18.002632,
  },
  {
    visitors: "1",
    City: "Hägersten",
    zipCode: "12648",
    lat: 59.30496240000001,
    lng: 18.002632,
  },
  {
    visitors: "1",
    City: "Hägersten",
    zipCode: "12648",
    lat: 59.30496240000001,
    lng: 18.002632,
  },
  {
    visitors: "1",
    City: "Johanneshov",
    zipCode: "12143",
    lat: 59.2968405,
    lng: 18.0750514,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11661",
    lat: 59.30814040000001,
    lng: 18.0805588,
  },
  {
    visitors: "1",
    City: "Västerås",
    zipCode: "72214",
    lat: 59.61289839999999,
    lng: 16.5506654,
  },
  {
    visitors: "1",
    City: "Västerås",
    zipCode: "72214",
    lat: 59.61289839999999,
    lng: 16.5506654,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11853",
    lat: 59.3151166,
    lng: 18.0513174,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11263",
    lat: 59.3241564,
    lng: 18.0049513,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11759",
    lat: 59.30601069999999,
    lng: 18.0265513,
  },
  {
    visitors: "1",
    City: "København S",
    zipCode: "2300",
    lat: 55.6571378,
    lng: 12.6094233,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11257",
    lat: 59.3286472,
    lng: 18.0034538,
  },
  {
    visitors: "1",
    City: "Örnsköldsvik",
    zipCode: "89133",
    lat: 63.2884878,
    lng: 18.7200146,
  },
  {
    visitors: "1",
    City: "Göteborg",
    zipCode: "41478",
    lat: 57.6755581,
    lng: 11.9324101,
  },
  {
    visitors: "1",
    City: "Grisslehamn",
    zipCode: "76457",
    lat: 60.1704586,
    lng: 18.7650315,
  },
  {
    visitors: "1",
    City: "Solna",
    zipCode: "16956",
    lat: 59.3713983,
    lng: 17.9984398,
  },
  {
    visitors: "4",
    City: "Bandhagen",
    zipCode: "12433",
    lat: 59.26968239999999,
    lng: 18.0439577,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11267",
    lat: 59.3213431,
    lng: 18.0051185,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11267",
    lat: 59.3213431,
    lng: 18.0051185,
  },
  {
    visitors: "3",
    City: "Stockholm",
    zipCode: "11822",
    lat: 59.31937139999999,
    lng: 18.0618785,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11854",
    lat: 59.3147506,
    lng: 18.0603755,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11854",
    lat: 59.3147506,
    lng: 18.0603755,
  },
  {
    visitors: "1",
    City: "Solna",
    zipCode: "16963",
    lat: 59.3621341,
    lng: 18.0099276,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11737",
    lat: 59.31781279999999,
    lng: 18.0285502,
  },
  {
    visitors: "1",
    City: "Älvsjö",
    zipCode: "12546",
    lat: 59.2883751,
    lng: 18.0250491,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11223",
    lat: 59.3294253,
    lng: 18.0436019,
  },
  {
    visitors: "1",
    City: "Johanneshov",
    zipCode: "12150",
    lat: 59.29641530000001,
    lng: 18.0755919,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11851",
    lat: 59.3169244,
    lng: 18.0529817,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11358",
    lat: 59.3404938,
    lng: 18.0611609,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11336",
    lat: 59.3394061,
    lng: 18.0336313,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11348",
    lat: 59.3451491,
    lng: 18.0559265,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11730",
    lat: 59.3179512,
    lng: 18.0366939,
  },
  {
    visitors: "1",
    City: "Nykvarn",
    zipCode: "15593",
    lat: 59.2020947,
    lng: 17.3020286,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "16751",
    lat: 59.3303737,
    lng: 17.9835081,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11730",
    lat: 59.3179512,
    lng: 18.0366939,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11431",
    lat: 59.34151799999999,
    lng: 18.074805,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11827",
    lat: 59.311614,
    lng: 18.0705928,
  },
  {
    visitors: "2",
    City: "Lidingö",
    zipCode: "18131",
    lat: 59.37407,
    lng: 18.1203419,
  },
  {
    visitors: "2",
    City: "Göteborg",
    zipCode: "41762",
    lat: 57.70298049999999,
    lng: 11.9163353,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11360",
    lat: 59.3393085,
    lng: 18.05384,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11447",
    lat: 59.3374698,
    lng: 18.0790397,
  },
  {
    visitors: "3",
    City: "Stockholm",
    zipCode: "11824",
    lat: 59.31996059999999,
    lng: 18.06296,
  },
  {
    visitors: "1",
    City: "Farsta",
    zipCode: "12361",
    lat: 59.2548802,
    lng: 18.0875745,
  },
  {
    visitors: "1",
    City: "Lisboa",
    zipCode: "1300-383",
    lat: 38.6997589,
    lng: -9.1819097,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11320",
    lat: 59.3394968,
    lng: 18.0382051,
  },
  {
    visitors: "1",
    City: "Järfälla",
    zipCode: "17675",
    lat: 59.4641811,
    lng: 17.8002033,
  },
  {
    visitors: "1",
    City: "Järfälla",
    zipCode: "17675",
    lat: 59.4641811,
    lng: 17.8002033,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11649",
    lat: 59.32932349999999,
    lng: 18.0685808,
  },
  {
    visitors: "5",
    City: "Stockholm",
    zipCode: "11218",
    lat: 59.3340817,
    lng: 18.0169221,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11726",
    lat: 59.3169608,
    lng: 18.0488263,
  },
  {
    visitors: "2",
    City: "Hägersten",
    zipCode: "12648",
    lat: 59.30496240000001,
    lng: 18.002632,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11354",
    lat: 59.3502308,
    lng: 18.0555166,
  },
  {
    visitors: "2",
    City: "Hägersten",
    zipCode: "12679",
    lat: 59.29043599999999,
    lng: 18.0028027,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11732",
    lat: 59.3198462,
    lng: 18.040352,
  },
  {
    visitors: "1",
    City: "Farsta",
    zipCode: "12361",
    lat: 59.2548802,
    lng: 18.0875745,
  },
  {
    visitors: "4",
    City: "Stockholm",
    zipCode: "11630",
    lat: 59.3156702,
    lng: 18.0942083,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11848",
    lat: 59.3179864,
    lng: 18.0624583,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11665",
    lat: 59.3096489,
    lng: 18.0778194,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11665",
    lat: 59.3096489,
    lng: 18.0778194,
  },
  {
    visitors: "1",
    City: "Storvreta",
    zipCode: "74345",
    lat: 59.9403813,
    lng: 17.6777549,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11633",
    lat: 59.31377189999999,
    lng: 18.0853919,
  },
  {
    visitors: "1",
    City: "Saltsjö-Duvnäs",
    zipCode: "13150",
    lat: 59.3008006,
    lng: 18.1982809,
  },
  {
    visitors: "1",
    City: "Hägersten",
    zipCode: "12931",
    lat: 59.29174210000001,
    lng: 17.9832141,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11726",
    lat: 59.3169608,
    lng: 18.0488263,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11543",
    lat: 59.35304730000001,
    lng: 18.0956333,
  },
  {
    visitors: "1",
    City: "Hägersten",
    zipCode: "12648",
    lat: 59.30496240000001,
    lng: 18.002632,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11246",
    lat: 59.336545,
    lng: 18.0302207,
  },
  {
    visitors: "2",
    City: "Sundbyberg",
    zipCode: "17273",
    lat: 59.3673762,
    lng: 17.9613382,
  },
  {
    visitors: "1",
    City: "Lidingö",
    zipCode: "18141",
    lat: 59.3542694,
    lng: 18.1855698,
  },
  {
    visitors: "1",
    City: "Solna",
    zipCode: "16938",
    lat: 59.3679746,
    lng: 17.9872097,
  },
  {
    visitors: "1",
    City: "Farsta",
    zipCode: "12355",
    lat: 59.25351689999999,
    lng: 18.0868188,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11771",
    lat: 59.3167961,
    lng: 17.9986892,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11730",
    lat: 59.3179512,
    lng: 18.0366939,
  },
  {
    visitors: "1",
    City: "Bromma",
    zipCode: "16762",
    lat: 59.32026920000001,
    lng: 17.9708925,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11828",
    lat: 59.3142975,
    lng: 18.0674393,
  },
  {
    visitors: "2",
    City: "Solna",
    zipCode: "16952",
    lat: 59.3624572,
    lng: 18.0024396,
  },
  {
    visitors: "4",
    City: "Stockholm",
    zipCode: "11356",
    lat: 59.3475067,
    lng: 18.0602555,
  },
  {
    visitors: "1",
    City: "Hägersten",
    zipCode: "12628",
    lat: 59.29790389999999,
    lng: 17.9999777,
  },
  {
    visitors: "1",
    City: "Solna",
    zipCode: "16966",
    lat: 59.362475,
    lng: 18.0127564,
  },
  {
    visitors: "1",
    City: "Bromma",
    zipCode: "16844",
    lat: 59.3487101,
    lng: 17.8594983,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11740",
    lat: 59.31734159999999,
    lng: 18.0227335,
  },
  {
    visitors: "1",
    City: "Enskede",
    zipCode: "12247",
    lat: 59.2727321,
    lng: 18.0642114,
  },
  {
    visitors: "2",
    City: "Uppsala",
    zipCode: "75329",
    lat: 59.8643491,
    lng: 17.6473871,
  },
  {
    visitors: "1",
    City: "Handen",
    zipCode: "13639",
    lat: 59.156349,
    lng: 18.1394217,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11855",
    lat: 59.3145841,
    lng: 18.0615388,
  },
  {
    visitors: "1",
    City: "Hägersten",
    zipCode: "12934",
    lat: 59.30122799999999,
    lng: 17.9818769,
  },
  {
    visitors: "1",
    City: "Solna",
    zipCode: "17157",
    lat: 59.34946040000001,
    lng: 17.9931328,
  },
  {
    visitors: "1",
    City: "Hägersten",
    zipCode: "12635",
    lat: 59.30018450000001,
    lng: 18.0074495,
  },
];

const travelDataB = [
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11849",
    lat: 59.3179366,
    lng: 18.0563075,
  },
  {
    visitors: "2",
    City: "Tyresö",
    zipCode: "13546",
    lat: 59.24092640000001,
    lng: 18.2231264,
  },
  {
    visitors: "1",
    City: "Nacka",
    zipCode: "13142",
    lat: 59.31026259999999,
    lng: 18.1913199,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11862",
    lat: 59.30889029999999,
    lng: 18.0753247,
  },
  {
    visitors: "1",
    City: "London",
    zipCode: "W9 1PG",
    lat: 51.5293133,
    lng: -0.1849839,
  },
  {
    visitors: "2",
    City: "Hägersten",
    zipCode: "12947",
    lat: 59.2927139,
    lng: 17.9659512,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11237",
    lat: 59.32907,
    lng: 18.0304668,
  },
  {
    visitors: "1",
    City: "Handen",
    zipCode: "13643",
    lat: 59.1671701,
    lng: 18.1442719,
  },
  {
    visitors: "1",
    City: "Nacka",
    zipCode: "13160",
    lat: 59.3037016,
    lng: 18.1490386,
  },
  {
    visitors: "3",
    City: "Johanneshov",
    zipCode: "12144",
    lat: 59.2949099,
    lng: 18.0755459,
  },
  {
    visitors: "1",
    City: "Uppsala",
    zipCode: "75228",
    lat: 59.8795111,
    lng: 17.588815,
  },
  {
    visitors: "3",
    City: "Hägersten",
    zipCode: "12653",
    lat: 59.30652240000001,
    lng: 17.9838643,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11849",
    lat: 59.3179366,
    lng: 18.0563075,
  },
  {
    visitors: "2",
    City: "Hägersten",
    zipCode: "12642",
    lat: 59.2904947,
    lng: 17.9893568,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11849",
    lat: 59.3179366,
    lng: 18.0563075,
  },
  {
    visitors: "2",
    City: "Skövde",
    zipCode: "54131",
    lat: 58.3868634,
    lng: 13.8305073,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11230",
    lat: 59.33273399999999,
    lng: 18.037369,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11756",
    lat: 59.3036688,
    lng: 18.0325305,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11357",
    lat: 59.34272139999999,
    lng: 18.062495,
  },
  {
    visitors: "1",
    City: "Bagarmossen",
    zipCode: "12841",
    lat: 59.27883660000001,
    lng: 18.1253378,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11354",
    lat: 59.3502308,
    lng: 18.0555166,
  },
  {
    visitors: "2",
    City: "Hägersten",
    zipCode: "12939",
    lat: 59.3079797,
    lng: 17.957958,
  },
  {
    visitors: "1",
    City: "Oberglatt ZH",
    zipCode: "8154",
    lat: 47.4766643,
    lng: 8.5194782,
  },
  {
    visitors: "1",
    City: "Bromma",
    zipCode: "16735",
    lat: 59.3368293,
    lng: 17.9773508,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11737",
    lat: 59.31781279999999,
    lng: 18.0285502,
  },
  {
    visitors: "1",
    City: "Hägersten",
    zipCode: "12634",
    lat: 59.3022899,
    lng: 18.0109368,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "16570",
    lat: 59.37480540000001,
    lng: 17.8221273,
  },
  {
    visitors: "1",
    City: "Lidingö",
    zipCode: "18142",
    lat: 59.3658535,
    lng: 18.1516362,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11850",
    lat: 59.3164285,
    lng: 18.0590482,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11850",
    lat: 59.3164285,
    lng: 18.0590482,
  },
  {
    visitors: "2",
    City: "Saltsjö-Boo",
    zipCode: "13232",
    lat: 59.3330868,
    lng: 18.2600677,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11741",
    lat: 59.31435019999999,
    lng: 18.0436713,
  },
  {
    visitors: "2",
    City: "Rimbo",
    zipCode: "76292",
    lat: 59.7436995,
    lng: 18.3601496,
  },
  {
    visitors: "1",
    City: "Sollentuna",
    zipCode: "19259",
    lat: 59.44798469999999,
    lng: 17.9623397,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11738",
    lat: 59.31558519999999,
    lng: 18.0272198,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11533",
    lat: 59.34321780000001,
    lng: 18.0941111,
  },
  {
    visitors: "2",
    City: "Enskede",
    zipCode: "12242",
    lat: 59.2760729,
    lng: 18.0512685,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "12061",
    lat: 59.3032408,
    lng: 18.0926804,
  },
  {
    visitors: "3",
    City: "Stockholm",
    zipCode: "11825",
    lat: 59.3217552,
    lng: 18.0543176,
  },
  {
    visitors: "2",
    City: "stockholm",
    zipCode: "11357",
    lat: 59.34272139999999,
    lng: 18.062495,
  },
  {
    visitors: "1",
    City: "Huddinge",
    zipCode: "14146",
    lat: 59.2321537,
    lng: 17.989227,
  },
  {
    visitors: "2",
    City: "Huddinge",
    zipCode: "14146",
    lat: 59.2321537,
    lng: 17.989227,
  },
  {
    visitors: "1",
    City: "Märsta",
    zipCode: "19534",
    lat: 59.62343199999999,
    lng: 17.859283,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11239",
    lat: 59.3315906,
    lng: 18.0310499,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11454",
    lat: 59.33409690000001,
    lng: 18.0863526,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11645",
    lat: 59.3198495,
    lng: 18.0754293,
  },
  {
    visitors: "2",
    City: "Sollentuna",
    zipCode: "19251",
    lat: 59.44042990000001,
    lng: 17.9683594,
  },
  {
    visitors: "1",
    City: "Bandhagen",
    zipCode: "12454",
    lat: 59.2643041,
    lng: 18.0399695,
  },
  {
    visitors: "3",
    City: "Värmdö",
    zipCode: "13931",
    lat: 59.3218517,
    lng: 18.4866868,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "12068",
    lat: 59.30501880000001,
    lng: 18.1088086,
  },
  {
    visitors: "1",
    City: "Uppsala",
    zipCode: "75262",
    lat: 59.8496604,
    lng: 17.5943441,
  },
  {
    visitors: "5",
    City: "Skellefteå",
    zipCode: "93134",
    lat: 64.7489377,
    lng: 20.965913,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11456",
    lat: 59.33086110000001,
    lng: 18.0842661,
  },
  {
    visitors: "5",
    City: "Stockholm",
    zipCode: "11735",
    lat: 59.3167248,
    lng: 18.0309594,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11548",
    lat: 59.3621193,
    lng: 18.0946565,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11737",
    lat: 59.31781279999999,
    lng: 18.0285502,
  },
  {
    visitors: "2",
    City: "Jordbro",
    zipCode: "13650",
    lat: 59.1494392,
    lng: 18.0996886,
  },
  {
    visitors: "3",
    City: "Hässelby",
    zipCode: "16576",
    lat: 59.38683059999999,
    lng: 17.800104,
  },
  {
    visitors: "1",
    City: "Bromma",
    zipCode: "16842",
    lat: 59.3397832,
    lng: 17.9397131,
  },
  {
    visitors: "1",
    City: "Sundbyberg",
    zipCode: "17457",
    lat: 59.37589469999999,
    lng: 17.938027,
  },
  {
    visitors: "4",
    City: "Stockholm",
    zipCode: "11457",
    lat: 59.33241839999999,
    lng: 18.0876796,
  },
  {
    visitors: "2",
    City: "Solna",
    zipCode: "17151",
    lat: 59.3497722,
    lng: 17.9804935,
  },
  {
    visitors: "1",
    City: "Bandhagen",
    zipCode: "12462",
    lat: 59.25557240000001,
    lng: 18.0386337,
  },
  {
    visitors: "2",
    City: "",
    zipCode: "",
  },
  {
    visitors: "2",
    City: "London",
    zipCode: "E8 3LL",
    lat: 51.54163339999999,
    lng: -0.0657077,
  },
  {
    visitors: "4",
    City: "Stockholm",
    zipCode: "11852",
    lat: 59.315929,
    lng: 18.0625382,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11852",
    lat: 59.315929,
    lng: 18.0625382,
  },
  {
    visitors: "1",
    City: "Bromma",
    zipCode: "168 53",
    lat: 59.352842,
    lng: 17.8933748,
  },
  {
    visitors: "3",
    City: "Stockholm",
    zipCode: "11329",
    lat: 59.3409434,
    lng: 18.051513,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11853",
    lat: 59.3151166,
    lng: 18.0513174,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "12032",
    lat: 59.30222449999999,
    lng: 18.084202,
  },
  {
    visitors: "2",
    City: "Hägersten",
    zipCode: "12635",
    lat: 59.30018450000001,
    lng: 18.0074495,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "12062",
    lat: 59.30420359999999,
    lng: 18.0898573,
  },
  {
    visitors: "2",
    City: "Solna",
    zipCode: "16953",
    lat: 59.36493040000001,
    lng: 17.9994429,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "12069",
    lat: 59.30393129999999,
    lng: 18.1137923,
  },
  {
    visitors: "1",
    City: "Uppsala",
    zipCode: "75235",
    lat: 59.85885210000001,
    lng: 17.6183006,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11851",
    lat: 59.3169244,
    lng: 18.0529817,
  },
  {
    visitors: "1",
    City: "Bromma",
    zipCode: "16762",
    lat: 59.32026920000001,
    lng: 17.9708925,
  },
  {
    visitors: "1",
    City: "Enskede Gård",
    zipCode: "12048",
    lat: 59.287521,
    lng: 18.0751986,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11644",
    lat: 59.31248979999999,
    lng: 18.1060048,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11328",
    lat: 59.3416126,
    lng: 18.0494344,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11332",
    lat: 59.3432762,
    lng: 18.0377917,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11822",
    lat: 59.31937139999999,
    lng: 18.0618785,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11822",
    lat: 59.31937139999999,
    lng: 18.0618785,
  },
  {
    visitors: "1",
    City: "Hägersten",
    zipCode: "12954",
    lat: 59.28631650000001,
    lng: 17.9586597,
  },
  {
    visitors: "1",
    City: "Nacka",
    zipCode: "13174",
    lat: 59.31801069999999,
    lng: 18.133785,
  },
  {
    visitors: "1",
    City: "Nacka",
    zipCode: "13174",
    lat: 59.31801069999999,
    lng: 18.133785,
  },
  {
    visitors: "1",
    City: "Bromma",
    zipCode: "16865",
    lat: 59.36089759999999,
    lng: 17.9571923,
  },
  {
    visitors: "2",
    City: "",
    zipCode: "",
  },
  {
    visitors: "2",
    City: "Bandhagen",
    zipCode: "12476",
    lat: 59.25863,
    lng: 18.0094438,
  },
  {
    visitors: "2",
    City: "Katrineholm",
    zipCode: "64137",
    lat: 58.97954670000001,
    lng: 16.2056706,
  },
  {
    visitors: "2",
    City: "Tyresö",
    zipCode: "13551",
    lat: 59.224985,
    lng: 18.2047597,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11726",
    lat: 59.3169608,
    lng: 18.0488263,
  },
  {
    visitors: "2",
    City: "Enskede",
    zipCode: "12262",
    lat: 59.2814884,
    lng: 18.0861468,
  },
  {
    visitors: "2",
    City: "Enskede",
    zipCode: "12264",
    lat: 59.27860259999999,
    lng: 18.0595728,
  },
  {
    visitors: "1",
    City: "Hässelby",
    zipCode: "16552",
    lat: 59.3719233,
    lng: 17.8399296,
  },
  {
    visitors: "1",
    City: "Sollentuna",
    zipCode: "19134",
    lat: 59.42002179999999,
    lng: 17.9390709,
  },
  {
    visitors: "2",
    City: "Spånga",
    zipCode: "16362",
    lat: 59.3972707,
    lng: 17.9158275,
  },
  {
    visitors: "1",
    City: "Hägersten",
    zipCode: "12650",
    lat: 59.3079066,
    lng: 18.0054548,
  },
  {
    visitors: "3",
    City: "Uppsala",
    zipCode: "75313",
    lat: 59.86034859999999,
    lng: 17.6223238,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11624",
    lat: 59.3131745,
    lng: 18.0765799,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "12067",
    lat: 59.30568659999999,
    lng: 18.1041561,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11560",
    lat: 59.34379910000001,
    lng: 18.1118038,
  },
  {
    visitors: "2",
    City: "Bromma",
    zipCode: "16831",
    lat: 59.33626659999999,
    lng: 17.9499288,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11353",
    lat: 59.3454055,
    lng: 18.0593376,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11249",
    lat: 59.3375856,
    lng: 18.0242345,
  },
  {
    visitors: "2",
    City: "STOCKHOLM",
    zipCode: "12146",
    lat: 59.29829720000001,
    lng: 18.1038021,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11731",
    lat: 59.3186634,
    lng: 18.0356141,
  },
  {
    visitors: "3",
    City: "Örebro",
    zipCode: "70593",
    lat: 59.2752626,
    lng: 15.2134105,
  },
  {
    visitors: "1",
    City: "Enskede",
    zipCode: "12262",
    lat: 59.2814884,
    lng: 18.0861468,
  },
  {
    visitors: "3",
    City: "Stockholm",
    zipCode: "11632",
    lat: 59.314452,
    lng: 18.0936233,
  },
  {
    visitors: "4",
    City: "Saltsjö-boo",
    zipCode: "13240",
    lat: 59.31647359999999,
    lng: 18.2256412,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11830",
    lat: 59.3119331,
    lng: 18.0742087,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11824",
    lat: 59.31996059999999,
    lng: 18.06296,
  },
  {
    visitors: "1",
    City: "Bromma",
    zipCode: "16762",
    lat: 59.32026920000001,
    lng: 17.9708925,
  },
  {
    visitors: "1",
    City: "Bromma",
    zipCode: "16762",
    lat: 59.32026920000001,
    lng: 17.9708925,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11737",
    lat: 59.31781279999999,
    lng: 18.0285502,
  },
  {
    visitors: "3",
    City: "Stockholm",
    zipCode: "11734",
    lat: 59.3163132,
    lng: 18.0364435,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11335",
    lat: 59.3429678,
    lng: 18.0282274,
  },
  {
    visitors: "2",
    City: "Bromma",
    zipCode: "16844",
    lat: 59.3487101,
    lng: 17.8594983,
  },
  {
    visitors: "2",
    City: "Bromma",
    zipCode: "16743",
    lat: 59.33600080000001,
    lng: 17.9831696,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11349",
    lat: 59.3475834,
    lng: 18.0545151,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11349",
    lat: 59.3475834,
    lng: 18.0545151,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11349",
    lat: 59.3475834,
    lng: 18.0545151,
  },
  {
    visitors: "4",
    City: "Stockholm",
    zipCode: "11356",
    lat: 59.3475067,
    lng: 18.0602555,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11258",
    lat: 59.3271447,
    lng: 18.0087731,
  },
  {
    visitors: "2",
    City: "Johanneshov",
    zipCode: "12145",
    lat: 59.29921249999999,
    lng: 18.0948304,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11736",
    lat: 59.3174805,
    lng: 18.0308767,
  },
  {
    visitors: "1",
    City: "Bromma",
    zipCode: "16875",
    lat: 59.3435472,
    lng: 17.9379458,
  },
  {
    visitors: "1",
    City: "Nacka",
    zipCode: "13147",
    lat: 59.3176523,
    lng: 18.2020133,
  },
  {
    visitors: "1",
    City: "Sollentuna",
    zipCode: "19251",
    lat: 59.44042990000001,
    lng: 17.9683594,
  },
  {
    visitors: "3",
    City: "Hägersten",
    zipCode: "12930",
    lat: 59.2931754,
    lng: 17.9862008,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11736",
    lat: 59.3174805,
    lng: 18.0308767,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11359",
    lat: 59.3396915,
    lng: 18.0576664,
  },
  {
    visitors: "1",
    City: "Hägersten",
    zipCode: "12630",
    lat: 59.293983,
    lng: 18.0144242,
  },
  {
    visitors: "1",
    City: "Toulouse",
    zipCode: "31400",
    lat: 43.5611027,
    lng: 1.4531355,
  },
  {
    visitors: "1",
    City: "Handen",
    zipCode: "13648",
    lat: 59.17831219999999,
    lng: 18.1262739,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11728",
    lat: 59.3176261,
    lng: 18.044173,
  },
  {
    visitors: "2",
    City: "Sköndal",
    zipCode: "12867",
    lat: 59.2556578,
    lng: 18.1106446,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11861",
    lat: 59.3054695,
    lng: 18.0589493,
  },
  {
    visitors: "1",
    City: "Nykvarn",
    zipCode: "15594",
    lat: 59.1554021,
    lng: 17.3398831,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11235",
    lat: 59.3270914,
    lng: 18.0273904,
  },
  {
    visitors: "1",
    City: "Södertälje",
    zipCode: "15134",
    lat: 59.19668970000001,
    lng: 17.6145078,
  },
  {
    visitors: "2",
    City: "Budapest",
    zipCode: "1092",
    lat: 47.497912,
    lng: 19.040235,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11847",
    lat: 59.3174468,
    lng: 18.0675278,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11445",
    lat: 59.3373704,
    lng: 18.0842802,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11249",
    lat: 59.3375856,
    lng: 18.0242345,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11227",
    lat: 59.33123699999999,
    lng: 18.0478438,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11249",
    lat: 59.3375856,
    lng: 18.0242345,
  },
  {
    visitors: "1",
    City: "Hägersten",
    zipCode: "12649",
    lat: 59.3054783,
    lng: 18.0094417,
  },
  {
    visitors: "2",
    City: "Tyresö",
    zipCode: "13553",
    lat: 59.21693029999999,
    lng: 18.1987355,
  },
  {
    visitors: "1",
    City: "Winterthur",
    zipCode: "8408",
    lat: 47.5086606,
    lng: 8.6873516,
  },
  {
    visitors: "2",
    City: "Uppsala",
    zipCode: "75218",
    lat: 59.8651698,
    lng: 17.6157616,
  },
  {
    visitors: "1",
    City: "Enskede",
    zipCode: "12246",
    lat: 59.26502409999999,
    lng: 18.0764794,
  },
  {
    visitors: "1",
    City: "Hägersten",
    zipCode: "12649",
    lat: 59.3054783,
    lng: 18.0094417,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11730",
    lat: 59.3179512,
    lng: 18.0366939,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11543",
    lat: 59.35304730000001,
    lng: 18.0956333,
  },
  {
    visitors: "1",
    City: "Enskede Gård",
    zipCode: "12047",
    lat: 59.2901978,
    lng: 18.0668989,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11622",
    lat: 59.3183437,
    lng: 18.0807468,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11759",
    lat: 59.30601069999999,
    lng: 18.0265513,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11759",
    lat: 59.30601069999999,
    lng: 18.0265513,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11641",
    lat: 59.3114746,
    lng: 18.0975226,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11539",
    lat: 59.34788439999999,
    lng: 18.0991992,
  },
  {
    visitors: "1",
    City: "Sundsvall",
    zipCode: "85653",
    lat: 62.4186638,
    lng: 17.3189922,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "12147",
    lat: 59.295174,
    lng: 18.0866781,
  },
  {
    visitors: "1",
    City: "Bandhagen",
    zipCode: "12454",
    lat: 59.2643041,
    lng: 18.0399695,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11443",
    lat: 59.340141,
    lng: 18.0831215,
  },
  {
    visitors: "1",
    City: "Bromma",
    zipCode: "16874",
    lat: 59.34491749999999,
    lng: 17.9492451,
  },
  {
    visitors: "2",
    City: "Sundbyberg",
    zipCode: "17274",
    lat: 59.3681764,
    lng: 17.9622517,
  },
  {
    visitors: "1",
    City: "Ekerö",
    zipCode: "17838",
    lat: 59.2702842,
    lng: 17.8148258,
  },
  {
    visitors: "1",
    City: "Johanneshov",
    zipCode: "12155",
    lat: 59.29641530000001,
    lng: 18.0755919,
  },
  {
    visitors: "1",
    City: "Philippine",
    zipCode: "4553 PS",
    lat: 51.2811246,
    lng: 3.753463699999999,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11326",
    lat: 59.34433610000001,
    lng: 18.044696,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11731",
    lat: 59.3186634,
    lng: 18.0356141,
  },
  {
    visitors: "2",
    City: "Nacka",
    zipCode: "13131",
    lat: 59.315617,
    lng: 18.1334547,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11731",
    lat: 59.3186634,
    lng: 18.0356141,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11335",
    lat: 59.3429678,
    lng: 18.0282274,
  },
  {
    visitors: "3",
    City: "Stockholm",
    zipCode: "11124",
    lat: 59.3364515,
    lng: 18.053005,
  },
  {
    visitors: "1",
    City: "Västerås",
    zipCode: "72214",
    lat: 59.61289839999999,
    lng: 16.5506654,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11248",
    lat: 59.338765,
    lng: 18.0263967,
  },
  {
    visitors: "2",
    City: "Farsta",
    zipCode: "12363",
    lat: 59.24458319999999,
    lng: 18.1152535,
  },
  {
    visitors: "4",
    City: "Stockholm",
    zipCode: "11830",
    lat: 59.3119331,
    lng: 18.0742087,
  },
  {
    visitors: "1",
    City: "Årsta",
    zipCode: "12051",
    lat: 59.3016082,
    lng: 18.0599405,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11857",
    lat: 59.31030930000001,
    lng: 18.0680142,
  },
  {
    visitors: "2",
    City: "Kungsängen",
    zipCode: "19693",
    lat: 59.4573426,
    lng: 17.6896702,
  },
  {
    visitors: "1",
    City: "Sundbyberg",
    zipCode: "17453",
    lat: 59.3787335,
    lng: 17.9678069,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11730",
    lat: 59.3179512,
    lng: 18.0366939,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11771",
    lat: 59.3167961,
    lng: 17.9986892,
  },
  {
    visitors: "4",
    City: "Årsta",
    zipCode: "12053",
    lat: 59.300929,
    lng: 18.0542907,
  },
  {
    visitors: "1",
    City: "Lidingö",
    zipCode: "18158",
    lat: 59.3511532,
    lng: 18.1529059,
  },
  {
    visitors: "1",
    City: "STOCKHOLM",
    zipCode: "11259",
    lat: 59.32908450000001,
    lng: 18.013427,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11733",
    lat: 59.32239069999999,
    lng: 18.0290508,
  },
  {
    visitors: "1",
    City: "Hässelby",
    zipCode: "16561",
    lat: 59.35600540000001,
    lng: 17.8288917,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11559",
    lat: 59.3452485,
    lng: 18.1110931,
  },
  {
    visitors: "1",
    City: "Södertälje",
    zipCode: "15132",
    lat: 59.187041,
    lng: 17.6304601,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11734",
    lat: 59.3163132,
    lng: 18.0364435,
  },
  {
    visitors: "1",
    City: "Årsta",
    zipCode: "12058",
    lat: 59.29982289999999,
    lng: 18.0438234,
  },
  {
    visitors: "1",
    City: "Enskede",
    zipCode: "12248",
    lat: 59.2739164,
    lng: 18.0715179,
  },
  {
    visitors: "2",
    City: "Linköping",
    zipCode: "58224",
    lat: 58.4110533,
    lng: 15.6193685,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11247",
    lat: 59.3357892,
    lng: 18.0303035,
  },
  {
    visitors: "2",
    City: "Enskede",
    zipCode: "12260",
    lat: 59.2698955,
    lng: 18.078814,
  },
  {
    visitors: "2",
    City: "Sundbyberg",
    zipCode: "17270",
    lat: 59.3624863,
    lng: 17.9734906,
  },
  {
    visitors: "2",
    City: "Hägersten",
    zipCode: "12949",
    lat: 59.291059,
    lng: 17.9775714,
  },
  {
    visitors: "1",
    City: "Johanneshov",
    zipCode: "12136",
    lat: 59.292824,
    lng: 18.0875031,
  },
  {
    visitors: "2",
    City: "Sollentuna",
    zipCode: "19143",
    lat: 59.41423400000001,
    lng: 17.9850728,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11427",
    lat: 59.34538079999999,
    lng: 18.0738136,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11828",
    lat: 59.3142975,
    lng: 18.0674393,
  },
  {
    visitors: "2",
    City: "Ekeren",
    zipCode: "2180",
    lat: 51.2771047,
    lng: 4.4175641,
  },
  {
    visitors: "1",
    City: "Kista",
    zipCode: "16441",
    lat: 59.4034596,
    lng: 17.9377849,
  },
  {
    visitors: "2",
    City: "Enskede",
    zipCode: "12247",
    lat: 59.2727321,
    lng: 18.0642114,
  },
  {
    visitors: "1",
    City: "Hägersten",
    zipCode: "12957",
    lat: 59.28603409999999,
    lng: 17.9524659,
  },
  {
    visitors: "2",
    City: "Huddinge",
    zipCode: "14144",
    lat: 59.23546219999999,
    lng: 17.9660301,
  },
  {
    visitors: "2",
    City: "Farsta",
    zipCode: "12361",
    lat: 59.2548802,
    lng: 18.0875745,
  },
  {
    visitors: "1",
    City: "Vårby",
    zipCode: "14347",
    lat: 59.2687359,
    lng: 17.8809399,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11737",
    lat: 59.31781279999999,
    lng: 18.0285502,
  },
  {
    visitors: "1",
    City: "Nacka",
    zipCode: "13133",
    lat: 59.2911155,
    lng: 18.1692636,
  },
  {
    visitors: "1",
    City: "Johanneshov",
    zipCode: "12146",
    lat: 59.29829720000001,
    lng: 18.1038021,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11727",
    lat: 59.3188049,
    lng: 18.046335,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11739",
    lat: 59.3140818,
    lng: 18.0325367,
  },
  {
    visitors: "2",
    City: "Bromma",
    zipCode: "16751",
    lat: 59.3303737,
    lng: 17.9835081,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11256",
    lat: 59.33132930000001,
    lng: 18.0002944,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11854",
    lat: 59.3147506,
    lng: 18.0603755,
  },
  {
    visitors: "1",
    City: "Linköping",
    zipCode: "58435",
    lat: 58.4075319,
    lng: 15.5639145,
  },
  {
    visitors: "2",
    City: "Johanneshov",
    zipCode: "12137",
    lat: 59.2952652,
    lng: 18.093823,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11759",
    lat: 59.30601069999999,
    lng: 18.0265513,
  },
  {
    visitors: "1",
    City: "Johanneshov",
    zipCode: "12151",
    lat: 59.28957299999999,
    lng: 18.1127486,
  },
  {
    visitors: "1",
    City: "Årsta",
    zipCode: "12057",
    lat: 59.2965475,
    lng: 18.0433218,
  },
  {
    visitors: "2",
    City: "Enskede",
    zipCode: "12246",
    lat: 59.26502409999999,
    lng: 18.0764794,
  },
  {
    visitors: "1",
    City: "Johanneshov",
    zipCode: "12149",
    lat: 59.29057460000001,
    lng: 18.1057731,
  },
  {
    visitors: "1",
    City: "Linköping",
    zipCode: "58435",
    lat: 58.4075319,
    lng: 15.5639145,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11230",
    lat: 59.33273399999999,
    lng: 18.037369,
  },
  {
    visitors: "1",
    City: "Sundbyberg",
    zipCode: "17270",
    lat: 59.3624863,
    lng: 17.9734906,
  },
  {
    visitors: "2",
    City: "Ekolsund",
    zipCode: "74950",
    lat: 59.61970050000001,
    lng: 17.3766542,
  },
  {
    visitors: "1",
    City: "Lidingö",
    zipCode: "18163",
    lat: 59.35258549999999,
    lng: 18.2282174,
  },
  {
    visitors: "3",
    City: "Bromma",
    zipCode: "16841",
    lat: 59.33068939999999,
    lng: 17.9027637,
  },
  {
    visitors: "1",
    City: "Solna",
    zipCode: "17172",
    lat: 59.3477922,
    lng: 17.9996201,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11822",
    lat: 59.31937139999999,
    lng: 18.0618785,
  },
  {
    visitors: "2",
    City: "Hässelby",
    zipCode: "16554",
    lat: 59.3666722,
    lng: 17.8534222,
  },
  {
    visitors: "1",
    City: "Sundbyberg",
    zipCode: "17239",
    lat: 59.3586958,
    lng: 17.9687559,
  },
  {
    visitors: "1",
    City: "Hägersten",
    zipCode: "12950",
    lat: 59.2870225,
    lng: 17.9745887,
  },
  {
    visitors: "4",
    City: "Stockholm",
    zipCode: "11256",
    lat: 59.33132930000001,
    lng: 18.0002944,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11216",
    lat: 59.34023329999999,
    lng: 18.0071073,
  },
  {
    visitors: "2",
    City: "Uppsala",
    zipCode: "75229",
    lat: 59.8636601,
    lng: 17.6091257,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11821",
    lat: 59.3185059,
    lng: 18.0581784,
  },
  {
    visitors: "2",
    City: "Västerås",
    zipCode: "72338",
    lat: 59.6294025,
    lng: 16.5720828,
  },
  {
    visitors: "3",
    City: "Hägersten",
    zipCode: "12951",
    lat: 59.2846625,
    lng: 17.9702772,
  },
  {
    visitors: "1",
    City: "Hägersten",
    zipCode: "12951",
    lat: 59.2846625,
    lng: 17.9702772,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "12629",
    lat: 59.2974064,
    lng: 18.0034648,
  },
  {
    visitors: "1",
    City: "Skarpnäck",
    zipCode: "12832",
    lat: 59.26675640000001,
    lng: 18.1369173,
  },
  {
    visitors: "1",
    City: "Skarpnäck",
    zipCode: "12832",
    lat: 59.26675640000001,
    lng: 18.1369173,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11851",
    lat: 59.3169244,
    lng: 18.0529817,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11243",
    lat: 59.33270169999999,
    lng: 18.0167517,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11637",
    lat: 59.31242160000001,
    lng: 18.0792381,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11736",
    lat: 59.3174805,
    lng: 18.0308767,
  },
  {
    visitors: "1",
    City: "Sundbyberg",
    zipCode: "17235",
    lat: 59.3633091,
    lng: 17.9650886,
  },
  {
    visitors: "2",
    City: "Enskede",
    zipCode: "12234",
    lat: 59.28191320000001,
    lng: 18.0909642,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11646",
    lat: 59.3185448,
    lng: 18.0728497,
  },
  {
    visitors: "4",
    City: "Visby",
    zipCode: "62155",
    lat: 57.6478561,
    lng: 18.2981354,
  },
  {
    visitors: "2",
    City: "Enskede",
    zipCode: "12264",
    lat: 59.27860259999999,
    lng: 18.0595728,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11538",
    lat: 59.3455783,
    lng: 18.1036039,
  },
  {
    visitors: "2",
    City: "Hägersten",
    zipCode: "12629",
    lat: 59.2974064,
    lng: 18.0034648,
  },
  {
    visitors: "1",
    City: "Châtillon",
    zipCode: "92320",
    lat: 48.80454169999999,
    lng: 2.2869271,
  },
  {
    visitors: "1",
    City: "Huddinge",
    zipCode: "14145",
    lat: 59.2362075,
    lng: 18.0024816,
  },
  {
    visitors: "1",
    City: "Skärholmen",
    zipCode: "12760",
    lat: 59.29096699999999,
    lng: 17.9364167,
  },
  {
    visitors: "2",
    City: "Saltsjö-Boo",
    zipCode: "13240",
    lat: 59.31647359999999,
    lng: 18.2256412,
  },
  {
    visitors: "2",
    City: "Hägersten",
    zipCode: "12948",
    lat: 59.29448960000001,
    lng: 17.971758,
  },
  {
    visitors: "1",
    City: "Malmö",
    zipCode: "21429",
    lat: 55.58678699999999,
    lng: 13.007839,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11623",
    lat: 59.3125021,
    lng: 18.0760798,
  },
  {
    visitors: "2",
    City: "Årsta",
    zipCode: "12056",
    lat: 59.2925275,
    lng: 18.0506254,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11256",
    lat: 59.33132930000001,
    lng: 18.0002944,
  },
  {
    visitors: "3",
    City: "Täby",
    zipCode: "18772",
    lat: 59.50179649999999,
    lng: 18.060505,
  },
  {
    visitors: "2",
    City: "Bandhagen",
    zipCode: "12450",
    lat: 59.2622793,
    lng: 18.0333313,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11251",
    lat: 59.3389931,
    lng: 18.0117631,
  },
  {
    visitors: "1",
    City: "STOCKHOLM",
    zipCode: "16844",
    lat: 59.3487101,
    lng: 17.8594983,
  },
  {
    visitors: "1",
    City: "Skärholmen",
    zipCode: "12736",
    lat: 59.2865471,
    lng: 17.9151948,
  },
  {
    visitors: "8",
    City: "Stockholm",
    zipCode: "11542",
    lat: 59.36228120000001,
    lng: 18.0883292,
  },
  {
    visitors: "2",
    City: "Årsta",
    zipCode: "12056",
    lat: 59.2925275,
    lng: 18.0506254,
  },
  {
    visitors: "2",
    City: "Johanneshov",
    zipCode: "12153",
    lat: 59.2900858,
    lng: 18.1247144,
  },
  {
    visitors: "1",
    City: "Farsta",
    zipCode: "12343",
    lat: 59.23837020000001,
    lng: 18.1119141,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11324",
    lat: 59.3403071,
    lng: 18.0468548,
  },
  {
    visitors: "2",
    City: "Nacka",
    zipCode: "13160",
    lat: 59.3037016,
    lng: 18.1490386,
  },
  {
    visitors: "2",
    City: "Lidingö",
    zipCode: "18143",
    lat: 59.37879109999999,
    lng: 18.1496904,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11735",
    lat: 59.3167248,
    lng: 18.0309594,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11731",
    lat: 59.3186634,
    lng: 18.0356141,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11731",
    lat: 59.3186634,
    lng: 18.0356141,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11764",
    lat: 59.3082564,
    lng: 18.0134281,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11764",
    lat: 59.3082564,
    lng: 18.0134281,
  },
  {
    visitors: "3",
    City: "Stockholm",
    zipCode: "11737",
    lat: 59.31781279999999,
    lng: 18.0285502,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11631",
    lat: 59.3114214,
    lng: 18.0862177,
  },
  {
    visitors: "2",
    City: "Linköping",
    zipCode: "58434",
    lat: 58.41229919999999,
    lng: 15.5608754,
  },
  {
    visitors: "2",
    City: "Nacka",
    zipCode: "13137",
    lat: 59.3090724,
    lng: 18.1427423,
  },
  {
    visitors: "2",
    City: "Johanneshov",
    zipCode: "12147",
    lat: 59.295174,
    lng: 18.0866781,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11732",
    lat: 59.3198462,
    lng: 18.040352,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11826",
    lat: 59.3156423,
    lng: 18.068439,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "12032",
    lat: 59.30222449999999,
    lng: 18.084202,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11765",
    lat: 59.3119428,
    lng: 18.008444,
  },
  {
    visitors: "1",
    City: "Hägersten",
    zipCode: "12635",
    lat: 59.30018450000001,
    lng: 18.0074495,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11250",
    lat: 59.3362401,
    lng: 18.0232365,
  },
  {
    visitors: "2",
    City: "Hägersten",
    zipCode: "12629",
    lat: 59.2974064,
    lng: 18.0034648,
  },
  {
    visitors: "1",
    City: "Solna",
    zipCode: "16936",
    lat: 59.3653989,
    lng: 17.9804739,
  },
  {
    visitors: "1",
    City: "Älvsjö",
    zipCode: "12552",
    lat: 59.26754680000001,
    lng: 17.9756062,
  },
  {
    visitors: "3",
    City: "Stockholm",
    zipCode: "11737",
    lat: 59.31781279999999,
    lng: 18.0285502,
  },
  {
    visitors: "3",
    City: "Enskede",
    zipCode: "12263",
    lat: 59.2791331,
    lng: 18.0818234,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11629",
    lat: 59.31471440000001,
    lng: 18.0833575,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11730",
    lat: 59.3179512,
    lng: 18.0366939,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11730",
    lat: 59.3179512,
    lng: 18.0366939,
  },
  {
    visitors: "2",
    City: "Nacka",
    zipCode: "13161",
    lat: 59.30563489999999,
    lng: 18.1537034,
  },
  {
    visitors: "1",
    City: "Årsta",
    zipCode: "12054",
    lat: 59.298572,
    lng: 18.0499684,
  },
  {
    visitors: "2",
    City: "Johanneshov",
    zipCode: "12138",
    lat: 59.29619499999999,
    lng: 18.1003061,
  },
  {
    visitors: "3",
    City: "Bandhagen",
    zipCode: "12457",
    lat: 59.262389,
    lng: 18.0507523,
  },
  {
    visitors: "2",
    City: "Hägersten",
    zipCode: "12948",
    lat: 59.29448960000001,
    lng: 17.971758,
  },
  {
    visitors: "2",
    City: "Stocksund",
    zipCode: "18274",
    lat: 59.3881332,
    lng: 18.0637231,
  },
  {
    visitors: "1",
    City: "Johanneshov",
    zipCode: "12149",
    lat: 59.29057460000001,
    lng: 18.1057731,
  },
  {
    visitors: "2",
    City: "Hägersten",
    zipCode: "12636",
    lat: 59.30151129999999,
    lng: 17.9981495,
  },
  {
    visitors: "1",
    City: "Hägersten",
    zipCode: "12930",
    lat: 59.2931754,
    lng: 17.9862008,
  },
  {
    visitors: "2",
    City: "Södertälje",
    zipCode: "15163",
    lat: 59.19932979999999,
    lng: 17.6062263,
  },
  {
    visitors: "4",
    City: "Stockholm",
    zipCode: "11640",
    lat: 59.31196459999999,
    lng: 18.0837254,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "12063",
    lat: 59.304752,
    lng: 18.092518,
  },
  {
    visitors: "3",
    City: "Hägersten",
    zipCode: "12933",
    lat: 59.3001462,
    lng: 17.986859,
  },
  {
    visitors: "2",
    City: "Skärholmen",
    zipCode: "12738",
    lat: 59.28319550000001,
    lng: 17.9178587,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11630",
    lat: 59.3156702,
    lng: 18.0942083,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11750",
    lat: 59.3189319,
    lng: 18.0194102,
  },
  {
    visitors: "2",
    City: "Ekerö",
    zipCode: "17853",
    lat: 59.28874250000001,
    lng: 17.7259087,
  },
  {
    visitors: "2",
    City: "Farsta",
    zipCode: "12334",
    lat: 59.2506064,
    lng: 18.0940343,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11635",
    lat: 59.3128091,
    lng: 18.0882159,
  },
  {
    visitors: "3",
    City: "Bandhagen",
    zipCode: "12453",
    lat: 59.2628726,
    lng: 18.0369817,
  },
  {
    visitors: "2",
    City: "Hägersten",
    zipCode: "12635",
    lat: 59.30018450000001,
    lng: 18.0074495,
  },
  {
    visitors: "1",
    City: "Sundbyberg",
    zipCode: "17270",
    lat: 59.3624863,
    lng: 17.9734906,
  },
  {
    visitors: "2",
    City: "Sköndal",
    zipCode: "12864",
    lat: 59.2544933,
    lng: 18.123919,
  },
  {
    visitors: "1",
    City: "Älvsjö",
    zipCode: "12574",
    lat: 59.2847914,
    lng: 18.0423137,
  },
  {
    visitors: "1",
    City: "Älvsjö",
    zipCode: "12574",
    lat: 59.2847914,
    lng: 18.0423137,
  },
  {
    visitors: "2",
    City: "Hägersten",
    zipCode: "12638",
    lat: 59.2934389,
    lng: 17.9921767,
  },
  {
    visitors: "1",
    City: "Bagarmossen",
    zipCode: "12841",
    lat: 59.27883660000001,
    lng: 18.1253378,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11324",
    lat: 59.3403071,
    lng: 18.0468548,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11330",
    lat: 59.3457102,
    lng: 18.0363793,
  },
  {
    visitors: "2",
    City: "Enskede",
    zipCode: "12246",
    lat: 59.26502409999999,
    lng: 18.0764794,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11739",
    lat: 59.3140818,
    lng: 18.0325367,
  },
  {
    visitors: "2",
    City: "Hägersten",
    zipCode: "12949",
    lat: 59.291059,
    lng: 17.9775714,
  },
  {
    visitors: "1",
    City: "København K",
    zipCode: "1150",
    lat: 55.6760968,
    lng: 12.5683371,
  },
  {
    visitors: "1",
    City: "Johanneshov",
    zipCode: "12150",
    lat: 59.29641530000001,
    lng: 18.0755919,
  },
  {
    visitors: "1",
    City: "Enskede",
    zipCode: "12266",
    lat: 59.2690316,
    lng: 18.0588936,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11771",
    lat: 59.3167961,
    lng: 17.9986892,
  },
  {
    visitors: "1",
    City: "Nacka",
    zipCode: "13139",
    lat: 59.31278,
    lng: 18.1790219,
  },
  {
    visitors: "1",
    City: "Nacka",
    zipCode: "13139",
    lat: 59.31278,
    lng: 18.1790219,
  },
  {
    visitors: "2",
    City: "Hägersten",
    zipCode: "12635",
    lat: 59.30018450000001,
    lng: 18.0074495,
  },
  {
    visitors: "1",
    City: "Solna",
    zipCode: "16957",
    lat: 59.3666176,
    lng: 18.0032692,
  },
  {
    visitors: "1",
    City: "Hägersten",
    zipCode: "12932",
    lat: 59.2977865,
    lng: 17.9825444,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11638",
    lat: 59.31045859999999,
    lng: 18.0890415,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11639",
    lat: 59.3102435,
    lng: 18.084054,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11634",
    lat: 59.31365819999999,
    lng: 18.097861,
  },
  {
    visitors: "1",
    City: "Linköping",
    zipCode: "58434",
    lat: 58.41229919999999,
    lng: 15.5608754,
  },
  {
    visitors: "1",
    City: "Älvsjö",
    zipCode: "12534",
    lat: 59.27850449999999,
    lng: 17.9978277,
  },
  {
    visitors: "1",
    City: "STOCKHOLM",
    zipCode: "11823",
    lat: 59.31906499999999,
    lng: 18.0523194,
  },
  {
    visitors: "1",
    City: "STOCKHOLM",
    zipCode: "11823",
    lat: 59.31906499999999,
    lng: 18.0523194,
  },
  {
    visitors: "1",
    City: "Bromma",
    zipCode: "16743",
    lat: 59.33600080000001,
    lng: 17.9831696,
  },
  {
    visitors: "1",
    City: "Gustavsberg",
    zipCode: "13436",
    lat: 59.3304061,
    lng: 18.3963901,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "12062",
    lat: 59.30420359999999,
    lng: 18.0898573,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11231",
    lat: 59.3308822,
    lng: 18.0347074,
  },
  {
    visitors: "1",
    City: "Hägersten",
    zipCode: "12934",
    lat: 59.30122799999999,
    lng: 17.9818769,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11259",
    lat: 59.32908450000001,
    lng: 18.013427,
  },
  {
    visitors: "1",
    City: "Uppsala",
    zipCode: "75229",
    lat: 59.8636601,
    lng: 17.6091257,
  },
  {
    visitors: "1",
    City: "Älvsjö",
    zipCode: "12544",
    lat: 59.28070669999999,
    lng: 18.008448,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11856",
    lat: 59.31006250000001,
    lng: 18.0723352,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11326",
    lat: 59.34433610000001,
    lng: 18.044696,
  },
  {
    visitors: "2",
    City: "stockholm",
    zipCode: "11847",
    lat: 59.3174468,
    lng: 18.0675278,
  },
  {
    visitors: "1",
    City: "Sundbyberg",
    zipCode: "17239",
    lat: 59.3586958,
    lng: 17.9687559,
  },
  {
    visitors: "3",
    City: "Hägersten",
    zipCode: "12647",
    lat: 59.30310060000001,
    lng: 17.9948274,
  },
];

const travelDataC = [
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11737",
    lat: 59.31781279999999,
    lng: 18.0285502,
  },
  {
    visitors: "1",
    City: "Enskededalen",
    zipCode: "12131",
    lat: 59.2856124,
    lng: 18.0962892,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11448",
    lat: 59.33879330000001,
    lng: 18.0795415,
  },
  {
    visitors: "2",
    City: "Sollentuna",
    zipCode: "19256",
    lat: 59.43644740000001,
    lng: 17.9963833,
  },
  {
    visitors: "1",
    City: "Älta",
    zipCode: "13831",
    lat: 59.254015,
    lng: 18.1737229,
  },
  {
    visitors: "1",
    City: "Katrineholm",
    zipCode: "64147",
    lat: 59.0045948,
    lng: 16.2172113,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11348",
    lat: 59.3451491,
    lng: 18.0559265,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11425",
    lat: 59.3428511,
    lng: 18.0654899,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11667",
    lat: 59.30675269999999,
    lng: 18.0785613,
  },
  {
    visitors: "1",
    City: "Järfälla",
    zipCode: "17761",
    lat: 59.42451519999999,
    lng: 17.8071543,
  },
  {
    visitors: "4",
    City: "Stockholm",
    zipCode: "11354",
    lat: 59.3502308,
    lng: 18.0555166,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11646",
    lat: 59.3185448,
    lng: 18.0728497,
  },
  {
    visitors: "1",
    City: "Lidingö",
    zipCode: "18142",
    lat: 59.3658535,
    lng: 18.1516362,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "12067",
    lat: 59.30568659999999,
    lng: 18.1041561,
  },
  {
    visitors: "2",
    City: "Hässelby",
    zipCode: "16552",
    lat: 59.3719233,
    lng: 17.8399296,
  },
  {
    visitors: "1",
    City: "Romakloster",
    zipCode: "62234",
    lat: 57.4117366,
    lng: 18.4352746,
  },
  {
    visitors: "1",
    City: "Oxelösund",
    zipCode: "61331",
    lat: 58.66938519999999,
    lng: 17.1542272,
  },
  {
    visitors: "2",
    City: "Märsta",
    zipCode: "19543",
    lat: 59.6331846,
    lng: 17.8535225,
  },
  {
    visitors: "2",
    City: "Umeå",
    zipCode: "90342",
    lat: 63.8303942,
    lng: 20.2848088,
  },
  {
    visitors: "1",
    City: "Bromma",
    zipCode: "16866",
    lat: 59.3585356,
    lng: 17.9528732,
  },
  {
    visitors: "3",
    City: "Rimbo",
    zipCode: "76261",
    lat: 59.74757429999999,
    lng: 18.3447878,
  },
  {
    visitors: "2",
    City: "Västerhaninge",
    zipCode: "13741",
    lat: 59.1149187,
    lng: 18.1227035,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11849",
    lat: 59.3179366,
    lng: 18.0563075,
  },
  {
    visitors: "1",
    City: "Malmö",
    zipCode: "21218",
    lat: 55.6057985,
    lng: 13.0278325,
  },
  {
    visitors: "2",
    City: "Solna",
    zipCode: "16979",
    lat: 59.37008409999999,
    lng: 18.00164,
  },
  {
    visitors: "1",
    City: "Enebyberg",
    zipCode: "18246",
    lat: 59.4291284,
    lng: 18.0477587,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "12066",
    lat: 59.30291659999999,
    lng: 18.1053115,
  },
  {
    visitors: "1",
    City: "Malmö",
    zipCode: "21440",
    lat: 55.5920792,
    lng: 13.0241943,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11757",
    lat: 59.3051404,
    lng: 18.0339435,
  },
  {
    visitors: "2",
    City: "Bromma",
    zipCode: "16731",
    lat: 59.3370396,
    lng: 17.9575718,
  },
  {
    visitors: "3",
    City: "Johanneshov",
    zipCode: "12137",
    lat: 59.2952652,
    lng: 18.093823,
  },
  {
    visitors: "1",
    City: "Solna",
    zipCode: "17152",
    lat: 59.3502895,
    lng: 17.9873113,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11663",
    lat: 59.3092322,
    lng: 18.0807273,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11663",
    lat: 59.3092322,
    lng: 18.0807273,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11342",
    lat: 59.34167369999999,
    lng: 18.033383,
  },
  {
    visitors: "1",
    City: "Bandhagen",
    zipCode: "12457",
    lat: 59.262389,
    lng: 18.0507523,
  },
  {
    visitors: "1",
    City: "Bromma",
    zipCode: "16874",
    lat: 59.34491749999999,
    lng: 17.9492451,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11348",
    lat: 59.3451491,
    lng: 18.0559265,
  },
  {
    visitors: "2",
    City: "Västerås",
    zipCode: "72344",
    lat: 59.61334960000001,
    lng: 16.5814704,
  },
  {
    visitors: "1",
    City: "Västerås",
    zipCode: "72344",
    lat: 59.61334960000001,
    lng: 16.5814704,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11335",
    lat: 59.3429678,
    lng: 18.0282274,
  },
  {
    visitors: "2",
    City: "Nacka",
    zipCode: "13145",
    lat: 59.3135377,
    lng: 18.1969962,
  },
  {
    visitors: "1",
    City: "Sköndal",
    zipCode: "12862",
    lat: 59.2642169,
    lng: 18.1080165,
  },
  {
    visitors: "1",
    City: "Trosa",
    zipCode: "61933",
    lat: 58.90823259999999,
    lng: 17.5630035,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11862",
    lat: 59.30889029999999,
    lng: 18.0753247,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11644",
    lat: 59.31248979999999,
    lng: 18.1060048,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11333",
    lat: 59.3436049,
    lng: 18.0328849,
  },
  {
    visitors: "1",
    City: "Hägersten",
    zipCode: "12634",
    lat: 59.3022899,
    lng: 18.0109368,
  },
  {
    visitors: "1",
    City: "Sollentuna",
    zipCode: "19270",
    lat: 59.4531047,
    lng: 17.9209633,
  },
  {
    visitors: "2",
    City: "Lidingö",
    zipCode: "18155",
    lat: 59.34440350000001,
    lng: 18.1608684,
  },
  {
    visitors: "2",
    City: "Sollentuna",
    zipCode: "19146",
    lat: 59.4302185,
    lng: 17.9143775,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11530",
    lat: 59.3428362,
    lng: 18.0902826,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11441",
    lat: 59.34186259999999,
    lng: 18.0827923,
  },
  {
    visitors: "1",
    City: "Solna",
    zipCode: "17170",
    lat: 59.3523277,
    lng: 17.9991184,
  },
  {
    visitors: "1",
    City: "Nacka",
    zipCode: "13153",
    lat: 59.3108448,
    lng: 18.1640397,
  },
  {
    visitors: "1",
    City: "Vällingby",
    zipCode: "16273",
    lat: 59.3549234,
    lng: 17.8865524,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11263",
    lat: 59.3241564,
    lng: 18.0049513,
  },
  {
    visitors: "1",
    City: "Sundbyberg",
    zipCode: "17265",
    lat: 59.3589989,
    lng: 17.9731631,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11661",
    lat: 59.30814040000001,
    lng: 18.0805588,
  },
  {
    visitors: "1",
    City: "Sollentuna",
    zipCode: "19144",
    lat: 59.41953699999999,
    lng: 17.9477363,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11526",
    lat: 59.3365032,
    lng: 18.0994189,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11247",
    lat: 59.3357892,
    lng: 18.0303035,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11257",
    lat: 59.3286472,
    lng: 18.0034538,
  },
  {
    visitors: "1",
    City: "Hägersten",
    zipCode: "12631",
    lat: 59.2971622,
    lng: 18.0077821,
  },
  {
    visitors: "3",
    City: "Stockholm",
    zipCode: "11362",
    lat: 59.3414715,
    lng: 18.0387054,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11362",
    lat: 59.3414715,
    lng: 18.0387054,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11356",
    lat: 59.3475067,
    lng: 18.0602555,
  },
  {
    visitors: "1",
    City: "Järfälla",
    zipCode: "17671",
    lat: 59.4487214,
    lng: 17.8043161,
  },
  {
    visitors: "1",
    City: "Hägersten",
    zipCode: "12937",
    lat: 59.3093478,
    lng: 17.9692472,
  },
  {
    visitors: "1",
    City: "Nacka",
    zipCode: "13153",
    lat: 59.3108448,
    lng: 18.1640397,
  },
  {
    visitors: "1",
    City: "Sundbyberg",
    zipCode: "17449",
    lat: 59.37701120000001,
    lng: 17.9485065,
  },
  {
    visitors: "1",
    City: "Lidingö",
    zipCode: "18137",
    lat: 59.3507232,
    lng: 18.1325877,
  },
  {
    visitors: "2",
    City: "Falun",
    zipCode: "79171",
    lat: 60.60769790000001,
    lng: 15.6290262,
  },
  {
    visitors: "1",
    City: "Göteborg",
    zipCode: "41311",
    lat: 57.6900919,
    lng: 11.94588,
  },
  {
    visitors: "1",
    City: "Göteborg",
    zipCode: "41715",
    lat: 57.7163461,
    lng: 11.927032,
  },
  {
    visitors: "1",
    City: "Göteborg",
    zipCode: "41715",
    lat: 57.7163461,
    lng: 11.927032,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11237",
    lat: 59.32907,
    lng: 18.0304668,
  },
  {
    visitors: "1",
    City: "Ödåkra",
    zipCode: "25474",
    lat: 56.1045818,
    lng: 12.7483633,
  },
  {
    visitors: "3",
    City: "Sundbyberg",
    zipCode: "17441",
    lat: 59.3859739,
    lng: 17.9744533,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11428",
    lat: 59.35117519999999,
    lng: 18.0723261,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11351",
    lat: 59.3447329,
    lng: 18.0588375,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11328",
    lat: 59.3416126,
    lng: 18.0494344,
  },
  {
    visitors: "1",
    City: "Johanneshov",
    zipCode: "12143",
    lat: 59.2968405,
    lng: 18.0750514,
  },
  {
    visitors: "3",
    City: "Tullinge",
    zipCode: "14645",
    lat: 59.21180630000001,
    lng: 17.9025097,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11733",
    lat: 59.32239069999999,
    lng: 18.0290508,
  },
  {
    visitors: "1",
    City: "Sundbyberg",
    zipCode: "17269",
    lat: 59.3618675,
    lng: 17.9791475,
  },
  {
    visitors: "1",
    City: "Lidingö",
    zipCode: "18137",
    lat: 59.3507232,
    lng: 18.1325877,
  },
  {
    visitors: "1",
    City: "Bandhagen",
    zipCode: "12458",
    lat: 59.2618757,
    lng: 18.043949,
  },
  {
    visitors: "1",
    City: "Stocksund",
    zipCode: "18270",
    lat: 59.37538970000001,
    lng: 18.0903568,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11738",
    lat: 59.31558519999999,
    lng: 18.0272198,
  },
  {
    visitors: "2",
    City: "Årsta",
    zipCode: "12056",
    lat: 59.2925275,
    lng: 18.0506254,
  },
  {
    visitors: "5",
    City: "Stockholm",
    zipCode: "11358",
    lat: 59.3404938,
    lng: 18.0611609,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11358",
    lat: 59.3404938,
    lng: 18.0611609,
  },
  {
    visitors: "1",
    City: "Täby",
    zipCode: "18753",
    lat: 59.470831,
    lng: 18.0477828,
  },
  {
    visitors: "2",
    City: "Bromma",
    zipCode: "16834",
    lat: 59.33356209999999,
    lng: 17.9427897,
  },
  {
    visitors: "1",
    City: "Bromma",
    zipCode: "16834",
    lat: 59.33356209999999,
    lng: 17.9427897,
  },
  {
    visitors: "1",
    City: "Bromma",
    zipCode: "16834",
    lat: 59.33356209999999,
    lng: 17.9427897,
  },
  {
    visitors: "1",
    City: "Huddinge",
    zipCode: "14150",
    lat: 59.2161254,
    lng: 17.9504923,
  },
  {
    visitors: "1",
    City: "Umeå",
    zipCode: "90421",
    lat: 63.81263989999999,
    lng: 20.2635825,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11633",
    lat: 59.31377189999999,
    lng: 18.0853919,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11633",
    lat: 59.31377189999999,
    lng: 18.0853919,
  },
  {
    visitors: "1",
    City: "Bandhagen",
    zipCode: "12434",
    lat: 59.2678465,
    lng: 18.0515894,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11459",
    lat: 59.33901119999999,
    lng: 18.0871123,
  },
  {
    visitors: "1",
    City: "Saltsjö-Boo",
    zipCode: "13233",
    lat: 59.331915,
    lng: 18.2527287,
  },
  {
    visitors: "3",
    City: "Stockholm",
    zipCode: "11636",
    lat: 59.3141967,
    lng: 18.0902143,
  },
  {
    visitors: "2",
    City: "Bandhagen",
    zipCode: "12456",
    lat: 59.264066,
    lng: 18.0494271,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11632",
    lat: 59.314452,
    lng: 18.0936233,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11128",
    lat: 59.3256304,
    lng: 18.0636338,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11357",
    lat: 59.34272139999999,
    lng: 18.062495,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11822",
    lat: 59.31937139999999,
    lng: 18.0618785,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11539",
    lat: 59.34788439999999,
    lng: 18.0991992,
  },
  {
    visitors: "6",
    City: "Stockholm",
    zipCode: "11431",
    lat: 59.34151799999999,
    lng: 18.074805,
  },
  {
    visitors: "2",
    City: "Täby",
    zipCode: "18773",
    lat: 59.50114949999999,
    lng: 18.0859249,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11854",
    lat: 59.3147506,
    lng: 18.0603755,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11763",
    lat: 59.30860560000001,
    lng: 18.0214021,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11245",
    lat: 59.3343961,
    lng: 18.0257303,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11266",
    lat: 59.32071959999999,
    lng: 17.9860103,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11266",
    lat: 59.32071959999999,
    lng: 17.9860103,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11266",
    lat: 59.32071959999999,
    lng: 17.9860103,
  },
  {
    visitors: "2",
    City: "Järfälla",
    zipCode: "17753",
    lat: 59.4175941,
    lng: 17.8364961,
  },
  {
    visitors: "1",
    City: "ENSKEDE",
    zipCode: "12243",
    lat: 59.28211659999999,
    lng: 18.0506123,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11332",
    lat: 59.3432762,
    lng: 18.0377917,
  },
  {
    visitors: "3",
    City: "Stockholm",
    zipCode: "11444",
    lat: 59.33762059999999,
    lng: 18.0825338,
  },
  {
    visitors: "1",
    City: "Spånga",
    zipCode: "16340",
    lat: 59.3817149,
    lng: 17.9127202,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11255",
    lat: 59.3304997,
    lng: 18.0061127,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11438",
    lat: 59.34064129999999,
    lng: 18.0796284,
  },
  {
    visitors: "1",
    City: "Hägersten",
    zipCode: "12653",
    lat: 59.30652240000001,
    lng: 17.9838643,
  },
  {
    visitors: "1",
    City: "Hägersten",
    zipCode: "12649",
    lat: 59.3054783,
    lng: 18.0094417,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11358",
    lat: 59.3404938,
    lng: 18.0611609,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "17455",
    lat: 59.3794391,
    lng: 17.944507,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "17455",
    lat: 59.3794391,
    lng: 17.944507,
  },
  {
    visitors: "3",
    City: "Täby",
    zipCode: "18339",
    lat: 59.44340330000001,
    lng: 18.0690483,
  },
  {
    visitors: "1",
    City: "Malmö",
    zipCode: "21175",
    lat: 55.6151835,
    lng: 12.9838135,
  },
  {
    visitors: "1",
    City: "Farsta",
    zipCode: "12347",
    lat: 59.242296,
    lng: 18.0923523,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11851",
    lat: 59.3169244,
    lng: 18.0529817,
  },
  {
    visitors: "1",
    City: "Sundbyberg",
    zipCode: "17461",
    lat: 59.3810514,
    lng: 17.9514925,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11457",
    lat: 59.33241839999999,
    lng: 18.0876796,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11738",
    lat: 59.31558519999999,
    lng: 18.0272198,
  },
  {
    visitors: "2",
    City: "Lidingö",
    zipCode: "18146",
    lat: 59.37341960000001,
    lng: 18.1663352,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11453",
    lat: 59.33413720000001,
    lng: 18.0847723,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11763",
    lat: 59.30860560000001,
    lng: 18.0214021,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11760",
    lat: 59.3085567,
    lng: 18.0290851,
  },
  {
    visitors: "1",
    City: "Solna",
    zipCode: "17159",
    lat: 59.3513667,
    lng: 18.0019466,
  },
  {
    visitors: "1",
    City: "Hägersten",
    zipCode: "12949",
    lat: 59.291059,
    lng: 17.9775714,
  },
  {
    visitors: "1",
    City: "Nyköping",
    zipCode: "61165",
    lat: 58.76644,
    lng: 17.0212346,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11335",
    lat: 59.3429678,
    lng: 18.0282274,
  },
  {
    visitors: "1",
    City: "STOCKHOLM",
    zipCode: "11335",
    lat: 59.3429678,
    lng: 18.0282274,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11853",
    lat: 59.3151166,
    lng: 18.0513174,
  },
  {
    visitors: "1",
    City: "Solna",
    zipCode: "16957",
    lat: 59.3666176,
    lng: 18.0032692,
  },
  {
    visitors: "4",
    City: "Skänninge",
    zipCode: "59692",
    lat: 58.41947009999999,
    lng: 15.1532963,
  },
  {
    visitors: "1",
    City: "Skänninge",
    zipCode: "59692",
    lat: 58.41947009999999,
    lng: 15.1532963,
  },
  {
    visitors: "2",
    City: "Nacka",
    zipCode: "13165",
    lat: 59.3023385,
    lng: 18.1171117,
  },
  {
    visitors: "1",
    City: "Malmö",
    zipCode: "21438",
    lat: 55.59542769999999,
    lng: 13.0258558,
  },
  {
    visitors: "1",
    City: "Bromma",
    zipCode: "16841",
    lat: 59.33068939999999,
    lng: 17.9027637,
  },
  {
    visitors: "1",
    City: "TRÅNGSUND",
    zipCode: "14263",
    lat: 59.22765270000001,
    lng: 18.1450474,
  },
  {
    visitors: "3",
    City: "Hägersten",
    zipCode: "12630",
    lat: 59.293983,
    lng: 18.0144242,
  },
  {
    visitors: "1",
    City: "Solna",
    zipCode: "16937",
    lat: 59.369272,
    lng: 17.984629,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11326",
    lat: 59.34433610000001,
    lng: 18.044696,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "10465",
    lat: 59.320134,
    lng: 18.0696408,
  },
  {
    visitors: "2",
    City: "Årsta",
    zipCode: "12038",
    lat: 59.2980063,
    lng: 18.0669126,
  },
  {
    visitors: "1",
    City: "Hässelby",
    zipCode: "16563",
    lat: 59.36400099999999,
    lng: 17.8328304,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11858",
    lat: 59.3092637,
    lng: 18.0714195,
  },
  {
    visitors: "1",
    City: "Kista",
    zipCode: "16441",
    lat: 59.4034596,
    lng: 17.9377849,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11737",
    lat: 59.31781279999999,
    lng: 18.0285502,
  },
  {
    visitors: "1",
    City: "Handen",
    zipCode: "13637",
    lat: 59.16095869999999,
    lng: 18.1358015,
  },
  {
    visitors: "1",
    City: "JOHANNESHOV",
    zipCode: "12153",
    lat: 59.2900858,
    lng: 18.1247144,
  },
  {
    visitors: "1",
    City: "Norsborg",
    zipCode: "14573",
    lat: 59.2555772,
    lng: 17.8347862,
  },
  {
    visitors: "1",
    City: "Örebro",
    zipCode: "70342",
    lat: 59.27363279999999,
    lng: 15.1955622,
  },
  {
    visitors: "1",
    City: "Hägersten",
    zipCode: "12628",
    lat: 59.29790389999999,
    lng: 17.9999777,
  },
  {
    visitors: "3",
    City: "Stockholm",
    zipCode: "11849",
    lat: 59.3179366,
    lng: 18.0563075,
  },
  {
    visitors: "1",
    City: "Farsta",
    zipCode: "12341",
    lat: 59.24381169999999,
    lng: 18.0973331,
  },
  {
    visitors: "4",
    City: "Stockholm",
    zipCode: "11532",
    lat: 59.3429221,
    lng: 18.0922798,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "12070",
    lat: 59.30728790000001,
    lng: 18.1111431,
  },
  {
    visitors: "1",
    City: "Solna",
    zipCode: "17075",
    lat: 59.3807761,
    lng: 18.0292368,
  },
  {
    visitors: "1",
    City: "Västra Frölunda",
    zipCode: "42134",
    lat: 57.6572571,
    lng: 11.9282961,
  },
  {
    visitors: "2",
    City: "Vega",
    zipCode: "13649",
    lat: 59.1882045,
    lng: 18.1317212,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "12071",
    lat: 59.3087092,
    lng: 18.1038324,
  },
  {
    visitors: "4",
    City: "Stockholm",
    zipCode: "11350",
    lat: 59.34351079999999,
    lng: 18.0556749,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11265",
    lat: 59.31828170000001,
    lng: 17.9848496,
  },
  {
    visitors: "1",
    City: "Sandarne",
    zipCode: "82673",
    lat: 61.2486518,
    lng: 17.195476,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11353",
    lat: 59.3454055,
    lng: 18.0593376,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11852",
    lat: 59.315929,
    lng: 18.0625382,
  },
  {
    visitors: "1",
    City: "Bromma",
    zipCode: "16733",
    lat: 59.3417837,
    lng: 17.9765132,
  },
  {
    visitors: "2",
    City: "Bromma",
    zipCode: "16868",
    lat: 59.3464846,
    lng: 17.9748442,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11633",
    lat: 59.31377189999999,
    lng: 18.0853919,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11426",
    lat: 59.3438229,
    lng: 18.0703998,
  },
  {
    visitors: "1",
    City: "Solna",
    zipCode: "16959",
    lat: 59.36393449999999,
    lng: 18.0064324,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11454",
    lat: 59.33409690000001,
    lng: 18.0863526,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11324",
    lat: 59.3403071,
    lng: 18.0468548,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11639",
    lat: 59.3102435,
    lng: 18.084054,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11440",
    lat: 59.3389628,
    lng: 18.080956,
  },
  {
    visitors: "1",
    City: "SOLNA",
    zipCode: "17150",
    lat: 59.3482803,
    lng: 17.9909718,
  },
  {
    visitors: "3",
    City: "Stockholm",
    zipCode: "11236",
    lat: 59.32949720000001,
    lng: 18.0352885,
  },
  {
    visitors: "1",
    City: "Uppsala",
    zipCode: "75422",
    lat: 59.8712634,
    lng: 17.6459408,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11445",
    lat: 59.3373704,
    lng: 18.0842802,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11445",
    lat: 59.3373704,
    lng: 18.0842802,
  },
  {
    visitors: "1",
    City: "Sollentuna",
    zipCode: "19276",
    lat: 59.4712115,
    lng: 17.8982074,
  },
  {
    visitors: "1",
    City: "Dubai",
    zipCode: "",
    lat: 25.2048493,
    lng: 55.2707828,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11523",
    lat: 59.33356089999999,
    lng: 18.0965827,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11224",
    lat: 59.3293926,
    lng: 18.0503362,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11438",
    lat: 59.34064129999999,
    lng: 18.0796284,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11545",
    lat: 59.3576592,
    lng: 18.0868206,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11445",
    lat: 59.3373704,
    lng: 18.0842802,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11452",
    lat: 59.3341776,
    lng: 18.083192,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11851",
    lat: 59.3169244,
    lng: 18.0529817,
  },
  {
    visitors: "1",
    City: "Farsta",
    zipCode: "12356",
    lat: 59.2557132,
    lng: 18.0817688,
  },
  {
    visitors: "1",
    City: "Burradoo",
    zipCode: "2576",
    lat: -34.5030332,
    lng: 150.4058178,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11453",
    lat: 59.33413720000001,
    lng: 18.0847723,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11522",
    lat: 59.3366653,
    lng: 18.0930966,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "12064",
    lat: 59.30459020000001,
    lng: 18.0988338,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11624",
    lat: 59.3131745,
    lng: 18.0765799,
  },
  {
    visitors: "2",
    City: "Göteborg",
    zipCode: "41658",
    lat: 57.71272820000001,
    lng: 12.0099206,
  },
  {
    visitors: "2",
    City: "Solna",
    zipCode: "16971",
    lat: 59.3697381,
    lng: 18.0100915,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11330",
    lat: 59.3457102,
    lng: 18.0363793,
  },
  {
    visitors: "1",
    City: "Hägersten",
    zipCode: "12628",
    lat: 59.29790389999999,
    lng: 17.9999777,
  },
  {
    visitors: "1",
    City: "Johanneshov",
    zipCode: "12136",
    lat: 59.292824,
    lng: 18.0875031,
  },
  {
    visitors: "1",
    City: "Enskede",
    zipCode: "12247",
    lat: 59.2727321,
    lng: 18.0642114,
  },
  {
    visitors: "1",
    City: "STOCKHOLM",
    zipCode: "11766",
    lat: 59.3159005,
    lng: 18.014591,
  },
  {
    visitors: "1",
    City: "STOCKHOLM",
    zipCode: "11766",
    lat: 59.3159005,
    lng: 18.014591,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11360",
    lat: 59.3393085,
    lng: 18.05384,
  },
  {
    visitors: "1",
    City: "Sundbyberg",
    zipCode: "17464",
    lat: 59.3826621,
    lng: 17.9461835,
  },
  {
    visitors: "1",
    City: "Johanneshov",
    zipCode: "12144",
    lat: 59.2949099,
    lng: 18.0755459,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11830",
    lat: 59.3119331,
    lng: 18.0742087,
  },
  {
    visitors: "1",
    City: "HÄGERSTEN",
    zipCode: "12930",
    lat: 59.2931754,
    lng: 17.9862008,
  },
  {
    visitors: "2",
    City: "Östersund",
    zipCode: "83131",
    lat: 63.1849807,
    lng: 14.6381949,
  },
  {
    visitors: "1",
    City: "Gällivare",
    zipCode: "98239",
    lat: 67.1379,
    lng: 20.6593618,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11629",
    lat: 59.31471440000001,
    lng: 18.0833575,
  },
  {
    visitors: "3",
    City: "STOCKHOLM",
    zipCode: "11739",
    lat: 59.3140818,
    lng: 18.0325367,
  },
  {
    visitors: "1",
    City: "Hägersten",
    zipCode: "12628",
    lat: 59.29790389999999,
    lng: 17.9999777,
  },
  {
    visitors: "2",
    City: "Eskilstuna",
    zipCode: "63343",
    lat: 59.3746076,
    lng: 16.5173311,
  },
  {
    visitors: "1",
    City: "Fürth",
    zipCode: "90765",
    lat: 49.5005653,
    lng: 10.9940158,
  },
  {
    visitors: "1",
    City: "Täby",
    zipCode: "18374",
    lat: 59.4448173,
    lng: 18.0891596,
  },
  {
    visitors: "2",
    City: "Göteborg",
    zipCode: "41255",
    lat: 57.6953846,
    lng: 11.9845071,
  },
  {
    visitors: "1",
    City: "GÖTEBORG",
    zipCode: "41255",
    lat: 57.6953846,
    lng: 11.9845071,
  },
  {
    visitors: "1",
    City: "Enskededalen",
    zipCode: "12132",
    lat: 59.288124,
    lng: 18.0891528,
  },
  {
    visitors: "2",
    City: "Enskede",
    zipCode: "12242",
    lat: 59.2760729,
    lng: 18.0512685,
  },
  {
    visitors: "2",
    City: "Johanneshov",
    zipCode: "12145",
    lat: 59.29921249999999,
    lng: 18.0948304,
  },
  {
    visitors: "1",
    City: "Bromma",
    zipCode: "16761",
    lat: 59.32227719999999,
    lng: 17.9672348,
  },
  {
    visitors: "2",
    City: "Sköndal",
    zipCode: "12866",
    lat: 59.25801149999999,
    lng: 18.1149681,
  },
  {
    visitors: "2",
    City: "Norrköping",
    zipCode: "60371",
    lat: 58.5689314,
    lng: 16.2411066,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11663",
    lat: 59.3092322,
    lng: 18.0807273,
  },
  {
    visitors: "2",
    City: "Nacka",
    zipCode: "13145",
    lat: 59.3135377,
    lng: 18.1969962,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11732",
    lat: 59.3198462,
    lng: 18.040352,
  },
  {
    visitors: "1",
    City: "Sollentuna",
    zipCode: "19146",
    lat: 59.4302185,
    lng: 17.9143775,
  },
  {
    visitors: "2",
    City: "Älvsjö",
    zipCode: "12535",
    lat: 59.2844273,
    lng: 18.0240513,
  },
  {
    visitors: "1",
    City: "Nacka",
    zipCode: "13146",
    lat: 59.32235420000001,
    lng: 18.2106977,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11738",
    lat: 59.31558519999999,
    lng: 18.0272198,
  },
  {
    visitors: "1",
    City: "Årsta",
    zipCode: "12058",
    lat: 59.29982289999999,
    lng: 18.0438234,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11227",
    lat: 59.33123699999999,
    lng: 18.0478438,
  },
  {
    visitors: "2",
    City: "Hägersten",
    zipCode: "12652",
    lat: 59.30729299999999,
    lng: 17.9915032,
  },
  {
    visitors: "2",
    City: "Johanneshov",
    zipCode: "12154",
    lat: 59.29641530000001,
    lng: 18.0755919,
  },
  {
    visitors: "1",
    City: "Göteborg",
    zipCode: "41651",
    lat: 57.716185,
    lng: 12.0338287,
  },
  {
    visitors: "4",
    City: "Stockholm",
    zipCode: "11160",
    lat: 59.3379267,
    lng: 18.0569988,
  },
  {
    visitors: "3",
    City: "Stockholm",
    zipCode: "11446",
    lat: 59.3361436,
    lng: 18.0759595,
  },
  {
    visitors: "1",
    City: "Lidingö",
    zipCode: "18155",
    lat: 59.34440350000001,
    lng: 18.1608684,
  },
  {
    visitors: "2",
    City: "Nacka",
    zipCode: "13165",
    lat: 59.3023385,
    lng: 18.1171117,
  },
  {
    visitors: "1",
    City: "Sundbyberg",
    zipCode: "17269",
    lat: 59.3618675,
    lng: 17.9791475,
  },
  {
    visitors: "2",
    City: "Huskvarna",
    zipCode: "56132",
    lat: 57.7924465,
    lng: 14.26213,
  },
  {
    visitors: "1",
    City: "Hägersten",
    zipCode: "12940",
    lat: 59.305595,
    lng: 17.9433528,
  },
  {
    visitors: "2",
    City: "Johanneshov",
    zipCode: "12136",
    lat: 59.292824,
    lng: 18.0875031,
  },
  {
    visitors: "1",
    City: "Hägersten",
    zipCode: "12636",
    lat: 59.30151129999999,
    lng: 17.9981495,
  },
  {
    visitors: "1",
    City: "Västerhaninge",
    zipCode: "13738",
    lat: 59.11354590000001,
    lng: 18.0909766,
  },
  {
    visitors: "2",
    City: "Bromma",
    zipCode: "16732",
    lat: 59.33772389999999,
    lng: 17.9632213,
  },
  {
    visitors: "1",
    City: "Bromma",
    zipCode: "16732",
    lat: 59.33772389999999,
    lng: 17.9632213,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11859",
    lat: 59.3063213,
    lng: 18.0685889,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11242",
    lat: 59.3320932,
    lng: 18.0301356,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11242",
    lat: 59.3320932,
    lng: 18.0301356,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11242",
    lat: 59.3320932,
    lng: 18.0301356,
  },
  {
    visitors: "1",
    City: "Sundbyberg",
    zipCode: "17230",
    lat: 59.3629989,
    lng: 17.977732,
  },
  {
    visitors: "1",
    City: "Hägersten",
    zipCode: "12649",
    lat: 59.3054783,
    lng: 18.0094417,
  },
  {
    visitors: "1",
    City: "Södertälje",
    zipCode: "15270",
    lat: 59.1919736,
    lng: 17.6429393,
  },
  {
    visitors: "4",
    City: "Solna",
    zipCode: "17152",
    lat: 59.3502895,
    lng: 17.9873113,
  },
  {
    visitors: "1",
    City: "Hägersten",
    zipCode: "12629",
    lat: 59.2974064,
    lng: 18.0034648,
  },
  {
    visitors: "1",
    City: "Sundbyberg",
    zipCode: "17264",
    lat: 59.3602077,
    lng: 17.9685871,
  },
  {
    visitors: "1",
    City: "Valls",
    zipCode: "43800",
    lat: 41.2854449,
    lng: 1.2494589,
  },
  {
    visitors: "3",
    City: "Södertälje",
    zipCode: "15138",
    lat: 59.16755209999999,
    lng: 17.6583856,
  },
  {
    visitors: "1",
    City: "Hägersten",
    zipCode: "12634",
    lat: 59.3022899,
    lng: 18.0109368,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11436",
    lat: 59.33933369999999,
    lng: 18.0744682,
  },
  {
    visitors: "2",
    City: "Jersey City",
    zipCode: "07310-3133",
    lat: 40.7300567,
    lng: -74.03294989999999,
  },
  {
    visitors: "2",
    City: "Hägersten",
    zipCode: "12956",
    lat: 59.2833715,
    lng: 17.9558443,
  },
  {
    visitors: "3",
    City: "Stockholm",
    zipCode: "11446",
    lat: 59.3361436,
    lng: 18.0759595,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11621",
    lat: 59.31552780000001,
    lng: 18.0783302,
  },
  {
    visitors: "2",
    City: "Johanneshov",
    zipCode: "12138",
    lat: 59.29619499999999,
    lng: 18.1003061,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11453",
    lat: 59.33413720000001,
    lng: 18.0847723,
  },
  {
    visitors: "2",
    City: "Hägersten",
    zipCode: "12634",
    lat: 59.3022899,
    lng: 18.0109368,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11621",
    lat: 59.31552780000001,
    lng: 18.0783302,
  },
  {
    visitors: "1",
    City: "Enskede Gård",
    zipCode: "12047",
    lat: 59.2901978,
    lng: 18.0668989,
  },
  {
    visitors: "3",
    City: "Huddinge",
    zipCode: "14151",
    lat: 59.2102383,
    lng: 17.9448751,
  },
  {
    visitors: "1",
    City: "Huddinge",
    zipCode: "14151",
    lat: 59.2102383,
    lng: 17.9448751,
  },
  {
    visitors: "1",
    City: "Huddinge",
    zipCode: "14151",
    lat: 59.2102383,
    lng: 17.9448751,
  },
  {
    visitors: "1",
    City: "Huddinge",
    zipCode: "14151",
    lat: 59.2102383,
    lng: 17.9448751,
  },
  {
    visitors: "1",
    City: "Sundbyberg",
    zipCode: "17234",
    lat: 59.36524049999999,
    lng: 17.9645861,
  },
  {
    visitors: "1",
    City: "Vallauris",
    zipCode: "06220",
    lat: 43.577244,
    lng: 7.055041,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11332",
    lat: 59.3432762,
    lng: 18.0377917,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "12071",
    lat: 59.3087092,
    lng: 18.1038324,
  },
  {
    visitors: "2",
    City: "Nacka",
    zipCode: "13148",
    lat: 59.3151299,
    lng: 18.1730445,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11557",
    lat: 59.3476674,
    lng: 18.0916254,
  },
  {
    visitors: "1",
    City: "Hägersten",
    zipCode: "12939",
    lat: 59.3079797,
    lng: 17.957958,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11536",
    lat: 59.34687340000001,
    lng: 18.0958677,
  },
  {
    visitors: "2",
    City: "Djurhamn",
    zipCode: "13973",
    lat: 59.3,
    lng: 18.683333,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11420",
    lat: 59.34759320000001,
    lng: 18.0622524,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11420",
    lat: 59.34759320000001,
    lng: 18.0622524,
  },
  {
    visitors: "1",
    City: "Lund",
    zipCode: "22222",
    lat: 55.7045458,
    lng: 13.1916855,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11737",
    lat: 59.31781279999999,
    lng: 18.0285502,
  },
  {
    visitors: "2",
    City: "Lidingö",
    zipCode: "18137",
    lat: 59.3507232,
    lng: 18.1325877,
  },
  {
    visitors: "1",
    City: "Spånga",
    zipCode: "16365",
    lat: 59.39853559999999,
    lng: 17.8858631,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11734",
    lat: 59.3163132,
    lng: 18.0364435,
  },
  {
    visitors: "1",
    City: "Norrköping",
    zipCode: "60345",
    lat: 58.58180400000001,
    lng: 16.1841708,
  },
  {
    visitors: "1",
    City: "London",
    zipCode: "E2 0BU",
    lat: 51.5262318,
    lng: -0.0593397,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11825",
    lat: 59.3217552,
    lng: 18.0543176,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11735",
    lat: 59.3167248,
    lng: 18.0309594,
  },
  {
    visitors: "4",
    City: "Stockholm",
    zipCode: "11622",
    lat: 59.3183437,
    lng: 18.0807468,
  },
  {
    visitors: "2",
    City: "Värmdö",
    zipCode: "13936",
    lat: 59.3163663,
    lng: 18.4465719,
  },
  {
    visitors: "4",
    City: "Stockholm",
    zipCode: "11543",
    lat: 59.35304730000001,
    lng: 18.0956333,
  },
  {
    visitors: "1",
    City: "Upplands Väsby",
    zipCode: "19436",
    lat: 59.5265229,
    lng: 17.9151775,
  },
  {
    visitors: "1",
    City: "Johanneshov",
    zipCode: "12147",
    lat: 59.295174,
    lng: 18.0866781,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11730",
    lat: 59.3179512,
    lng: 18.0366939,
  },
  {
    visitors: "1",
    City: "Malmö",
    zipCode: "21129",
    lat: 55.6053029,
    lng: 13.0127906,
  },
  {
    visitors: "3",
    City: "Enskede",
    zipCode: "12240",
    lat: 59.26856259999999,
    lng: 18.0881073,
  },
  {
    visitors: "2",
    City: "Katrineholm",
    zipCode: "64130",
    lat: 58.9943085,
    lng: 16.205483,
  },
  {
    visitors: "1",
    City: "Spoleto",
    zipCode: "06049",
    lat: 42.7404881,
    lng: 12.7378003,
  },
  {
    visitors: "1",
    City: "Nacka",
    zipCode: "13131",
    lat: 59.315617,
    lng: 18.1334547,
  },
  {
    visitors: "1",
    City: "Hägersten",
    zipCode: "12954",
    lat: 59.28631650000001,
    lng: 17.9586597,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11732",
    lat: 59.3198462,
    lng: 18.040352,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11258",
    lat: 59.3271447,
    lng: 18.0087731,
  },
  {
    visitors: "2",
    City: "Ingarö",
    zipCode: "13461",
    lat: 59.2889777,
    lng: 18.3689021,
  },
  {
    visitors: "1",
    City: "Nacka",
    zipCode: "13172",
    lat: 59.317118,
    lng: 18.117826,
  },
  {
    visitors: "1",
    City: "Bromma",
    zipCode: "16848",
    lat: 59.3483673,
    lng: 17.8855834,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11243",
    lat: 59.33270169999999,
    lng: 18.0167517,
  },
  {
    visitors: "1",
    City: "Enskede",
    zipCode: "12262",
    lat: 59.2814884,
    lng: 18.0861468,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11344",
    lat: 59.34756950000001,
    lng: 18.0441996,
  },
  {
    visitors: "1",
    City: "Nacka",
    zipCode: "13141",
    lat: 59.3035431,
    lng: 18.1759789,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11152",
    lat: 59.3289065,
    lng: 18.0641377,
  },
  {
    visitors: "1",
    City: "Tyresö",
    zipCode: "13569",
    lat: 59.21174809999999,
    lng: 18.3879394,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11731",
    lat: 59.3186634,
    lng: 18.0356141,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11646",
    lat: 59.3185448,
    lng: 18.0728497,
  },
  {
    visitors: "1",
    City: "Vuollerim",
    zipCode: "96030",
    lat: 66.4305028,
    lng: 20.606452,
  },
  {
    visitors: "1",
    City: "Nacka",
    zipCode: "13165",
    lat: 59.3023385,
    lng: 18.1171117,
  },
  {
    visitors: "1",
    City: "Nacka",
    zipCode: "13136",
    lat: 59.308732,
    lng: 18.1347586,
  },
  {
    visitors: "2",
    City: "Järfälla",
    zipCode: "17754",
    lat: 59.4144082,
    lng: 17.8524948,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11359",
    lat: 59.3396915,
    lng: 18.0576664,
  },
  {
    visitors: "1",
    City: "Järfälla",
    zipCode: "17743",
    lat: 59.42471449999999,
    lng: 17.8690899,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11624",
    lat: 59.3131745,
    lng: 18.0765799,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11624",
    lat: 59.3131745,
    lng: 18.0765799,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11736",
    lat: 59.3174805,
    lng: 18.0308767,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11249",
    lat: 59.3375856,
    lng: 18.0242345,
  },
  {
    visitors: "1",
    City: "Enskededalen",
    zipCode: "12134",
    lat: 59.2779813,
    lng: 18.1054026,
  },
  {
    visitors: "3",
    City: "Stockholm",
    zipCode: "11227",
    lat: 59.33123699999999,
    lng: 18.0478438,
  },
  {
    visitors: "1",
    City: "ÄLVSJÖ",
    zipCode: "12542",
    lat: 59.2777194,
    lng: 18.0293596,
  },
  {
    visitors: "2",
    City: "Värnamo",
    zipCode: "33142",
    lat: 57.17339939999999,
    lng: 14.0682451,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11245",
    lat: 59.3343961,
    lng: 18.0257303,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11852",
    lat: 59.315929,
    lng: 18.0625382,
  },
  {
    visitors: "2",
    City: "Göteborg",
    zipCode: "41270",
    lat: 57.6914391,
    lng: 12.0143636,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11343",
    lat: 59.3478588,
    lng: 18.0408724,
  },
  {
    visitors: "1",
    City: "Johanneshov",
    zipCode: "12147",
    lat: 59.295174,
    lng: 18.0866781,
  },
  {
    visitors: "1",
    City: "Åkersberga",
    zipCode: "18431",
    lat: 59.48474039999999,
    lng: 18.2960468,
  },
  {
    visitors: "1",
    City: "Järfälla",
    zipCode: "17737",
    lat: 59.426913,
    lng: 17.8455971,
  },
  {
    visitors: "2",
    City: "Älvsjö",
    zipCode: "12573",
    lat: 59.2884782,
    lng: 18.0373359,
  },
  {
    visitors: "4",
    City: "Saltsjö-Boo",
    zipCode: "13232",
    lat: 59.3330868,
    lng: 18.2600677,
  },
  {
    visitors: "2",
    City: "Växjö",
    zipCode: "35253",
    lat: 56.8476831,
    lng: 14.7954325,
  },
  {
    visitors: "1",
    City: "Växjö",
    zipCode: "35253",
    lat: 56.8476831,
    lng: 14.7954325,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11527",
    lat: 59.3381236,
    lng: 18.1322083,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11640",
    lat: 59.31196459999999,
    lng: 18.0837254,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11342",
    lat: 59.34167369999999,
    lng: 18.033383,
  },
  {
    visitors: "2",
    City: "Hägersten",
    zipCode: "12948",
    lat: 59.29448960000001,
    lng: 17.971758,
  },
  {
    visitors: "1",
    City: "Lidingö",
    zipCode: "18129",
    lat: 59.3972701,
    lng: 18.1350896,
  },
  {
    visitors: "2",
    City: "Lidingö",
    zipCode: "18157",
    lat: 59.351915,
    lng: 18.1708978,
  },
  {
    visitors: "2",
    City: "Boca Raton",
    zipCode: "33431-5422",
    lat: 26.3767589,
    lng: -80.1037721,
  },
  {
    visitors: "2",
    City: "Huddinge",
    zipCode: "14139",
    lat: 59.2596728,
    lng: 17.9839052,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11548",
    lat: 59.3621193,
    lng: 18.0946565,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11360",
    lat: 59.3393085,
    lng: 18.05384,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11343",
    lat: 59.3478588,
    lng: 18.0408724,
  },
  {
    visitors: "1",
    City: "STOCKHOLM",
    zipCode: "11352",
    lat: 59.34406019999999,
    lng: 18.0583375,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11634",
    lat: 59.31365819999999,
    lng: 18.097861,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11217",
    lat: 59.3387484,
    lng: 18.0160865,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11457",
    lat: 59.33241839999999,
    lng: 18.0876796,
  },
  {
    visitors: "2",
    City: "Rimbo",
    zipCode: "76292",
    lat: 59.7436995,
    lng: 18.3601496,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11439",
    lat: 59.3356837,
    lng: 18.0778717,
  },
  {
    visitors: "1",
    City: "Bromma",
    zipCode: "16852",
    lat: 59.3358692,
    lng: 17.8924463,
  },
  {
    visitors: "1",
    City: "Gävle",
    zipCode: "80270",
    lat: 60.6537102,
    lng: 17.1122895,
  },
  {
    visitors: "2",
    City: "Enskede",
    zipCode: "12234",
    lat: 59.28191320000001,
    lng: 18.0909642,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11360",
    lat: 59.3393085,
    lng: 18.05384,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11733",
    lat: 59.32239069999999,
    lng: 18.0290508,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11356",
    lat: 59.3475067,
    lng: 18.0602555,
  },
  {
    visitors: "2",
    City: "Djursholm",
    zipCode: "18264",
    lat: 59.39687439999999,
    lng: 18.0650678,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11233",
    lat: 59.33592359999999,
    lng: 18.0358744,
  },
  {
    visitors: "3",
    City: "Stockholm",
    zipCode: "11320",
    lat: 59.3394968,
    lng: 18.0382051,
  },
  {
    visitors: "3",
    City: "Årsta",
    zipCode: "12056",
    lat: 59.2925275,
    lng: 18.0506254,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11622",
    lat: 59.3183437,
    lng: 18.0807468,
  },
  {
    visitors: "1",
    City: "Brandbergen",
    zipCode: "13663",
    lat: 59.1691094,
    lng: 18.1643156,
  },
  {
    visitors: "1",
    City: "Sollentuna",
    zipCode: "19150",
    lat: 59.4475853,
    lng: 17.9336554,
  },
  {
    visitors: "1",
    City: "Solna",
    zipCode: "16939",
    lat: 59.36680390000001,
    lng: 17.9902061,
  },
  {
    visitors: "2",
    City: "Tumba",
    zipCode: "14753",
    lat: 59.1939738,
    lng: 17.8344604,
  },
  {
    visitors: "2",
    City: "Johanneshov",
    zipCode: "12146",
    lat: 59.29829720000001,
    lng: 18.1038021,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11354",
    lat: 59.3502308,
    lng: 18.0555166,
  },
  {
    visitors: "4",
    City: "Stockholm",
    zipCode: "11354",
    lat: 59.3502308,
    lng: 18.0555166,
  },
  {
    visitors: "1",
    City: "Linköping",
    zipCode: "58238",
    lat: 58.41334190000001,
    lng: 15.6039469,
  },
  {
    visitors: "2",
    City: "Sollentuna",
    zipCode: "19141",
    lat: 59.412824,
    lng: 17.9530842,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11665",
    lat: 59.3096489,
    lng: 18.0778194,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11635",
    lat: 59.3128091,
    lng: 18.0882159,
  },
  {
    visitors: "1",
    City: "Vällingby",
    zipCode: "16244",
    lat: 59.3774211,
    lng: 17.8510364,
  },
  {
    visitors: "2",
    City: "Vällingby",
    zipCode: "16244",
    lat: 59.3774211,
    lng: 17.8510364,
  },
  {
    visitors: "1",
    City: "Sundbyberg",
    zipCode: "17448",
    lat: 59.3792497,
    lng: 17.9353557,
  },
  {
    visitors: "1",
    City: "Sundbyberg",
    zipCode: "17448",
    lat: 59.3792497,
    lng: 17.9353557,
  },
  {
    visitors: "2",
    City: "Sundbyberg",
    zipCode: "17448",
    lat: 59.3792497,
    lng: 17.9353557,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11738",
    lat: 59.31558519999999,
    lng: 18.0272198,
  },
  {
    visitors: "1",
    City: "Trollhättan",
    zipCode: "46151",
    lat: 58.27559590000001,
    lng: 12.2930964,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11628",
    lat: 59.3181016,
    lng: 18.0902238,
  },
  {
    visitors: "2",
    City: "Tyresö",
    zipCode: "13542",
    lat: 59.23907209999999,
    lng: 18.2513442,
  },
  {
    visitors: "1",
    City: "Göteborg",
    zipCode: "41722",
    lat: 57.721312,
    lng: 11.9466024,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11329",
    lat: 59.3409434,
    lng: 18.051513,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11243",
    lat: 59.33270169999999,
    lng: 18.0167517,
  },
  {
    visitors: "1",
    City: "Nyköping",
    zipCode: "85080",
    lat: 58.75284389999999,
    lng: 17.0091593,
  },
  {
    visitors: "1",
    City: "Linköping",
    zipCode: "58233",
    lat: 58.4147475,
    lng: 15.6117495,
  },
  {
    visitors: "1",
    City: "Visby",
    zipCode: "62143",
    lat: 57.6333144,
    lng: 18.3168804,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "16854",
    lat: 59.346698,
    lng: 17.9209847,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11124",
    lat: 59.3364515,
    lng: 18.053005,
  },
  {
    visitors: "1",
    City: "Göteborg",
    zipCode: "41455",
    lat: 57.69450459999999,
    lng: 11.9184883,
  },
  {
    visitors: "1",
    City: "Saltsjö-Boo",
    zipCode: "13230",
    lat: 59.3268743,
    lng: 18.2566877,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11529",
    lat: 59.3422116,
    lng: 18.0959392,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11631",
    lat: 59.3114214,
    lng: 18.0862177,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11221",
    lat: 59.32820659999999,
    lng: 18.0430189,
  },
  {
    visitors: "1",
    City: "Karlsborg",
    zipCode: "54695",
    lat: 58.6913121,
    lng: 14.4830968,
  },
  {
    visitors: "2",
    City: "Upplands Väsby",
    zipCode: "19449",
    lat: 59.5175111,
    lng: 17.9130815,
  },
  {
    visitors: "1",
    City: "Upplands Väsby",
    zipCode: "19449",
    lat: 59.5175111,
    lng: 17.9130815,
  },
  {
    visitors: "1",
    City: "Upplands Väsby",
    zipCode: "19449",
    lat: 59.5175111,
    lng: 17.9130815,
  },
  {
    visitors: "1",
    City: "Tyresö",
    zipCode: "13544",
    lat: 59.236394,
    lng: 18.2287399,
  },
  {
    visitors: "2",
    City: "Årsta",
    zipCode: "12055",
    lat: 59.2963017,
    lng: 18.0476402,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11623",
    lat: 59.3125021,
    lng: 18.0760798,
  },
  {
    visitors: "1",
    City: "Bandhagen",
    zipCode: "12462",
    lat: 59.25557240000001,
    lng: 18.0386337,
  },
  {
    visitors: "1",
    City: "Bandhagen",
    zipCode: "12462",
    lat: 59.25557240000001,
    lng: 18.0386337,
  },
  {
    visitors: "2",
    City: "Solna",
    zipCode: "17076",
    lat: 59.3809576,
    lng: 18.0383963,
  },
  {
    visitors: "2",
    City: "stockholm",
    zipCode: "11357",
    lat: 59.34272139999999,
    lng: 18.062495,
  },
  {
    visitors: "1",
    City: "Malmö",
    zipCode: "21433",
    lat: 55.5957521,
    lng: 13.022847,
  },
  {
    visitors: "1",
    City: "Hägersten",
    zipCode: "12628",
    lat: 59.29790389999999,
    lng: 17.9999777,
  },
  {
    visitors: "1",
    City: "Sundbyberg",
    zipCode: "17279",
    lat: 59.36875449999999,
    lng: 17.9778073,
  },
  {
    visitors: "1",
    City: "Sundbyberg",
    zipCode: "17279",
    lat: 59.36875449999999,
    lng: 17.9778073,
  },
  {
    visitors: "1",
    City: "Sundbyberg",
    zipCode: "17279",
    lat: 59.36875449999999,
    lng: 17.9778073,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11246",
    lat: 59.336545,
    lng: 18.0302207,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11246",
    lat: 59.336545,
    lng: 18.0302207,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11258",
    lat: 59.3271447,
    lng: 18.0087731,
  },
  {
    visitors: "3",
    City: "Stockholm",
    zipCode: "11247",
    lat: 59.3357892,
    lng: 18.0303035,
  },
  {
    visitors: "1",
    City: "STOCKHOLM",
    zipCode: "11321",
    lat: 59.33727640000001,
    lng: 18.0420287,
  },
  {
    visitors: "4",
    City: "Stockholm",
    zipCode: "11355",
    lat: 59.3464977,
    lng: 18.0595054,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11342",
    lat: 59.34167369999999,
    lng: 18.033383,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11769",
    lat: 59.3149276,
    lng: 17.9875107,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11222",
    lat: 59.328492,
    lng: 18.0371168,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11237",
    lat: 59.32907,
    lng: 18.0304668,
  },
  {
    visitors: "1",
    City: "Visby",
    zipCode: "62154",
    lat: 57.6227366,
    lng: 18.3091385,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11861",
    lat: 59.3054695,
    lng: 18.0589493,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11861",
    lat: 59.3054695,
    lng: 18.0589493,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11622",
    lat: 59.3183437,
    lng: 18.0807468,
  },
  {
    visitors: "1",
    City: "Katrineholm",
    zipCode: "64131",
    lat: 58.993669,
    lng: 16.2162066,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11249",
    lat: 59.3375856,
    lng: 18.0242345,
  },
  {
    visitors: "2",
    City: "Tyresö",
    zipCode: "13563",
    lat: 59.2430803,
    lng: 18.285266,
  },
  {
    visitors: "1",
    City: "Nacka",
    zipCode: "13146",
    lat: 59.32235420000001,
    lng: 18.2106977,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11737",
    lat: 59.31781279999999,
    lng: 18.0285502,
  },
  {
    visitors: "1",
    City: "Lidingö",
    zipCode: "18161",
    lat: 59.3398201,
    lng: 18.1721703,
  },
  {
    visitors: "2",
    City: "Älvsjö",
    zipCode: "12547",
    lat: 59.27949539999999,
    lng: 18.0034188,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11544",
    lat: 59.35434199999999,
    lng: 18.0878954,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11737",
    lat: 59.31781279999999,
    lng: 18.0285502,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11215",
    lat: 59.338047,
    lng: 18.0050293,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11537",
    lat: 59.3473789,
    lng: 18.0975334,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11537",
    lat: 59.3473789,
    lng: 18.0975334,
  },
  {
    visitors: "1",
    City: "Vimmerby",
    zipCode: "59835",
    lat: 57.6701343,
    lng: 15.8661176,
  },
  {
    visitors: "1",
    City: "Sundbyberg",
    zipCode: "17277",
    lat: 59.3696606,
    lng: 17.9688209,
  },
  {
    visitors: "2",
    City: "Johanneshov",
    zipCode: "12137",
    lat: 59.2952652,
    lng: 18.093823,
  },
  {
    visitors: "1",
    City: "Johanneshov",
    zipCode: "12137",
    lat: 59.2952652,
    lng: 18.093823,
  },
  {
    visitors: "3",
    City: "Älvsjö",
    zipCode: "12551",
    lat: 59.2677434,
    lng: 17.9898699,
  },
  {
    visitors: "1",
    City: "Bandhagen",
    zipCode: "12456",
    lat: 59.264066,
    lng: 18.0494271,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11458",
    lat: 59.3369938,
    lng: 18.0856104,
  },
  {
    visitors: "1",
    City: "Enskede",
    zipCode: "12265",
    lat: 59.2737559,
    lng: 18.0778264,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11362",
    lat: 59.3414715,
    lng: 18.0387054,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11444",
    lat: 59.33762059999999,
    lng: 18.0825338,
  },
  {
    visitors: "3",
    City: "Stockholm",
    zipCode: "11535",
    lat: 59.3450277,
    lng: 18.0983595,
  },
  {
    visitors: "1",
    City: "Danderyd",
    zipCode: "18230",
    lat: 59.3882659,
    lng: 18.0367343,
  },
  {
    visitors: "1",
    City: "Tumba",
    zipCode: "14740",
    lat: 59.2033599,
    lng: 17.831104,
  },
  {
    visitors: "1",
    City: "Sundbyberg",
    zipCode: "17230",
    lat: 59.3629989,
    lng: 17.977732,
  },
  {
    visitors: "1",
    City: "Thurles",
    zipCode: "E41 YH29",
    lat: 52.6909941,
    lng: -7.713194400000001,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11245",
    lat: 59.3343961,
    lng: 18.0257303,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11225",
    lat: 59.33027149999999,
    lng: 18.0480923,
  },
  {
    visitors: "1",
    City: "Solna",
    zipCode: "16968",
    lat: 59.36545539999999,
    lng: 18.0114243,
  },
  {
    visitors: "1",
    City: "Bagarmossen",
    zipCode: "12848",
    lat: 59.2780101,
    lng: 18.1465992,
  },
  {
    visitors: "1",
    City: "Bagarmossen",
    zipCode: "12848",
    lat: 59.2780101,
    lng: 18.1465992,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11771",
    lat: 59.3167961,
    lng: 17.9986892,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11432",
    lat: 59.3398337,
    lng: 18.0709753,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11543",
    lat: 59.35304730000001,
    lng: 18.0956333,
  },
  {
    visitors: "3",
    City: "Stockholm",
    zipCode: "11728",
    lat: 59.3176261,
    lng: 18.044173,
  },
  {
    visitors: "1",
    City: "Hässelby",
    zipCode: "16565",
    lat: 59.3660614,
    lng: 17.820854,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11852",
    lat: 59.315929,
    lng: 18.0625382,
  },
  {
    visitors: "1",
    City: "Malmö",
    zipCode: "21429",
    lat: 55.58678699999999,
    lng: 13.007839,
  },
  {
    visitors: "2",
    City: "Bandhagen",
    zipCode: "12430",
    lat: 59.2643329,
    lng: 18.0605456,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11853",
    lat: 59.3151166,
    lng: 18.0513174,
  },
  {
    visitors: "1",
    City: "Skanör",
    zipCode: "23930",
    lat: 55.4100741,
    lng: 12.852037,
  },
  {
    visitors: "5",
    City: "Stockholm",
    zipCode: "11365",
    lat: 59.3485241,
    lng: 18.0362146,
  },
  {
    visitors: "1",
    City: "Nacka",
    zipCode: "13135",
    lat: 59.3082204,
    lng: 18.1227845,
  },
  {
    visitors: "2",
    City: "Eindhoven",
    zipCode: "5644 BZ",
    lat: 51.4152427,
    lng: 5.4870055,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11234",
    lat: 59.3352905,
    lng: 18.0337954,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11533",
    lat: 59.34321780000001,
    lng: 18.0941111,
  },
  {
    visitors: "1",
    City: "Sundbyberg",
    zipCode: "17460",
    lat: 59.3795925,
    lng: 17.9381834,
  },
  {
    visitors: "4",
    City: "Stockholm",
    zipCode: "12066",
    lat: 59.30291659999999,
    lng: 18.1053115,
  },
  {
    visitors: "1",
    City: "Lidingö",
    zipCode: "18139",
    lat: 59.34871450000001,
    lng: 18.1465674,
  },
  {
    visitors: "2",
    City: "Hägersten",
    zipCode: "12677",
    lat: 59.2829776,
    lng: 18.0107715,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11730",
    lat: 59.3179512,
    lng: 18.0366939,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11454",
    lat: 59.33409690000001,
    lng: 18.0863526,
  },
  {
    visitors: "1",
    City: "Solna",
    zipCode: "16958",
    lat: 59.36422280000001,
    lng: 18.0031042,
  },
  {
    visitors: "1",
    City: "Santa Monica",
    zipCode: "90405-4857",
    lat: 34.00974600000001,
    lng: -118.4659576,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11261",
    lat: 59.32516560000001,
    lng: 18.0056989,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11540",
    lat: 59.3465891,
    lng: 18.1069357,
  },
  {
    visitors: "4",
    City: "Malmö",
    zipCode: "21151",
    lat: 55.5991233,
    lng: 13.0062938,
  },
  {
    visitors: "1",
    City: "Malmö",
    zipCode: "21151",
    lat: 55.5991233,
    lng: 13.0062938,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11826",
    lat: 59.3156423,
    lng: 18.068439,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11629",
    lat: 59.31471440000001,
    lng: 18.0833575,
  },
  {
    visitors: "1",
    City: "Bromma",
    zipCode: "16844",
    lat: 59.3487101,
    lng: 17.8594983,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11734",
    lat: 59.3163132,
    lng: 18.0364435,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11827",
    lat: 59.311614,
    lng: 18.0705928,
  },
  {
    visitors: "3",
    City: "Stockholm",
    zipCode: "11640",
    lat: 59.31196459999999,
    lng: 18.0837254,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11360",
    lat: 59.3393085,
    lng: 18.05384,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11458",
    lat: 59.3369938,
    lng: 18.0856104,
  },
  {
    visitors: "1",
    City: "Nacka",
    zipCode: "13135",
    lat: 59.3082204,
    lng: 18.1227845,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11625",
    lat: 59.3159875,
    lng: 18.0764192,
  },
  {
    visitors: "1",
    City: "Bandhagen",
    zipCode: "12454",
    lat: 59.2643041,
    lng: 18.0399695,
  },
  {
    visitors: "2",
    City: "Åkersberga",
    zipCode: "18433",
    lat: 59.514937,
    lng: 18.2909373,
  },
  {
    visitors: "1",
    City: "Trosa",
    zipCode: "61934",
    lat: 58.9066956,
    lng: 17.5316616,
  },
  {
    visitors: "1",
    City: "Sollentuna",
    zipCode: "19256",
    lat: 59.43644740000001,
    lng: 17.9963833,
  },
  {
    visitors: "1",
    City: "Hägersten",
    zipCode: "12628",
    lat: 59.29790389999999,
    lng: 17.9999777,
  },
  {
    visitors: "1",
    City: "Sollentuna",
    zipCode: "19272",
    lat: 59.4643713,
    lng: 17.920256,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11520",
    lat: 59.33817699999999,
    lng: 18.0929339,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11823",
    lat: 59.31906499999999,
    lng: 18.0523194,
  },
  {
    visitors: "1",
    City: "Solna",
    zipCode: "16960",
    lat: 59.359652,
    lng: 18.0077653,
  },
  {
    visitors: "2",
    City: "Skanör",
    zipCode: "23932",
    lat: 55.4103497,
    lng: 12.8626481,
  },
  {
    visitors: "2",
    City: "Älvsjö",
    zipCode: "12573",
    lat: 59.2884782,
    lng: 18.0373359,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11661",
    lat: 59.30814040000001,
    lng: 18.0805588,
  },
  {
    visitors: "2",
    City: "Sollentuna",
    zipCode: "19277",
    lat: 59.4795467,
    lng: 17.8708065,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11336",
    lat: 59.3394061,
    lng: 18.0336313,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11245",
    lat: 59.3343961,
    lng: 18.0257303,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11443",
    lat: 59.340141,
    lng: 18.0831215,
  },
  {
    visitors: "2",
    City: "Västra Frölunda",
    zipCode: "42651",
    lat: 57.6561669,
    lng: 11.8944908,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11139",
    lat: 59.338359,
    lng: 18.0669802,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11739",
    lat: 59.3140818,
    lng: 18.0325367,
  },
  {
    visitors: "1",
    City: "Handen",
    zipCode: "13641",
    lat: 59.1585358,
    lng: 18.150024,
  },
  {
    visitors: "1",
    City: "Berlicum Nb",
    zipCode: "5258 GB",
    lat: 51.67848859999999,
    lng: 5.4045519,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11528",
    lat: 59.3424775,
    lng: 18.1122499,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11442",
    lat: 59.3361895,
    lng: 18.0795363,
  },
  {
    visitors: "1",
    City: "Solna",
    zipCode: "17170",
    lat: 59.3523277,
    lng: 17.9991184,
  },
  {
    visitors: "1",
    City: "Varniu sen",
    zipCode: "LT-88267",
    lat: 55.8079962,
    lng: 22.3967203,
  },
  {
    visitors: "1",
    City: "Årsta",
    zipCode: "12051",
    lat: 59.3016082,
    lng: 18.0599405,
  },
  {
    visitors: "2",
    City: "Uppsala",
    zipCode: "75331",
    lat: 59.8609617,
    lng: 17.6449055,
  },
  {
    visitors: "1",
    City: "STOCKHOLM",
    zipCode: "11336",
    lat: 59.3394061,
    lng: 18.0336313,
  },
  {
    visitors: "1",
    City: "Lidingö",
    zipCode: "18139",
    lat: 59.34871450000001,
    lng: 18.1465674,
  },
  {
    visitors: "1",
    City: "Bandhagen",
    zipCode: "12452",
    lat: 59.26656629999999,
    lng: 18.037151,
  },
  {
    visitors: "2",
    City: "Enskede",
    zipCode: "12246",
    lat: 59.26502409999999,
    lng: 18.0764794,
  },
  {
    visitors: "1",
    City: "Bromma",
    zipCode: "16848",
    lat: 59.3483673,
    lng: 17.8855834,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11641",
    lat: 59.3114746,
    lng: 18.0975226,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11533",
    lat: 59.34321780000001,
    lng: 18.0941111,
  },
  {
    visitors: "1",
    City: "Johanneshov",
    zipCode: "12145",
    lat: 59.29921249999999,
    lng: 18.0948304,
  },
  {
    visitors: "4",
    City: "Visby",
    zipCode: "62157",
    lat: 57.6379348,
    lng: 18.2859842,
  },
  {
    visitors: "1",
    City: "Skärholmen",
    zipCode: "12734",
    lat: 59.2936588,
    lng: 17.9384014,
  },
  {
    visitors: "1",
    City: "Skärholmen",
    zipCode: "12734",
    lat: 59.2936588,
    lng: 17.9384014,
  },
  {
    visitors: "3",
    City: "Stockholm",
    zipCode: "11334",
    lat: 59.34465369999999,
    lng: 18.0320538,
  },
  {
    visitors: "2",
    City: "Bagarmossen",
    zipCode: "12847",
    lat: 59.2827044,
    lng: 18.1346562,
  },
  {
    visitors: "1",
    City: "Järfälla",
    zipCode: "17745",
    lat: 59.41108509999999,
    lng: 17.8654968,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11225",
    lat: 59.33027149999999,
    lng: 18.0480923,
  },
  {
    visitors: "4",
    City: "Djursholm",
    zipCode: "18266",
    lat: 59.408308,
    lng: 18.0684174,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11230",
    lat: 59.33273399999999,
    lng: 18.037369,
  },
  {
    visitors: "2",
    City: "Johanneshov",
    zipCode: "12148",
    lat: 59.2933395,
    lng: 18.0994673,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11333",
    lat: 59.3436049,
    lng: 18.0328849,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "12068",
    lat: 59.30501880000001,
    lng: 18.1088086,
  },
  {
    visitors: "2",
    City: "Hägersten",
    zipCode: "12945",
    lat: 59.2954052,
    lng: 17.967939,
  },
  {
    visitors: "1",
    City: "Hägersten",
    zipCode: "12647",
    lat: 59.30310060000001,
    lng: 17.9948274,
  },
  {
    visitors: "1",
    City: "Järfälla",
    zipCode: "17753",
    lat: 59.4175941,
    lng: 17.8364961,
  },
  {
    visitors: "1",
    City: "Sollentuna",
    zipCode: "19150",
    lat: 59.4475853,
    lng: 17.9336554,
  },
  {
    visitors: "1",
    City: "Norrköping",
    zipCode: "60365",
    lat: 58.6140114,
    lng: 16.2559725,
  },
  {
    visitors: "1",
    City: "Luleå",
    zipCode: "97443",
    lat: 65.60110949999999,
    lng: 22.2121334,
  },
  {
    visitors: "1",
    City: "Ludvika",
    zipCode: "77133",
    lat: 60.14346889999999,
    lng: 15.1826672,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11530",
    lat: 59.3428362,
    lng: 18.0902826,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11530",
    lat: 59.3428362,
    lng: 18.0902826,
  },
  {
    visitors: "4",
    City: "Stockholm",
    zipCode: "11736",
    lat: 59.3174805,
    lng: 18.0308767,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11251",
    lat: 59.3389931,
    lng: 18.0117631,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11756",
    lat: 59.3036688,
    lng: 18.0325305,
  },
  {
    visitors: "2",
    City: "Skellefteå",
    zipCode: "93134",
    lat: 64.7489377,
    lng: 20.965913,
  },
  {
    visitors: "1",
    City: "Vårby",
    zipCode: "14346",
    lat: 59.2691081,
    lng: 17.8651957,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11362",
    lat: 59.3414715,
    lng: 18.0387054,
  },
  {
    visitors: "1",
    City: "Täby",
    zipCode: "18339",
    lat: 59.44340330000001,
    lng: 18.0690483,
  },
  {
    visitors: "1",
    City: "Täby",
    zipCode: "18339",
    lat: 59.44340330000001,
    lng: 18.0690483,
  },
  {
    visitors: "1",
    City: "Enskededalen",
    zipCode: "12131",
    lat: 59.2856124,
    lng: 18.0962892,
  },
  {
    visitors: "1",
    City: "Göteborg",
    zipCode: "41327",
    lat: 57.7003398,
    lng: 11.9440083,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11448",
    lat: 59.33879330000001,
    lng: 18.0795415,
  },
  {
    visitors: "2",
    City: "STOCKHOLM",
    zipCode: "12069",
    lat: 59.30393129999999,
    lng: 18.1137923,
  },
  {
    visitors: "1",
    City: "Nacka",
    zipCode: "13136",
    lat: 59.308732,
    lng: 18.1347586,
  },
  {
    visitors: "2",
    City: "Nacka",
    zipCode: "13136",
    lat: 59.308732,
    lng: 18.1347586,
  },
  {
    visitors: "1",
    City: "Lidingö",
    zipCode: "18143",
    lat: 59.37879109999999,
    lng: 18.1496904,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11364",
    lat: 59.34498619999999,
    lng: 18.0297252,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11768",
    lat: 59.31461539999999,
    lng: 18.0001365,
  },
  {
    visitors: "1",
    City: "Falun",
    zipCode: "79173",
    lat: 60.6088478,
    lng: 15.6194098,
  },
  {
    visitors: "1",
    City: "Linköping",
    zipCode: "58215",
    lat: 58.4036263,
    lng: 15.6011767,
  },
  {
    visitors: "1",
    City: "Venice",
    zipCode: "90291-4608",
    lat: 33.9962144,
    lng: -118.4688776,
  },
  {
    visitors: "1",
    City: "Älvsjö",
    zipCode: "12531",
    lat: 59.2792832,
    lng: 18.0106056,
  },
  {
    visitors: "1",
    City: "Älvsjö",
    zipCode: "12531",
    lat: 59.2792832,
    lng: 18.0106056,
  },
  {
    visitors: "3",
    City: "Stockholm",
    zipCode: "11323",
    lat: 59.34420579999999,
    lng: 18.0417015,
  },
  {
    visitors: "6",
    City: "Stockholm",
    zipCode: "11338",
    lat: 59.3419191,
    lng: 18.0290587,
  },
  {
    visitors: "6",
    City: "Stockholm",
    zipCode: "11338",
    lat: 59.3419191,
    lng: 18.0290587,
  },
  {
    visitors: "1",
    City: "Uppsala",
    zipCode: "75428",
    lat: 59.88130880000001,
    lng: 17.6461365,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11330",
    lat: 59.3457102,
    lng: 18.0363793,
  },
  {
    visitors: "1",
    City: "Norrköping",
    zipCode: "60361",
    lat: 58.5810351,
    lng: 16.2270346,
  },
  {
    visitors: "1",
    City: "Gustavsberg",
    zipCode: "13436",
    lat: 59.3304061,
    lng: 18.3963901,
  },
  {
    visitors: "1",
    City: "Enskede",
    zipCode: "12234",
    lat: 59.28191320000001,
    lng: 18.0909642,
  },
  {
    visitors: "1",
    City: "Farsta",
    zipCode: "12361",
    lat: 59.2548802,
    lng: 18.0875745,
  },
  {
    visitors: "1",
    City: "Farsta",
    zipCode: "12343",
    lat: 59.23837020000001,
    lng: 18.1119141,
  },
  {
    visitors: "2",
    City: "Solna",
    zipCode: "16958",
    lat: 59.36422280000001,
    lng: 18.0031042,
  },
  {
    visitors: "2",
    City: "Enskede",
    zipCode: "12234",
    lat: 59.28191320000001,
    lng: 18.0909642,
  },
  {
    visitors: "5",
    City: "Johanneshov",
    zipCode: "12148",
    lat: 59.2933395,
    lng: 18.0994673,
  },
  {
    visitors: "1",
    City: "Johanneshov",
    zipCode: "12148",
    lat: 59.2933395,
    lng: 18.0994673,
  },
  {
    visitors: "2",
    City: "Johanneshov",
    zipCode: "12148",
    lat: 59.2933395,
    lng: 18.0994673,
  },
  {
    visitors: "1",
    City: "SUNDBYBERG",
    zipCode: "17264",
    lat: 59.3602077,
    lng: 17.9685871,
  },
  {
    visitors: "4",
    City: "Åby",
    zipCode: "61634",
    lat: 58.6557833,
    lng: 16.1486817,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11638",
    lat: 59.31045859999999,
    lng: 18.0890415,
  },
  {
    visitors: "3",
    City: "Stockholm",
    zipCode: "11326",
    lat: 59.34433610000001,
    lng: 18.044696,
  },
  {
    visitors: "1",
    City: "Sundbyberg",
    zipCode: "17464",
    lat: 59.3826621,
    lng: 17.9461835,
  },
  {
    visitors: "2",
    City: "Linköping",
    zipCode: "58221",
    lat: 58.4181236,
    lng: 15.6198852,
  },
  {
    visitors: "1",
    City: "Helsingborg",
    zipCode: "25443",
    lat: 56.0615248,
    lng: 12.6948327,
  },
  {
    visitors: "1",
    City: "Hägersten",
    zipCode: "12652",
    lat: 59.30729299999999,
    lng: 17.9915032,
  },
  {
    visitors: "3",
    City: "Nyköping",
    zipCode: "61130",
    lat: 58.75314529999999,
    lng: 16.9996238,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11261",
    lat: 59.32516560000001,
    lng: 18.0056989,
  },
  {
    visitors: "1",
    City: "Upplands Väsby",
    zipCode: "19433",
    lat: 59.5167514,
    lng: 17.9105376,
  },
  {
    visitors: "2",
    City: "Nacka",
    zipCode: "13142",
    lat: 59.31026259999999,
    lng: 18.1913199,
  },
  {
    visitors: "1",
    City: "Sundbyberg",
    zipCode: "17270",
    lat: 59.3624863,
    lng: 17.9734906,
  },
  {
    visitors: "3",
    City: "Söderby",
    zipCode: "13665",
    lat: 59.18554879999999,
    lng: 18.1699371,
  },
  {
    visitors: "1",
    City: "Lidingö",
    zipCode: "18146",
    lat: 59.37341960000001,
    lng: 18.1663352,
  },
  {
    visitors: "1",
    City: "Göteborg",
    zipCode: "41729",
    lat: 57.74777189999999,
    lng: 11.8916457,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11533",
    lat: 59.34321780000001,
    lng: 18.0941111,
  },
  {
    visitors: "1",
    City: "Olhão",
    zipCode: "8700-240",
    lat: 37.0360453,
    lng: -7.840113000000001,
  },
  {
    visitors: "1",
    City: "Saltsjöbaden",
    zipCode: "13344",
    lat: 59.2887553,
    lng: 18.2577207,
  },
  {
    visitors: "1",
    City: "Hägersten",
    zipCode: "12677",
    lat: 59.2829776,
    lng: 18.0107715,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11849",
    lat: 59.3179366,
    lng: 18.0563075,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11665",
    lat: 59.3096489,
    lng: 18.0778194,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11665",
    lat: 59.3096489,
    lng: 18.0778194,
  },
  {
    visitors: "1",
    City: "Vaxholm",
    zipCode: "18534",
    lat: 59.4062744,
    lng: 18.3307898,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11428",
    lat: 59.35117519999999,
    lng: 18.0723261,
  },
  {
    visitors: "1",
    City: "Linköping",
    zipCode: "58232",
    lat: 58.41637470000001,
    lng: 15.6133707,
  },
  {
    visitors: "1",
    City: "Linköping",
    zipCode: "58252",
    lat: 58.40596439999999,
    lng: 15.6381527,
  },
  {
    visitors: "1",
    City: "Huskvarna",
    zipCode: "56141",
    lat: 57.7837073,
    lng: 14.281472,
  },
  {
    visitors: "1",
    City: "Huskvarna",
    zipCode: "56141",
    lat: 57.7837073,
    lng: 14.281472,
  },
  {
    visitors: "1",
    City: "Årsta",
    zipCode: "12050",
    lat: 59.2948111,
    lng: 18.0632524,
  },
  {
    visitors: "1",
    City: "Uppsala",
    zipCode: "75650",
    lat: 59.80712759999999,
    lng: 17.6269762,
  },
  {
    visitors: "2",
    City: "Årsta",
    zipCode: "12038",
    lat: 59.2980063,
    lng: 18.0669126,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11356",
    lat: 59.3475067,
    lng: 18.0602555,
  },
  {
    visitors: "4",
    City: "Bandhagen",
    zipCode: "12432",
    lat: 59.272704,
    lng: 18.0436291,
  },
  {
    visitors: "2",
    City: "Sollentuna",
    zipCode: "19164",
    lat: 59.4325558,
    lng: 17.9425355,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11321",
    lat: 59.33727640000001,
    lng: 18.0420287,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11233",
    lat: 59.33592359999999,
    lng: 18.0358744,
  },
  {
    visitors: "1",
    City: "Solna",
    zipCode: "16932",
    lat: 59.363048,
    lng: 17.9813087,
  },
  {
    visitors: "2",
    City: "Hägersten",
    zipCode: "12652",
    lat: 59.30729299999999,
    lng: 17.9915032,
  },
  {
    visitors: "2",
    City: "Johanneshov",
    zipCode: "12138",
    lat: 59.29619499999999,
    lng: 18.1003061,
  },
  {
    visitors: "1",
    City: "Bollebygd",
    zipCode: "51736",
    lat: 57.67107249999999,
    lng: 12.572131,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11326",
    lat: 59.34433610000001,
    lng: 18.044696,
  },
  {
    visitors: "1",
    City: "Tullinge",
    zipCode: "14634",
    lat: 59.17593069999999,
    lng: 17.9145221,
  },
  {
    visitors: "2",
    City: "Bandhagen",
    zipCode: "12453",
    lat: 59.2628726,
    lng: 18.0369817,
  },
  {
    visitors: "1",
    City: "Sollentuna",
    zipCode: "19248",
    lat: 59.46296160000001,
    lng: 17.9669794,
  },
  {
    visitors: "1",
    City: "Örebro",
    zipCode: "70218",
    lat: 59.2541731,
    lng: 15.225979,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11437",
    lat: 59.340808,
    lng: 18.078464,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11321",
    lat: 59.33727640000001,
    lng: 18.0420287,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11631",
    lat: 59.3114214,
    lng: 18.0862177,
  },
  {
    visitors: "1",
    City: "Hässelby",
    zipCode: "16558",
    lat: 59.36828209999999,
    lng: 17.8314751,
  },
  {
    visitors: "1",
    City: "Gustavsberg",
    zipCode: "13431",
    lat: 59.328938,
    lng: 18.3706928,
  },
  {
    visitors: "8",
    City: "Stockholm",
    zipCode: "11847",
    lat: 59.3174468,
    lng: 18.0675278,
  },
  {
    visitors: "1",
    City: "Bromma",
    zipCode: "16833",
    lat: 59.3303855,
    lng: 17.949443,
  },
  {
    visitors: "1",
    City: "Bromma",
    zipCode: "16751",
    lat: 59.3303737,
    lng: 17.9835081,
  },
  {
    visitors: "1",
    City: "Nyköping",
    zipCode: "61164",
    lat: 58.7441736,
    lng: 17.0493951,
  },
  {
    visitors: "5",
    City: "Lidingö",
    zipCode: "18165",
    lat: 59.34519659999999,
    lng: 18.2175044,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11447",
    lat: 59.3374698,
    lng: 18.0790397,
  },
  {
    visitors: "2",
    City: "Sollentuna",
    zipCode: "19134",
    lat: 59.42002179999999,
    lng: 17.9390709,
  },
  {
    visitors: "2",
    City: "Hägersten",
    zipCode: "12933",
    lat: 59.3001462,
    lng: 17.986859,
  },
  {
    visitors: "1",
    City: "Sollentuna",
    zipCode: "19269",
    lat: 59.48115840000001,
    lng: 17.9068425,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11550",
    lat: 59.3456448,
    lng: 18.0849637,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11152",
    lat: 59.3289065,
    lng: 18.0641377,
  },
  {
    visitors: "4",
    City: "Borås",
    zipCode: "50451",
    lat: 57.7144317,
    lng: 12.9479174,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11353",
    lat: 59.3454055,
    lng: 18.0593376,
  },
  {
    visitors: "2",
    City: "Sollentuna",
    zipCode: "19251",
    lat: 59.44042990000001,
    lng: 17.9683594,
  },
  {
    visitors: "2",
    City: "Danderyd",
    zipCode: "18257",
    lat: 59.39279549999999,
    lng: 18.0310718,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11856",
    lat: 59.31006250000001,
    lng: 18.0723352,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11247",
    lat: 59.3357892,
    lng: 18.0303035,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11531",
    lat: 59.34258080000001,
    lng: 18.0868707,
  },
  {
    visitors: "1",
    City: "STOCKHOLM",
    zipCode: "11761",
    lat: 59.31138329999999,
    lng: 18.0253904,
  },
  {
    visitors: "1",
    City: "STOCKHOLM",
    zipCode: "11325",
    lat: 59.34060359999999,
    lng: 18.0486848,
  },
  {
    visitors: "1",
    City: "Lidingö",
    zipCode: "18167",
    lat: 59.3532618,
    lng: 18.2028922,
  },
  {
    visitors: "2",
    City: "Lidingö",
    zipCode: "18167",
    lat: 59.3532618,
    lng: 18.2028922,
  },
  {
    visitors: "1",
    City: "Järna",
    zipCode: "15338",
    lat: 59.11343429999999,
    lng: 17.5786783,
  },
  {
    visitors: "1",
    City: "Johanneshov",
    zipCode: "12143",
    lat: 59.2968405,
    lng: 18.0750514,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11733",
    lat: 59.32239069999999,
    lng: 18.0290508,
  },
  {
    visitors: "1",
    City: "Tranås",
    zipCode: "57334",
    lat: 58.0437349,
    lng: 14.9913377,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11245",
    lat: 59.3343961,
    lng: 18.0257303,
  },
  {
    visitors: "3",
    City: "Hjo",
    zipCode: "54430",
    lat: 58.29567110000001,
    lng: 14.2814636,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11862",
    lat: 59.30889029999999,
    lng: 18.0753247,
  },
  {
    visitors: "3",
    City: "Hägersten",
    zipCode: "12647",
    lat: 59.30310060000001,
    lng: 17.9948274,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11827",
    lat: 59.311614,
    lng: 18.0705928,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11827",
    lat: 59.311614,
    lng: 18.0705928,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11328",
    lat: 59.3416126,
    lng: 18.0494344,
  },
  {
    visitors: "1",
    City: "Hässelby",
    zipCode: "16563",
    lat: 59.36400099999999,
    lng: 17.8328304,
  },
  {
    visitors: "2",
    City: "Vendelsö",
    zipCode: "13673",
    lat: 59.20518360000001,
    lng: 18.1873858,
  },
  {
    visitors: "1",
    City: "Hägersten",
    zipCode: "12931",
    lat: 59.29174210000001,
    lng: 17.9832141,
  },
  {
    visitors: "1",
    City: "Knutby",
    zipCode: "74012",
    lat: 59.9094869,
    lng: 18.2656587,
  },
  {
    visitors: "1",
    City: "Älta",
    zipCode: "13839",
    lat: 59.24805929999999,
    lng: 18.1866415,
  },
  {
    visitors: "1",
    City: "SOLNA",
    zipCode: "16952",
    lat: 59.3624572,
    lng: 18.0024396,
  },
  {
    visitors: "1",
    City: "Älvsjö",
    zipCode: "12552",
    lat: 59.26754680000001,
    lng: 17.9756062,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11861",
    lat: 59.3054695,
    lng: 18.0589493,
  },
  {
    visitors: "6",
    City: "Stockholm",
    zipCode: "11362",
    lat: 59.3414715,
    lng: 18.0387054,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "12032",
    lat: 59.30222449999999,
    lng: 18.084202,
  },
  {
    visitors: "3",
    City: "Vällingby",
    zipCode: "16252",
    lat: 59.36209239999999,
    lng: 17.8963296,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11430",
    lat: 59.3418453,
    lng: 18.0673185,
  },
  {
    visitors: "1",
    City: "Johanneshov",
    zipCode: "12144",
    lat: 59.2949099,
    lng: 18.0755459,
  },
  {
    visitors: "1",
    City: "Lidingö",
    zipCode: "18162",
    lat: 59.3418388,
    lng: 18.199493,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "12232",
    lat: 59.2848437,
    lng: 18.0834974,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11222",
    lat: 59.328492,
    lng: 18.0371168,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11628",
    lat: 59.3181016,
    lng: 18.0902238,
  },
  {
    visitors: "1",
    City: "Sundbyberg",
    zipCode: "17266",
    lat: 59.3596506,
    lng: 17.9633487,
  },
  {
    visitors: "2",
    City: "Solna",
    zipCode: "16973",
    lat: 59.3717657,
    lng: 18.0167491,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "19910",
    lat: 59.32932349999999,
    lng: 18.0685808,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11360",
    lat: 59.3393085,
    lng: 18.05384,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11360",
    lat: 59.3393085,
    lng: 18.05384,
  },
  {
    visitors: "1",
    City: "SUNDBYBERG",
    zipCode: "17267",
    lat: 59.362509,
    lng: 17.9641752,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11251",
    lat: 59.3389931,
    lng: 18.0117631,
  },
  {
    visitors: "1",
    City: "Trosa",
    zipCode: "61934",
    lat: 58.9066956,
    lng: 17.5316616,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11345",
    lat: 59.34790940000001,
    lng: 18.0470283,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11236",
    lat: 59.32949720000001,
    lng: 18.0352885,
  },
  {
    visitors: "1",
    City: "Enskede",
    zipCode: "12262",
    lat: 59.2814884,
    lng: 18.0861468,
  },
  {
    visitors: "1",
    City: "Gudžiūnai",
    zipCode: "15155",
    lat: 55.5084852,
    lng: 23.773735,
  },
  {
    visitors: "2",
    City: "Sollentuna",
    zipCode: "19251",
    lat: 59.44042990000001,
    lng: 17.9683594,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11727",
    lat: 59.3188049,
    lng: 18.046335,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11327",
    lat: 59.343504,
    lng: 18.0505177,
  },
  {
    visitors: "2",
    City: "Västerås",
    zipCode: "72214",
    lat: 59.61289839999999,
    lng: 16.5506654,
  },
  {
    visitors: "1",
    City: "Bagarmossen",
    zipCode: "12847",
    lat: 59.2827044,
    lng: 18.1346562,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11121",
    lat: 59.33242929999999,
    lng: 18.0603179,
  },
  {
    visitors: "1",
    City: "Skarpnäck",
    zipCode: "12833",
    lat: 59.267425,
    lng: 18.13227,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11854",
    lat: 59.3147506,
    lng: 18.0603755,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11354",
    lat: 59.3502308,
    lng: 18.0555166,
  },
  {
    visitors: "1",
    City: "Solna",
    zipCode: "16937",
    lat: 59.369272,
    lng: 17.984629,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11136",
    lat: 59.3362917,
    lng: 18.0593253,
  },
  {
    visitors: "2",
    City: "Västerås",
    zipCode: "72209",
    lat: 59.6118953,
    lng: 16.5541111,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11332",
    lat: 59.3432762,
    lng: 18.0377917,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11124",
    lat: 59.3364515,
    lng: 18.053005,
  },
  {
    visitors: "1",
    City: "Hässelby",
    zipCode: "16556",
    lat: 59.36361559999999,
    lng: 17.8434676,
  },
  {
    visitors: "4",
    City: "Stockholm",
    zipCode: "11853",
    lat: 59.3151166,
    lng: 18.0513174,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11243",
    lat: 59.33270169999999,
    lng: 18.0167517,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11823",
    lat: 59.31906499999999,
    lng: 18.0523194,
  },
  {
    visitors: "1",
    City: "Ljungskile",
    zipCode: "45993",
    lat: 58.2301512,
    lng: 12.0064756,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11428",
    lat: 59.35117519999999,
    lng: 18.0723261,
  },
  {
    visitors: "1",
    City: "Tartu",
    zipCode: "67345",
    lat: 58.37798299999999,
    lng: 26.7290383,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11260",
    lat: 59.3257293,
    lng: 18.0160867,
  },
  {
    visitors: "1",
    City: "Farsta",
    zipCode: "12356",
    lat: 59.2557132,
    lng: 18.0817688,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11821",
    lat: 59.3185059,
    lng: 18.0581784,
  },
  {
    visitors: "2",
    City: "VALLENTUNA",
    zipCode: "18642",
    lat: 59.5186485,
    lng: 18.0886304,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11231",
    lat: 59.3308822,
    lng: 18.0347074,
  },
  {
    visitors: "1",
    City: "Johanneshov",
    zipCode: "12152",
    lat: 59.2942813,
    lng: 18.1214057,
  },
  {
    visitors: "1",
    City: "TRÅNGSUND",
    zipCode: "14265",
    lat: 59.2370491,
    lng: 18.1417712,
  },
  {
    visitors: "1",
    City: "Solna",
    zipCode: "16931",
    lat: 59.36121430000001,
    lng: 17.9889637,
  },
  {
    visitors: "1",
    City: "Lidingö",
    zipCode: "18142",
    lat: 59.3658535,
    lng: 18.1516362,
  },
  {
    visitors: "2",
    City: "Hägersten",
    zipCode: "12953",
    lat: 59.28397870000001,
    lng: 17.9646367,
  },
  {
    visitors: "1",
    City: "Sundbyberg",
    zipCode: "17267",
    lat: 59.362509,
    lng: 17.9641752,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11622",
    lat: 59.3183437,
    lng: 18.0807468,
  },
  {
    visitors: "1",
    City: "Falun",
    zipCode: "79160",
    lat: 60.60522269999999,
    lng: 15.6248151,
  },
  {
    visitors: "2",
    City: "Värmdö",
    zipCode: "13930",
    lat: 59.3188896,
    lng: 18.5106692,
  },
  {
    visitors: "1",
    City: "Uddevalla",
    zipCode: "45132",
    lat: 58.3505986,
    lng: 11.9490108,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11355",
    lat: 59.3464977,
    lng: 18.0595054,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11355",
    lat: 59.3464977,
    lng: 18.0595054,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11863",
    lat: 59.313106,
    lng: 18.0549714,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11239",
    lat: 59.3315906,
    lng: 18.0310499,
  },
  {
    visitors: "1",
    City: "Bromma",
    zipCode: "16765",
    lat: 59.3153477,
    lng: 17.9479804,
  },
  {
    visitors: "2",
    City: "TYRESÖ",
    zipCode: "13555",
    lat: 59.22901179999999,
    lng: 18.2180635,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11359",
    lat: 59.3396915,
    lng: 18.0576664,
  },
  {
    visitors: "1",
    City: "Solna",
    zipCode: "16963",
    lat: 59.3621341,
    lng: 18.0099276,
  },
  {
    visitors: "2",
    City: "Solna",
    zipCode: "16965",
    lat: 59.3634364,
    lng: 18.0099272,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "16836",
    lat: 59.33666199999999,
    lng: 17.9392928,
  },
  {
    visitors: "1",
    City: "Bellheim",
    zipCode: "76756",
    lat: 49.1946847,
    lng: 8.2931195,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11236",
    lat: 59.32949720000001,
    lng: 18.0352885,
  },
  {
    visitors: "4",
    City: "Linköping",
    zipCode: "58937",
    lat: 58.3703816,
    lng: 15.7060942,
  },
  {
    visitors: "2",
    City: "Hässelby",
    zipCode: "16552",
    lat: 59.3719233,
    lng: 17.8399296,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11452",
    lat: 59.3341776,
    lng: 18.083192,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11452",
    lat: 59.3341776,
    lng: 18.083192,
  },
  {
    visitors: "2",
    City: "Umeå",
    zipCode: "90588",
    lat: 63.8498182,
    lng: 19.9537577,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11664",
    lat: 59.3074681,
    lng: 18.0800587,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11460",
    lat: 59.3356541,
    lng: 18.0897666,
  },
  {
    visitors: "1",
    City: "Bromma",
    zipCode: "16731",
    lat: 59.3370396,
    lng: 17.9575718,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11661",
    lat: 59.30814040000001,
    lng: 18.0805588,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11542",
    lat: 59.36228120000001,
    lng: 18.0883292,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11867",
    lat: 59.30865169999999,
    lng: 18.0523067,
  },
  {
    visitors: "1",
    City: "Södertälje",
    zipCode: "15249",
    lat: 59.2102246,
    lng: 17.6344755,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11730",
    lat: 59.3179512,
    lng: 18.0366939,
  },
  {
    visitors: "4",
    City: "Stockholm",
    zipCode: "11620",
    lat: 59.3173323,
    lng: 18.0774194,
  },
  {
    visitors: "2",
    City: "Vällingby",
    zipCode: "16254",
    lat: 59.3569294,
    lng: 17.8828874,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "12062",
    lat: 59.30420359999999,
    lng: 18.0898573,
  },
  {
    visitors: "2",
    City: "Huddinge",
    zipCode: "14136",
    lat: 59.23633,
    lng: 17.9821561,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11851",
    lat: 59.3169244,
    lng: 18.0529817,
  },
  {
    visitors: "4",
    City: "Enskededalen",
    zipCode: "12134",
    lat: 59.2779813,
    lng: 18.1054026,
  },
  {
    visitors: "3",
    City: "Stockholm",
    zipCode: "11527",
    lat: 59.3381236,
    lng: 18.1322083,
  },
  {
    visitors: "3",
    City: "Stockholm",
    zipCode: "11667",
    lat: 59.30675269999999,
    lng: 18.0785613,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11667",
    lat: 59.30675269999999,
    lng: 18.0785613,
  },
  {
    visitors: "1",
    City: "Årsta",
    zipCode: "12058",
    lat: 59.29982289999999,
    lng: 18.0438234,
  },
  {
    visitors: "2",
    City: "Enköping",
    zipCode: "74944",
    lat: 59.62815939999999,
    lng: 17.1266271,
  },
  {
    visitors: "2",
    City: "Värmdö",
    zipCode: "13932",
    lat: 59.31794779999999,
    lng: 18.4866275,
  },
  {
    visitors: "2",
    City: "HEBERG",
    zipCode: "31196",
    lat: 56.8828299,
    lng: 12.6280713,
  },
  {
    visitors: "2",
    City: "Solna",
    zipCode: "17143",
    lat: 59.35584919999999,
    lng: 17.99529,
  },
  {
    visitors: "2",
    City: "Djursholm",
    zipCode: "18266",
    lat: 59.408308,
    lng: 18.0684174,
  },
  {
    visitors: "1",
    City: "Saltsjöbaden",
    zipCode: "13333",
    lat: 59.29544680000001,
    lng: 18.2937199,
  },
  {
    visitors: "2",
    City: "Göteborg",
    zipCode: "41322",
    lat: 57.6850991,
    lng: 11.967999,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11220",
    lat: 59.3257766,
    lng: 18.0470069,
  },
  {
    visitors: "2",
    City: "Bromma",
    zipCode: "16853",
    lat: 59.352842,
    lng: 17.8933748,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11453",
    lat: 59.33413720000001,
    lng: 18.0847723,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11646",
    lat: 59.3185448,
    lng: 18.0728497,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11257",
    lat: 59.3286472,
    lng: 18.0034538,
  },
  {
    visitors: "1",
    City: "",
    zipCode: "",
  },
  {
    visitors: "1",
    City: "Falun",
    zipCode: "79146",
    lat: 60.5833411,
    lng: 15.7388102,
  },
  {
    visitors: "1",
    City: "Uppsala",
    zipCode: "75239",
    lat: 59.84756909999999,
    lng: 17.6149306,
  },
  {
    visitors: "1",
    City: "Segeltorp",
    zipCode: "14173",
    lat: 59.26095180000001,
    lng: 17.9540595,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11534",
    lat: 59.3452303,
    lng: 18.0904546,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11636",
    lat: 59.3141967,
    lng: 18.0902143,
  },
  {
    visitors: "2",
    City: "Årsta",
    zipCode: "12052",
    lat: 59.29488430000001,
    lng: 18.054947,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11267",
    lat: 59.3213431,
    lng: 18.0051185,
  },
  {
    visitors: "1",
    City: "Farsta",
    zipCode: "12342",
    lat: 59.24691849999999,
    lng: 18.0990013,
  },
  {
    visitors: "1",
    City: "Bromma",
    zipCode: "16869",
    lat: 59.3434396,
    lng: 17.9648737,
  },
  {
    visitors: "1",
    City: "Rimforsa",
    zipCode: "59043",
    lat: 58.1368196,
    lng: 15.6812611,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11353",
    lat: 59.3454055,
    lng: 18.0593376,
  },
  {
    visitors: "1",
    City: "Uppsala",
    zipCode: "75322",
    lat: 59.85592759999999,
    lng: 17.6421948,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11441",
    lat: 59.34186259999999,
    lng: 18.0827923,
  },
  {
    visitors: "1",
    City: "Malmö",
    zipCode: "21441",
    lat: 55.59192729999999,
    lng: 13.0288732,
  },
  {
    visitors: "3",
    City: "Tollered",
    zipCode: "44850",
    lat: 57.8192649,
    lng: 12.4188879,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11632",
    lat: 59.314452,
    lng: 18.0936233,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11665",
    lat: 59.3096489,
    lng: 18.0778194,
  },
  {
    visitors: "2",
    City: "Bromma",
    zipCode: "16756",
    lat: 59.33134639999999,
    lng: 17.9662235,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11540",
    lat: 59.3465891,
    lng: 18.1069357,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11741",
    lat: 59.31435019999999,
    lng: 18.0436713,
  },
  {
    visitors: "1",
    City: "Jönköping",
    zipCode: "55318",
    lat: 57.78101059999999,
    lng: 14.1529537,
  },
  {
    visitors: "1",
    City: "Gustafs",
    zipCode: "78350",
    lat: 60.41140559999999,
    lng: 15.6110187,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11761",
    lat: 59.31138329999999,
    lng: 18.0253904,
  },
  {
    visitors: "1",
    City: "Johanneshov",
    zipCode: "12138",
    lat: 59.29619499999999,
    lng: 18.1003061,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11258",
    lat: 59.3271447,
    lng: 18.0087731,
  },
  {
    visitors: "1",
    City: "Västerhaninge",
    zipCode: "13742",
    lat: 59.1243049,
    lng: 18.1194369,
  },
  {
    visitors: "1",
    City: "Solna",
    zipCode: "16967",
    lat: 59.363794,
    lng: 18.0230743,
  },
  {
    visitors: "3",
    City: "Stockholm",
    zipCode: "11732",
    lat: 59.3198462,
    lng: 18.040352,
  },
  {
    visitors: "2",
    City: "Solna",
    zipCode: "17062",
    lat: 59.38435490000001,
    lng: 17.9845277,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11352",
    lat: 59.34406019999999,
    lng: 18.0583375,
  },
  {
    visitors: "1",
    City: "Bagarmossen",
    zipCode: "12847",
    lat: 59.2827044,
    lng: 18.1346562,
  },
  {
    visitors: "5",
    City: "Stockholm",
    zipCode: "11355",
    lat: 59.3464977,
    lng: 18.0595054,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11225",
    lat: 59.33027149999999,
    lng: 18.0480923,
  },
  {
    visitors: "2",
    City: "Ekerö",
    zipCode: "17851",
    lat: 59.2838223,
    lng: 17.8452351,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11231",
    lat: 59.3308822,
    lng: 18.0347074,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11218",
    lat: 59.3340817,
    lng: 18.0169221,
  },
  {
    visitors: "1",
    City: "Falun",
    zipCode: "79171",
    lat: 60.60769790000001,
    lng: 15.6290262,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11353",
    lat: 59.3454055,
    lng: 18.0593376,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11259",
    lat: 59.32908450000001,
    lng: 18.013427,
  },
  {
    visitors: "1",
    City: "Nacka",
    zipCode: "13172",
    lat: 59.317118,
    lng: 18.117826,
  },
  {
    visitors: "1",
    City: "Nacka",
    zipCode: "13172",
    lat: 59.317118,
    lng: 18.117826,
  },
  {
    visitors: "4",
    City: "Göteborg",
    zipCode: "41119",
    lat: 57.7030577,
    lng: 11.9569601,
  },
  {
    visitors: "1",
    City: "Johanneshov",
    zipCode: "12148",
    lat: 59.2933395,
    lng: 18.0994673,
  },
  {
    visitors: "2",
    City: "Vallentuna",
    zipCode: "18651",
    lat: 59.51106369999999,
    lng: 18.073895,
  },
  {
    visitors: "2",
    City: "Vimmerby",
    zipCode: "59839",
    lat: 57.6614981,
    lng: 15.8654056,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11526",
    lat: 59.3365032,
    lng: 18.0994189,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11526",
    lat: 59.3365032,
    lng: 18.0994189,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11251",
    lat: 59.3389931,
    lng: 18.0117631,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11347",
    lat: 59.3536674,
    lng: 18.0496961,
  },
  {
    visitors: "1",
    City: "Älvsjö",
    zipCode: "12559",
    lat: 59.27963330000001,
    lng: 17.9742656,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11225",
    lat: 59.33027149999999,
    lng: 18.0480923,
  },
  {
    visitors: "1",
    City: "Sollentuna",
    zipCode: "19149",
    lat: 59.4436954,
    lng: 17.9243307,
  },
  {
    visitors: "3",
    City: "Stockholm",
    zipCode: "11358",
    lat: 59.3404938,
    lng: 18.0611609,
  },
  {
    visitors: "1",
    City: "Hägersten",
    zipCode: "12653",
    lat: 59.30652240000001,
    lng: 17.9838643,
  },
  {
    visitors: "2",
    City: "Nacka",
    zipCode: "13132",
    lat: 59.3100621,
    lng: 18.1151422,
  },
  {
    visitors: "1",
    City: "Södertälje",
    zipCode: "15173",
    lat: 59.1951408,
    lng: 17.6229401,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11732",
    lat: 59.3198462,
    lng: 18.040352,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11764",
    lat: 59.3082564,
    lng: 18.0134281,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11352",
    lat: 59.34406019999999,
    lng: 18.0583375,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11322",
    lat: 59.3408062,
    lng: 18.0433622,
  },
  {
    visitors: "3",
    City: "Solna",
    zipCode: "16935",
    lat: 59.3635749,
    lng: 17.9932873,
  },
  {
    visitors: "2",
    City: "Luleå",
    zipCode: "97234",
    lat: 65.584069,
    lng: 22.1646466,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11420",
    lat: 59.34759320000001,
    lng: 18.0622524,
  },
  {
    visitors: "1",
    City: "Sollentuna",
    zipCode: "19252",
    lat: 59.44851329999999,
    lng: 17.9743488,
  },
  {
    visitors: "3",
    City: "Järfälla",
    zipCode: "17737",
    lat: 59.426913,
    lng: 17.8455971,
  },
  {
    visitors: "1",
    City: "Hägersten",
    zipCode: "12642",
    lat: 59.2904947,
    lng: 17.9893568,
  },
  {
    visitors: "1",
    City: "Göteborg",
    zipCode: "41251",
    lat: 57.69939890000001,
    lng: 11.9914013,
  },
  {
    visitors: "3",
    City: "Årsta",
    zipCode: "12039",
    lat: 59.2999432,
    lng: 18.0715683,
  },
  {
    visitors: "1",
    City: "Årsta",
    zipCode: "12039",
    lat: 59.2999432,
    lng: 18.0715683,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11730",
    lat: 59.3179512,
    lng: 18.0366939,
  },
  {
    visitors: "1",
    City: "Sundbyberg",
    zipCode: "17453",
    lat: 59.3787335,
    lng: 17.9678069,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11437",
    lat: 59.340808,
    lng: 18.078464,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11440",
    lat: 59.3389628,
    lng: 18.080956,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11730",
    lat: 59.3179512,
    lng: 18.0366939,
  },
  {
    visitors: "3",
    City: "Älvsjö",
    zipCode: "12530",
    lat: 59.2744881,
    lng: 18.0051298,
  },
  {
    visitors: "3",
    City: "Sundsvall",
    zipCode: "85733",
    lat: 62.4126218,
    lng: 17.2535858,
  },
  {
    visitors: "2",
    City: "Nacka",
    zipCode: "13139",
    lat: 59.31278,
    lng: 18.1790219,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11642",
    lat: 59.3092808,
    lng: 18.0868777,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11328",
    lat: 59.3416126,
    lng: 18.0494344,
  },
  {
    visitors: "2",
    City: "Johanneshov",
    zipCode: "12145",
    lat: 59.29921249999999,
    lng: 18.0948304,
  },
  {
    visitors: "2",
    City: "Hägersten",
    zipCode: "12635",
    lat: 59.30018450000001,
    lng: 18.0074495,
  },
  {
    visitors: "1",
    City: "Gävle",
    zipCode: "80325",
    lat: 60.6808675,
    lng: 17.1278939,
  },
  {
    visitors: "2",
    City: "Solna",
    zipCode: "17169",
    lat: 59.3527142,
    lng: 18.0064531,
  },
  {
    visitors: "1",
    City: "Solna",
    zipCode: "17169",
    lat: 59.3527142,
    lng: 18.0064531,
  },
  {
    visitors: "5",
    City: "Stockholm",
    zipCode: "11240",
    lat: 59.33166970000001,
    lng: 18.0278909,
  },
  {
    visitors: "1",
    City: "Täby",
    zipCode: "18775",
    lat: 59.48330859999999,
    lng: 18.0751975,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11348",
    lat: 59.3451491,
    lng: 18.0559265,
  },
  {
    visitors: "1",
    City: "Gustavsberg",
    zipCode: "13432",
    lat: 59.35079820000001,
    lng: 18.3535907,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11625",
    lat: 59.3159875,
    lng: 18.0764192,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11761",
    lat: 59.31138329999999,
    lng: 18.0253904,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11232",
    lat: 59.3342456,
    lng: 18.0372037,
  },
  {
    visitors: "1",
    City: "Nacka Strand",
    zipCode: "13152",
    lat: 59.31764539999999,
    lng: 18.1607449,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11548",
    lat: 59.3621193,
    lng: 18.0946565,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11426",
    lat: 59.3438229,
    lng: 18.0703998,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11360",
    lat: 59.3393085,
    lng: 18.05384,
  },
  {
    visitors: "2",
    City: "Hägersten",
    zipCode: "12954",
    lat: 59.28631650000001,
    lng: 17.9586597,
  },
  {
    visitors: "3",
    City: "Stockholm",
    zipCode: "11524",
    lat: 59.33935499999999,
    lng: 18.0951,
  },
  {
    visitors: "2",
    City: "Johanneshov",
    zipCode: "12137",
    lat: 59.2952652,
    lng: 18.093823,
  },
  {
    visitors: "1",
    City: "Aix en Provence",
    zipCode: "13100",
    lat: 43.5338335,
    lng: 5.509246999999999,
  },
  {
    visitors: "1",
    City: "Skärholmen",
    zipCode: "12760",
    lat: 59.29096699999999,
    lng: 17.9364167,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11223",
    lat: 59.3294253,
    lng: 18.0436019,
  },
  {
    visitors: "1",
    City: "Uppsala",
    zipCode: "75318",
    lat: 59.8471124,
    lng: 17.6614483,
  },
  {
    visitors: "1",
    City: "Solna",
    zipCode: "17165",
    lat: 59.3485875,
    lng: 18.0227397,
  },
  {
    visitors: "1",
    City: "Bagarmossen",
    zipCode: "12845",
    lat: 59.2760739,
    lng: 18.1367893,
  },
  {
    visitors: "2",
    City: "Vällingby",
    zipCode: "16264",
    lat: 59.36374039999999,
    lng: 17.884686,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11337",
    lat: 59.3427698,
    lng: 18.036128,
  },
  {
    visitors: "1",
    City: "STOCKHOLM",
    zipCode: "11261",
    lat: 59.32516560000001,
    lng: 18.0056989,
  },
  {
    visitors: "1",
    City: "Nacka",
    zipCode: "13146",
    lat: 59.32235420000001,
    lng: 18.2106977,
  },
  {
    visitors: "2",
    City: "Stora Sundby",
    zipCode: "63535",
    lat: 59.2701436,
    lng: 16.1267008,
  },
  {
    visitors: "1",
    City: "Falun",
    zipCode: "79162",
    lat: 60.6007913,
    lng: 15.6245088,
  },
  {
    visitors: "1",
    City: "Upplands Väsby",
    zipCode: "19440",
    lat: 59.5198291,
    lng: 17.8966554,
  },
  {
    visitors: "2",
    City: "Solna",
    zipCode: "16955",
    lat: 59.37104749999999,
    lng: 17.9904515,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11639",
    lat: 59.3102435,
    lng: 18.084054,
  },
  {
    visitors: "3",
    City: "Stockholm",
    zipCode: "11218",
    lat: 59.3340817,
    lng: 18.0169221,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11218",
    lat: 59.3340817,
    lng: 18.0169221,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11750",
    lat: 59.3189319,
    lng: 18.0194102,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11360",
    lat: 59.3393085,
    lng: 18.05384,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11360",
    lat: 59.3393085,
    lng: 18.05384,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11858",
    lat: 59.3092637,
    lng: 18.0714195,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11423",
    lat: 59.3499042,
    lng: 18.0630045,
  },
  {
    visitors: "3",
    City: "Stockholm",
    zipCode: "11253",
    lat: 59.3353572,
    lng: 17.9981311,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11327",
    lat: 59.343504,
    lng: 18.0505177,
  },
  {
    visitors: "1",
    City: "Upplands Väsby",
    zipCode: "19477",
    lat: 59.5202623,
    lng: 17.9014993,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11215",
    lat: 59.338047,
    lng: 18.0050293,
  },
  {
    visitors: "2",
    City: "Malmö",
    zipCode: "21439",
    lat: 55.5935267,
    lng: 13.023856,
  },
  {
    visitors: "2",
    City: "Hägersten",
    zipCode: "12954",
    lat: 59.28631650000001,
    lng: 17.9586597,
  },
  {
    visitors: "1",
    City: "Lidingö",
    zipCode: "18132",
    lat: 59.3634944,
    lng: 18.1369656,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11365",
    lat: 59.3485241,
    lng: 18.0362146,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11365",
    lat: 59.3485241,
    lng: 18.0362146,
  },
  {
    visitors: "1",
    City: "Bromma",
    zipCode: "16743",
    lat: 59.33600080000001,
    lng: 17.9831696,
  },
  {
    visitors: "3",
    City: "Stockholm",
    zipCode: "11344",
    lat: 59.34756950000001,
    lng: 18.0441996,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11540",
    lat: 59.3465891,
    lng: 18.1069357,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11538",
    lat: 59.3455783,
    lng: 18.1036039,
  },
  {
    visitors: "3",
    City: "Järfälla",
    zipCode: "17744",
    lat: 59.4134902,
    lng: 17.866431,
  },
  {
    visitors: "4",
    City: "Järfälla",
    zipCode: "17744",
    lat: 59.4134902,
    lng: 17.866431,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11535",
    lat: 59.3450277,
    lng: 18.0983595,
  },
  {
    visitors: "1",
    City: "Playa Del Rey",
    zipCode: "90293-7662",
    lat: 33.9508728,
    lng: -118.4455164,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11629",
    lat: 59.31471440000001,
    lng: 18.0833575,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11662",
    lat: 59.31065450000001,
    lng: 18.075993,
  },
  {
    visitors: "1",
    City: "Uppsala",
    zipCode: "75423",
    lat: 59.867431,
    lng: 17.6385748,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11439",
    lat: 59.3356837,
    lng: 18.0778717,
  },
  {
    visitors: "1",
    City: "Hägersten",
    zipCode: "12940",
    lat: 59.305595,
    lng: 17.9433528,
  },
  {
    visitors: "1",
    City: "Västra Frölunda",
    zipCode: "42671",
    lat: 57.68356000000001,
    lng: 11.8861644,
  },
  {
    visitors: "1",
    City: "INGARÖ",
    zipCode: "13467",
    lat: 59.2287896,
    lng: 18.5558188,
  },
  {
    visitors: "1",
    City: "Karlskoga",
    zipCode: "69134",
    lat: 59.3281151,
    lng: 14.5096023,
  },
  {
    visitors: "2",
    City: "Vallentuna",
    zipCode: "18694",
    lat: 59.6011328,
    lng: 18.1062069,
  },
  {
    visitors: "1",
    City: "Täby",
    zipCode: "18338",
    lat: 59.4353661,
    lng: 18.0614451,
  },
  {
    visitors: "4",
    City: "Visby",
    zipCode: "62149",
    lat: 57.6200866,
    lng: 18.2876242,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11332",
    lat: 59.3432762,
    lng: 18.0377917,
  },
  {
    visitors: "1",
    City: "Sundbyberg",
    zipCode: "17269",
    lat: 59.3618675,
    lng: 17.9791475,
  },
  {
    visitors: "2",
    City: "Årsta",
    zipCode: "12051",
    lat: 59.3016082,
    lng: 18.0599405,
  },
  {
    visitors: "2",
    City: "Hägersten",
    zipCode: "12948",
    lat: 59.29448960000001,
    lng: 17.971758,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11664",
    lat: 59.3074681,
    lng: 18.0800587,
  },
  {
    visitors: "1",
    City: "Saltsjö-Duvnäs",
    zipCode: "13150",
    lat: 59.3008006,
    lng: 18.1982809,
  },
  {
    visitors: "2",
    City: "Värmdö",
    zipCode: "13960",
    lat: 59.26689560000001,
    lng: 18.600498,
  },
  {
    visitors: "2",
    City: "Sollentuna",
    zipCode: "19273",
    lat: 59.45065820000001,
    lng: 17.8856255,
  },
  {
    visitors: "1",
    City: "Hägersten",
    zipCode: "12937",
    lat: 59.3093478,
    lng: 17.9692472,
  },
  {
    visitors: "1",
    City: "TUMBA",
    zipCode: "14743",
    lat: 59.2135836,
    lng: 17.8270792,
  },
  {
    visitors: "2",
    City: "Hägersten",
    zipCode: "12635",
    lat: 59.30018450000001,
    lng: 18.0074495,
  },
  {
    visitors: "1",
    City: "Sköndal",
    zipCode: "12866",
    lat: 59.25801149999999,
    lng: 18.1149681,
  },
  {
    visitors: "1",
    City: "Bandhagen",
    zipCode: "12457",
    lat: 59.262389,
    lng: 18.0507523,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11526",
    lat: 59.3365032,
    lng: 18.0994189,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11820",
    lat: 59.3203432,
    lng: 18.0667845,
  },
  {
    visitors: "1",
    City: "Hägersten",
    zipCode: "12635",
    lat: 59.30018450000001,
    lng: 18.0074495,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11548",
    lat: 59.3621193,
    lng: 18.0946565,
  },
  {
    visitors: "1",
    City: "Västerås",
    zipCode: "72347",
    lat: 59.6047942,
    lng: 16.5845283,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11232",
    lat: 59.3342456,
    lng: 18.0372037,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11354",
    lat: 59.3502308,
    lng: 18.0555166,
  },
  {
    visitors: "1",
    City: "Solna",
    zipCode: "16958",
    lat: 59.36422280000001,
    lng: 18.0031042,
  },
  {
    visitors: "4",
    City: "Bollnäs",
    zipCode: "82143",
    lat: 61.37837929999999,
    lng: 16.3921489,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11640",
    lat: 59.31196459999999,
    lng: 18.0837254,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11448",
    lat: 59.33879330000001,
    lng: 18.0795415,
  },
  {
    visitors: "1",
    City: "Eskilstuna",
    zipCode: "63342",
    lat: 59.3781172,
    lng: 16.5102565,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11632",
    lat: 59.314452,
    lng: 18.0936233,
  },
  {
    visitors: "3",
    City: "Stockholm",
    zipCode: "11341",
    lat: 59.3397781,
    lng: 18.0297231,
  },
  {
    visitors: "1",
    City: "Göteborg",
    zipCode: "41309",
    lat: 57.69283349999999,
    lng: 11.9510556,
  },
  {
    visitors: "2",
    City: "Gnesta",
    zipCode: "64692",
    lat: 58.99413000000001,
    lng: 17.3065948,
  },
  {
    visitors: "2",
    City: "Hägersten",
    zipCode: "12633",
    lat: 59.3032944,
    lng: 18.0091098,
  },
  {
    visitors: "2",
    City: "Solna",
    zipCode: "17169",
    lat: 59.3527142,
    lng: 18.0064531,
  },
  {
    visitors: "1",
    City: "Gothenburg",
    zipCode: "41477",
    lat: 57.67081889999999,
    lng: 11.9368586,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11335",
    lat: 59.3429678,
    lng: 18.0282274,
  },
  {
    visitors: "2",
    City: "Huddinge",
    zipCode: "14143",
    lat: 59.25027830000001,
    lng: 17.9872278,
  },
  {
    visitors: "1",
    City: "Huddinge",
    zipCode: "14146",
    lat: 59.2321537,
    lng: 17.989227,
  },
  {
    visitors: "1",
    City: "Solna",
    zipCode: "16937",
    lat: 59.369272,
    lng: 17.984629,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11536",
    lat: 59.34687340000001,
    lng: 18.0958677,
  },
  {
    visitors: "2",
    City: "Älvsjö",
    zipCode: "12548",
    lat: 59.2822125,
    lng: 18.0051384,
  },
  {
    visitors: "1",
    City: "Hägersten",
    zipCode: "12946",
    lat: 59.28976889999999,
    lng: 17.9631344,
  },
  {
    visitors: "1",
    City: "Trångsund",
    zipCode: "14263",
    lat: 59.22765270000001,
    lng: 18.1450474,
  },
  {
    visitors: "1",
    City: "Enskede",
    zipCode: "12242",
    lat: 59.2760729,
    lng: 18.0512685,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "12030",
    lat: 59.30197709999999,
    lng: 18.0885223,
  },
  {
    visitors: "2",
    City: "Enskede",
    zipCode: "12263",
    lat: 59.2791331,
    lng: 18.0818234,
  },
  {
    visitors: "1",
    City: "Älvsjö",
    zipCode: "12535",
    lat: 59.2844273,
    lng: 18.0240513,
  },
  {
    visitors: "1",
    City: "Nacka",
    zipCode: "13144",
    lat: 59.31110210000001,
    lng: 18.2112924,
  },
  {
    visitors: "2",
    City: "Enskede",
    zipCode: "12265",
    lat: 59.2737559,
    lng: 18.0778264,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11625",
    lat: 59.3159875,
    lng: 18.0764192,
  },
  {
    visitors: "1",
    City: "Uccle",
    zipCode: "1180",
    lat: 50.7904904,
    lng: 4.3559193,
  },
  {
    visitors: "1",
    City: "Bandhagen",
    zipCode: "12467",
    lat: 59.2595901,
    lng: 18.0313385,
  },
  {
    visitors: "1",
    City: "Bandhagen",
    zipCode: "12467",
    lat: 59.2595901,
    lng: 18.0313385,
  },
  {
    visitors: "2",
    City: "Djursholm",
    zipCode: "18263",
    lat: 59.4076614,
    lng: 18.0937619,
  },
  {
    visitors: "3",
    City: "Stockholm",
    zipCode: "11640",
    lat: 59.31196459999999,
    lng: 18.0837254,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11325",
    lat: 59.34060359999999,
    lng: 18.0486848,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11826",
    lat: 59.3156423,
    lng: 18.068439,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11451",
    lat: 59.33345929999999,
    lng: 18.0791149,
  },
  {
    visitors: "1",
    City: "Upplands Väsby",
    zipCode: "19473",
    lat: 59.51214580000001,
    lng: 17.9194115,
  },
  {
    visitors: "1",
    City: "Upplands Väsby",
    zipCode: "19473",
    lat: 59.51214580000001,
    lng: 17.9194115,
  },
  {
    visitors: "1",
    City: "Johanneshov",
    zipCode: "12147",
    lat: 59.295174,
    lng: 18.0866781,
  },
  {
    visitors: "1",
    City: "ENSKEDE GÅRD",
    zipCode: "12047",
    lat: 59.2901978,
    lng: 18.0668989,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11553",
    lat: 59.3411242,
    lng: 18.1009289,
  },
  {
    visitors: "1",
    City: "TROSA",
    zipCode: "61933",
    lat: 58.90823259999999,
    lng: 17.5630035,
  },
  {
    visitors: "1",
    City: "Hägersten",
    zipCode: "12655",
    lat: 59.30993299999999,
    lng: 18.000807,
  },
  {
    visitors: "1",
    City: "STOCKHOLM",
    zipCode: "11269",
    lat: 59.33441939999999,
    lng: 18.0411949,
  },
  {
    visitors: "1",
    City: "Solna",
    zipCode: "17070",
    lat: 59.3825338,
    lng: 18.0247407,
  },
  {
    visitors: "1",
    City: "Spånga",
    zipCode: "16362",
    lat: 59.3972707,
    lng: 17.9158275,
  },
  {
    visitors: "1",
    City: "Spånga",
    zipCode: "16362",
    lat: 59.3972707,
    lng: 17.9158275,
  },
  {
    visitors: "1",
    City: "Spånga",
    zipCode: "16362",
    lat: 59.3972707,
    lng: 17.9158275,
  },
  {
    visitors: "1",
    City: "Burlingame",
    zipCode: "94010-5428",
    lat: 37.5685247,
    lng: -122.367428,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11728",
    lat: 59.3176261,
    lng: 18.044173,
  },
  {
    visitors: "1",
    City: "Limerick",
    zipCode: "",
    lat: 52.663839,
    lng: -8.626729899999999,
  },
  {
    visitors: "1",
    City: "Skärholmen",
    zipCode: "12734",
    lat: 59.2936588,
    lng: 17.9384014,
  },
  {
    visitors: "1",
    City: "Bandhagen",
    zipCode: "12458",
    lat: 59.2618757,
    lng: 18.043949,
  },
  {
    visitors: "2",
    City: "Göteborg",
    zipCode: "41671",
    lat: 57.7194066,
    lng: 12.0152953,
  },
  {
    visitors: "3",
    City: "Falkenberg",
    zipCode: "31137",
    lat: 56.9079116,
    lng: 12.5131753,
  },
  {
    visitors: "1",
    City: "Lidingö",
    zipCode: "18136",
    lat: 59.3536649,
    lng: 18.1354296,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11146",
    lat: 59.3337467,
    lng: 18.0732103,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11537",
    lat: 59.3473789,
    lng: 18.0975334,
  },
  {
    visitors: "1",
    City: "Enskede",
    zipCode: "12247",
    lat: 59.2727321,
    lng: 18.0642114,
  },
  {
    visitors: "2",
    City: "STOCKHOLM",
    zipCode: "11737",
    lat: 59.31781279999999,
    lng: 18.0285502,
  },
  {
    visitors: "2",
    City: "Danderyd",
    zipCode: "18257",
    lat: 59.39279549999999,
    lng: 18.0310718,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11637",
    lat: 59.31242160000001,
    lng: 18.0792381,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11441",
    lat: 59.34186259999999,
    lng: 18.0827923,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11636",
    lat: 59.3141967,
    lng: 18.0902143,
  },
  {
    visitors: "2",
    City: "Göteborg",
    zipCode: "41876",
    lat: 57.7075177,
    lng: 11.9140082,
  },
  {
    visitors: "2",
    City: "Hudiksvall",
    zipCode: "82442",
    lat: 61.7195012,
    lng: 17.0999663,
  },
  {
    visitors: "1",
    City: "Hägersten",
    zipCode: "12649",
    lat: 59.3054783,
    lng: 18.0094417,
  },
  {
    visitors: "1",
    City: "Sulz am Neckar",
    zipCode: "72172",
    lat: 48.3650475,
    lng: 8.6333682,
  },
  {
    visitors: "2",
    City: "Värmdö",
    zipCode: "13940",
    lat: 59.29881160000001,
    lng: 18.4850025,
  },
  {
    visitors: "1",
    City: "Johanneshov",
    zipCode: "12137",
    lat: 59.2952652,
    lng: 18.093823,
  },
  {
    visitors: "1",
    City: "Uppsala",
    zipCode: "75220",
    lat: 59.8684038,
    lng: 17.6125938,
  },
  {
    visitors: "2",
    City: "Stenungsund",
    zipCode: "44455",
    lat: 58.0439747,
    lng: 11.8218663,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11524",
    lat: 59.33935499999999,
    lng: 18.0951,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11250",
    lat: 59.3362401,
    lng: 18.0232365,
  },
  {
    visitors: "1",
    City: "Bandhagen",
    zipCode: "12471",
    lat: 59.26727500000001,
    lng: 18.0087805,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11851",
    lat: 59.3169244,
    lng: 18.0529817,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11430",
    lat: 59.3418453,
    lng: 18.0673185,
  },
  {
    visitors: "4",
    City: "Solna",
    zipCode: "16934",
    lat: 59.36491150000001,
    lng: 17.9891264,
  },
  {
    visitors: "1",
    City: "Bandhagen",
    zipCode: "12430",
    lat: 59.2643329,
    lng: 18.0605456,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11123",
    lat: 59.33510630000001,
    lng: 18.0520055,
  },
  {
    visitors: "1",
    City: "Hägersten",
    zipCode: "12629",
    lat: 59.2974064,
    lng: 18.0034648,
  },
  {
    visitors: "2",
    City: "Hägersten",
    zipCode: "12629",
    lat: 59.2974064,
    lng: 18.0034648,
  },
  {
    visitors: "1",
    City: "Hägersten",
    zipCode: "12626",
    lat: 59.2991333,
    lng: 17.9887311,
  },
  {
    visitors: "1",
    City: "Årsta",
    zipCode: "12052",
    lat: 59.29488430000001,
    lng: 18.054947,
  },
  {
    visitors: "1",
    City: "Hägersten",
    zipCode: "12632",
    lat: 59.30246469999999,
    lng: 18.0149229,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11345",
    lat: 59.34790940000001,
    lng: 18.0470283,
  },
  {
    visitors: "1",
    City: "Sollentuna",
    zipCode: "87080",
    lat: 59.43049730000001,
    lng: 17.950916,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11325",
    lat: 59.34060359999999,
    lng: 18.0486848,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11325",
    lat: 59.34060359999999,
    lng: 18.0486848,
  },
  {
    visitors: "1",
    City: "ENSKEDE",
    zipCode: "12265",
    lat: 59.2737559,
    lng: 18.0778264,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11631",
    lat: 59.3114214,
    lng: 18.0862177,
  },
  {
    visitors: "2",
    City: "Hägersten",
    zipCode: "12647",
    lat: 59.30310060000001,
    lng: 17.9948274,
  },
  {
    visitors: "1",
    City: "Zaragoza",
    zipCode: "50009",
    lat: 41.640049,
    lng: -0.9032768999999999,
  },
  {
    visitors: "2",
    City: "Hägersten",
    zipCode: "12679",
    lat: 59.29043599999999,
    lng: 18.0028027,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11769",
    lat: 59.3149276,
    lng: 17.9875107,
  },
  {
    visitors: "2",
    City: "JOHANNESHOV",
    zipCode: "12149",
    lat: 59.29057460000001,
    lng: 18.1057731,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11236",
    lat: 59.32949720000001,
    lng: 18.0352885,
  },
  {
    visitors: "1",
    City: "Djursholm",
    zipCode: "18260",
    lat: 59.3935378,
    lng: 18.0883964,
  },
  {
    visitors: "2",
    City: "Umeå",
    zipCode: "90133",
    lat: 63.85436910000001,
    lng: 20.1999166,
  },
  {
    visitors: "2",
    City: "Uppsala",
    zipCode: "75422",
    lat: 59.8712634,
    lng: 17.6459408,
  },
  {
    visitors: "1",
    City: "Sköndal",
    zipCode: "12867",
    lat: 59.2556578,
    lng: 18.1106446,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11646",
    lat: 59.3185448,
    lng: 18.0728497,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11628",
    lat: 59.3181016,
    lng: 18.0902238,
  },
  {
    visitors: "2",
    City: "Hägersten",
    zipCode: "12637",
    lat: 59.2984889,
    lng: 17.998483,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11731",
    lat: 59.3186634,
    lng: 18.0356141,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11731",
    lat: 59.3186634,
    lng: 18.0356141,
  },
  {
    visitors: "1",
    City: "ÄLVSJÖ",
    zipCode: "12570",
    lat: 59.2801559,
    lng: 18.030523,
  },
  {
    visitors: "1",
    City: "Årsta",
    zipCode: "12054",
    lat: 59.298572,
    lng: 18.0499684,
  },
  {
    visitors: "2",
    City: "Buffalo",
    zipCode: "14223-1435",
    lat: 42.9719383,
    lng: -78.84392679999999,
  },
  {
    visitors: "2",
    City: "Bromma",
    zipCode: "16833",
    lat: 59.3303855,
    lng: 17.949443,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11121",
    lat: 59.33242929999999,
    lng: 18.0603179,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11852",
    lat: 59.315929,
    lng: 18.0625382,
  },
  {
    visitors: "2",
    City: "Saltsjöbaden",
    zipCode: "13334",
    lat: 59.2803437,
    lng: 18.2849323,
  },
  {
    visitors: "2",
    City: "Sollentuna",
    zipCode: "19276",
    lat: 59.4712115,
    lng: 17.8982074,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11542",
    lat: 59.36228120000001,
    lng: 18.0883292,
  },
  {
    visitors: "1",
    City: "Insjön",
    zipCode: "79340",
    lat: 60.6765665,
    lng: 15.0854559,
  },
  {
    visitors: "2",
    City: "Hudiksvall",
    zipCode: "82452",
    lat: 61.7319867,
    lng: 17.1201943,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11352",
    lat: 59.34406019999999,
    lng: 18.0583375,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11417",
    lat: 59.37049219999999,
    lng: 18.0673629,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11662",
    lat: 59.31065450000001,
    lng: 18.075993,
  },
  {
    visitors: "3",
    City: "Stockholm",
    zipCode: "11726",
    lat: 59.3169608,
    lng: 18.0488263,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11215",
    lat: 59.338047,
    lng: 18.0050293,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11823",
    lat: 59.31906499999999,
    lng: 18.0523194,
  },
  {
    visitors: "1",
    City: "Nacka",
    zipCode: "13172",
    lat: 59.317118,
    lng: 18.117826,
  },
  {
    visitors: "1",
    City: "Nacka",
    zipCode: "13172",
    lat: 59.317118,
    lng: 18.117826,
  },
  {
    visitors: "1",
    City: "Tyresö",
    zipCode: "13530",
    lat: 59.2430935,
    lng: 18.264665,
  },
  {
    visitors: "2",
    City: "Bandhagen",
    zipCode: "12454",
    lat: 59.2643041,
    lng: 18.0399695,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11328",
    lat: 59.3416126,
    lng: 18.0494344,
  },
  {
    visitors: "1",
    City: "Uppsala",
    zipCode: "75334",
    lat: 59.8780353,
    lng: 17.6320128,
  },
  {
    visitors: "1",
    City: "Växjö",
    zipCode: "35238",
    lat: 56.8908584,
    lng: 14.801121,
  },
  {
    visitors: "1",
    City: "GÄVLE",
    zipCode: "80628",
    lat: 60.6760852,
    lng: 17.0673601,
  },
  {
    visitors: "1",
    City: "Sollentuna",
    zipCode: "19134",
    lat: 59.42002179999999,
    lng: 17.9390709,
  },
  {
    visitors: "2",
    City: "Mjölby",
    zipCode: "59532",
    lat: 58.326673,
    lng: 15.1173745,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11262",
    lat: 59.3263753,
    lng: 18.0011279,
  },
  {
    visitors: "1",
    City: "Lidingö",
    zipCode: "18134",
    lat: 59.36264169999999,
    lng: 18.116974,
  },
  {
    visitors: "1",
    City: "Hägersten",
    zipCode: "12637",
    lat: 59.2984889,
    lng: 17.998483,
  },
  {
    visitors: "1",
    City: "Brämhult",
    zipCode: "50732",
    lat: 57.7343235,
    lng: 12.9959413,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11445",
    lat: 59.3373704,
    lng: 18.0842802,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11346",
    lat: 59.35097649999999,
    lng: 18.0476967,
  },
  {
    visitors: "4",
    City: "Stockholm",
    zipCode: "12032",
    lat: 59.30222449999999,
    lng: 18.084202,
  },
  {
    visitors: "4",
    City: "Göteborg",
    zipCode: "41744",
    lat: 57.75202369999999,
    lng: 11.9225768,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11542",
    lat: 59.36228120000001,
    lng: 18.0883292,
  },
  {
    visitors: "1",
    City: "Hägersten",
    zipCode: "12654",
    lat: 59.3057329,
    lng: 17.9880998,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11438",
    lat: 59.34064129999999,
    lng: 18.0796284,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11222",
    lat: 59.328492,
    lng: 18.0371168,
  },
  {
    visitors: "2",
    City: "Bålsta",
    zipCode: "74651",
    lat: 59.5726656,
    lng: 17.5340833,
  },
  {
    visitors: "1",
    City: "Bålsta",
    zipCode: "74651",
    lat: 59.5726656,
    lng: 17.5340833,
  },
  {
    visitors: "1",
    City: "Norsborg",
    zipCode: "14556",
    lat: 59.2426907,
    lng: 17.8471065,
  },
  {
    visitors: "1",
    City: "Uppsala",
    zipCode: "75341",
    lat: 59.85267689999999,
    lng: 17.655657,
  },
  {
    visitors: "1",
    City: "Örebro",
    zipCode: "70286",
    lat: 59.2607453,
    lng: 15.2694544,
  },
  {
    visitors: "1",
    City: "TÄBY",
    zipCode: "18768",
    lat: 59.46818320000001,
    lng: 18.0872061,
  },
  {
    visitors: "2",
    City: "Uppsala",
    zipCode: "75231",
    lat: 59.85765569999999,
    lng: 17.6066893,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11534",
    lat: 59.3452303,
    lng: 18.0904546,
  },
  {
    visitors: "2",
    City: "Årsta",
    zipCode: "12039",
    lat: 59.2999432,
    lng: 18.0715683,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11438",
    lat: 59.34064129999999,
    lng: 18.0796284,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11218",
    lat: 59.3340817,
    lng: 18.0169221,
  },
  {
    visitors: "2",
    City: "Mölnlycke",
    zipCode: "43537",
    lat: 57.65941789999999,
    lng: 12.1372607,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "12070",
    lat: 59.30728790000001,
    lng: 18.1111431,
  },
  {
    visitors: "1",
    City: "Johanneshov",
    zipCode: "12147",
    lat: 59.295174,
    lng: 18.0866781,
  },
  {
    visitors: "2",
    City: "Vaxholm",
    zipCode: "18531",
    lat: 59.4015532,
    lng: 18.3427724,
  },
  {
    visitors: "1",
    City: "Hägersten",
    zipCode: "12677",
    lat: 59.2829776,
    lng: 18.0107715,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11221",
    lat: 59.32820659999999,
    lng: 18.0430189,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11538",
    lat: 59.3455783,
    lng: 18.1036039,
  },
  {
    visitors: "2",
    City: "Berlin",
    zipCode: "10625",
    lat: 52.5087124,
    lng: 13.3137619,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11734",
    lat: 59.3163132,
    lng: 18.0364435,
  },
  {
    visitors: "2",
    City: "STOCKHOLM",
    zipCode: "11352",
    lat: 59.34406019999999,
    lng: 18.0583375,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11352",
    lat: 59.34406019999999,
    lng: 18.0583375,
  },
  {
    visitors: "1",
    City: "Djursholm",
    zipCode: "18267",
    lat: 59.41840750000001,
    lng: 18.0811077,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11242",
    lat: 59.3320932,
    lng: 18.0301356,
  },
  {
    visitors: "2",
    City: "Örebro",
    zipCode: "70210",
    lat: 59.271991,
    lng: 15.2093181,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11638",
    lat: 59.31045859999999,
    lng: 18.0890415,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11522",
    lat: 59.3366653,
    lng: 18.0930966,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11237",
    lat: 59.32907,
    lng: 18.0304668,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11829",
    lat: 59.3121569,
    lng: 18.0681005,
  },
  {
    visitors: "4",
    City: "Stockholm",
    zipCode: "11730",
    lat: 59.3179512,
    lng: 18.0366939,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11730",
    lat: 59.3179512,
    lng: 18.0366939,
  },
  {
    visitors: "1",
    City: "Johanneshov",
    zipCode: "12154",
    lat: 59.29641530000001,
    lng: 18.0755919,
  },
  {
    visitors: "2",
    City: "Sigtuna",
    zipCode: "19341",
    lat: 59.62932980000001,
    lng: 17.7587082,
  },
  {
    visitors: "1",
    City: "Sigtuna",
    zipCode: "19341",
    lat: 59.62932980000001,
    lng: 17.7587082,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11360",
    lat: 59.3393085,
    lng: 18.05384,
  },
  {
    visitors: "1",
    City: "Kungsängen",
    zipCode: "19634",
    lat: 59.4786646,
    lng: 17.7180895,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11865",
    lat: 59.31256999999999,
    lng: 18.0626161,
  },
  {
    visitors: "4",
    City: "Kalmar",
    zipCode: "39233",
    lat: 56.66133749999999,
    lng: 16.3548417,
  },
  {
    visitors: "1",
    City: "Bromma",
    zipCode: "16833",
    lat: 59.3303855,
    lng: 17.949443,
  },
  {
    visitors: "1",
    City: "Bromma",
    zipCode: "16869",
    lat: 59.3434396,
    lng: 17.9648737,
  },
  {
    visitors: "3",
    City: "London",
    zipCode: "SW9 6HE",
    lat: 51.4738561,
    lng: -0.1114872,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11443",
    lat: 59.340141,
    lng: 18.0831215,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11646",
    lat: 59.3185448,
    lng: 18.0728497,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11646",
    lat: 59.3185448,
    lng: 18.0728497,
  },
  {
    visitors: "2",
    City: "Lidingö",
    zipCode: "18155",
    lat: 59.34440350000001,
    lng: 18.1608684,
  },
  {
    visitors: "2",
    City: "Sundbyberg",
    zipCode: "17265",
    lat: 59.3589989,
    lng: 17.9731631,
  },
  {
    visitors: "2",
    City: "Smålandsstenar",
    zipCode: "33331",
    lat: 57.1622747,
    lng: 13.4195918,
  },
  {
    visitors: "1",
    City: "Varberg",
    zipCode: "43252",
    lat: 57.0950576,
    lng: 12.2442358,
  },
  {
    visitors: "1",
    City: "Tyresö",
    zipCode: "13552",
    lat: 59.2239751,
    lng: 18.1808606,
  },
  {
    visitors: "2",
    City: "Årsta",
    zipCode: "12054",
    lat: 59.298572,
    lng: 18.0499684,
  },
  {
    visitors: "2",
    City: "Bromma",
    zipCode: "16875",
    lat: 59.3435472,
    lng: 17.9379458,
  },
  {
    visitors: "1",
    City: "Bromma",
    zipCode: "16875",
    lat: 59.3435472,
    lng: 17.9379458,
  },
  {
    visitors: "3",
    City: "Malmö",
    zipCode: "21433",
    lat: 55.5957521,
    lng: 13.022847,
  },
  {
    visitors: "1",
    City: "SOLLENTUNA",
    zipCode: "19257",
    lat: 59.4329141,
    lng: 17.9950528,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11431",
    lat: 59.34151799999999,
    lng: 18.074805,
  },
  {
    visitors: "2",
    City: "Bromma",
    zipCode: "16762",
    lat: 59.32026920000001,
    lng: 17.9708925,
  },
  {
    visitors: "2",
    City: "Sundbyberg",
    zipCode: "17265",
    lat: 59.3589989,
    lng: 17.9731631,
  },
  {
    visitors: "1",
    City: "Pezenas",
    zipCode: "34120",
    lat: 43.461531,
    lng: 3.423193,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11850",
    lat: 59.3164285,
    lng: 18.0590482,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11850",
    lat: 59.3164285,
    lng: 18.0590482,
  },
  {
    visitors: "1",
    City: "Upplands Väsby",
    zipCode: "19463",
    lat: 59.510337,
    lng: 17.9374637,
  },
  {
    visitors: "1",
    City: "Solna",
    zipCode: "16959",
    lat: 59.36393449999999,
    lng: 18.0064324,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11361",
    lat: 59.3393414,
    lng: 18.0471034,
  },
  {
    visitors: "1",
    City: "Hägersten",
    zipCode: "12939",
    lat: 59.3079797,
    lng: 17.957958,
  },
  {
    visitors: "3",
    City: "Bromma",
    zipCode: "16838",
    lat: 59.3406511,
    lng: 17.9216682,
  },
  {
    visitors: "1",
    City: "Bromma",
    zipCode: "16763",
    lat: 59.3153931,
    lng: 17.9685741,
  },
  {
    visitors: "1",
    City: "Göteborg",
    zipCode: "41877",
    lat: 57.7051896,
    lng: 11.9006932,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "12032",
    lat: 59.30222449999999,
    lng: 18.084202,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11737",
    lat: 59.31781279999999,
    lng: 18.0285502,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11352",
    lat: 59.34406019999999,
    lng: 18.0583375,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11733",
    lat: 59.32239069999999,
    lng: 18.0290508,
  },
  {
    visitors: "1",
    City: "Hisings Backa",
    zipCode: "42241",
    lat: 57.7309923,
    lng: 11.969512,
  },
  {
    visitors: "2",
    City: "Tyresö",
    zipCode: "13544",
    lat: 59.236394,
    lng: 18.2287399,
  },
  {
    visitors: "1",
    City: "STOCKHOLM",
    zipCode: "11628",
    lat: 59.3181016,
    lng: 18.0902238,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11852",
    lat: 59.315929,
    lng: 18.0625382,
  },
  {
    visitors: "1",
    City: "STOCKHOLM",
    zipCode: "11358",
    lat: 59.3404938,
    lng: 18.0611609,
  },
  {
    visitors: "1",
    City: "Lidingö",
    zipCode: "18159",
    lat: 59.35316979999999,
    lng: 18.1544137,
  },
  {
    visitors: "1",
    City: "Johanneshov",
    zipCode: "12143",
    lat: 59.2968405,
    lng: 18.0750514,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11430",
    lat: 59.3418453,
    lng: 18.0673185,
  },
  {
    visitors: "1",
    City: "Hägersten",
    zipCode: "12637",
    lat: 59.2984889,
    lng: 17.998483,
  },
  {
    visitors: "2",
    City: "Bromma",
    zipCode: "16853",
    lat: 59.352842,
    lng: 17.8933748,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11238",
    lat: 59.3316776,
    lng: 18.0330452,
  },
  {
    visitors: "1",
    City: "Värnamo",
    zipCode: "33153",
    lat: 57.1753878,
    lng: 14.012887,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11215",
    lat: 59.338047,
    lng: 18.0050293,
  },
  {
    visitors: "2",
    City: "Malmö",
    zipCode: "21427",
    lat: 55.5872407,
    lng: 13.0101759,
  },
  {
    visitors: "1",
    City: "Solna",
    zipCode: "16958",
    lat: 59.36422280000001,
    lng: 18.0031042,
  },
  {
    visitors: "2",
    City: "Göteborg",
    zipCode: "41658",
    lat: 57.71272820000001,
    lng: 12.0099206,
  },
  {
    visitors: "2",
    City: "Saltsjöbaden",
    zipCode: "13334",
    lat: 59.2803437,
    lng: 18.2849323,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11825",
    lat: 59.3217552,
    lng: 18.0543176,
  },
  {
    visitors: "1",
    City: "Järfälla",
    zipCode: "17675",
    lat: 59.4641811,
    lng: 17.8002033,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11448",
    lat: 59.33879330000001,
    lng: 18.0795415,
  },
  {
    visitors: "1",
    City: "Gustavsberg",
    zipCode: "13443",
    lat: 59.31477,
    lng: 18.4065336,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11442",
    lat: 59.3361895,
    lng: 18.0795363,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11427",
    lat: 59.34538079999999,
    lng: 18.0738136,
  },
  {
    visitors: "1",
    City: "Sundbyberg",
    zipCode: "17451",
    lat: 59.3770764,
    lng: 17.97946,
  },
  {
    visitors: "4",
    City: "Stockholm",
    zipCode: "11327",
    lat: 59.343504,
    lng: 18.0505177,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11153",
    lat: 59.3312634,
    lng: 18.0684656,
  },
  {
    visitors: "2",
    City: "Solna",
    zipCode: "16979",
    lat: 59.37008409999999,
    lng: 18.00164,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11759",
    lat: 59.30601069999999,
    lng: 18.0265513,
  },
  {
    visitors: "1",
    City: "Enskede",
    zipCode: "12241",
    lat: 59.28009220000001,
    lng: 18.0439691,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11355",
    lat: 59.3464977,
    lng: 18.0595054,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11444",
    lat: 59.33762059999999,
    lng: 18.0825338,
  },
  {
    visitors: "3",
    City: "Sollentuna",
    zipCode: "19143",
    lat: 59.41423400000001,
    lng: 17.9850728,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11336",
    lat: 59.3394061,
    lng: 18.0336313,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11636",
    lat: 59.3141967,
    lng: 18.0902143,
  },
  {
    visitors: "2",
    City: "Lidingö",
    zipCode: "18139",
    lat: 59.34871450000001,
    lng: 18.1465674,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11335",
    lat: 59.3429678,
    lng: 18.0282274,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "12032",
    lat: 59.30222449999999,
    lng: 18.084202,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11730",
    lat: 59.3179512,
    lng: 18.0366939,
  },
  {
    visitors: "3",
    City: "Bandhagen",
    zipCode: "12432",
    lat: 59.272704,
    lng: 18.0436291,
  },
  {
    visitors: "1",
    City: "Huddinge",
    zipCode: "14146",
    lat: 59.2321537,
    lng: 17.989227,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11227",
    lat: 59.33123699999999,
    lng: 18.0478438,
  },
  {
    visitors: "1",
    City: "Bromma",
    zipCode: "16832",
    lat: 59.3319856,
    lng: 17.9512673,
  },
  {
    visitors: "1",
    City: "Chatou",
    zipCode: "78400",
    lat: 48.8977009,
    lng: 2.1541059,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11228",
    lat: 59.33442669999999,
    lng: 18.0463503,
  },
  {
    visitors: "4",
    City: "Tungelsta",
    zipCode: "13756",
    lat: 59.1030671,
    lng: 18.0445645,
  },
  {
    visitors: "1",
    City: "Solna",
    zipCode: "16967",
    lat: 59.363794,
    lng: 18.0230743,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11226",
    lat: 59.3325823,
    lng: 18.048843,
  },
  {
    visitors: "2",
    City: "Solna",
    zipCode: "17076",
    lat: 59.3809576,
    lng: 18.0383963,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11827",
    lat: 59.311614,
    lng: 18.0705928,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11343",
    lat: 59.3478588,
    lng: 18.0408724,
  },
  {
    visitors: "2",
    City: "Lidingö",
    zipCode: "18144",
    lat: 59.3774415,
    lng: 18.1383511,
  },
  {
    visitors: "3",
    City: "Lidingö",
    zipCode: "18148",
    lat: 59.3754397,
    lng: 18.1936855,
  },
  {
    visitors: "2",
    City: "Mörrum",
    zipCode: "37590",
    lat: 56.17479119999999,
    lng: 14.756367,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11442",
    lat: 59.3361895,
    lng: 18.0795363,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11636",
    lat: 59.3141967,
    lng: 18.0902143,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11624",
    lat: 59.3131745,
    lng: 18.0765799,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11624",
    lat: 59.3131745,
    lng: 18.0765799,
  },
  {
    visitors: "1",
    City: "Bandhagen",
    zipCode: "12467",
    lat: 59.2595901,
    lng: 18.0313385,
  },
  {
    visitors: "1",
    City: "Göteborg",
    zipCode: "41747",
    lat: 57.7494492,
    lng: 11.9269399,
  },
  {
    visitors: "1",
    City: "Lidingö",
    zipCode: "18166",
    lat: 59.3626675,
    lng: 18.2202833,
  },
  {
    visitors: "1",
    City: "Lidingö",
    zipCode: "18166",
    lat: 59.3626675,
    lng: 18.2202833,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11633",
    lat: 59.31377189999999,
    lng: 18.0853919,
  },
  {
    visitors: "2",
    City: "Hackås",
    zipCode: "84594",
    lat: 62.92558229999999,
    lng: 14.5160635,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11739",
    lat: 59.3140818,
    lng: 18.0325367,
  },
  {
    visitors: "2",
    City: "Johanneshov",
    zipCode: "12150",
    lat: 59.29641530000001,
    lng: 18.0755919,
  },
  {
    visitors: "1",
    City: "Enskededalen",
    zipCode: "12133",
    lat: 59.28385359999999,
    lng: 18.1007693,
  },
  {
    visitors: "1",
    City: "Enskededalen",
    zipCode: "12133",
    lat: 59.28385359999999,
    lng: 18.1007693,
  },
  {
    visitors: "1",
    City: "Hägersten",
    zipCode: "12948",
    lat: 59.29448960000001,
    lng: 17.971758,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11349",
    lat: 59.3475834,
    lng: 18.0545151,
  },
  {
    visitors: "1",
    City: "Enskede",
    zipCode: "12262",
    lat: 59.2814884,
    lng: 18.0861468,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11444",
    lat: 59.33762059999999,
    lng: 18.0825338,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11642",
    lat: 59.3092808,
    lng: 18.0868777,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11345",
    lat: 59.34790940000001,
    lng: 18.0470283,
  },
  {
    visitors: "1",
    City: "Älvsjö",
    zipCode: "12560",
    lat: 59.28138699999999,
    lng: 17.9697837,
  },
  {
    visitors: "1",
    City: "Sundbyberg",
    zipCode: "17230",
    lat: 59.3629989,
    lng: 17.977732,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "12062",
    lat: 59.30420359999999,
    lng: 18.0898573,
  },
  {
    visitors: "1",
    City: "Sollentuna",
    zipCode: "19137",
    lat: 59.4000153,
    lng: 17.9777622,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11553",
    lat: 59.3411242,
    lng: 18.1009289,
  },
  {
    visitors: "4",
    City: "Sköndal",
    zipCode: "12862",
    lat: 59.2642169,
    lng: 18.1080165,
  },
  {
    visitors: "2",
    City: "Sköndal",
    zipCode: "12862",
    lat: 59.2642169,
    lng: 18.1080165,
  },
  {
    visitors: "1",
    City: "Johanneshov",
    zipCode: "12154",
    lat: 59.29641530000001,
    lng: 18.0755919,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11624",
    lat: 59.3131745,
    lng: 18.0765799,
  },
  {
    visitors: "1",
    City: "Handen",
    zipCode: "13639",
    lat: 59.156349,
    lng: 18.1394217,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11632",
    lat: 59.314452,
    lng: 18.0936233,
  },
  {
    visitors: "2",
    City: "Bromma",
    zipCode: "16870",
    lat: 59.3639633,
    lng: 17.9533279,
  },
  {
    visitors: "2",
    City: "Älta",
    zipCode: "13830",
    lat: 59.2577092,
    lng: 18.1790571,
  },
  {
    visitors: "4",
    City: "Stockholm",
    zipCode: "11448",
    lat: 59.33879330000001,
    lng: 18.0795415,
  },
  {
    visitors: "1",
    City: "Skarpnäck",
    zipCode: "12830",
    lat: 59.2648624,
    lng: 18.1424605,
  },
  {
    visitors: "1",
    City: "BANDHAGEN",
    zipCode: "12431",
    lat: 59.2670079,
    lng: 18.0522521,
  },
  {
    visitors: "2",
    City: "Enskede",
    zipCode: "12266",
    lat: 59.2690316,
    lng: 18.0588936,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11353",
    lat: 59.3454055,
    lng: 18.0593376,
  },
  {
    visitors: "1",
    City: "Huddinge",
    zipCode: "14151",
    lat: 59.2102383,
    lng: 17.9448751,
  },
  {
    visitors: "1",
    City: "Hägersten",
    zipCode: "12957",
    lat: 59.28603409999999,
    lng: 17.9524659,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11741",
    lat: 59.31435019999999,
    lng: 18.0436713,
  },
  {
    visitors: "1",
    City: "Lidingö",
    zipCode: "18139",
    lat: 59.34871450000001,
    lng: 18.1465674,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11460",
    lat: 59.3356541,
    lng: 18.0897666,
  },
  {
    visitors: "1",
    City: "Saltsjö-Boo",
    zipCode: "13252",
    lat: 59.32754300000001,
    lng: 18.2623561,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11429",
    lat: 59.34096289999999,
    lng: 18.0669843,
  },
  {
    visitors: "1",
    City: "Karlskrona",
    zipCode: "37137",
    lat: 56.1648911,
    lng: 15.5652547,
  },
  {
    visitors: "2",
    City: "Farsta",
    zipCode: "12361",
    lat: 59.2548802,
    lng: 18.0875745,
  },
  {
    visitors: "2",
    City: "Hägersten",
    zipCode: "12632",
    lat: 59.30246469999999,
    lng: 18.0149229,
  },
  {
    visitors: "1",
    City: "Solna",
    zipCode: "17169",
    lat: 59.3527142,
    lng: 18.0064531,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11861",
    lat: 59.3054695,
    lng: 18.0589493,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11558",
    lat: 59.3456167,
    lng: 18.0994429,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11558",
    lat: 59.3456167,
    lng: 18.0994429,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11559",
    lat: 59.3452485,
    lng: 18.1110931,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11446",
    lat: 59.3361436,
    lng: 18.0759595,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11540",
    lat: 59.3465891,
    lng: 18.1069357,
  },
  {
    visitors: "2",
    City: "Uppsala",
    zipCode: "75326",
    lat: 59.85813640000001,
    lng: 17.6545551,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11248",
    lat: 59.338765,
    lng: 18.0263967,
  },
  {
    visitors: "1",
    City: "Trollhättan",
    zipCode: "46135",
    lat: 58.2780377,
    lng: 12.2828897,
  },
  {
    visitors: "1",
    City: "Göteborg",
    zipCode: "41664",
    lat: 57.7127834,
    lng: 11.9943576,
  },
  {
    visitors: "1",
    City: "Hägersten",
    zipCode: "12934",
    lat: 59.30122799999999,
    lng: 17.9818769,
  },
  {
    visitors: "2",
    City: "Gustavsberg",
    zipCode: "13432",
    lat: 59.35079820000001,
    lng: 18.3535907,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11544",
    lat: 59.35434199999999,
    lng: 18.0878954,
  },
  {
    visitors: "2",
    City: "Bromma",
    zipCode: "16756",
    lat: 59.33134639999999,
    lng: 17.9662235,
  },
  {
    visitors: "1",
    City: "Stockholms län",
    zipCode: "11560",
    lat: 59.34379910000001,
    lng: 18.1118038,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11826",
    lat: 59.3156423,
    lng: 18.068439,
  },
  {
    visitors: "2",
    City: "Bålsta",
    zipCode: "74635",
    lat: 59.56712969999999,
    lng: 17.5094848,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11217",
    lat: 59.3387484,
    lng: 18.0160865,
  },
  {
    visitors: "2",
    City: "Huddinge",
    zipCode: "14146",
    lat: 59.2321537,
    lng: 17.989227,
  },
  {
    visitors: "2",
    City: "Gustavsberg",
    zipCode: "13439",
    lat: 59.3084386,
    lng: 18.3784562,
  },
  {
    visitors: "1",
    City: "Bandhagen",
    zipCode: "12432",
    lat: 59.272704,
    lng: 18.0436291,
  },
  {
    visitors: "1",
    City: "Sundbyberg",
    zipCode: "17231",
    lat: 59.3633973,
    lng: 17.9670846,
  },
  {
    visitors: "1",
    City: "Bandhagen",
    zipCode: "12465",
    lat: 59.2678465,
    lng: 18.0515894,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11233",
    lat: 59.33592359999999,
    lng: 18.0358744,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11233",
    lat: 59.33592359999999,
    lng: 18.0358744,
  },
  {
    visitors: "2",
    City: "Solna",
    zipCode: "16937",
    lat: 59.369272,
    lng: 17.984629,
  },
  {
    visitors: "1",
    City: "Hägersten",
    zipCode: "12930",
    lat: 59.2931754,
    lng: 17.9862008,
  },
  {
    visitors: "1",
    City: "Sollentuna",
    zipCode: "19147",
    lat: 59.43382620000001,
    lng: 17.9466994,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11238",
    lat: 59.3316776,
    lng: 18.0330452,
  },
  {
    visitors: "1",
    City: "Norrköping",
    zipCode: "60220",
    lat: 58.5938531,
    lng: 16.1761529,
  },
  {
    visitors: "2",
    City: "Årsta",
    zipCode: "12039",
    lat: 59.2999432,
    lng: 18.0715683,
  },
  {
    visitors: "4",
    City: "Tyresö",
    zipCode: "13544",
    lat: 59.236394,
    lng: 18.2287399,
  },
  {
    visitors: "1",
    City: "Tyresö",
    zipCode: "13544",
    lat: 59.236394,
    lng: 18.2287399,
  },
];

const darkMapStyles = [
  {
    elementType: "geometry",
    stylers: [
      {
        color: "#242f3e",
      },
    ],
  },
  {
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#746855",
      },
    ],
  },
  {
    elementType: "labels.text.stroke",
    stylers: [
      {
        color: "#242f3e",
      },
    ],
  },
  {
    featureType: "administrative",
    elementType: "geometry",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "administrative.locality",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#d59563",
      },
    ],
  },
  {
    featureType: "administrative.neighborhood",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "poi",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "poi",
    elementType: "labels.text",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "poi",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#d59563",
      },
    ],
  },
  {
    featureType: "poi.park",
    elementType: "geometry",
    stylers: [
      {
        color: "#263c3f",
      },
    ],
  },
  {
    featureType: "poi.park",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#6b9a76",
      },
    ],
  },
  {
    featureType: "road",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "road",
    elementType: "geometry",
    stylers: [
      {
        color: "#38414e",
      },
    ],
  },
  {
    featureType: "road",
    elementType: "geometry.stroke",
    stylers: [
      {
        color: "#212a37",
      },
    ],
  },
  {
    featureType: "road",
    elementType: "labels",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "road",
    elementType: "labels.icon",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "road",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#9ca5b3",
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "geometry",
    stylers: [
      {
        color: "#746855",
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "geometry.stroke",
    stylers: [
      {
        color: "#1f2835",
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#f3d19c",
      },
    ],
  },
  {
    featureType: "transit",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "transit",
    elementType: "geometry",
    stylers: [
      {
        color: "#2f3948",
      },
    ],
  },
  {
    featureType: "transit.station",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#d59563",
      },
    ],
  },
  {
    featureType: "water",
    elementType: "geometry",
    stylers: [
      {
        color: "#17263c",
      },
    ],
  },
  {
    featureType: "water",
    elementType: "geometry.stroke",
    stylers: [
      {
        color: "#ffeb3b",
      },
      {
        visibility: "on",
      },
      {
        weight: 8,
      },
    ],
  },
  {
    featureType: "water",
    elementType: "labels.text",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "water",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#515c6d",
      },
    ],
  },
  {
    featureType: "water",
    elementType: "labels.text.stroke",
    stylers: [
      {
        color: "#17263c",
      },
    ],
  },
];

const travelDataD = [
  {
    visitors: "1",
    City: "San Francisco",
    zipCode: "94109-6023",
    lat: 37.7929789,
    lng: -122.4212424,
  },
  {
    visitors: "2",
    City: "Dundas",
    zipCode: "L9H 4N8",
    lat: 43.2599259,
    lng: -79.940868,
  },
  {
    visitors: "3",
    City: "Årsta",
    zipCode: "12054",
    lat: 59.298572,
    lng: 18.0499684,
  },
  {
    visitors: "2",
    City: "Årsta",
    zipCode: "12057",
    lat: 59.2965475,
    lng: 18.0433218,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11255",
    lat: 59.3304997,
    lng: 18.0061127,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11622",
    lat: 59.3183437,
    lng: 18.0807468,
  },
  {
    visitors: "2",
    City: "Spånga",
    zipCode: "16347",
    lat: 59.370147,
    lng: 17.891974,
  },
  {
    visitors: "1",
    City: "Hägersten",
    zipCode: "12647",
    lat: 59.30310060000001,
    lng: 17.9948274,
  },
  {
    visitors: "1",
    City: "Johanneshov",
    zipCode: "12154",
    lat: 59.2872186,
    lng: 18.1084212,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11252",
    lat: 59.3371223,
    lng: 17.9987951,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11750",
    lat: 59.3189319,
    lng: 18.0194102,
  },
  {
    visitors: "1",
    City: "Edmonton",
    zipCode: "T6R 0V3",
    lat: 53.4452181,
    lng: -113.552485,
  },
  {
    visitors: "3",
    City: "Trångsund",
    zipCode: "14264",
    lat: 59.2333647,
    lng: 18.1570187,
  },
  {
    visitors: "2",
    City: "Västerås",
    zipCode: "72338",
    lat: 59.6294025,
    lng: 16.5720828,
  },
  {
    visitors: "1",
    City: "Uppsala",
    zipCode: "75220",
    lat: 59.8684038,
    lng: 17.6125938,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11252",
    lat: 59.3371223,
    lng: 17.9987951,
  },
  {
    visitors: "2",
    City: "Täby",
    zipCode: "18371",
    lat: 59.4360563,
    lng: 18.0774666,
  },
  {
    visitors: "1",
    City: "Huddinge",
    zipCode: "14141",
    lat: 59.2556161,
    lng: 17.9706454,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11727",
    lat: 59.3188049,
    lng: 18.046335,
  },
  {
    visitors: "1",
    City: "Bagarmossen",
    zipCode: "12847",
    lat: 59.2827044,
    lng: 18.1346562,
  },
  {
    visitors: "1",
    City: "Tullinge",
    zipCode: "14646",
    lat: 59.2109972,
    lng: 17.9134321,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11728",
    lat: 59.3176261,
    lng: 18.044173,
  },
  {
    visitors: "2",
    City: "Huddinge",
    zipCode: "14139",
    lat: 59.2596728,
    lng: 17.9839052,
  },
  {
    visitors: "1",
    City: "Uppsala",
    zipCode: "75318",
    lat: 59.8471124,
    lng: 17.6614483,
  },
  {
    visitors: "1",
    City: "Calgary",
    zipCode: "T2Y 3N5",
    lat: 50.9002875,
    lng: -114.0976334,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11726",
    lat: 59.3169608,
    lng: 18.0488263,
  },
  {
    visitors: "2",
    City: "Sevelen",
    zipCode: "9475",
    lat: 47.1251828,
    lng: 9.478797199999999,
  },
  {
    visitors: "4",
    City: "Lidingö",
    zipCode: "18141",
    lat: 59.3542694,
    lng: 18.1855698,
  },
  {
    visitors: "1",
    City: "Hägersten",
    zipCode: "12635",
    lat: 59.30018450000001,
    lng: 18.0074495,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11230",
    lat: 59.33273399999999,
    lng: 18.037369,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11829",
    lat: 59.3121569,
    lng: 18.0681005,
  },
  {
    visitors: "1",
    City: "Johanneshov",
    zipCode: "12154",
    lat: 59.2872186,
    lng: 18.1084212,
  },
  {
    visitors: "2",
    City: "Upplands Väsby",
    zipCode: "19467",
    lat: 59.4881501,
    lng: 17.9468851,
  },
  {
    visitors: "1",
    City: "Tyresö",
    zipCode: "13543",
    lat: 59.24025160000001,
    lng: 18.2380671,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11646",
    lat: 59.3185448,
    lng: 18.0728497,
  },
  {
    visitors: "3",
    City: "Stockholm",
    zipCode: "11233",
    lat: 59.33592359999999,
    lng: 18.0358744,
  },
  {
    visitors: "1",
    City: "SANTA CRUZ DE TENERIFE",
    zipCode: "38007",
    lat: 28.4640211,
    lng: -16.2669247,
  },
  {
    visitors: "4",
    City: "Johanneshov",
    zipCode: "12146",
    lat: 59.29829720000001,
    lng: 18.1038021,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "12070",
    lat: 59.30728790000001,
    lng: 18.1111431,
  },
  {
    visitors: "1",
    City: "Appenweier",
    zipCode: "77767",
    lat: 48.5476784,
    lng: 7.9744954,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11535",
    lat: 59.3450277,
    lng: 18.0983595,
  },
  {
    visitors: "1",
    City: "Göteborg",
    zipCode: "41522",
    lat: 57.7377142,
    lng: 12.0194705,
  },
  {
    visitors: "2",
    City: "Årsta",
    zipCode: "12039",
    lat: 59.2999432,
    lng: 18.0715683,
  },
  {
    visitors: "1",
    City: "Ichikawa",
    zipCode: "2720123",
    lat: 35.6796936,
    lng: 139.927079,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11421",
    lat: 59.3523354,
    lng: 18.0590139,
  },
  {
    visitors: "1",
    City: "Nacka",
    zipCode: "13172",
    lat: 59.31745950000001,
    lng: 18.1258105,
  },
  {
    visitors: "2",
    City: "Oslo",
    zipCode: "0556",
    lat: 59.9266659,
    lng: 10.7625752,
  },
  {
    visitors: "2",
    City: "Hägersten",
    zipCode: "12628",
    lat: 59.29790389999999,
    lng: 17.9999777,
  },
  {
    visitors: "1",
    City: "Hägersten",
    zipCode: "12629",
    lat: 59.2974064,
    lng: 18.0034648,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11860",
    lat: 59.3039771,
    lng: 18.0745671,
  },
  {
    visitors: "1",
    City: "HENDAYE",
    zipCode: "64700",
    lat: 43.340423,
    lng: -1.7392812,
  },
  {
    visitors: "1",
    City: "Årsta",
    zipCode: "12060",
    lat: 59.3019122,
    lng: 18.0370145,
  },
  {
    visitors: "1",
    City: "Solna",
    zipCode: "16939",
    lat: 59.36680390000001,
    lng: 17.9902061,
  },
  {
    visitors: "1",
    City: "BARCELONA",
    zipCode: "08021",
    lat: 41.397617,
    lng: 2.1413415,
  },
  {
    visitors: "3",
    City: "Stockholm",
    zipCode: "16361",
    lat: 59.3903559,
    lng: 17.9068651,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "31006",
    lat: 59.32932349999999,
    lng: 18.0685808,
  },
  {
    visitors: "1",
    City: "Oslo",
    zipCode: "0752",
    lat: 59.9414893,
    lng: 10.6441288,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11361",
    lat: 59.3393414,
    lng: 18.0471034,
  },
  {
    visitors: "3",
    City: "Göteborg",
    zipCode: "41762",
    lat: 57.70298049999999,
    lng: 11.9163353,
  },
  {
    visitors: "2",
    City: "Farsta",
    zipCode: "12361",
    lat: 59.2548802,
    lng: 18.0875745,
  },
  {
    visitors: "1",
    City: "Farsta",
    zipCode: "12361",
    lat: 59.2548802,
    lng: 18.0875745,
  },
  {
    visitors: "1",
    City: "Enskede",
    zipCode: "12241",
    lat: 59.28009220000001,
    lng: 18.0439691,
  },
  {
    visitors: "1",
    City: "Sollentuna",
    zipCode: "191 44",
    lat: 59.41953699999999,
    lng: 17.9477363,
  },
  {
    visitors: "1",
    City: "Spånga",
    zipCode: "16343",
    lat: 59.37175299999999,
    lng: 17.927891,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11830",
    lat: 59.3119331,
    lng: 18.0742087,
  },
  {
    visitors: "1",
    City: "Uppsala",
    zipCode: "75444",
    lat: 59.8682314,
    lng: 17.6791596,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11638",
    lat: 59.31045859999999,
    lng: 18.0890415,
  },
  {
    visitors: "1",
    City: "Bandhagen",
    zipCode: "12455",
    lat: 59.26699319999999,
    lng: 18.0419635,
  },
  {
    visitors: "1",
    City: "Göteborg",
    zipCode: "41468",
    lat: 57.6867861,
    lng: 11.9315504,
  },
  {
    visitors: "1",
    City: "Söderhamn",
    zipCode: "82630",
    lat: 61.30381989999999,
    lng: 17.0589494,
  },
  {
    visitors: "2",
    City: "Sundbyberg",
    zipCode: "17273",
    lat: 59.3673762,
    lng: 17.9613382,
  },
  {
    visitors: "1",
    City: "Gustavsberg",
    zipCode: "13434",
    lat: 59.3356305,
    lng: 18.3861127,
  },
  {
    visitors: "1",
    City: "Hägersten",
    zipCode: "12944",
    lat: 59.2931988,
    lng: 17.9573203,
  },
  {
    visitors: "3",
    City: "Årsta",
    zipCode: "12060",
    lat: 59.3019122,
    lng: 18.0370145,
  },
  {
    visitors: "2",
    City: "Solna",
    zipCode: "16939",
    lat: 59.36680390000001,
    lng: 17.9902061,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11637",
    lat: 59.31242160000001,
    lng: 18.0792381,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11138",
    lat: 59.3376433,
    lng: 18.0654819,
  },
  {
    visitors: "2",
    City: "Årsta",
    zipCode: "12056",
    lat: 59.2925275,
    lng: 18.0506254,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11229",
    lat: 59.3324448,
    lng: 18.0406945,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11726",
    lat: 59.3169608,
    lng: 18.0488263,
  },
  {
    visitors: "2",
    City: "Årsta",
    zipCode: "12053",
    lat: 59.300929,
    lng: 18.0542907,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11368",
    lat: 59.3483105,
    lng: 18.0338022,
  },
  {
    visitors: "1",
    City: "Lidingö",
    zipCode: "18161",
    lat: 59.3398201,
    lng: 18.1721703,
  },
  {
    visitors: "1",
    City: "Norrköping",
    zipCode: "60235",
    lat: 58.5821652,
    lng: 16.1899022,
  },
  {
    visitors: "1",
    City: "Hässelby",
    zipCode: "16553",
    lat: 59.3703538,
    lng: 17.8484156,
  },
  {
    visitors: "2",
    City: "Zürich",
    zipCode: "8005",
    lat: 47.3893214,
    lng: 8.5131587,
  },
  {
    visitors: "1",
    City: "Hägersten",
    zipCode: "12635",
    lat: 59.30018450000001,
    lng: 18.0074495,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11756",
    lat: 59.3036688,
    lng: 18.0325305,
  },
  {
    visitors: "1",
    City: "Lidingö",
    zipCode: "18148",
    lat: 59.3754397,
    lng: 18.1936855,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11559",
    lat: 59.3452485,
    lng: 18.1110931,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11750",
    lat: 59.3189319,
    lng: 18.0194102,
  },
  {
    visitors: "1",
    City: "Munsö",
    zipCode: "17891",
    lat: 59.3757512,
    lng: 17.5987563,
  },
  {
    visitors: "2",
    City: "NORTH PERTH",
    zipCode: "6906",
    lat: -31.9273844,
    lng: 115.852492,
  },
  {
    visitors: "1",
    City: "'S-GRAVENHAGE",
    zipCode: "2561 TX",
    lat: 52.0769986,
    lng: 4.280090299999999,
  },
  {
    visitors: "1",
    City: "Reno",
    zipCode: "89511-4761",
    lat: 39.3809028,
    lng: -119.9277947,
  },
  {
    visitors: "2",
    City: "Lund",
    zipCode: "22646",
    lat: 55.7222132,
    lng: 13.2176092,
  },
  {
    visitors: "2",
    City: "Nacka Strand",
    zipCode: "13152",
    lat: 59.31764539999999,
    lng: 18.1607449,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "12079",
    lat: 59.3031194,
    lng: 18.0974169,
  },
  {
    visitors: "1",
    City: "Örebro",
    zipCode: "70224",
    lat: 59.2660965,
    lng: 15.2093626,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11639",
    lat: 59.3102435,
    lng: 18.084054,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11824",
    lat: 59.31996059999999,
    lng: 18.06296,
  },
  {
    visitors: "2",
    City: "Enskededalen",
    zipCode: "12132",
    lat: 59.288124,
    lng: 18.0891528,
  },
  {
    visitors: "2",
    City: "Älvsjö",
    zipCode: "12573",
    lat: 59.2884782,
    lng: 18.0373359,
  },
  {
    visitors: "2",
    City: "Lima",
    zipCode: "78064",
    lat: 60.91627320000001,
    lng: 13.1691588,
  },
  {
    visitors: "1",
    City: "Sundbyberg",
    zipCode: "17443",
    lat: 59.3737775,
    lng: 17.9686476,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11732",
    lat: 59.3198462,
    lng: 18.040352,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11640",
    lat: 59.31196459999999,
    lng: 18.0837254,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "12064",
    lat: 59.30459020000001,
    lng: 18.0988338,
  },
  {
    visitors: "2",
    City: "Solna",
    zipCode: "16959",
    lat: 59.36393449999999,
    lng: 18.0064324,
  },
  {
    visitors: "1",
    City: "Uppsala",
    zipCode: "75426",
    lat: 59.8789732,
    lng: 17.652243,
  },
  {
    visitors: "1",
    City: "Mirassol",
    zipCode: "15130055",
    lat: -20.8153322,
    lng: -49.51320579999999,
  },
  {
    visitors: "1",
    City: "Sollentuna",
    zipCode: "19143",
    lat: 59.41423400000001,
    lng: 17.9850728,
  },
  {
    visitors: "2",
    City: "Winterthur",
    zipCode: "8400",
    lat: 47.485687,
    lng: 8.7292922,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11539",
    lat: 59.34788439999999,
    lng: 18.0991992,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11827",
    lat: 59.311614,
    lng: 18.0705928,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11448",
    lat: 59.33879330000001,
    lng: 18.0795415,
  },
  {
    visitors: "2",
    City: "Nacka",
    zipCode: "13139",
    lat: 59.31278,
    lng: 18.1790219,
  },
  {
    visitors: "2",
    City: "Hägersten",
    zipCode: "12653",
    lat: 59.30652240000001,
    lng: 17.9838643,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11761",
    lat: 59.31138329999999,
    lng: 18.0253904,
  },
  {
    visitors: "1",
    City: "Uppsala",
    zipCode: "75334",
    lat: 59.8780353,
    lng: 17.6320128,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11255",
    lat: 59.3304997,
    lng: 18.0061127,
  },
  {
    visitors: "2",
    City: "Nacka",
    zipCode: "13133",
    lat: 59.2911155,
    lng: 18.1692636,
  },
  {
    visitors: "1",
    City: "Enskede",
    zipCode: "12249",
    lat: 59.2609651,
    lng: 18.0818234,
  },
  {
    visitors: "2",
    City: "Hägersten",
    zipCode: "12629",
    lat: 59.2974064,
    lng: 18.0034648,
  },
  {
    visitors: "1",
    City: "Solna",
    zipCode: "17149",
    lat: 59.35145989999999,
    lng: 17.9843165,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11862",
    lat: 59.30889029999999,
    lng: 18.0753247,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11247",
    lat: 59.3357892,
    lng: 18.0303035,
  },
  {
    visitors: "2",
    City: "Gustavsberg",
    zipCode: "13431",
    lat: 59.328938,
    lng: 18.3706928,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11824",
    lat: 59.31996059999999,
    lng: 18.06296,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11740",
    lat: 59.31734159999999,
    lng: 18.0227335,
  },
  {
    visitors: "1",
    City: "Linghem",
    zipCode: "58565",
    lat: 58.4317822,
    lng: 15.7802153,
  },
  {
    visitors: "3",
    City: "Malmö",
    zipCode: "21438",
    lat: 55.59542769999999,
    lng: 13.0258558,
  },
  {
    visitors: "1",
    City: "Bandhagen",
    zipCode: "12432",
    lat: 59.272704,
    lng: 18.0436291,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11661",
    lat: 59.30814040000001,
    lng: 18.0805588,
  },
  {
    visitors: "1",
    City: "Tucson",
    zipCode: "85719-3068",
    lat: 32.2438145,
    lng: -110.9447027,
  },
  {
    visitors: "1",
    City: "Hässelby",
    zipCode: "16557",
    lat: 59.37057549999999,
    lng: 17.8389402,
  },
  {
    visitors: "1",
    City: "Hägersten",
    zipCode: "12632",
    lat: 59.30246469999999,
    lng: 18.0149229,
  },
  {
    visitors: "1",
    City: "Hägersten",
    zipCode: "12632",
    lat: 59.30246469999999,
    lng: 18.0149229,
  },
  {
    visitors: "2",
    City: "Uppsala",
    zipCode: "75329",
    lat: 59.8643491,
    lng: 17.6473871,
  },
  {
    visitors: "1",
    City: "Lerdala",
    zipCode: "54197",
    lat: 58.47676569999999,
    lng: 13.711188,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11140",
    lat: 59.3394881,
    lng: 18.0629894,
  },
  {
    visitors: "1",
    City: "Ljungsbro",
    zipCode: "58574",
    lat: 58.4977499,
    lng: 15.4943216,
  },
  {
    visitors: "1",
    City: "Nacka",
    zipCode: "13146",
    lat: 59.32235420000001,
    lng: 18.2106977,
  },
  {
    visitors: "1",
    City: "Årsta",
    zipCode: "12058",
    lat: 59.29982289999999,
    lng: 18.0438234,
  },
  {
    visitors: "2",
    City: "Nacka",
    zipCode: "13175",
    lat: 59.3160409,
    lng: 18.1423506,
  },
  {
    visitors: "1",
    City: "Bandhagen",
    zipCode: "12476",
    lat: 59.25863,
    lng: 18.0094438,
  },
  {
    visitors: "2",
    City: "Skarpnäck",
    zipCode: "12838",
    lat: 59.27395809999999,
    lng: 18.1126974,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11849",
    lat: 59.3179366,
    lng: 18.0563075,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11767",
    lat: 59.3179883,
    lng: 18.0077781,
  },
  {
    visitors: "1",
    City: "Saltsjö-Boo",
    zipCode: "13243",
    lat: 59.3169698,
    lng: 18.2479527,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11138",
    lat: 59.3376433,
    lng: 18.0654819,
  },
  {
    visitors: "1",
    City: "Bandhagen",
    zipCode: "12432",
    lat: 59.272704,
    lng: 18.0436291,
  },
  {
    visitors: "2",
    City: "Saltsjö-Boo",
    zipCode: "13236",
    lat: 59.3320304,
    lng: 18.3187143,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11822",
    lat: 59.31937139999999,
    lng: 18.0618785,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "12071",
    lat: 59.3087092,
    lng: 18.1038324,
  },
  {
    visitors: "1",
    City: "Helsinki",
    zipCode: "00150",
    lat: 60.15114759999999,
    lng: 24.9235532,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11642",
    lat: 59.3092808,
    lng: 18.0868777,
  },
  {
    visitors: "1",
    City: "Bromma",
    zipCode: "16738",
    lat: 59.33946329999999,
    lng: 17.9928083,
  },
  {
    visitors: "2",
    City: "Årsta",
    zipCode: "12050",
    lat: 59.2948111,
    lng: 18.0632524,
  },
  {
    visitors: "1",
    City: "Hägersten",
    zipCode: "12947",
    lat: 59.2927139,
    lng: 17.9659512,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11625",
    lat: 59.3159875,
    lng: 18.0764192,
  },
  {
    visitors: "2",
    City: "Johanneshov",
    zipCode: "12150",
    lat: 59.2979718,
    lng: 18.1164323,
  },
  {
    visitors: "1",
    City: "Bromma",
    zipCode: "16845",
    lat: 59.34257479999999,
    lng: 17.8871032,
  },
  {
    visitors: "3",
    City: "Halmstad",
    zipCode: "30293",
    lat: 56.66606,
    lng: 12.8093096,
  },
  {
    visitors: "2",
    City: "Johanneshov",
    zipCode: "12135",
    lat: 59.2906003,
    lng: 18.0887437,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11636",
    lat: 59.3141967,
    lng: 18.0902143,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11531",
    lat: 59.34258080000001,
    lng: 18.0868707,
  },
  {
    visitors: "2",
    City: "Hägersten",
    zipCode: "12632",
    lat: 59.30246469999999,
    lng: 18.0149229,
  },
  {
    visitors: "1",
    City: "Enskede",
    zipCode: "12240",
    lat: 59.26856259999999,
    lng: 18.0881073,
  },
  {
    visitors: "2",
    City: "Nacka",
    zipCode: "13136",
    lat: 59.308732,
    lng: 18.1347586,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11820",
    lat: 59.3203432,
    lng: 18.0667845,
  },
  {
    visitors: "1",
    City: "Bandhagen",
    zipCode: "12458",
    lat: 59.2618757,
    lng: 18.043949,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11625",
    lat: 59.3159875,
    lng: 18.0764192,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11625",
    lat: 59.3159875,
    lng: 18.0764192,
  },
  {
    visitors: "2",
    City: "Sollentuna",
    zipCode: "19164",
    lat: 59.4325558,
    lng: 17.9425355,
  },
  {
    visitors: "1",
    City: "Brooklyn",
    zipCode: "11221-6372",
    lat: 40.6903213,
    lng: -73.9271644,
  },
  {
    visitors: "1",
    City: "Rönninge",
    zipCode: "14431",
    lat: 59.20141570000001,
    lng: 17.7607207,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11553",
    lat: 59.3411242,
    lng: 18.1009289,
  },
  {
    visitors: "1",
    City: "Seoul",
    zipCode: "03007",
    lat: 37.56521290000001,
    lng: 126.9773517,
  },
  {
    visitors: "2",
    City: "Södertälje",
    zipCode: "15157",
    lat: 59.22748999999999,
    lng: 17.5692172,
  },
  {
    visitors: "2",
    City: "Zürich",
    zipCode: "8003",
    lat: 47.37220629999999,
    lng: 8.5162396,
  },
  {
    visitors: "2",
    City: "Brandon",
    zipCode: "IP27 9GD",
    lat: 52.38505989999999,
    lng: 0.5416854,
  },
  {
    visitors: "3",
    City: "Stockholm",
    zipCode: "11728",
    lat: 59.3176261,
    lng: 18.044173,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11526",
    lat: 59.3365032,
    lng: 18.0994189,
  },
  {
    visitors: "1",
    City: "London",
    zipCode: "SW1V 3QX",
    lat: 51.4865198,
    lng: -0.1351375,
  },
  {
    visitors: "1",
    City: "Northville",
    zipCode: "48168-2601",
    lat: 42.4311464,
    lng: -83.4832692,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11352",
    lat: 59.34406019999999,
    lng: 18.0583375,
  },
  {
    visitors: "1",
    City: "Årsta",
    zipCode: "12052",
    lat: 59.29488430000001,
    lng: 18.054947,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11523",
    lat: 59.33356089999999,
    lng: 18.0965827,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11853",
    lat: 59.3151166,
    lng: 18.0513174,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11264",
    lat: 59.32267,
    lng: 17.9958121,
  },
  {
    visitors: "1",
    City: "Täby",
    zipCode: "18768",
    lat: 59.46818320000001,
    lng: 18.0872061,
  },
  {
    visitors: "3",
    City: "Stockholm",
    zipCode: "11351",
    lat: 59.3447329,
    lng: 18.0588375,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11256",
    lat: 59.33132930000001,
    lng: 18.0002944,
  },
  {
    visitors: "1",
    City: "Årsta",
    zipCode: "12051",
    lat: 59.3016082,
    lng: 18.0599405,
  },
  {
    visitors: "1",
    City: "Hägersten",
    zipCode: "12930",
    lat: 59.2931754,
    lng: 17.9862008,
  },
  {
    visitors: "1",
    City: "Saltsjö-Duvnäs",
    zipCode: "13150",
    lat: 59.3008006,
    lng: 18.1982809,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "113 45",
    lat: 59.34790940000001,
    lng: 18.0470283,
  },
  {
    visitors: "1",
    City: "Farsta",
    zipCode: "12354",
    lat: 59.25389449999999,
    lng: 18.0733111,
  },
  {
    visitors: "1",
    City: "Hägersten",
    zipCode: "12953",
    lat: 59.28397870000001,
    lng: 17.9646367,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11359",
    lat: 59.3396915,
    lng: 18.0576664,
  },
  {
    visitors: "1",
    City: "Sundsvall",
    zipCode: "85243",
    lat: 62.38472100000001,
    lng: 17.3059514,
  },
  {
    visitors: "2",
    City: "Schlieren",
    zipCode: "8952",
    lat: 47.3983398,
    lng: 8.446563099999999,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11633",
    lat: 59.31377189999999,
    lng: 18.0853919,
  },
  {
    visitors: "2",
    City: "Årsta",
    zipCode: "12054",
    lat: 59.298572,
    lng: 18.0499684,
  },
  {
    visitors: "1",
    City: "Farsta",
    zipCode: "12358",
    lat: 59.25763719999999,
    lng: 18.0761314,
  },
  {
    visitors: "1",
    City: "Årsta",
    zipCode: "12039",
    lat: 59.2999432,
    lng: 18.0715683,
  },
  {
    visitors: "1",
    City: "Sundsvall",
    zipCode: "85242",
    lat: 62.3876202,
    lng: 17.2862441,
  },
  {
    visitors: "1",
    City: "Lidingö",
    zipCode: "18155",
    lat: 59.34440350000001,
    lng: 18.1608684,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11750",
    lat: 59.3189319,
    lng: 18.0194102,
  },
  {
    visitors: "5",
    City: "Stockholm",
    zipCode: "11761",
    lat: 59.31138329999999,
    lng: 18.0253904,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11425",
    lat: 59.3428511,
    lng: 18.0654899,
  },
  {
    visitors: "4",
    City: "Skärholmen",
    zipCode: "12732",
    lat: 59.30003349999999,
    lng: 17.9353987,
  },
  {
    visitors: "4",
    City: "Stockholm",
    zipCode: "11243",
    lat: 59.33270169999999,
    lng: 18.0167517,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11769",
    lat: 59.3149276,
    lng: 17.9875107,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11848",
    lat: 59.3179864,
    lng: 18.0624583,
  },
  {
    visitors: "1",
    City: "Lidingö",
    zipCode: "18146",
    lat: 59.37341960000001,
    lng: 18.1663352,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11630",
    lat: 59.3156702,
    lng: 18.0942083,
  },
  {
    visitors: "1",
    City: "Årsta",
    zipCode: "12053",
    lat: 59.300929,
    lng: 18.0542907,
  },
  {
    visitors: "1",
    City: "Enskede Gård",
    zipCode: "12047",
    lat: 59.2901978,
    lng: 18.0668989,
  },
  {
    visitors: "1",
    City: "Johanneshov",
    zipCode: "12154",
    lat: 59.2872186,
    lng: 18.1084212,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11326",
    lat: 59.34433610000001,
    lng: 18.044696,
  },
  {
    visitors: "3",
    City: "Örebro",
    zipCode: "70382",
    lat: 59.2837775,
    lng: 15.261588,
  },
  {
    visitors: "2",
    City: "Vicenza",
    zipCode: "36100",
    lat: 45.5461815,
    lng: 11.5413779,
  },
  {
    visitors: "2",
    City: "14467 Potsdam",
    zipCode: "14467",
    lat: 52.39976910000001,
    lng: 13.0665558,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11734",
    lat: 59.3163132,
    lng: 18.0364435,
  },
  {
    visitors: "1",
    City: "Lisboa",
    zipCode: "1170-378",
    lat: 38.7194937,
    lng: -9.1239852,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11824",
    lat: 59.31996059999999,
    lng: 18.06296,
  },
  {
    visitors: "1",
    City: "Johanneshov",
    zipCode: "12154",
    lat: 59.2872186,
    lng: 18.1084212,
  },
  {
    visitors: "1",
    City: "Gävle",
    zipCode: "80255",
    lat: 60.6656551,
    lng: 17.1580821,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11358",
    lat: 59.3404938,
    lng: 18.0611609,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11666",
    lat: 59.3079362,
    lng: 18.0858772,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11419",
    lat: 59.3598885,
    lng: 18.0530312,
  },
  {
    visitors: "1",
    City: "Farsta",
    zipCode: "12359",
    lat: 59.2556382,
    lng: 18.0900656,
  },
  {
    visitors: "1",
    City: "Hägersten",
    zipCode: "12635",
    lat: 59.30018450000001,
    lng: 18.0074495,
  },
  {
    visitors: "2",
    City: "Hägersten",
    zipCode: "12951",
    lat: 59.2846625,
    lng: 17.9702772,
  },
  {
    visitors: "1",
    City: "Jönköping",
    zipCode: "55313",
    lat: 57.7825547,
    lng: 14.1457433,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11259",
    lat: 59.32908450000001,
    lng: 18.013427,
  },
  {
    visitors: "1",
    City: "Nacka",
    zipCode: "13131",
    lat: 59.315617,
    lng: 18.1334547,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11824",
    lat: 59.31996059999999,
    lng: 18.06296,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11225",
    lat: 59.33027149999999,
    lng: 18.0480923,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11629",
    lat: 59.31471440000001,
    lng: 18.0833575,
  },
  {
    visitors: "1",
    City: "LE PECQ",
    zipCode: "78230",
    lat: 48.8934955,
    lng: 2.1089728,
  },
  {
    visitors: "1",
    City: "Vagnhärad",
    zipCode: "61994",
    lat: 58.9636027,
    lng: 17.5269353,
  },
  {
    visitors: "1",
    City: "Johanneshov",
    zipCode: "12135",
    lat: 59.2906003,
    lng: 18.0887437,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11750",
    lat: 59.3189319,
    lng: 18.0194102,
  },
  {
    visitors: "1",
    City: "Hägersten",
    zipCode: "12652",
    lat: 59.30729299999999,
    lng: 17.9915032,
  },
  {
    visitors: "1",
    City: "Hägersten",
    zipCode: "12932",
    lat: 59.2977865,
    lng: 17.9825444,
  },
  {
    visitors: "1",
    City: "Årsta",
    zipCode: "12056",
    lat: 59.2925275,
    lng: 18.0506254,
  },
  {
    visitors: "2",
    City: "Villars-Sur-Glâne",
    zipCode: "1752",
    lat: 46.7934868,
    lng: 7.116231,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11422",
    lat: 59.3481825,
    lng: 18.0633348,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11822",
    lat: 59.31937139999999,
    lng: 18.0618785,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11731",
    lat: 59.3186634,
    lng: 18.0356141,
  },
  {
    visitors: "2",
    City: "Årsta",
    zipCode: "12038",
    lat: 59.2980063,
    lng: 18.0669126,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11829",
    lat: 59.3121569,
    lng: 18.0681005,
  },
  {
    visitors: "1",
    City: "Oslo",
    zipCode: "0550",
    lat: 59.91899969999999,
    lng: 10.758037,
  },
  {
    visitors: "1",
    City: "Linköping",
    zipCode: "58227",
    lat: 58.4086037,
    lng: 15.6199407,
  },
  {
    visitors: "1",
    City: "Solna",
    zipCode: "16931",
    lat: 59.36121430000001,
    lng: 17.9889637,
  },
  {
    visitors: "1",
    City: "Moledo",
    zipCode: "4910-691",
    lat: 41.8490076,
    lng: -8.856517499999999,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11668",
    lat: 59.3057526,
    lng: 18.0855397,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11140",
    lat: 59.3394881,
    lng: 18.0629894,
  },
  {
    visitors: "1",
    City: "Eskilstuna",
    zipCode: "63360",
    lat: 59.3788103,
    lng: 16.5166282,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11862",
    lat: 59.30889029999999,
    lng: 18.0753247,
  },
  {
    visitors: "1",
    City: "Lausanne",
    zipCode: "1005",
    lat: 46.52013909999999,
    lng: 6.6427496,
  },
  {
    visitors: "1",
    City: "Skärholmen",
    zipCode: "12733",
    lat: 59.2925416,
    lng: 17.9279509,
  },
  {
    visitors: "1",
    City: "Hägersten",
    zipCode: "12634",
    lat: 59.3022899,
    lng: 18.0109368,
  },
  {
    visitors: "1",
    City: "Södertälje",
    zipCode: "15167",
    lat: 59.17210309999999,
    lng: 17.5993182,
  },
  {
    visitors: "1",
    City: "Enskede",
    zipCode: "12247",
    lat: 59.2727321,
    lng: 18.0642114,
  },
  {
    visitors: "4",
    City: "Stockholm",
    zipCode: "11624",
    lat: 59.3131745,
    lng: 18.0765799,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11826",
    lat: 59.3156423,
    lng: 18.068439,
  },
  {
    visitors: "2",
    City: "Mililani",
    zipCode: "96789",
    lat: 21.4513314,
    lng: -158.0152807,
  },
  {
    visitors: "1",
    City: "Sundsvall",
    zipCode: "85234",
    lat: 62.39617119999999,
    lng: 17.2934324,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "12236",
    lat: 59.32932349999999,
    lng: 18.0685808,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11429",
    lat: 59.34096289999999,
    lng: 18.0669843,
  },
  {
    visitors: "1",
    City: "Flint",
    zipCode: "48503-3554",
    lat: 43.0145534,
    lng: -83.6720756,
  },
  {
    visitors: "2",
    City: "Västerås",
    zipCode: "72359",
    lat: 59.60642389999999,
    lng: 16.5748394,
  },
  {
    visitors: "1",
    City: "Linköping",
    zipCode: "58224",
    lat: 58.4110533,
    lng: 15.6193685,
  },
  {
    visitors: "1",
    City: "Nacka",
    zipCode: "13171",
    lat: 59.31543139999999,
    lng: 18.1088404,
  },
  {
    visitors: "1",
    City: "Frankfurt am Main",
    zipCode: "60389",
    lat: 50.1495363,
    lng: 8.726027499999999,
  },
  {
    visitors: "1",
    City: "Bromma",
    zipCode: "16878",
    lat: 59.364959,
    lng: 17.9500921,
  },
  {
    visitors: "1",
    City: "Brighton",
    zipCode: "BN2 5JP",
    lat: 50.819076,
    lng: -0.1092769,
  },
  {
    visitors: "1",
    City: "Bromma",
    zipCode: "167 74",
    lat: 59.330921,
    lng: 17.9273459,
  },
  {
    visitors: "2",
    City: "Eskilstuna",
    zipCode: "63350",
    lat: 59.3669072,
    lng: 16.5310162,
  },
  {
    visitors: "1",
    City: "Årsta",
    zipCode: "12038",
    lat: 59.2980063,
    lng: 18.0669126,
  },
  {
    visitors: "1",
    City: "Bromma",
    zipCode: "16875",
    lat: 59.3435472,
    lng: 17.9379458,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11228",
    lat: 59.33442669999999,
    lng: 18.0463503,
  },
  {
    visitors: "1",
    City: "Enskede",
    zipCode: "12231",
    lat: 59.2839296,
    lng: 18.0924643,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11847",
    lat: 59.3174468,
    lng: 18.0675278,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11532",
    lat: 59.3429221,
    lng: 18.0922798,
  },
  {
    visitors: "1",
    City: "Waterford",
    zipCode: "X91X0W8",
    lat: 52.2438666,
    lng: -7.1194388,
  },
  {
    visitors: "1",
    City: "Uppsala",
    zipCode: "75646",
    lat: 59.8174854,
    lng: 17.6059542,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11528",
    lat: 59.3424775,
    lng: 18.1122499,
  },
  {
    visitors: "1",
    City: "Johanneshov",
    zipCode: "12153",
    lat: 59.2900858,
    lng: 18.1247144,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11634",
    lat: 59.31365819999999,
    lng: 18.097861,
  },
  {
    visitors: "3",
    City: "Stockholm",
    zipCode: "11244",
    lat: 59.3337853,
    lng: 18.011764,
  },
  {
    visitors: "1",
    City: "Motala",
    zipCode: "59127",
    lat: 58.5311181,
    lng: 15.0209584,
  },
  {
    visitors: "1",
    City: "Årsta",
    zipCode: "12057",
    lat: 59.2965475,
    lng: 18.0433218,
  },
  {
    visitors: "1",
    City: "Sollentuna",
    zipCode: "19146",
    lat: 59.4302185,
    lng: 17.9143775,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11825",
    lat: 59.3217552,
    lng: 18.0543176,
  },
  {
    visitors: "2",
    City: "Sundbyberg",
    zipCode: "17461",
    lat: 59.3810514,
    lng: 17.9514925,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11765",
    lat: 59.3119428,
    lng: 18.008444,
  },
  {
    visitors: "1",
    City: "Umeå",
    zipCode: "90729",
    lat: 63.8156737,
    lng: 20.3114674,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11249",
    lat: 59.3375856,
    lng: 18.0242345,
  },
  {
    visitors: "1",
    City: "Johanneshov",
    zipCode: "12137",
    lat: 59.2952652,
    lng: 18.093823,
  },
  {
    visitors: "1",
    City: "Vällingby",
    zipCode: "16268",
    lat: 59.3653564,
    lng: 17.8627368,
  },
  {
    visitors: "6",
    City: "Stockholm",
    zipCode: "11425",
    lat: 59.3428511,
    lng: 18.0654899,
  },
  {
    visitors: "2",
    City: "Oakham",
    zipCode: "LE159RE",
    lat: 52.59386310000001,
    lng: -0.7283137,
  },
  {
    visitors: "1",
    City: "Enskede",
    zipCode: "12234",
    lat: 59.28191320000001,
    lng: 18.0909642,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11771",
    lat: 59.3167961,
    lng: 17.9986892,
  },
  {
    visitors: "2",
    City: "Hägersten",
    zipCode: "12931",
    lat: 59.29174210000001,
    lng: 17.9832141,
  },
  {
    visitors: "3",
    City: "Stockholm",
    zipCode: "11367",
    lat: 59.34903049999999,
    lng: 18.0378786,
  },
  {
    visitors: "1",
    City: "Bandhagen",
    zipCode: "12455",
    lat: 59.26699319999999,
    lng: 18.0419635,
  },
  {
    visitors: "1",
    City: "Danderyd",
    zipCode: "18253",
    lat: 59.4023919,
    lng: 18.0420733,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11630",
    lat: 59.3156702,
    lng: 18.0942083,
  },
  {
    visitors: "1",
    City: "Bandhagen",
    zipCode: "12452",
    lat: 59.26656629999999,
    lng: 18.037151,
  },
  {
    visitors: "1",
    City: "Lidingö",
    zipCode: "18161",
    lat: 59.3398201,
    lng: 18.1721703,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11221",
    lat: 59.32820659999999,
    lng: 18.0430189,
  },
  {
    visitors: "1",
    City: "Herenthout",
    zipCode: "2270",
    lat: 51.1356598,
    lng: 4.7460081,
  },
  {
    visitors: "1",
    City: "New York",
    zipCode: "10035-1281",
    lat: 40.794065,
    lng: -73.9271644,
  },
  {
    visitors: "1",
    City: "North Hills",
    zipCode: "91343-7819",
    lat: 34.2386799,
    lng: -118.4805569,
  },
  {
    visitors: "1",
    City: "Enskededalen",
    zipCode: "12130",
    lat: 59.2875429,
    lng: 18.0957961,
  },
  {
    visitors: "1",
    City: "Vagnhärad",
    zipCode: "61994",
    lat: 58.9636027,
    lng: 17.5269353,
  },
  {
    visitors: "2",
    City: "Lidingö",
    zipCode: "18144",
    lat: 59.3774415,
    lng: 18.1383511,
  },
  {
    visitors: "1",
    City: "Solna",
    zipCode: "16960",
    lat: 59.359652,
    lng: 18.0077653,
  },
  {
    visitors: "1",
    City: "Årsta",
    zipCode: "12055",
    lat: 59.2963017,
    lng: 18.0476402,
  },
  {
    visitors: "2",
    City: "San Francisco",
    zipCode: "94133-7290",
    lat: 37.8059887,
    lng: -122.4099154,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "17234",
    lat: 59.36524049999999,
    lng: 17.9645861,
  },
  {
    visitors: "1",
    City: "Gustavsberg",
    zipCode: "13437",
    lat: 59.33391109999999,
    lng: 18.4081091,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11361",
    lat: 59.3393414,
    lng: 18.0471034,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11637",
    lat: 59.31242160000001,
    lng: 18.0792381,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11661",
    lat: 59.30814040000001,
    lng: 18.0805588,
  },
  {
    visitors: "3",
    City: "Stockholm",
    zipCode: "11854",
    lat: 59.3147506,
    lng: 18.0603755,
  },
  {
    visitors: "2",
    City: "Hägersten",
    zipCode: "12642",
    lat: 59.2904947,
    lng: 17.9893568,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11328",
    lat: 59.3416126,
    lng: 18.0494344,
  },
  {
    visitors: "1",
    City: "Bromma",
    zipCode: "16744",
    lat: 59.33467470000001,
    lng: 17.9924796,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11327",
    lat: 59.343504,
    lng: 18.0505177,
  },
  {
    visitors: "5",
    City: "Stockholm",
    zipCode: "11733",
    lat: 59.32239069999999,
    lng: 18.0290508,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11733",
    lat: 59.32239069999999,
    lng: 18.0290508,
  },
  {
    visitors: "1",
    City: "Vagnhärad",
    zipCode: "61994",
    lat: 58.9636027,
    lng: 17.5269353,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11741",
    lat: 59.31435019999999,
    lng: 18.0436713,
  },
  {
    visitors: "2",
    City: "Ennis",
    zipCode: "V95 W7C3",
    lat: 52.928926,
    lng: -9.3416675,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11737",
    lat: 59.31781279999999,
    lng: 18.0285502,
  },
  {
    visitors: "2",
    City: "Johanneshov",
    zipCode: "12144",
    lat: 59.2949099,
    lng: 18.0755459,
  },
  {
    visitors: "1",
    City: "Hägersten",
    zipCode: "12653",
    lat: 59.30652240000001,
    lng: 17.9838643,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "117 26",
    lat: 59.3169608,
    lng: 18.0488263,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11726",
    lat: 59.3169608,
    lng: 18.0488263,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11346",
    lat: 59.35097649999999,
    lng: 18.0476967,
  },
  {
    visitors: "2",
    City: "Hägersten",
    zipCode: "12931",
    lat: 59.29174210000001,
    lng: 17.9832141,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11338",
    lat: 59.3419191,
    lng: 18.0290587,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11848",
    lat: 59.3179864,
    lng: 18.0624583,
  },
  {
    visitors: "1",
    City: "Johanneshov",
    zipCode: "12136",
    lat: 59.292824,
    lng: 18.0875031,
  },
  {
    visitors: "1",
    City: "Nacka",
    zipCode: "13135",
    lat: 59.3082204,
    lng: 18.1227845,
  },
  {
    visitors: "1",
    City: "Saltsjö-Boo",
    zipCode: "13248",
    lat: 59.3263828,
    lng: 18.2240432,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11864",
    lat: 59.3131127,
    lng: 18.0601238,
  },
  {
    visitors: "3",
    City: "Stockholm",
    zipCode: "11624",
    lat: 59.3131745,
    lng: 18.0765799,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "12052",
    lat: 59.29488430000001,
    lng: 18.054947,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11758",
    lat: 59.3076962,
    lng: 18.0303731,
  },
  {
    visitors: "2",
    City: "Bagarmossen",
    zipCode: "12844",
    lat: 59.27263550000001,
    lng: 18.142589,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11750",
    lat: 59.3189319,
    lng: 18.0194102,
  },
  {
    visitors: "1",
    City: "Mölnbo",
    zipCode: "15396",
    lat: 59.0337179,
    lng: 17.4193674,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11240",
    lat: 59.33166970000001,
    lng: 18.0278909,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11240",
    lat: 59.33166970000001,
    lng: 18.0278909,
  },
  {
    visitors: "1",
    City: "London",
    zipCode: "SE22 0NQ",
    lat: 51.4520012,
    lng: -0.0595275,
  },
  {
    visitors: "2",
    City: "Brandbergen",
    zipCode: "13663",
    lat: 59.1691094,
    lng: 18.1643156,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11531",
    lat: 59.34258080000001,
    lng: 18.0868707,
  },
  {
    visitors: "1",
    City: "Älvsjö",
    zipCode: "12545",
    lat: 59.27927409999999,
    lng: 18.0054609,
  },
  {
    visitors: "1",
    City: "Hägersten",
    zipCode: "12944",
    lat: 59.2931988,
    lng: 17.9573203,
  },
  {
    visitors: "1",
    City: "Hägersten",
    zipCode: "12635",
    lat: 59.30018450000001,
    lng: 18.0074495,
  },
  {
    visitors: "1",
    City: "Årsta",
    zipCode: "12056",
    lat: 59.2925275,
    lng: 18.0506254,
  },
  {
    visitors: "1",
    City: "Norsborg",
    zipCode: "14565",
    lat: 59.2353432,
    lng: 17.8097478,
  },
  {
    visitors: "2",
    City: "Johanneshov",
    zipCode: "12138",
    lat: 59.29619499999999,
    lng: 18.1003061,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11215",
    lat: 59.338047,
    lng: 18.0050293,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11458",
    lat: 59.3369938,
    lng: 18.0856104,
  },
  {
    visitors: "1",
    City: "Bromma",
    zipCode: "16754",
    lat: 59.3266462,
    lng: 17.9678926,
  },
  {
    visitors: "2",
    City: "Johanneshov",
    zipCode: "12150",
    lat: 59.2979718,
    lng: 18.1164323,
  },
  {
    visitors: "1",
    City: "Kista",
    zipCode: "16453",
    lat: 59.40066789999999,
    lng: 17.9482831,
  },
  {
    visitors: "1",
    City: "Nacka",
    zipCode: "13165",
    lat: 59.3023385,
    lng: 18.1171117,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "12030",
    lat: 59.30197709999999,
    lng: 18.0885223,
  },
  {
    visitors: "1",
    City: "Skarpnäck",
    zipCode: "12838",
    lat: 59.27395809999999,
    lng: 18.1126974,
  },
  {
    visitors: "2",
    City: "Köln",
    zipCode: "50733",
    lat: 50.96482710000001,
    lng: 6.9538234,
  },
  {
    visitors: "1",
    City: "Västerås",
    zipCode: "72219",
    lat: 59.61869009999999,
    lng: 16.5488925,
  },
  {
    visitors: "1",
    City: "Lund",
    zipCode: "22354",
    lat: 55.696737,
    lng: 13.1920055,
  },
  {
    visitors: "2",
    City: "Älvsjö",
    zipCode: "125 47",
    lat: 59.2819643,
    lng: 18.0074521,
  },
  {
    visitors: "2",
    City: "Hässelby",
    zipCode: "16557",
    lat: 59.37057549999999,
    lng: 17.8389402,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11453",
    lat: 59.33413720000001,
    lng: 18.0847723,
  },
  {
    visitors: "3",
    City: "Stockholm",
    zipCode: "11666",
    lat: 59.3079362,
    lng: 18.0858772,
  },
  {
    visitors: "1",
    City: "Sundbyberg",
    zipCode: "17278",
    lat: 59.3649252,
    lng: 17.9746515,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11225",
    lat: 59.33027149999999,
    lng: 18.0480923,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11766",
    lat: 59.3159005,
    lng: 18.014591,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "12039",
    lat: 59.2999432,
    lng: 18.0715683,
  },
  {
    visitors: "2",
    City: "Älvsjö",
    zipCode: "12553",
    lat: 59.2722225,
    lng: 17.9636588,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11848",
    lat: 59.3179864,
    lng: 18.0624583,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11322",
    lat: 59.3408062,
    lng: 18.0433622,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11761",
    lat: 59.31138329999999,
    lng: 18.0253904,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11852",
    lat: 59.315929,
    lng: 18.0625382,
  },
  {
    visitors: "1",
    City: "Bromma",
    zipCode: "16838",
    lat: 59.3406511,
    lng: 17.9216682,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11249",
    lat: 59.3375856,
    lng: 18.0242345,
  },
  {
    visitors: "1",
    City: "Västerås",
    zipCode: "72358",
    lat: 59.603056,
    lng: 16.5693667,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11739",
    lat: 59.3140818,
    lng: 18.0325367,
  },
  {
    visitors: "1",
    City: "Lidingö",
    zipCode: "18141",
    lat: 59.3542694,
    lng: 18.1855698,
  },
  {
    visitors: "1",
    City: "Hägersten",
    zipCode: "12636",
    lat: 59.30151129999999,
    lng: 17.9981495,
  },
  {
    visitors: "1",
    City: "Mirassol",
    zipCode: "15130055",
    lat: -20.8153322,
    lng: -49.51320579999999,
  },
  {
    visitors: "2",
    City: "Edinburgh",
    zipCode: "EH3 5LL",
    lat: 55.9641372,
    lng: -3.1986332,
  },
  {
    visitors: "1",
    City: "Kumla",
    zipCode: "69233",
    lat: 59.13356419999999,
    lng: 15.1270592,
  },
  {
    visitors: "1",
    City: "Sundbyberg",
    zipCode: "17271",
    lat: 59.3658363,
    lng: 17.968245,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11739",
    lat: 59.3140818,
    lng: 18.0325367,
  },
  {
    visitors: "2",
    City: "Killybegs",
    zipCode: "",
    lat: 54.6347625,
    lng: -8.4547084,
  },
  {
    visitors: "1",
    City: "Lidingö",
    zipCode: "18161",
    lat: 59.3398201,
    lng: 18.1721703,
  },
  {
    visitors: "5",
    City: "Stockholm",
    zipCode: "11853",
    lat: 59.3151166,
    lng: 18.0513174,
  },
  {
    visitors: "1",
    City: "Enskede",
    zipCode: "12234",
    lat: 59.28191320000001,
    lng: 18.0909642,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11862",
    lat: 59.30889029999999,
    lng: 18.0753247,
  },
  {
    visitors: "1",
    City: "Hägersten",
    zipCode: "12634",
    lat: 59.3022899,
    lng: 18.0109368,
  },
  {
    visitors: "1",
    City: "Hägersten",
    zipCode: "12652",
    lat: 59.30729299999999,
    lng: 17.9915032,
  },
  {
    visitors: "1",
    City: "Sundbyberg",
    zipCode: "17457",
    lat: 59.37589469999999,
    lng: 17.938027,
  },
  {
    visitors: "1",
    City: "Helsinki",
    zipCode: "00530",
    lat: 60.1809045,
    lng: 24.9526712,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11850",
    lat: 59.3164285,
    lng: 18.0590482,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11629",
    lat: 59.31471440000001,
    lng: 18.0833575,
  },
  {
    visitors: "1",
    City: "Sundsvall",
    zipCode: "85233",
    lat: 62.3918306,
    lng: 17.2859016,
  },
  {
    visitors: "1",
    City: "Sollentuna",
    zipCode: "19252",
    lat: 59.44851329999999,
    lng: 17.9743488,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11661",
    lat: 59.30814040000001,
    lng: 18.0805588,
  },
  {
    visitors: "1",
    City: "Hägersten",
    zipCode: "12636",
    lat: 59.30151129999999,
    lng: 17.9981495,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11767",
    lat: 59.3179883,
    lng: 18.0077781,
  },
  {
    visitors: "1",
    City: "Hägersten",
    zipCode: "12635",
    lat: 59.30018450000001,
    lng: 18.0074495,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11458",
    lat: 59.3369938,
    lng: 18.0856104,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11767",
    lat: 59.3179883,
    lng: 18.0077781,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11858",
    lat: 59.3092637,
    lng: 18.0714195,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11861",
    lat: 59.3054695,
    lng: 18.0589493,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11215",
    lat: 59.338047,
    lng: 18.0050293,
  },
  {
    visitors: "2",
    City: "Bromma",
    zipCode: "16751",
    lat: 59.3303737,
    lng: 17.9835081,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11264",
    lat: 59.32267,
    lng: 17.9958121,
  },
  {
    visitors: "3",
    City: "Västerhaninge",
    zipCode: "13736",
    lat: 59.132637,
    lng: 18.0718642,
  },
  {
    visitors: "2",
    City: "Hägersten",
    zipCode: "12650",
    lat: 59.3079066,
    lng: 18.0054548,
  },
  {
    visitors: "3",
    City: "Stockholm",
    zipCode: "12932",
    lat: 59.2977865,
    lng: 17.9825444,
  },
  {
    visitors: "2",
    City: "Sundbyberg",
    zipCode: "17458",
    lat: 59.3737092,
    lng: 17.9377001,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "12062",
    lat: 59.30420359999999,
    lng: 18.0898573,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11415",
    lat: 59.37283759999999,
    lng: 18.0613711,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11646",
    lat: 59.3185448,
    lng: 18.0728497,
  },
  {
    visitors: "1",
    City: "Upplands Väsby",
    zipCode: "19446",
    lat: 59.52107479999999,
    lng: 17.8904672,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11822",
    lat: 59.31937139999999,
    lng: 18.0618785,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11842",
    lat: 59.31015600000001,
    lng: 18.0469908,
  },
  {
    visitors: "3",
    City: "Horsham",
    zipCode: "RH13 6RG",
    lat: 51.0151317,
    lng: -0.3030787,
  },
  {
    visitors: "1",
    City: "Sundbyberg",
    zipCode: "17268",
    lat: 59.3633198,
    lng: 17.9702454,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11543",
    lat: 59.35304730000001,
    lng: 18.0956333,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11851",
    lat: 59.3169244,
    lng: 18.0529817,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11667",
    lat: 59.30675269999999,
    lng: 18.0785613,
  },
  {
    visitors: "1",
    City: "Berlin",
    zipCode: "10119",
    lat: 52.530741,
    lng: 13.4058114,
  },
  {
    visitors: "1",
    City: "Johanneshov",
    zipCode: "12155",
    lat: 59.2853779,
    lng: 18.1160577,
  },
  {
    visitors: "1",
    City: "Hägersten",
    zipCode: "12930",
    lat: 59.2931754,
    lng: 17.9862008,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11771",
    lat: 59.3167961,
    lng: 17.9986892,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11736",
    lat: 59.3174805,
    lng: 18.0308767,
  },
  {
    visitors: "1",
    City: "Hägersten",
    zipCode: "12655",
    lat: 59.30993299999999,
    lng: 18.000807,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11852",
    lat: 59.315929,
    lng: 18.0625382,
  },
  {
    visitors: "1",
    City: "Bagarmossen",
    zipCode: "12848",
    lat: 59.2780101,
    lng: 18.1465992,
  },
  {
    visitors: "1",
    City: "Nacka",
    zipCode: "13136",
    lat: 59.308732,
    lng: 18.1347586,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11730",
    lat: 59.3179512,
    lng: 18.0366939,
  },
  {
    visitors: "2",
    City: "Tallinn",
    zipCode: "13424",
    lat: 59.4128496,
    lng: 24.7032706,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11129",
    lat: 59.3244212,
    lng: 18.070782,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11129",
    lat: 59.3244212,
    lng: 18.070782,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11320",
    lat: 59.3394968,
    lng: 18.0382051,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11824",
    lat: 59.31996059999999,
    lng: 18.06296,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11824",
    lat: 59.31996059999999,
    lng: 18.06296,
  },
  {
    visitors: "2",
    City: "Hägersten",
    zipCode: "12636",
    lat: 59.30151129999999,
    lng: 17.9981495,
  },
  {
    visitors: "1",
    City: "Sundbyberg",
    zipCode: "17461",
    lat: 59.3810514,
    lng: 17.9514925,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11421",
    lat: 59.3523354,
    lng: 18.0590139,
  },
  {
    visitors: "1",
    City: "Järna",
    zipCode: "15338",
    lat: 59.11343429999999,
    lng: 17.5786783,
  },
  {
    visitors: "1",
    City: "Danderyd",
    zipCode: "18233",
    lat: 59.4015377,
    lng: 18.032407,
  },
  {
    visitors: "2",
    City: "Hägersten",
    zipCode: "12638",
    lat: 59.2934389,
    lng: 17.9921767,
  },
  {
    visitors: "2",
    City: "Åre",
    zipCode: "83752",
    lat: 63.3990428,
    lng: 13.0815059,
  },
  {
    visitors: "1",
    City: "Södertälje",
    zipCode: "15138",
    lat: 59.16755209999999,
    lng: 17.6583856,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11632",
    lat: 59.314452,
    lng: 18.0936233,
  },
  {
    visitors: "1",
    City: "Sundsvall",
    zipCode: "85741",
    lat: 62.40406900000001,
    lng: 17.261249,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11254",
    lat: 59.3300618,
    lng: 17.9961397,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11730",
    lat: 59.3179512,
    lng: 18.0366939,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11769",
    lat: 59.3149276,
    lng: 17.9875107,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11254",
    lat: 59.3300618,
    lng: 17.9961397,
  },
  {
    visitors: "2",
    City: "Hägersten",
    zipCode: "12638",
    lat: 59.2934389,
    lng: 17.9921767,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11726",
    lat: 59.3169608,
    lng: 18.0488263,
  },
  {
    visitors: "1",
    City: "Hägersten",
    zipCode: "12636",
    lat: 59.30151129999999,
    lng: 17.9981495,
  },
  {
    visitors: "1",
    City: "Solna",
    zipCode: "17159",
    lat: 59.3513667,
    lng: 18.0019466,
  },
  {
    visitors: "1",
    City: "Hägersten",
    zipCode: "12636",
    lat: 59.30151129999999,
    lng: 17.9981495,
  },
  {
    visitors: "2",
    City: "Älvsjö",
    zipCode: "12532",
    lat: 59.28134720000001,
    lng: 17.9971212,
  },
  {
    visitors: "1",
    City: "Johanneshov",
    zipCode: "12150",
    lat: 59.2979718,
    lng: 18.1164323,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11851",
    lat: 59.3169244,
    lng: 18.0529817,
  },
  {
    visitors: "1",
    City: "Gustavsberg",
    zipCode: "13434",
    lat: 59.3356305,
    lng: 18.3861127,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11734",
    lat: 59.3163132,
    lng: 18.0364435,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11368",
    lat: 59.3483105,
    lng: 18.0338022,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11741",
    lat: 59.31435019999999,
    lng: 18.0436713,
  },
  {
    visitors: "2",
    City: "Hägersten",
    zipCode: "12957",
    lat: 59.28603409999999,
    lng: 17.9524659,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11264",
    lat: 59.32267,
    lng: 17.9958121,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11530",
    lat: 59.3428362,
    lng: 18.0902826,
  },
  {
    visitors: "1",
    City: "Hägersten",
    zipCode: "12628",
    lat: 59.29790389999999,
    lng: 17.9999777,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11419",
    lat: 59.3598885,
    lng: 18.0530312,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11638",
    lat: 59.31045859999999,
    lng: 18.0890415,
  },
  {
    visitors: "2",
    City: "Sundbyberg",
    zipCode: "17233",
    lat: 59.3660793,
    lng: 17.9639192,
  },
  {
    visitors: "1",
    City: "Bromma",
    zipCode: "16844",
    lat: 59.3487101,
    lng: 17.8594983,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11231",
    lat: 59.3308822,
    lng: 18.0347074,
  },
  {
    visitors: "2",
    City: "Bagarmossen",
    zipCode: "12845",
    lat: 59.2760739,
    lng: 18.1367893,
  },
  {
    visitors: "4",
    City: "Stockholm",
    zipCode: "11735",
    lat: 59.3167248,
    lng: 18.0309594,
  },
  {
    visitors: "1",
    City: "Johanneshov",
    zipCode: "12150",
    lat: 59.2979718,
    lng: 18.1164323,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11336",
    lat: 59.3394061,
    lng: 18.0336313,
  },
  {
    visitors: "2",
    City: "Skarpnäck",
    zipCode: "12832",
    lat: 59.26675640000001,
    lng: 18.1369173,
  },
  {
    visitors: "2",
    City: "Bandhagen",
    zipCode: "12433",
    lat: 59.26968239999999,
    lng: 18.0439577,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11765",
    lat: 59.3119428,
    lng: 18.008444,
  },
  {
    visitors: "1",
    City: "Årsta",
    zipCode: "12059",
    lat: 59.2983042,
    lng: 18.0388388,
  },
  {
    visitors: "5",
    City: "Stockholm",
    zipCode: "11750",
    lat: 59.3189319,
    lng: 18.0194102,
  },
  {
    visitors: "1",
    City: "Hägersten",
    zipCode: "12629",
    lat: 59.2974064,
    lng: 18.0034648,
  },
  {
    visitors: "1",
    City: "MADRID",
    zipCode: "28028",
    lat: 40.4319062,
    lng: -3.664859,
  },
  {
    visitors: "1",
    City: "Bromma",
    zipCode: "16736",
    lat: 59.3398526,
    lng: 17.9770145,
  },
  {
    visitors: "3",
    City: "Stockholm",
    zipCode: "11857",
    lat: 59.31030930000001,
    lng: 18.0680142,
  },
  {
    visitors: "1",
    City: "Enskededalen",
    zipCode: "12132",
    lat: 59.288124,
    lng: 18.0891528,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11629",
    lat: 59.31471440000001,
    lng: 18.0833575,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11248",
    lat: 59.338765,
    lng: 18.0263967,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11664",
    lat: 59.3074681,
    lng: 18.0800587,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11247",
    lat: 59.3357892,
    lng: 18.0303035,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11632",
    lat: 59.314452,
    lng: 18.0936233,
  },
  {
    visitors: "1",
    City: "Solna",
    zipCode: "17155",
    lat: 59.35422999999999,
    lng: 17.9831491,
  },
  {
    visitors: "1",
    City: "Hägersten",
    zipCode: "12934",
    lat: 59.30122799999999,
    lng: 17.9818769,
  },
  {
    visitors: "4",
    City: "Hägersten",
    zipCode: "12629",
    lat: 59.2974064,
    lng: 18.0034648,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11351",
    lat: 59.3447329,
    lng: 18.0588375,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11351",
    lat: 59.3447329,
    lng: 18.0588375,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11738",
    lat: 59.31558519999999,
    lng: 18.0272198,
  },
  {
    visitors: "1",
    City: "Umeå",
    zipCode: "90738",
    lat: 63.8070395,
    lng: 20.3018459,
  },
  {
    visitors: "4",
    City: "Stockholm",
    zipCode: "11239",
    lat: 59.3315906,
    lng: 18.0310499,
  },
  {
    visitors: "1",
    City: "Johanneshov",
    zipCode: "12154",
    lat: 59.2872186,
    lng: 18.1084212,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11255",
    lat: 59.3304997,
    lng: 18.0061127,
  },
  {
    visitors: "2",
    City: "Göteborg",
    zipCode: "41522",
    lat: 57.7377142,
    lng: 12.0194705,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11266",
    lat: 59.32071959999999,
    lng: 17.9860103,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11357",
    lat: 59.34272139999999,
    lng: 18.062495,
  },
  {
    visitors: "1",
    City: "Enskededalen",
    zipCode: "12133",
    lat: 59.28385359999999,
    lng: 18.1007693,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11223",
    lat: 59.3294253,
    lng: 18.0436019,
  },
  {
    visitors: "1",
    City: "Hägersten",
    zipCode: "12930",
    lat: 59.2931754,
    lng: 17.9862008,
  },
  {
    visitors: "1",
    City: "Hägersten",
    zipCode: "12930",
    lat: 59.2931754,
    lng: 17.9862008,
  },
  {
    visitors: "1",
    City: "Göteborg",
    zipCode: "41758",
    lat: 57.7079705,
    lng: 11.928119,
  },
  {
    visitors: "1",
    City: "Johanneshov",
    zipCode: "12146",
    lat: 59.29829720000001,
    lng: 18.1038021,
  },
  {
    visitors: "3",
    City: "Lidingö",
    zipCode: "18144",
    lat: 59.3774415,
    lng: 18.1383511,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11853",
    lat: 59.3151166,
    lng: 18.0513174,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11223",
    lat: 59.3294253,
    lng: 18.0436019,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11761",
    lat: 59.31138329999999,
    lng: 18.0253904,
  },
  {
    visitors: "1",
    City: "Hägersten",
    zipCode: "12931",
    lat: 59.29174210000001,
    lng: 17.9832141,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11560",
    lat: 59.34379910000001,
    lng: 18.1118038,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11739",
    lat: 59.3140818,
    lng: 18.0325367,
  },
  {
    visitors: "6",
    City: "Stockholm",
    zipCode: "11765",
    lat: 59.3119428,
    lng: 18.008444,
  },
  {
    visitors: "1",
    City: "Bandhagen",
    zipCode: "12476",
    lat: 59.25863,
    lng: 18.0094438,
  },
  {
    visitors: "2",
    City: "Ljungsbro",
    zipCode: "58574",
    lat: 58.4977499,
    lng: 15.4943216,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11622",
    lat: 59.3183437,
    lng: 18.0807468,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11550",
    lat: 59.3456448,
    lng: 18.0849637,
  },
  {
    visitors: "3",
    City: "Hägersten",
    zipCode: "12947",
    lat: 59.2927139,
    lng: 17.9659512,
  },
  {
    visitors: "3",
    City: "Stockholm",
    zipCode: "11261",
    lat: 59.32516560000001,
    lng: 18.0056989,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11740",
    lat: 59.31734159999999,
    lng: 18.0227335,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11241",
    lat: 59.3311632,
    lng: 18.0262281,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11861",
    lat: 59.3054695,
    lng: 18.0589493,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11846",
    lat: 59.31849860000001,
    lng: 18.0692752,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11529",
    lat: 59.3422116,
    lng: 18.0959392,
  },
  {
    visitors: "1",
    City: "Farsta",
    zipCode: "12347",
    lat: 59.242296,
    lng: 18.0923523,
  },
  {
    visitors: "1",
    City: "Bromma",
    zipCode: "16771",
    lat: 59.31900700000001,
    lng: 17.9326937,
  },
  {
    visitors: "1",
    City: "Vällingby",
    zipCode: "16261",
    lat: 59.3614352,
    lng: 17.8720638,
  },
  {
    visitors: "1",
    City: "Bromma",
    zipCode: "16752",
    lat: 59.3296905,
    lng: 17.9778584,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11735",
    lat: 59.3167248,
    lng: 18.0309594,
  },
  {
    visitors: "2",
    City: "Linköping",
    zipCode: "58752",
    lat: 58.39103600000001,
    lng: 15.6287925,
  },
  {
    visitors: "1",
    City: "Bandhagen",
    zipCode: "12432",
    lat: 59.272704,
    lng: 18.0436291,
  },
  {
    visitors: "1",
    City: "Bromma",
    zipCode: "16766",
    lat: 59.3245933,
    lng: 17.9509504,
  },
  {
    visitors: "1",
    City: "Saltsjö-Boo",
    zipCode: "13237",
    lat: 59.35019320000001,
    lng: 18.2962117,
  },
  {
    visitors: "1",
    City: "Linköping",
    zipCode: "58224",
    lat: 58.4110533,
    lng: 15.6193685,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11739",
    lat: 59.3140818,
    lng: 18.0325367,
  },
  {
    visitors: "1",
    City: "Uppsala",
    zipCode: "75242",
    lat: 59.84190739999999,
    lng: 17.5964867,
  },
  {
    visitors: "1",
    City: "Enskede",
    zipCode: "12247",
    lat: 59.2727321,
    lng: 18.0642114,
  },
  {
    visitors: "1",
    City: "Bromma",
    zipCode: "16755",
    lat: 59.328577,
    lng: 17.9673911,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11253",
    lat: 59.3353572,
    lng: 17.9981311,
  },
  {
    visitors: "3",
    City: "Hägersten",
    zipCode: "12637",
    lat: 59.2984889,
    lng: 17.998483,
  },
  {
    visitors: "3",
    City: "Bagarmossen",
    zipCode: "12844",
    lat: 59.27263550000001,
    lng: 18.142589,
  },
  {
    visitors: "1",
    City: "Bagarmossen",
    zipCode: "12844",
    lat: 59.27263550000001,
    lng: 18.142589,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11331",
    lat: 59.3456742,
    lng: 18.0405381,
  },
  {
    visitors: "1",
    City: "Nacka",
    zipCode: "13144",
    lat: 59.31110210000001,
    lng: 18.2112924,
  },
  {
    visitors: "1",
    City: "Farsta",
    zipCode: "12363",
    lat: 59.24458319999999,
    lng: 18.1152535,
  },
  {
    visitors: "3",
    City: "Stockholm",
    zipCode: "11242",
    lat: 59.3320932,
    lng: 18.0301356,
  },
  {
    visitors: "1",
    City: "Farsta",
    zipCode: "12363",
    lat: 59.24458319999999,
    lng: 18.1152535,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11164",
    lat: 59.33049159999999,
    lng: 18.0556587,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11853",
    lat: 59.3151166,
    lng: 18.0513174,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11853",
    lat: 59.3151166,
    lng: 18.0513174,
  },
  {
    visitors: "1",
    City: "Osøyro",
    zipCode: "5210",
    lat: 60.18471659999999,
    lng: 5.469273400000001,
  },
  {
    visitors: "1",
    City: "Hägersten",
    zipCode: "12652",
    lat: 59.30729299999999,
    lng: 17.9915032,
  },
  {
    visitors: "1",
    City: "Tübingen",
    zipCode: "72070",
    lat: 48.5226576,
    lng: 9.0047096,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11758",
    lat: 59.3076962,
    lng: 18.0303731,
  },
  {
    visitors: "2",
    City: "Nacka",
    zipCode: "13141",
    lat: 59.3035431,
    lng: 18.1759789,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11853",
    lat: 59.3151166,
    lng: 18.0513174,
  },
  {
    visitors: "1",
    City: "Spånga",
    zipCode: "16351",
    lat: 59.38505989999999,
    lng: 17.8759422,
  },
  {
    visitors: "1",
    City: "Älvsjö",
    zipCode: "12553",
    lat: 59.2722225,
    lng: 17.9636588,
  },
  {
    visitors: "3",
    City: "Stockholm",
    zipCode: "11620",
    lat: 59.3173323,
    lng: 18.0774194,
  },
  {
    visitors: "2",
    City: "Tallinn",
    zipCode: "10145",
    lat: 59.4320048,
    lng: 24.7606811,
  },
  {
    visitors: "2",
    City: "Lidingö",
    zipCode: "18150",
    lat: 59.36383459999999,
    lng: 18.1254991,
  },
  {
    visitors: "1",
    City: "Kalmar",
    zipCode: "39255",
    lat: 56.6617726,
    lng: 16.3355439,
  },
  {
    visitors: "2",
    City: "Englewood",
    zipCode: "07631-2015",
    lat: 40.8928771,
    lng: -73.9726381,
  },
  {
    visitors: "1",
    City: "Täby",
    zipCode: "18731",
    lat: 59.44595140000001,
    lng: 18.0551133,
  },
  {
    visitors: "2",
    City: "Täby",
    zipCode: "18746",
    lat: 59.453974,
    lng: 18.0197297,
  },
  {
    visitors: "1",
    City: "Enskede",
    zipCode: "12246",
    lat: 59.26502409999999,
    lng: 18.0764794,
  },
  {
    visitors: "1",
    City: "Bandhagen",
    zipCode: "12434",
    lat: 59.27002950000001,
    lng: 18.0519243,
  },
  {
    visitors: "2",
    City: "Solna",
    zipCode: "16941",
    lat: 59.36238650000001,
    lng: 17.9969452,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11631",
    lat: 59.3114214,
    lng: 18.0862177,
  },
  {
    visitors: "1",
    City: "Älvsjö",
    zipCode: "12542",
    lat: 59.2777194,
    lng: 18.0293596,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11349",
    lat: 59.3475834,
    lng: 18.0545151,
  },
  {
    visitors: "1",
    City: "Lindau (Bodensee)",
    zipCode: "88131",
    lat: 47.55365430000001,
    lng: 9.693054499999999,
  },
  {
    visitors: "1",
    City: "Hägersten",
    zipCode: "12950",
    lat: 59.2870225,
    lng: 17.9745887,
  },
  {
    visitors: "2",
    City: "Bromma",
    zipCode: "16853",
    lat: 59.352842,
    lng: 17.8933748,
  },
  {
    visitors: "3",
    City: "Malmö",
    zipCode: "21150",
    lat: 55.6006142,
    lng: 13.0104662,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11635",
    lat: 59.3128091,
    lng: 18.0882159,
  },
  {
    visitors: "1",
    City: "Brasília",
    zipCode: "70683190",
    lat: -15.7975154,
    lng: -47.89188739999999,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11227",
    lat: 59.33123699999999,
    lng: 18.0478438,
  },
  {
    visitors: "1",
    City: "London",
    zipCode: "N5 1BH",
    lat: 51.5562701,
    lng: -0.1005768,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11537",
    lat: 59.3473789,
    lng: 18.0975334,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11853",
    lat: 59.3151166,
    lng: 18.0513174,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11264",
    lat: 59.32267,
    lng: 17.9958121,
  },
  {
    visitors: "1",
    City: "Gävle",
    zipCode: "80324",
    lat: 60.67687660000001,
    lng: 17.1183734,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11255",
    lat: 59.3304997,
    lng: 18.0061127,
  },
  {
    visitors: "1",
    City: "Ostermundigen",
    zipCode: "3072",
    lat: 46.9543249,
    lng: 7.4967031,
  },
  {
    visitors: "2",
    City: "Älta",
    zipCode: "13832",
    lat: 59.2598887,
    lng: 18.1691039,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11245",
    lat: 59.3343961,
    lng: 18.0257303,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11336",
    lat: 59.3394061,
    lng: 18.0336313,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11727",
    lat: 59.3188049,
    lng: 18.046335,
  },
  {
    visitors: "1",
    City: "Årsta",
    zipCode: "12054",
    lat: 59.298572,
    lng: 18.0499684,
  },
  {
    visitors: "2",
    City: "Lindome",
    zipCode: "43741",
    lat: 57.58427,
    lng: 12.0372046,
  },
  {
    visitors: "3",
    City: "Stockholm",
    zipCode: "12335",
    lat: 59.32932349999999,
    lng: 18.0685808,
  },
  {
    visitors: "1",
    City: "Lund",
    zipCode: "22354",
    lat: 55.696737,
    lng: 13.1920055,
  },
  {
    visitors: "1",
    City: "Dublin",
    zipCode: "D16 C4H3",
    lat: 53.27389239999999,
    lng: -6.313651999999999,
  },
  {
    visitors: "1",
    City: "Wien",
    zipCode: "1220",
    lat: 48.2351828,
    lng: 16.4810643,
  },
  {
    visitors: "2",
    City: "Gävle",
    zipCode: "80262",
    lat: 60.6580878,
    lng: 17.1473019,
  },
  {
    visitors: "1",
    City: "København Ø",
    zipCode: "2100",
    lat: 55.7096303,
    lng: 12.5768655,
  },
  {
    visitors: "1",
    City: "Malmö",
    zipCode: "21211",
    lat: 55.6072255,
    lng: 13.0211419,
  },
  {
    visitors: "1",
    City: "Vällingby",
    zipCode: "16261",
    lat: 59.3614352,
    lng: 17.8720638,
  },
  {
    visitors: "3",
    City: "Sollentuna",
    zipCode: "19146",
    lat: 59.4302185,
    lng: 17.9143775,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11360",
    lat: 59.3393085,
    lng: 18.05384,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11253",
    lat: 59.3353572,
    lng: 17.9981311,
  },
  {
    visitors: "1",
    City: "Hägersten",
    zipCode: "12632",
    lat: 59.30246469999999,
    lng: 18.0149229,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11730",
    lat: 59.3179512,
    lng: 18.0366939,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11823",
    lat: 59.31906499999999,
    lng: 18.0523194,
  },
  {
    visitors: "1",
    City: "Guadalajara",
    zipCode: "44600",
    lat: 20.6769383,
    lng: -103.3717253,
  },
  {
    visitors: "1",
    City: "Kalmar",
    zipCode: "39247",
    lat: 56.65394089999999,
    lng: 16.3455101,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11852",
    lat: 59.315929,
    lng: 18.0625382,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11123",
    lat: 59.33510630000001,
    lng: 18.0520055,
  },
  {
    visitors: "2",
    City: "Linköping",
    zipCode: "58434",
    lat: 58.41229919999999,
    lng: 15.5608754,
  },
  {
    visitors: "1",
    City: "Johanneshov",
    zipCode: "12145",
    lat: 59.29921249999999,
    lng: 18.0948304,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11857",
    lat: 59.31030930000001,
    lng: 18.0680142,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11245",
    lat: 59.3343961,
    lng: 18.0257303,
  },
  {
    visitors: "2",
    City: "Uppsala",
    zipCode: "75229",
    lat: 59.8636601,
    lng: 17.6091257,
  },
  {
    visitors: "1",
    City: "Oslo",
    zipCode: "0267",
    lat: 59.9205714,
    lng: 10.7033458,
  },
  {
    visitors: "1",
    City: "GIF-SUR-YVETTE",
    zipCode: "91190",
    lat: 48.714637,
    lng: 2.1263358,
  },
  {
    visitors: "2",
    City: "Ljusne",
    zipCode: "82675",
    lat: 61.2114046,
    lng: 17.1266474,
  },
  {
    visitors: "2",
    City: "Täby",
    zipCode: "18374",
    lat: 59.4448173,
    lng: 18.0891596,
  },
  {
    visitors: "1",
    City: "Johanneshov",
    zipCode: "12136",
    lat: 59.292824,
    lng: 18.0875031,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "116 20",
    lat: 59.3173323,
    lng: 18.0774194,
  },
  {
    visitors: "2",
    City: "Hägersten",
    zipCode: "12651",
    lat: 59.3092333,
    lng: 17.9961525,
  },
  {
    visitors: "1",
    City: "Rathgar",
    zipCode: "D06 WF51",
    lat: 53.3164662,
    lng: -6.2744794,
  },
  {
    visitors: "1",
    City: "Bromma",
    zipCode: "16831",
    lat: 59.33626659999999,
    lng: 17.9499288,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11734",
    lat: 59.3163132,
    lng: 18.0364435,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11631",
    lat: 59.3114214,
    lng: 18.0862177,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11730",
    lat: 59.3179512,
    lng: 18.0366939,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11762",
    lat: 59.30390569999999,
    lng: 18.023062,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11649",
    lat: 59.3116502,
    lng: 18.0976444,
  },
  {
    visitors: "1",
    City: "Kista",
    zipCode: "16438",
    lat: 59.4131842,
    lng: 17.9269306,
  },
  {
    visitors: "1",
    City: "Bromma",
    zipCode: "16743",
    lat: 59.33600080000001,
    lng: 17.9831696,
  },
  {
    visitors: "2",
    City: "Hägersten",
    zipCode: "12950",
    lat: 59.2870225,
    lng: 17.9745887,
  },
  {
    visitors: "1",
    City: "Umeå",
    zipCode: "90331",
    lat: 63.8243163,
    lng: 20.2814656,
  },
  {
    visitors: "1",
    City: "Ichikawa",
    zipCode: "2720123",
    lat: 35.6796936,
    lng: 139.927079,
  },
  {
    visitors: "1",
    City: "Donegal",
    zipCode: "F94 YY76",
    lat: 54.6560764,
    lng: -8.1171246,
  },
  {
    visitors: "2",
    City: "WYNNUM",
    zipCode: "4178",
    lat: -27.443694,
    lng: 153.173618,
  },
  {
    visitors: "1",
    City: "Älvsjö",
    zipCode: "12530",
    lat: 59.2744881,
    lng: 18.0051298,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11739",
    lat: 59.3140818,
    lng: 18.0325367,
  },
  {
    visitors: "1",
    City: "Hägersten",
    zipCode: "12628",
    lat: 59.29790389999999,
    lng: 17.9999777,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11642",
    lat: 59.3092808,
    lng: 18.0868777,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11240",
    lat: 59.33166970000001,
    lng: 18.0278909,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11127",
    lat: 59.322194,
    lng: 18.0694479,
  },
  {
    visitors: "1",
    City: "Huddinge",
    zipCode: "14152",
    lat: 59.22214109999999,
    lng: 17.9395548,
  },
  {
    visitors: "2",
    City: "Bromma",
    zipCode: "16743",
    lat: 59.33600080000001,
    lng: 17.9831696,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11737",
    lat: 59.31781279999999,
    lng: 18.0285502,
  },
  {
    visitors: "3",
    City: "Luleå",
    zipCode: "97245",
    lat: 65.5804792,
    lng: 22.177818,
  },
  {
    visitors: "1",
    City: "Uppsala",
    zipCode: "75645",
    lat: 59.8314112,
    lng: 17.6239295,
  },
  {
    visitors: "1",
    City: "Solna",
    zipCode: "17171",
    lat: 59.35031799999999,
    lng: 18.0027788,
  },
  {
    visitors: "1",
    City: "Årsta",
    zipCode: "12053",
    lat: 59.300929,
    lng: 18.0542907,
  },
  {
    visitors: "1",
    City: "Sundsvall",
    zipCode: "85733",
    lat: 62.4126218,
    lng: 17.2535858,
  },
  {
    visitors: "1",
    City: "Älvsjö",
    zipCode: "12555",
    lat: 59.2776043,
    lng: 17.9676321,
  },
  {
    visitors: "1",
    City: "Skarpnäck",
    zipCode: "12838",
    lat: 59.27395809999999,
    lng: 18.1126974,
  },
  {
    visitors: "1",
    City: "Mölnbo",
    zipCode: "153 60",
    lat: 59.04784,
    lng: 17.42373,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11726",
    lat: 59.3169608,
    lng: 18.0488263,
  },
  {
    visitors: "1",
    City: "Sundbyberg",
    zipCode: "17236",
    lat: 59.3659855,
    lng: 17.9593446,
  },
  {
    visitors: "1",
    City: "Farsta",
    zipCode: "12372",
    lat: 59.2348445,
    lng: 18.1105751,
  },
  {
    visitors: "2",
    City: "Bagarmossen",
    zipCode: "12844",
    lat: 59.27263550000001,
    lng: 18.142589,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11732",
    lat: 59.3198462,
    lng: 18.040352,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11347",
    lat: 59.3536674,
    lng: 18.0496961,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11347",
    lat: 59.3536674,
    lng: 18.0496961,
  },
  {
    visitors: "1",
    City: "Bromma",
    zipCode: "16875",
    lat: 59.3435472,
    lng: 17.9379458,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11531",
    lat: 59.34258080000001,
    lng: 18.0868707,
  },
  {
    visitors: "1",
    City: "London",
    zipCode: "SE16 4UT",
    lat: 51.4996435,
    lng: -0.06867039999999999,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11241",
    lat: 59.3311632,
    lng: 18.0262281,
  },
  {
    visitors: "2",
    City: "Brighton",
    zipCode: "BN2 9UH",
    lat: 50.8296821,
    lng: -0.1284165,
  },
  {
    visitors: "1",
    City: "Vällingby",
    zipCode: "16253",
    lat: 59.360387,
    lng: 17.8873601,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11730",
    lat: 59.3179512,
    lng: 18.0366939,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11852",
    lat: 59.315929,
    lng: 18.0625382,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11620",
    lat: 59.3173323,
    lng: 18.0774194,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "12032",
    lat: 59.30222449999999,
    lng: 18.084202,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11730",
    lat: 59.3179512,
    lng: 18.0366939,
  },
  {
    visitors: "1",
    City: "Saltsjöbaden",
    zipCode: "13342",
    lat: 59.29530279999999,
    lng: 18.2587708,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11228",
    lat: 59.33442669999999,
    lng: 18.0463503,
  },
  {
    visitors: "3",
    City: "Skellefteå",
    zipCode: "93236",
    lat: 64.71249130000001,
    lng: 21.1847493,
  },
  {
    visitors: "3",
    City: "Solna",
    zipCode: "16940",
    lat: 59.36741839999999,
    lng: 17.9819688,
  },
  {
    visitors: "1",
    City: "Karlsborg",
    zipCode: "54631",
    lat: 58.5179334,
    lng: 14.4919284,
  },
  {
    visitors: "20",
    City: "",
    zipCode: "",
  },
  {
    visitors: "3",
    City: "Ekolsund",
    zipCode: "74950",
    lat: 59.61970050000001,
    lng: 17.3766542,
  },
  {
    visitors: "4",
    City: "Bromma",
    zipCode: "16841",
    lat: 59.33068939999999,
    lng: 17.9027637,
  },
  {
    visitors: "4",
    City: "Hägersten",
    zipCode: "12632",
    lat: 59.30246469999999,
    lng: 18.0149229,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11863",
    lat: 59.313106,
    lng: 18.0549714,
  },
  {
    visitors: "1",
    City: "Årsta",
    zipCode: "12039",
    lat: 59.2999432,
    lng: 18.0715683,
  },
  {
    visitors: "1",
    City: "Bagarmossen",
    zipCode: "12847",
    lat: 59.2827044,
    lng: 18.1346562,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11733",
    lat: 59.32239069999999,
    lng: 18.0290508,
  },
  {
    visitors: "3",
    City: "Hägersten",
    zipCode: "12957",
    lat: 59.28603409999999,
    lng: 17.9524659,
  },
  {
    visitors: "4",
    City: "Stockholm",
    zipCode: "11356",
    lat: 59.3475067,
    lng: 18.0602555,
  },
  {
    visitors: "4",
    City: "Bagarmossen",
    zipCode: "12845",
    lat: 59.2760739,
    lng: 18.1367893,
  },
  {
    visitors: "1",
    City: "Göteborg",
    zipCode: "41308",
    lat: 57.69322260000001,
    lng: 11.9526278,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11732",
    lat: 59.3198462,
    lng: 18.040352,
  },
  {
    visitors: "4",
    City: "Stockholm",
    zipCode: "11856",
    lat: 59.31006250000001,
    lng: 18.0723352,
  },
  {
    visitors: "2",
    City: "Hägersten",
    zipCode: "12933",
    lat: 59.3001462,
    lng: 17.986859,
  },
  {
    visitors: "4",
    City: "Johanneshov",
    zipCode: "12138",
    lat: 59.29619499999999,
    lng: 18.1003061,
  },
  {
    visitors: "2",
    City: "Vallentuna",
    zipCode: "18642",
    lat: 59.5186485,
    lng: 18.0886304,
  },
  {
    visitors: "3",
    City: "Enskede",
    zipCode: "12248",
    lat: 59.2739164,
    lng: 18.0715179,
  },
  {
    visitors: "3",
    City: "Stockholm",
    zipCode: "11343",
    lat: 59.3478588,
    lng: 18.0408724,
  },
  {
    visitors: "4",
    City: "Stockholm",
    zipCode: "11760",
    lat: 59.3085567,
    lng: 18.0290851,
  },
  {
    visitors: "1",
    City: "Stockholm",
    zipCode: "11828",
    lat: 59.3142975,
    lng: 18.0674393,
  },
  {
    visitors: "1",
    City: "Hägersten",
    zipCode: "12647",
    lat: 59.30310060000001,
    lng: 17.9948274,
  },
  {
    visitors: "4",
    City: "Solna",
    zipCode: "17066",
    lat: 59.3771328,
    lng: 18.0104218,
  },
  {
    visitors: "1",
    City: "Farsta",
    zipCode: "12359",
    lat: 59.2556382,
    lng: 18.0900656,
  },
  {
    visitors: "2",
    City: "Stockholm",
    zipCode: "11644",
    lat: 59.31248979999999,
    lng: 18.1060048,
  },
];

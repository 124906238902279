/** @format */

import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { doc, setDoc } from "firebase/firestore";
import React from "react";
import { useState } from "react";
import { db } from "../../firebase";
import ConfirmDialog from "../../helpers/ConfirmDialog";

const SelectStatus = ({ bookingData, setBookingData, agreement, setLoading }) => {
  const [newValue, setNewValue] = useState();
  const bookingsLength = agreement && agreement.bookingids ? agreement.bookingids.length : 0;
  const handleChange = async () => {
    setLoading(true);
    // update bookingdata
    setBookingData((prev) => ({ ...prev, status: newValue }));
    // update agreement
    await setDoc(doc(db, "Agreements", bookingData.agreementId), { status: newValue }, { merge: true });
    //update all the bookings
    const updatePromises = agreement.bookingids.map((bookingId) =>
      setDoc(doc(db, "Bookings", bookingId), { status: newValue }, { merge: true })
    );
    await Promise.all(updatePromises);
    setLoading(false);
  };

  console.log("selectstatus", agreement);

  if (agreement)
    return (
      <ConfirmDialog
        onConfirm={handleChange}
        message={bookingsLength > 1 ? `Change status for ${bookingsLength} bookings?` : `Change status for ${bookingsLength} booking?`}
      >
        <FormControl fullWidth>
          {/* <InputLabel id="demo-simple-select-label">Status</InputLabel> */}
          <Select
            // sx={{ height: "30px", color: "transparent" }}
            size="small"
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={bookingData.status}
            label="Status"
            // onChange={(e) => handleChange(e.target.value)}
            onChange={(e) => setNewValue(e.target.value)}
          >
            <MenuItem value={"Pending Request"}>
              <b style={{ color: "red", marginRight: "15px" }}>█</b>
              <span style={{ color: "white" }}> Pending Request</span>
            </MenuItem>
            <MenuItem value={"Awaiting Artist"}>
              <b style={{ color: "orange", marginRight: "15px" }}>█</b>
              <span style={{ color: "white" }}> Awaiting Artist</span>
            </MenuItem>
            <MenuItem value={"Confirmed"}>
              <b style={{ color: "green", marginRight: "15px" }}>█</b>
              <span style={{ color: "white" }}> Confirmed</span>
            </MenuItem>
          </Select>
        </FormControl>
      </ConfirmDialog>
    );
};

export default SelectStatus;

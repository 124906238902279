/** @format */

import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import LinearProgress from "@mui/material/LinearProgress";
import { deleteObject, getDownloadURL, getStorage, ref, uploadBytesResumable } from "firebase/storage";
import CircularProgress from "@mui/material/CircularProgress";
import { useSelector } from "react-redux";
import { arrayRemove, arrayUnion, doc, getDoc, updateDoc } from "firebase/firestore";
import { db } from "../../firebase";
import ConfirmDialog from "../../helpers/ConfirmDialog";

const PdfUploader = ({ bookingid }) => {
  const currentVenue = useSelector((state) => state.counter.currentVenue);
  const venueid = currentVenue && currentVenue.venueid;
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [uploadProgresses, setUploadProgresses] = useState({});
  const [loading, setLoading] = useState(false);
  const [fetching, setFetching] = useState(false);

  const handleFileChange = (event) => {
    setSelectedFiles(Array.from(event.target.files));
  };

  const handleUpload = async () => {
    setLoading(true);
    const storage = getStorage();

    const uploadPromises = selectedFiles.map((file) => {
      const storageRef = ref(storage, `venueImages/${venueid}/${file.name}`);
      const uploadTask = uploadBytesResumable(storageRef, file);

      return new Promise((resolve, reject) => {
        uploadTask.on(
          "state_changed",
          (snapshot) => {
            const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
            setUploadProgresses((prevProgresses) => ({
              ...prevProgresses,
              [file.name]: progress,
            }));
          },
          (error) => reject(error),
          () => {
            getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
              const fileData = {
                url: downloadURL,
                name: file.name, // Capture the original filename here
              };

              // Update Firestore document
              const venueDocRef = doc(db, "Venues", venueid);
              updateDoc(venueDocRef, {
                attachments: arrayUnion(fileData),
              })
                .then(() => {
                  resolve(downloadURL); // Resolve with the download URL after updating Firestore
                })
                .catch((error) => {
                  console.error("Error updating Firestore with download URL:", error);
                  reject(error);
                });
            });
          }
        );
      });
    });

    Promise.all(uploadPromises)
      .then((urls) => {
        console.log("All files uploaded and Firestore updated successfully", urls);
        setTimeout(() => {
          setLoading(false);
          fetchAttachments();
        }, 2000);
      })
      .catch((error) => {
        console.error("Error during file upload or Firestore update:", error);
        setLoading(false);
      });
  };

  const [attachments, setAttachments] = useState([]);

  const fetchAttachments = async () => {
    setFetching(true);
    const venueDocRef = doc(db, "Venues", venueid);
    try {
      const docSnap = await getDoc(venueDocRef);
      if (docSnap.exists()) {
        // Assuming 'attachments' is an array of URLs
        const fetchedAttachments = docSnap.data().attachments || [];
        console.log("Fetched attachments:", fetchedAttachments);
        setAttachments(fetchedAttachments);
        setFetching(false);
      } else {
        console.log("No such document!");
      }
    } catch (error) {
      console.error("Error fetching attachments:", error);
    }
  };

  useEffect(() => {
    fetchAttachments();
  }, [venueid]); // Re-fetch whenever venueid changes

  const removeAttachment = async (attachment) => {
    const storage = getStorage();
    // Ensure the path here matches how you've stored the file in Firebase Storage
    const fileRef = ref(storage, `venueImages/${venueid}/${attachment.name}`);

    try {
      console.log("trying to delete file: " + fileRef);
      // Delete from Firebase Storage
      await deleteObject(fileRef);
      console.log("file sucessfully removed");
      console.log("File deleted successfully from storage.");

      // Remove from Firestore document
      console.log("Removing attachment from Venue document...");
      const venueDocRef = doc(db, "Venues", venueid);
      await updateDoc(venueDocRef, {
        attachments: arrayRemove(attachment),
      });
      console.log("Attachment removed from Firestore document.");

      // Update local state to reflect the removal
      setAttachments((current) => current.filter((att) => att.url !== attachment.url));
    } catch (error) {
      console.error("Error removing attachment:", error);
    }
  };

  return (
    <div>
      {/* Display Upload Progress */}
      {loading && (
        <>
          {Object.entries(uploadProgresses).map(([fileName, progress]) => (
            <div key={fileName} style={{ marginTop: "10px" }}>
              <LinearProgress variant="determinate" value={progress} />
              <p>
                {fileName}: {progress}%
              </p>
            </div>
          ))}
        </>
      )}
      {/* Display Attachments */}
      {/* <div className="attachments">
        <h3>Attachments:</h3>
        {attachments.map((attachment, index) => (
          <div key={index}>
            <a href={attachment.url} target="_blank" rel="noopener noreferrer">
              {attachment.name}
            </a>
          </div>
        ))}
      </div> */}
      <div className="attachments" style={{ padding: "30px" }}>
        {fetching && (
          <>
            <CircularProgress size={24} style={{ marginRight: "15px" }} />
            Loading...
          </>
        )}

        {attachments.length > 0 ? (
          <>
            {attachments.map((attachment, index) => (
              <div key={index} style={{ display: "flex", alignItems: "center", marginBottom: "10px", color: "#00ffd4" }}>
                <a href={attachment.url} target="_blank" rel="noopener noreferrer" style={{ marginRight: "10px" }}>
                  <span style={{ color: "#00ffd4" }}>{attachment.name}</span>
                </a>
                <ConfirmDialog onConfirm={() => removeAttachment(attachment)} variant="outlined">
                  Remove
                </ConfirmDialog>
                {/* <Button variant="outlined" size="small" onClick={() => removeAttachment(attachment)} style={{ marginLeft: "10px" }}>
              Remove
            </Button> */}
              </div>
            ))}
          </>
        ) : (
          <p>No attachments yet</p>
        )}
      </div>
      <br></br>
      Upload PDF:s or documents to attach to your agreements.
      <br></br>
      {/* <input type="file" multiple accept=".pdf,.doc,.docx" onChange={handleFileChange} className="uploadInput" /> */}
      <input type="file" multiple onChange={handleFileChange} className="uploadInput" />
      <Button variant="outlined" size="small" onClick={handleUpload} disabled={loading}>
        {loading ? (
          <>
            <CircularProgress size={24} style={{ marginRight: "15px" }} />
            Uploading...
          </>
        ) : (
          "Upload"
        )}
      </Button>
    </div>
  );
};

export default PdfUploader;

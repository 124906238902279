/** @format */

export const getEndChargeBody = (booking, amount, OCR) => {
  let body = {};
  console.log("BOOKING", booking);

  body = {
    // ClientAccess: "LIVE",
    ClientAccess: "LIVE",
    status: "CREATED",
    amount: {
      currency: "SEK",
      value: amount,
    },
    chargeBearer: "SHAR",
    creditor: {
      account: {
        accountNumber: "SE8550000000052311147846",
        accountType: "IBAN",
        bic: "ESSESESSXXX",
        currencyCode: "SEK",
      },
      address: {
        city: "Stockholm",
        street: "Odengatan",
        country: "Sweden",
      },
      message: OCR,
      name: "Marcus Dernulf",
    },
    // debtor: {
    //   account: {
    //     accountNumber: "string",
    //     accountType: "IBAN",
    //     bic: "string",
    //     currencyCode: "string",
    //   },
    //   email: booking.agent.email,
    //   message: "172800000",
    //   name: `${booking.agent.firstName} ${booking.agent.lastName}`,
    //   phoneNumber: booking.agent.phone,
    // },
    externalReference: OCR,
    remittanceInformation: {
      value: OCR,
      type: "UNSTRUCTURED",
    },
    region: "SE",
    paymentProductId: "se-domestic-credit-transfer",
    paymentTemplateId: "string",
    settlementCreditors: [
      {
        accountNumber: "SE8273693077948953984714",
        accountType: "IBAN",
        bic: "HANDSESS",
        amount: amount,
        currencyCode: "SEK",
        name: "string",
      },
    ],
  };

  console.log("ENDCHARGE BODY", body);
  return body;
};

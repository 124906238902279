/** @format */

import {
  storeBookings,
  storeBookingsForCurrentVenue,
  storeCapMin,
  storeCurrentVenue,
  storeCurrentVenueBookings,
  storeOwnedVenues,
} from "../features/venueFilter/venueFilterSlice";
import { useSelector, useDispatch } from "react-redux";
import { collection, doc, getDoc, getDocs, onSnapshot, query, where } from "firebase/firestore";
import { db } from "../firebase";
import { useEffect, useState } from "react";
var j = 0;

export function reloadCalendarBookings(currentVenue) {
  // if (currentVenue.venueid === undefined) return;

  const bookingCollectionRef = collection(db, "Bookings");
  const dispatch = useDispatch();
  let tempCurrentVenue = [];
  // const currentVenue = useSelector((state) => state.counter.currentVenue);
  // alert("reloading");
  useEffect(() => {
    async function setListener() {
      if (currentVenue.venueid === undefined) return;
      if (currentVenue && currentVenue.venueid) {
        // alert("now");
        console.time("bookings time");
        const q = query(collection(db, "Bookings"), where("venueid", "==", currentVenue.venueid));
        const unsubscribe = await onSnapshot(q, async (querySnapshot) => {
          let bookings = [];
          querySnapshot.forEach((doc) => {
            bookings.push(doc.data());
          });
          bookings.sort(function (a, b) {
            return new Date(a.date) - new Date(b.date);
          });
          tempCurrentVenue = { ...currentVenue, bookings };
          console.timeEnd("bookings time");
          await dispatchToStore();

          return;
        });
      }
    }
    // alert("reloading " + currentVenue.venueid);
    setListener();
  }, [currentVenue]);

  async function dispatchToStore() {
    // alert("dispatched");
    const tmpFiltered = tempCurrentVenue.bookings.filter((item) => item.status !== "Killed");
    dispatch(storeCurrentVenueBookings(tempCurrentVenue.bookings));
    dispatch(storeCurrentVenue(tempCurrentVenue)); // With all bookings inside currentVenue.bookings // <=== HERE
    // dispatch(storeCurrentVenue(currentVenue)); // With no bookings inside currentVenue.bookings
  }
}

export function listenAllBookings() {
  const bookingCollectionRef = collection(db, "Bookings");
  const dispatch = useDispatch();
  var tempBookings = [];

  setListener();

  async function setListener() {
    const q = query(collection(db, "Bookings"));
    const unsubscribe = onSnapshot(q, async (querySnapshot) => {
      let bookings = [];
      querySnapshot.forEach((doc) => {
        bookings.push(doc.data());
      });
      tempBookings = bookings;
      await dispatchToStore();
      return;
    });
    // console.log('Done');
  }

  async function dispatchToStore() {
    console.log("dispatching(tempBookings):", tempBookings);
    dispatch(storeBookings(tempBookings));
    // console.log("dispatched");
    // dispatch(storeBookingsForCurrentVenue(tempCurrentVenue))
    // dispatch(storeCapMin(tempCurrentVenue))
    // console.log(tempBookings);
  }
}

export function OwnerVenuesListener(props) {
  var temp = [];
  var ownedVenues = [];
  const dispatch = useDispatch();
  var tempBookings = [];
  // console.log(props.venueid);
  props.venueid.map((id) => {
    setListener(id);
  });

  useEffect(() => {
    setTimeout(() => {
      dispatchToStore();
    }, 1000);
  }, [temp]);

  async function setListener(venueid) {
    const q = query(collection(db, "Venues"), where("venueid", "==", venueid));
    const unsubscribe = onSnapshot(q, async (querySnapshot) => {
      querySnapshot.forEach((doc) => {
        ownedVenues.push(doc.data());
      });
      temp = ownedVenues;

      //   console.log("dispatched", temp);
      //   return;
    });
  }

  async function dispatchToStore() {
    const tempOwnedVenueUniq = [...new Map(temp.map((e) => [e.venueid, e])).values()];
    dispatch(storeOwnedVenues(tempOwnedVenueUniq));
  }
}

// export function listenToOwnedVenuesBookings() {
//   const venueAccess = useSelector((state) => state.counter.venueAccess);
//   const bookingCollectionRef = collection(db, "Bookings");
//   const dispatch = useDispatch();
//   const [runDispatch, setRundDispatch] = useState(false);
//   let tempVenue = [];
//   let tempOwnedVenue = [];
//   let venueData = [];

//   // if (venueAccess === undefined || venueAccess === "none") return;

//   // first get owned venue data (all venues in venueAccess)
//   async function getVenueData(prop) {
//     await venueAccess.map(async (venueid, index) => {
//       var vData = await getDoc(doc(db, "Venues", venueid));
//       tempVenue = await vData.data();
//       venueData.push(tempVenue);
//       // console.log(await venueData);
//     });
//     setListener();
//   }

//   // then set listener for bookings and add to venue data in order to dispatch to "ownedVenues"
//   // async function setListener() {
//   //   var index = 0;
//   //   var i = 0;
//   //   {
//   //     venueData &&
//   //       // venueData.venueid && //removing the bug of not finding a venueid onload....
//   //       console.log("= = = = = = >>>", venueData);
//   //     venueData.map((venue) => {
//   //       const q = query(collection(db, "Bookings"), where("venueid", "===", venue.venueid));
//   //       const unsubscribe = onSnapshot(q, async (querySnapshot) => {
//   //         let bookings = [];
//   //         await querySnapshot.forEach(async (doc) => {
//   //           bookings.push(await doc.data());
//   //         });
//   //         await bookings.sort(function (a, b) {
//   //           return new Date(a.date) - new Date(b.date);
//   //         });
//   //         await tempOwnedVenue.push({ ...venue, bookings });
//   //         // console.log(tempOwnedVenue);
//   //       });
//   //     });
//   //   }
//   // }

//   // Chat-Gpts version of above
//   // (set listener for bookings and add to venue data in order to dispatch to "ownedVenues")
//   async function setListener() {
//     console.log("venueData: ", venueData);
//     const venueIds = venueData.map((venue) => venue.venueid);
//     const q = query(collection(db, "Bookings"), where("venueid", "in", venueIds));
//     const unsubscribe = onSnapshot(q, async (querySnapshot) => {
//       let tempOwnedVenue = [];
//       await Promise.all(
//         venueData.map(async (venue) => {
//           const bookings = querySnapshot.docs
//             .filter((doc) => doc.data().venueid === venue.venueid)
//             .map((doc) => doc.data())
//             .sort((a, b) => new Date(a.date) - new Date(b.date));
//           tempOwnedVenue.push({ ...venue, bookings });
//         })
//       );
//       console.log(tempOwnedVenue);
//     });
//   }

//   // Do stuff when parameters are ready and set
//   useEffect(() => {
//     async function getData() {
//       await getVenueData(setListener());
//       setTimeout(() => {
//         setListener();
//         // console.log(tempOwnedVenue);
//       }, 2000);
//       setTimeout(() => {
//         dispatchToStore();
//       }, 3000);
//     }
//     venueAccess !== "none" ? getData() : console.log("NOT YET");
//   }, [venueAccess]);

//   // dispatch to "ownedVenues"
//   async function dispatchToStore() {
//     const tempOwnedVenueUniq = [...new Map(tempOwnedVenue.map((e) => [e.venueid, e])).values()];
//     dispatch(storeOwnedVenues(tempOwnedVenueUniq));
//     // alert("owned Venues");
//   }
// }

export function listenToOwnedVenuesBookings() {
  const venueAccess = useSelector((state) => state.counter.venueAccess);
  const dispatch = useDispatch();
  let venueData = [];
  useEffect(() => {
    if (venueAccess[0] === "none") return;

    async function setListener() {
      // alla venues som användaren har access till.
      const venueIds = venueAccess;
      console.log("❌HÄR venueAccess", venueAccess);
      // hämta alla bokningar för alla venues som användaren har access till
      const q = query(collection(db, "Bookings"), where("venueid", "in", venueIds));
      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const tempOwnedVenue = venueData.map((venue) => {
          const bookings = querySnapshot.docs
            .filter((doc) => doc.data().venueid === venue.venueid)
            .map((doc) => doc.data())
            .sort((a, b) => new Date(a.date) - new Date(b.date));
          console.log("❌HÄR", ...venue, bookings);
          return { ...venue, bookings };
        });

        dispatchToStore(tempOwnedVenue);
      });
    }
    setListener();
  }, [venueAccess]);

  function dispatchToStore(tempOwnedVenue) {
    const tempOwnedVenueUniq = [...new Map(tempOwnedVenue.map((e) => [e.venueid, e])).values()];
    console.log("tempOwnedVenueUniq", tempOwnedVenueUniq);
    console.log("tempOwnedVenue", tempOwnedVenue);
    dispatch(storeOwnedVenues(tempOwnedVenueUniq));
  }

  const ownedVenues = useSelector((state) => state.counter.ownedVenues);

  useEffect(() => {
    if (ownedVenues.length === 0 || ownedVenues[0] === "none") return;
    console.log("OwnedVenues", ownedVenues);
  }, [ownedVenues]);
}

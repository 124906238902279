/** @format */

import React from "react";
import { calculateBookingPrice } from "../MiscUIComponents/calculateBookingPrice";
import { arrayUnion, collection, doc, getDoc, getDocs, query, setDoc, updateDoc, where } from "firebase/firestore";
import { db } from "../../firebase";
import { removePercentage } from "../../helpers/Numbers";
import store from "../../app/store";

const OrderChanges = async (agreementId) => {
  alert("running OrderChanges");
  let agreement = {};
  let settlementPosts = [];
  let bookings = [];

  // Hämta alla bokningar som hör till avtalet (för att kunna lägga till datum i förändringarna)
  await getBookingsByAgreementId();

  // Hämta agreement
  await getAgreement(agreementId);
  if (!agreement.versions) return;
  if (agreement.versions.length < 2) {
    alert("nothing to compare");
    return;
  }

  async function getAgreement() {
    if (!agreementId) return;
    const docSnap = await getDoc(doc(db, "Agreements", agreementId));
    agreement = docSnap.data();
    console.log("agreement", agreement);
    // return docSnap.data();
  }

  // Använd de två senaste versionerna av avtalet
  let versions = [];
  versions = (agreement.versions && Object.values(agreement.versions)).slice(-2); // Only compare the last two versions
  // console.log("versions", versions);

  // Hämta alla bokningar i de två versionerna och spara "selected" options i en array för gamla och nya versionen
  let selectedOptionsInVersions = [];
  let bookingIdsOld = versions[0] && Object.keys(versions[0].featuresInBookings);
  let bookingIdsNew = versions[1] && Object.keys(versions[1].featuresInBookings);
  let bookingIds = [];
  bookingIds[0] = bookingIdsOld;
  bookingIds[1] = bookingIdsNew;
  // console.log("bookingIds", bookingIds);

  let differencePerBooking = [];
  let pricePerBooking = [];

  versions.map((version, versionIndex) => {
    let timestamp = version.timestamp;
    console.log("timestamp", timestamp);
  });

  let selectedOptionsPerBooking = [];
  // Loop through the (two latest) versions
  versions.map((version, versionIndex) => {
    let timestamp = version.timestamp;
    pricePerBooking[versionIndex] = [];
    selectedOptionsPerBooking[versionIndex] = [];
    // Check each booking for each version
    bookingIds[versionIndex].map((bookingId) => {
      let bookingFeatures = versions[versionIndex].featuresInBookings[bookingId];
      selectedOptionsPerBooking[versionIndex][bookingId] = [];
      // check each feature in the booking
      Object.values(bookingFeatures).map((feature) => {
        // check each option in the feature
        Object.values(feature.options).map((option) => {
          selectedOptionsPerBooking[versionIndex][bookingId][option.optionId] = [];
          let price = option.price;
          option.selected &&
            selectedOptionsPerBooking[versionIndex][bookingId][option.optionId].push({
              selected: option.selected,
              price: option.price,
              vat: option.vat,
              quantity: option.quantity,
              each: option.each,
              option: option.option,
              optionId: option.optionId,
            });
        });
      });
    });
  });
  console.log("selectedOptionsPerBooking", selectedOptionsPerBooking);
  console.log("Price per Booking", pricePerBooking);
  console.log("differencePerBooking", differencePerBooking);
  console.log("bookingIds", bookingIds);

  // Jämför de två versionerna av avtalet genom att jämföra valda options i varje bokning.
  let result = []; // The result array
  await bookingIds[1].map((bookingId) => {
    result[bookingId] = [{}]; // Initialize the result array
    compareObjects(bookingId, selectedOptionsPerBooking[0][bookingId], selectedOptionsPerBooking[1][bookingId]);
  });

  console.log("result", result);
  console.log("settlementPosts", settlementPosts);
  // Returnera avtalsförändringar med specifikation om förändringen.
  let orderChanges = [];
  let sumOfChanges = 0;
  settlementPosts.map((post) => {
    sumOfChanges += Number(post.sum) + Number(post.vatSum);
    orderChanges.push(post);
    console.log(
      `Type: ${post.type}, Quantity: ${post.quantity}, Each: ${post.cost}, Vat: ${post.vat}, Sum: ${Number(post.sum) + Number(post.vatSum)}`
    );
  });

  storeWhatsChangedInAgreement();

  settlementPosts["sumOfChanges"] = sumOfChanges; // Add the total sum of changes to the settlementPosts array
  return settlementPosts;
  // storeSettlementPosts(settlementPosts); // Store the settlementPosts in the database to be shown in the settlement view (BookingSummary)

  // Store the changes in the agreement in order to keep track of the changes.
  async function storeWhatsChangedInAgreement() {
    let tmp = (agreement.whatsChanged && JSON.parse(JSON.stringify(agreement.whatsChanged))) || [];
    let now = new Date(Date.now()).toLocaleString("sv-SE", { timeZone: "Europe/Stockholm" });
    tmp.push({ timestamp: now, changes: settlementPosts || [] });
    await setDoc(doc(db, "Agreements", agreementId), { whatsChanged: tmp }, { merge: true });
  }

  async function storeSettlementPosts() {
    settlementPosts.forEach(async (post) => {
      console.log("post", post);

      await updateDoc(doc(db, "Bookings", post.bookingId), {
        summary: arrayUnion(post),
      });
    });
  }

  function compareObjects(bookingId, obj1, obj2) {
    // Gå igenom samtliga feature options
    for (let key in obj2) {
      const optionBefore = obj1[key][0];
      const optionNow = obj2[key][0];

      // Om produkten inte fanns med innan, men finns nu
      if (!optionBefore && optionNow) {
        result[bookingId].push({
          [key]: {
            option: optionNow.option,
            difference: optionNow.price,
            each: optionNow.each,
            quantity: optionNow.quantity,
            vat: optionNow.vat,
          },
        });
        // Lägg till i settlementPosts
        settlementPosts.push({
          bookingId: bookingId,
          date: bookings.find((booking) => booking.bookingid === bookingId).date,
          cost: optionNow.each === undefined ? optionNow.price : optionNow.each,
          quantity: optionNow.quantity === undefined ? 1 : optionNow.quantity === 0 ? 1 : optionNow.quantity,
          sum: optionNow.price,
          vat: optionNow.vat,
          vatSum: Number(optionNow.price) * (removePercentage(optionNow.vat) / 100),
          type: optionNow.option,
        });
      }

      // Om produkten fanns med innan men inte finns nu
      // Se upp så vi inte tar bort en kostnad som inte betalats. Dvs den här ska bara läggga till en minuspost om det är en kostnad som redan betalats.
      if (optionBefore && !optionNow) {
        result[bookingId].push({
          [key]: {
            option: optionBefore.option,
            difference: -optionBefore.price,
            each: optionBefore.each,
            quantity: -optionBefore.quantity,
            vat: optionBefore.vat,
          },
        });
        // Lägg till i settlementPosts
        settlementPosts.push({
          bookingId: bookingId,
          date: bookings.find((booking) => booking.bookingid === bookingId).date,
          cost: optionBefore.each === undefined ? -optionBefore.price : -optionBefore.each,
          sum: -optionBefore.price,
          vat: optionBefore.vat,
          quantity: optionBefore.quantity === undefined ? -1 : -optionBefore.quantity,
          vatSum: -Number(optionBefore.price) * (removePercentage(optionBefore.vat) / 100),
          type: optionBefore.option,
        });
      }

      // Om produkten fanns med innan och finns nu men förändrats
      if (optionBefore && optionNow) {
        if (Number(optionBefore.price) !== Number(optionNow.price)) {
          result[bookingId].push({
            [key]: {
              option: optionNow.option,
              difference: Number(optionNow.price) - Number(optionBefore.price),
              each: optionNow.each,
              quantity: optionNow.quantity - optionBefore.quantity,
              vat: optionNow.vat,
            },
          });
          // Lägg till i settlementPosts
          settlementPosts.push({
            bookingId: bookingId,
            date: bookings.find((booking) => booking.bookingid === bookingId).date,
            cost: optionNow.each,
            sum: Number(optionNow.price) - Number(optionBefore.price),
            vat: optionNow.vat,
            quantity: optionNow.quantity - optionBefore.quantity,
            vatSum: (Number(optionNow.price) - Number(optionBefore.price)) * (removePercentage(optionNow.vat) / 100),
            type: optionNow.option,
          });
        }
      }
    }

    // Om bokning fanns förut men nu har tagits bort
    //  Jämför bookingIds[0] och bookingIds[1] för att se om någon bokning har tagits bort
    // if (!bookingIds[0].includes(bookingId)) {
    //   result[bookingId].push({ booking: "removed" });
    // }

    return result;
  }

  async function getBookingsByAgreementId() {
    let tmp = [];
    const querySnapshot = await getDocs(query(collection(db, "Bookings"), where("agreementId", "==", agreementId)));
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      //   console.log(doc.id, " => ", doc.data());
      tmp.push({ id: doc.id, ...doc.data() });
    });
    bookings = tmp;
  }

  // return <div>{orderChanges}</div>;
};

export default OrderChanges;
